import { graphQLMutationWithAuth, Patch, SharedState } from "@rvs/client-infra";
import { Cmd } from "@typescript-tea/core";
import { v4 as uuid } from "uuid";
import { Project } from "@rvs/shared";
import { Action, calculateSystem, createRoomUpdateMutations, State } from "../state";
import * as GQLOps from "../../../../generated/generated-operations";
import * as M from "../mutations";

export function CreateRoom(
  action: ReturnType<typeof Action.CreateRoom>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;

  const roomTables = getRoomTables(state);
  if (!roomTables) {
    return [state];
  }
  const systemId = action.systemId;
  const newRoom: Project.Room = Project.createRoom(
    roomTables.roomFloorTable,
    roomTables.roomTemplatesTable,
    roomTables.marketValveTypes,
    sharedState.market.name,
    0,
    sharedState.translate
  );
  const {
    updatedProject: projectWithNewRoom,
    updatedNewRoom,
    patches: addPatches,
  } = Project.addRoom(project, systemId, { type: "last" }, newRoom);
  const { updatedCalcState, updatedProject, roomPatches } = calculateSystem(
    sharedState.market.name,
    state.metaProduct,
    state.materialTables,
    projectWithNewRoom,
    state.calculationStates,
    systemId
  );
  const { patchedNewRoom, otherRoomsPatches } = preApplyNewRoomPatches(updatedNewRoom, roomPatches);
  const newRoomCmd = graphQLMutation<
    GQLOps.ProjectState_CreateRoomMutation,
    GQLOps.ProjectState_CreateRoomMutationVariables,
    Action
  >(
    M.createRoomMutation,
    {
      input: { ...patchedNewRoom, systemId },
    },
    sharedState.market.name,
    Action.NoOp
  );
  const roomMutationCmds = createRoomUpdateMutations(
    graphQLMutation,
    [...otherRoomsPatches, ...addPatches],
    sharedState.market.name,
    systemId
  );
  return [
    { ...state, calculationStates: updatedCalcState, project: updatedProject },
    Cmd.batch([newRoomCmd, roomMutationCmds]),
  ];
}

export function UpdateRoom(
  action: ReturnType<typeof Action.UpdateRoom>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;
  const system = project.systems.find((s) => s.id === action.systemId);
  if (!system) {
    return [state];
  }
  const room = system.rooms.find((r) => r.id === action.patch.id);
  if (!room) {
    return [state];
  }
  const updatedRoom: Project.Room = { ...room, ...action.patch };
  const projectWithUpdatedRoom = Project.replaceRoom(project, action.systemId, updatedRoom);
  const { updatedCalcState, updatedProject, roomPatches } = calculateSystem(
    sharedState.market.name,
    state.metaProduct,
    state.materialTables,
    projectWithUpdatedRoom,
    state.calculationStates,
    action.systemId
  );
  return [
    { ...state, calculationStates: updatedCalcState, project: updatedProject },
    createRoomUpdateMutations(graphQLMutation, [action.patch, ...roomPatches], system.id, sharedState.market.name),
  ];
}

export function UpdateRoomSo(
  action: ReturnType<typeof Action.UpdateRoomSo>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;
  const system = project.systems.find((s) => s.id === action.systemId);

  if (!system) {
    return [state];
  }

  let updatedProject = project;

  for (const roomPatch of action.roomList) {
    const room = system.rooms.find((r) => r.id === roomPatch.id);
    if (!room) {
      return [state];
    }

    const updatedRoom: Project.Room = { ...room, sortNo: roomPatch.sortNo };

    updatedProject = Project.replaceRoom(updatedProject, action.systemId, updatedRoom);
  }

  return [
    { ...state, project: updatedProject },
    createRoomUpdateMutations(graphQLMutation, [...action.roomList], system.id, sharedState.market.name),
  ];
}

export function DuplicateRoom(
  action: ReturnType<typeof Action.DuplicateRoom>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;
  const system = project.systems.find((s) => s.id === action.systemId);
  if (!system) {
    return [state];
  }
  const currentRoom = system.rooms.find((r) => r.id === action.roomId);
  if (!currentRoom) {
    return [state];
  }
  const newRoom: Project.Room = {
    ...currentRoom,
    id: uuid(),
  };
  const {
    updatedProject: projectWithDuplicatedRoom,
    updatedNewRoom,
    patches: addPatches,
  } = Project.addRoom(project, system.id, { type: "after", roomId: currentRoom.id }, newRoom);
  const { updatedCalcState, updatedProject, roomPatches } = calculateSystem(
    sharedState.market.name,
    state.metaProduct,
    state.materialTables,
    projectWithDuplicatedRoom,
    state.calculationStates,
    system.id
  );
  const { patchedNewRoom, otherRoomsPatches } = preApplyNewRoomPatches(updatedNewRoom, roomPatches);
  const newRoomCmd = graphQLMutation<
    GQLOps.ProjectState_CreateRoomMutation,
    GQLOps.ProjectState_CreateRoomMutationVariables,
    Action
  >(
    M.createRoomMutation,
    {
      input: { ...patchedNewRoom, systemId: system.id },
    },
    sharedState.market.name,
    Action.NoOp
  );

  return [
    { ...state, calculationStates: updatedCalcState, project: updatedProject },
    Cmd.batch([
      newRoomCmd,
      createRoomUpdateMutations(
        graphQLMutation,
        [...otherRoomsPatches, ...addPatches],
        system.id,
        sharedState.market.name
      ),
    ]),
  ];
}

export function RemoveRoom(
  action: ReturnType<typeof Action.RemoveRoom>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;

  const system = project.systems.find((s) => s.id === action.systemId);
  if (!system) {
    return [state];
  }
  const projectWithoutRoom = Project.removeRoom(project, action.systemId, action.roomId);
  const { updatedCalcState, updatedProject, roomPatches } = calculateSystem(
    sharedState.market.name,
    state.metaProduct,
    state.materialTables,
    projectWithoutRoom,
    state.calculationStates,
    action.systemId
  );
  const removeRemoveCmd = graphQLMutation<
    GQLOps.ProjectState_RemoveRoomMutation,
    GQLOps.ProjectState_RemoveRoomMutationVariables,
    Action
  >(
    M.removeRoomMutation,
    {
      input: {
        id: action.roomId,
      },
    },
    sharedState.market.name,
    Action.NoOp
  );
  const updateRoomMutations = createRoomUpdateMutations(
    graphQLMutation,
    roomPatches,
    system.id,
    sharedState.market.name
  );
  return [
    { ...state, calculationStates: updatedCalcState, project: updatedProject },
    Cmd.batch([removeRemoveCmd, updateRoomMutations]),
  ];
}

function getRoomTables(pageState: State):
  | {
      readonly roomTemplatesTable: Project.RoomTemplatesTable;
      readonly roomFloorTable: Project.RoomFloorTable;
      readonly marketValveTypes: Project.MarketValveTypesTable;
    }
  | undefined {
  const roomTemplatesTable = pageState.metaProduct.product?.modules.custom_tables.RoomTemplates;
  const roomFloorTable = pageState.metaProduct.product?.modules.custom_tables.RoomFloor;
  const marketValveTypes = pageState.metaProduct.product?.modules.custom_tables.MarketValveTypes;
  if (!roomTemplatesTable || !roomFloorTable || !marketValveTypes) {
    return undefined;
  }
  return { roomTemplatesTable, roomFloorTable, marketValveTypes };
}

function preApplyNewRoomPatches(
  newRoom: Project.Room,
  roomPatches: ReadonlyArray<Patch<Project.Room>>
): { readonly patchedNewRoom: Project.Room; readonly otherRoomsPatches: ReadonlyArray<Patch<Project.Room>> } {
  const newRoomPatches = roomPatches.filter((p) => p.id === newRoom.id);
  const otherRoomsPatches = roomPatches.filter((p) => p.id !== newRoom.id);
  const patchedNewRoom = newRoomPatches.reduce<Project.Room>((room, patch) => ({ ...room, ...patch }), newRoom);
  return { patchedNewRoom, otherRoomsPatches };
}
