import { Texts } from "@rvs/shared";
import { texts } from "@rvs/shared/src/lang-texts";
import * as React from "react";
import { Icon } from "./icon";

interface Props {
  readonly header: React.ReactNode;
  readonly extraHeader?: React.ReactNode;
  readonly closedContent?: React.ReactNode;
  readonly children?: React.ReactNode;
  readonly closed?: boolean;
  readonly onToggleClosed: () => void;
  readonly draggable?: boolean | React.HTMLAttributes<HTMLDivElement>;
  readonly onDragStart?: () => void;
  readonly onDragEnter?: () => void;
  readonly onDragEnd?: () => void;
  readonly onDragOver?: () => void;
  readonly translate: Texts.TranslateFn;
}

export function Expander(props: Props): React.ReactElement<Props> {
  const {
    header,
    extraHeader,
    children,
    closed,
    onToggleClosed,
    closedContent,
    onDragStart,
    onDragEnter,
    onDragEnd,
    onDragOver,
  } = props;
  const closable = closed !== undefined;

  return (
    <div className="flex flex-col space-y-8 p-8">
      {closable ? (
        <div className="flex items-center space-x-24">
          <a onClick={() => onToggleClosed()}>
            <div className="text-md cursor-pointer">
              <div className="flex flex-row space-x-8 items-center">
                {closed ? (
                  <>
                    <Icon className="mx-8 w-16 text-base ml-32" icon="chevron-right" size={"sm"} />
                  </>
                ) : (
                  <>
                    <Icon className="mx-8 w-16 text-base ml-32" icon="chevron-down" size={"sm"} />
                  </>
                )}
                {typeof header === "string" ? (
                  <>
                    <div
                      className={"dragInfo"}
                      draggable
                      onDragStart={onDragStart}
                      onDragEnter={onDragEnter}
                      onDragEnd={onDragEnd}
                      onDragOver={(e) => {
                        if (onDragOver === undefined) {
                          return;
                        }
                        e.preventDefault();
                        onDragOver();
                      }}
                      title={props.translate(texts.drag_and_drop_info_system)}
                    >
                      {header}
                    </div>

                    <div className="hide">
                      <Icon className="mx-8 w-16 text-base" icon="arrow-up-arrow-down" size={"sm"} />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </a>
          {typeof header !== "string" ? header : null}
          {extraHeader}
        </div>
      ) : (
        <div className="text-md">
          <div className="flex flex-row space-x-8">
            <span>{header}</span>
            <Icon size={"sm"} className="mx-8 w-16 text-base" icon="arrow-up-arrow-down" />
            {extraHeader}
          </div>
        </div>
      )}
      {!closed && children}
      {closed && closedContent}
    </div>
  );
}
