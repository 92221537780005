import * as Gql from "../../generated/generated-operations";

export type CategoriesRow = Pick<Gql.Categories, "sort_no" | "group" | "category">;

export interface Categories {
  readonly sortOrder: ReadonlyMap<string, number>;
}

export function createCategories(categoriesTable: ReadonlyArray<CategoriesRow>): Categories {
  const sortOrder = new Map(
    categoriesTable.map((row) => [createCategoryName(row.group || "", row.category || ""), row.sort_no || 0])
  );
  return {
    sortOrder,
  };
}

export function getSortOrder(categories: Categories, categoryName: string, defaultSortNo: number): number {
  return categories.sortOrder.get(categoryName) ?? defaultSortNo;
}

export function createCategoryName(group: string, category: string | undefined): string {
  return `${group}${category ? `_${category}` : ""}`;
}
