import gql from "graphql-tag";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { v4 as uuid } from "uuid";
import { saveAs } from "file-saver";
import { SharedState, Routes, graphQLMutationWithAuth, HttpFetch, NavigationEffectManager } from "@rvs/client-infra";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { Gaeb, UserSettings } from "@rvs/shared";
import * as GQLOps from "../../../../generated/generated-operations";
import * as M from "./mutations";
import * as ProjectState from "../../project-state";
import { clientConfig } from "../../../../client-config";

const queryMetaCountries = gql`
  query queryMetaCountries($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        custom_tables {
          Countries {
            name
          }
        }
      }
    }
  }
`;

// STATE

export interface State {
  readonly savedTemplateId: string | undefined;
  readonly createdFromTemplateProjectId: "waiting" | string | undefined;
  readonly confirmUnlock: boolean;
  readonly gaebFormat: string | undefined;
  readonly countries: ReadonlyArray<string> | undefined;
}

export const Action = ctorsUnion({
  CreateFromTemplate: () => ({}),
  CreatedFromTemplate: (response: GQLOps.GeneralProject_CreateProjectFromTemplateMutation) => ({ response }),
  UnlockProject: (state: "confirm" | "done") => ({ state }),
  SaveAsTemplate: () => ({}),
  TemplateSaved: (response: GQLOps.GeneralProject_SaveProjectAsTemplateMutation) => ({ response }),
  ExportToGaeb: () => ({}),
  HandleGaebExport: (response: Gaeb.Response, gaebFormat: Gaeb.FormatOption) => ({ response, gaebFormat }),
  SetGaebFormat: (format: string | undefined) => ({ format }),
  NoOp: () => ({}),
  DataResponseRecieved: (data: GQLOps.QueryMetaCountriesQuery) => ({ data }),
});

export type Action = CtorsUnion<typeof Action>;

export function init(
  _location: Routes.ProjectLocation,
  sharedState: SharedState.SharedState,
  prevState: State | undefined,
  projectId: string
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  if (prevState) {
    return [
      {
        ...prevState,
        savedTemplateId: prevState.savedTemplateId === projectId ? undefined : prevState.savedTemplateId,
        createdFromTemplateProjectId:
          prevState.createdFromTemplateProjectId === projectId ? undefined : prevState.createdFromTemplateProjectId,
      },
    ];
  } else {
    const countriesCmd = sharedState.graphQLProductQuery<
      GQLOps.QueryMetaCountriesQuery,
      GQLOps.QueryMetaCountriesQueryVariables,
      Action
    >(
      queryMetaCountries,
      {
        productId: clientConfig.promaster_meta_id,
      },
      (data) => Action.DataResponseRecieved(data)
    );
    return [
      {
        savedTemplateId: undefined,
        createdFromTemplateProjectId: undefined,
        confirmUnlock: false,
        gaebFormat: "gaebxml32",
        countries: undefined,
      },
      countriesCmd,
    ];
  }
}

export function update(
  action: Action,
  state: State,
  projectState: ProjectState.State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const { project } = projectState;
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  switch (action.type) {
    case "SaveAsTemplate": {
      const cmd = graphQLMutation<
        GQLOps.GeneralProject_SaveProjectAsTemplateMutation,
        GQLOps.GeneralProject_SaveProjectAsTemplateMutationVariables,
        Action
      >(
        M.saveProjectAsTemplateMutation,
        {
          input: {
            id: project.id,
            templateId: uuid(),
          },
        },
        sharedState.market.name,
        Action.TemplateSaved
      );
      return [state, cmd];
    }

    case "TemplateSaved": {
      return [{ ...state, savedTemplateId: action.response.saveProjectAsTemplate.id }];
    }

    case "CreateFromTemplate": {
      if (project.template) {
        return [
          { ...state, createdFromTemplateProjectId: "waiting" },
          graphQLMutation<
            GQLOps.GeneralProject_CreateProjectFromTemplateMutation,
            GQLOps.GeneralProject_CreateProjectFromTemplateMutationVariables,
            Action
          >(
            M.createProjectFromTemplateMutation,
            {
              input: {
                id: uuid(),
                templateId: project.id,
              },
            },
            sharedState.market.name,
            Action.CreatedFromTemplate
          ),
        ];
      } else {
        return [state];
      }
    }

    case "CreatedFromTemplate": {
      const newUrl = Routes.buildUrl(
        Routes.RootLocation.MainLocation(
          Routes.MainLocation.Project(action.response.createProjectFromTemplate.id, Routes.ProjectLocation.General())
        )
      );
      return [state, NavigationEffectManager.pushUrl(newUrl)];
    }

    case "UnlockProject": {
      switch (action.state) {
        case "confirm":
          return [{ ...state, confirmUnlock: true }];
        case "done":
          return [{ ...state, confirmUnlock: false }];
        default:
          return [state];
      }
    }

    case "ExportToGaeb": {
      const gaebExportInfo = Gaeb.getExportInfo(project, state.gaebFormat, sharedState.translate);
      if (!gaebExportInfo || !Gaeb.isExportInfoOk(gaebExportInfo)) {
        return [state];
      }

      const req: Gaeb.Request = {
        market: sharedState.market.name,
        locale: UserSettings.selectLanguage(sharedState.userSettings),
        project: project.id,
        systems: project.systems.map((s) => s.id),
        gaebInfo: gaebExportInfo,
      };
      return [
        { ...state },
        HttpFetch.postWithAuth(sharedState.activeUser)(
          {},
          "/rest/gaeb/exportProject",
          "json",
          "application/json",
          JSON.stringify(req),
          (data) => Action.HandleGaebExport(data as unknown as Gaeb.Response, gaebExportInfo.gaebformat)
        ),
      ];
    }

    case "SetGaebFormat": {
      return [{ ...state, gaebFormat: action.format }];
    }

    case "HandleGaebExport": {
      saveAs(
        new Blob([action.response.message], {
          type: action.gaebFormat.mime,
        }),
        `${project.name || "geab"}.${action.gaebFormat.extension}`
      );
      return [state];
    }

    case "NoOp": {
      return [state];
    }

    case "DataResponseRecieved": {
      return [{ ...state, countries: action.data.product?.modules.custom_tables.Countries.map((e) => e.name || "") }];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}
