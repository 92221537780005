import { airflowUnits, areaUnits, lengthUnits, volumeUnits, dimensionlessPerDurationUnits } from "./units";

export const Quantity = {
  Airflow: { type: "airflow", units: airflowUnits } as const,
  Length: { type: "length", units: lengthUnits } as const,
  Area: { type: "area", units: areaUnits } as const,
  Volume: { type: "volume", units: volumeUnits } as const,
  DimensionlessPerDuration: { type: "dimensionless_per_duration", units: dimensionlessPerDurationUnits } as const,
};

export type Quantities = typeof Quantity[keyof typeof Quantity];

export type QuantityTypes = Quantities["type"];
