import { TextKey } from "../../lang-texts";
import { Room, System } from "../types";

export type Error<T> = {
  readonly property: keyof T;
  readonly message: TextKey;
};

export type ErrorMap<T> = { readonly [id: string]: ReadonlyArray<Error<T>> };

export type RoomError = Error<Room>;
export type SystemError = Error<System>;

export type RoomErrors = ErrorMap<Room>;
export type SystemErrors = ErrorMap<System>;

export interface ProjectValidation {
  readonly roomErrors: ErrorMap<Room>;
  readonly systemErrors: ErrorMap<System>;
}

export interface SystemValidation {
  readonly roomErrors: ErrorMap<Room>;
  readonly systemErrors: ReadonlyArray<SystemError>;
}
