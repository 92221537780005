import * as AI from "abstract-image";
import { AbstractDoc as AD } from "abstract-document";

export function createNote(): AD.ImageResource.ImageResource {
  const infoWidth = 6;
  const infoHeight = 27;
  const infoPadding = 3;
  const infoPaddingTop = 13;
  const infoStrokeWidth = 3;
  const infoBetween = 4;
  const fullInfoWidth = infoWidth + infoPadding * 2 + infoStrokeWidth * 2;
  const fullInfoHeight = infoPaddingTop * 2 + infoHeight + infoWidth + infoBetween;

  const components = [
    createRectangle({
      x: 0,
      y: 0,
      width: fullInfoWidth,
      height: fullInfoHeight,
      color: AI.fromArgb(0xff, 200, 0, 0),
      strokeWidth: infoStrokeWidth,
    }),
    createRectangle({
      x: infoStrokeWidth + infoPadding,
      y: infoPaddingTop,
      width: infoWidth,
      height: infoWidth,
      color: AI.fromArgb(0xff, 255, 0, 0),
      background: AI.fromArgb(0xff, 240, 0, 0),
    }),
    createRectangle({
      x: infoStrokeWidth + infoPadding,
      y: infoPaddingTop + infoWidth + infoBetween,
      width: infoWidth,
      height: infoHeight,
      color: AI.fromArgb(0xff, 255, 0, 0),
      background: AI.fromArgb(0xff, 240, 0, 0),
    }),
  ];

  return AD.ImageResource.create({
    id: "0",
    abstractImage: AI.createAbstractImage(
      AI.createPoint(0, 0),
      AI.createSize(fullInfoWidth, fullInfoHeight),
      AI.white,
      components
    ),
  });
}

export function createRectangle(props: {
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
  readonly color?: AI.Color;
  readonly strokeWidth?: number;
  readonly background?: AI.Color;
}): AI.Rectangle {
  return AI.createRectangle(
    AI.createPoint(props.x, props.y),
    AI.createPoint(props.x + props.width, props.y + props.height),
    props.color ?? AI.black,
    props.strokeWidth ?? 1,
    props.background ?? AI.white
  );
}

export function createText(props: {
  readonly x: number;
  readonly y: number;
  readonly text: string;
  readonly fontSize?: number;
  readonly textColor?: AI.Color;
  readonly fontFamily?: string;
  readonly fontWeight?: AI.AbstractFontWeight;
  readonly clockwiseRotationDegrees?: number;
  readonly textAlignment?: AI.TextAlignment;
  readonly horizontalGrowthDirection?: AI.GrowthDirection;
  readonly verticalGrowthDirection?: AI.GrowthDirection;
  readonly strokeThickness?: number;
  readonly strokeColor?: AI.Color;
  readonly italic?: boolean;
}): AI.Text {
  return AI.createText(
    AI.createPoint(props.x, props.y),
    props.text,
    props.fontFamily ?? "DaxlineOffcPro",
    props.fontSize ?? 10,
    props.textColor ?? AI.black,
    props.fontWeight ?? "normal",
    props.clockwiseRotationDegrees ?? 0,
    props.textAlignment ?? "center",
    props.horizontalGrowthDirection ?? "uniform",
    props.verticalGrowthDirection ?? "uniform",
    props.strokeThickness ?? 0,
    props.strokeColor ?? AI.black,
    props.italic ?? false
  );
}
