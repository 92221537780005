import gql from "graphql-tag";

export const createSystemMutation = gql`
  mutation ProjectState_createSystem($input: CreateSystemInput!) {
    createSystem(input: $input) {
      system {
        id
        name
      }
    }
  }
`;

export const duplicateSystemMutation = gql`
  mutation ProjectState_duplicateSystem($input: DuplicateSystemInput!) {
    duplicateSystem(input: $input) {
      system {
        id
        sortNo
        name
        pipeDiameter
        totalArea
        airChangeRate
        rooms {
          id
          sortNo
          name
          building
          floor
          apartment
          roomType
          airType
          floorType
          supplyAirFactor
          airflow
          ceilingHeight
          floorArea
          width
          length
          volume
          people
          valves
          ducts
          valveType
          customAirFlow
          customValves
        }
        materials {
          id
          sortNo
          itemNumber
          quantity
          singleCost
          singleSalesPrice
          singleNetPrice
          currency
          included
          type
          name
          packageName
        }
        ventilationConcept {
          id
          name
          totalArea
          totalVolume
          occupancy
          windowlessRooms
          furnace
          higherRequirementsSound
          higherRequirementsAirQuality
          higherRequirementsEnergyEfficiency
          requiredAirflow
          infiltrationAirflow
          airflowStatus
          higherRequirementsStatus
          windowlessRoomsStatus
          furnaceStatus
          finalStatus
        }
      }
    }
  }
`;

export const createRoomMutation = gql`
  mutation ProjectState_createRoom($input: CreateRoomInput!) {
    createRoom(input: $input) {
      id
    }
  }
`;

export const removeSystemMutation = gql`
  mutation ProjectState_removeSystem($input: RemoveSystemInput!) {
    removeSystem(input: $input) {
      id
    }
  }
`;

export const removeRoomMutation = gql`
  mutation ProjectState_removeRoom($input: RemoveRoomInput!) {
    removeRoom(input: $input) {
      id
    }
  }
`;

export const updateProjectMutation = gql`
  mutation ProjectState_updateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
    }
  }
`;

export const updateRoomMutation = gql`
  mutation ProjectState_updateRoom($input: UpdateRoomInput!) {
    updateRoom(input: $input) {
      id
    }
  }
`;

export const updateRoomsMutation = gql`
  mutation ProjectState_updateRooms($input: UpdateRoomsInput!) {
    updateRooms(input: $input) {
      id
    }
  }
`;

export const updateSystemMutation = gql`
  mutation ProjectState_updateSystem($input: UpdateSystemInput!) {
    updateSystem(input: $input) {
      id
    }
  }
`;

export const removeProjectMutation = gql`
  mutation ProjectState_removeProject($input: RemoveProjectInput!) {
    removeProject(input: $input) {
      id
    }
  }
`;

export const updateProjectLockStateMutation = gql`
  mutation ProjectState_updateProjectLockState($input: UpdateProjectLockStateInput!) {
    updateProjectLockState(input: $input) {
      id
      locked
      permissions
    }
  }
`;

export const createProjectShareMutation = gql`
  mutation ProjectState_createProjectShare($input: CreateProjectShareInput!) {
    createProjectShare(input: $input) {
      projectShare {
        id
      }
    }
  }
`;

export const removeShareShareMutation = gql`
  mutation ProjectState_removeShare($input: RemoveProjectShareInput!) {
    removeProjectShare(input: $input) {
      id
    }
  }
`;

export const saveProjectAsTemplateMutation = gql`
  mutation ProjectState_saveProjectAsTemplate($input: SaveProjectAsTemplateInput!) {
    saveProjectAsTemplate(input: $input) {
      id
    }
  }
`;

export const createProjectFromTemplateMutation = gql`
  mutation ProjectState_createProjectFromTemplate($input: CreateProjectFromTemplateInput!) {
    createProjectFromTemplate(input: $input) {
      id
    }
  }
`;

export const createVentilationConceptProjectMutation = gql`
  mutation ProjectState_createVentilationConceptProject($input: CreateVentilationConceptProjectInput!) {
    createVentilationConceptProject(input: $input) {
      id
    }
  }
`;

export const updateVentilationConceptProjectMutation = gql`
  mutation ProjectState_updateVentilationConceptProject($input: UpdateVentilationConceptProjectInput!) {
    updateVentilationConceptProject(input: $input) {
      id
    }
  }
`;

export const createVentilationConceptSystemMutation = gql`
  mutation ProjectState_createVentilationConceptSystem($input: CreateVentilationConceptSystemInput!) {
    createVentilationConceptSystem(input: $input) {
      id
    }
  }
`;

export const updateVentilationConceptSystemMutation = gql`
  mutation ProjectState_updateVentilationConceptSystem($input: UpdateVentilationConceptSystemInput!) {
    updateVentilationConceptSystem(input: $input) {
      id
    }
  }
`;

export const createMaterialsMutation = gql`
  mutation ProjectState_createMaterials($input: CreateMaterialsInput!) {
    createMaterials(input: $input) {
      ids
    }
  }
`;

export const updateMaterialsMutation = gql`
  mutation ProjectState_updateMaterials($input: UpdateMaterialsInput!) {
    updateMaterials(input: $input) {
      ids
    }
  }
`;

export const removeMaterialsMutation = gql`
  mutation ProjectState_removeMaterials($input: RemoveMaterialsInput!) {
    removeMaterials(input: $input) {
      ids
    }
  }
`;

export const updateSystemPricesMutation = gql`
  mutation ProjectState_updateSystemPrices($input: UpdateSystemPricesInput!) {
    updateSystemPrices(input: $input) {
      id
      status
      updatedMaterials {
        id
        singleCost
        singleSalesPrice
        singleNetPrice
        currency
      }
      debugData
    }
  }
`;

export const updateCustomMaterialMutation = gql`
  mutation ProjectState_updateCustomMaterial($input: UpdateCustomMaterialInput!) {
    updateCustomMaterial(input: $input) {
      systemId
      materialId
      status
      updatedMaterial {
        id
        singleCost
        singleSalesPrice
        singleNetPrice
        currency
        name
      }
      debugData
    }
  }
`;

export const createQuoteRequestMutation = gql`
  mutation ProjectState_createQuoteRequest($input: CreateQuoteRequestInput!) {
    createQuoteRequest(input: $input) {
      quoteRequestSent
      quoteRequest {
        id
        market
        requestedBy
        requestDate
      }
    }
  }
`;

export const createSystemPdf = gql`
  mutation ProjectState_createSystemPdf($input: CreateSystemPdfInput!) {
    createSystemPdf(input: $input) {
      id
    }
  }
`;

export const updateSystemPdf = gql`
  mutation ProjectState_updateSystemPdf($input: UpdateSystemPdfInput!) {
    updateSystemPdf(input: $input) {
      id
    }
  }
`;

export const removeSystemPdf = gql`
  mutation ProjectState_removeSystemPdf($input: RemoveSystemPdfInput!) {
    removeSystemPdf(input: $input) {
      id
    }
  }
`;
