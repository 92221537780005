import * as React from "react";
import { Icon, Textfield } from ".";

export function AcceptDeclineTextField({
  value,
  disabled,
  onAccept,
  onDecline,
}: {
  readonly value: string;
  readonly onAccept: (value: string) => void;
  readonly onDecline: () => void;
  readonly disabled?: boolean;
}): JSX.Element {
  const [textValue, setTextValue] = React.useState(value);
  return (
    <React.Fragment>
      <Textfield
        classNameInner="text-md leading-none h-full -ml-4"
        value={textValue}
        disabled={disabled}
        onChange={(v) => setTextValue(v || "")}
      />
      <Icon icon={"check"} onClick={() => onAccept(textValue)} />
      <Icon icon={"times"} onClick={() => onDecline()} />
    </React.Fragment>
  );
}
