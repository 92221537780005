// import gql from "graphql-tag";
import { Sub } from "@typescript-tea/core";
import { OidcEffectManager } from "@rvs/client-infra";
import * as State from "./state";

export function subscriptions(_state: State.State): Sub<State.Action> {
  const userSessionSub = OidcEffectManager.listenUserSession(State.Action.UserSessionChanged);
  const accessTokenRefreshedSub = OidcEffectManager.accessTokenRefreshed(State.Action.AccessTokenRefreshed);
  return Sub.batch([userSessionSub, accessTokenRefreshedSub]);
}
