import { gql } from "graphql-tag";
import { GraphQlUtils, Project } from "..";
import * as GqlOps from "../generated/generated-operations";

export type ProjectQuery = GqlOps.Project_ProjectQuery;
export type ProjectQueryVariables = GqlOps.Project_ProjectQueryVariables;
export type MetaProductQuery = GqlOps.ProjectMetaProductQuery;
export type MetaProductQueryVariables = GqlOps.ProjectMetaProductQueryVariables;
export type ProjectStatusQuery = GqlOps.Project_ProjectStatusQuery;
export type ProjectStatusQueryVariables = GqlOps.Project_ProjectStatusQueryVariables;

export const queryStatus = gql`
  query Project_projectStatus($projectId: ID!) {
    projectStatus(id: $projectId) {
      id
      exists
      access
    }
  }
`;

export const query = gql`
  query Project_project($projectId: ID!) {
    project(id: $projectId) {
      ...Project_project
    }
  }

  fragment Project_project on Project {
    id
    created
    edited
    locked
    name
    owned
    owner
    permissions
    template
    projectAddressname
    projectStreet
    projectPostalcode
    projectCity
    projectCountry
    customerAddressname
    customerStreet
    customerPostalcode
    customerCity
    customerCountry
    customerCustomerNumber
    gaebformat
    customerNumber
    systems {
      ...Project_system
    }
    shares {
      ...Project_projectShare
    }
    ventilationConcept {
      id
      windConditions
      buildingType
      buildingTightnessInput
      buildingTightness
      thermalProtection
    }
    userQuoteRequest {
      id
      market
      requestedBy
      requestDate
    }
    organizationQuoteRequests {
      id
      market
      requestedBy
      requestDate
    }
  }

  fragment Project_system on System {
    id
    sortNo
    name
    pipeDiameter
    totalArea
    airChangeRate
    rooms {
      ...Project_room
    }
    materials {
      ...Project_material
    }
    ventilationConcept {
      ...Project_ventilationConcept
    }
    additionalDocuments {
      ...Project_additionalDocuments
    }
  }

  fragment Project_room on Room {
    id
    sortNo
    name
    building
    floor
    apartment
    roomType
    airType
    floorType
    supplyAirFactor
    airflow
    ceilingHeight
    floorArea
    width
    length
    volume
    people
    valves
    ducts
    valveType
    customAirFlow
    customValves
  }

  fragment Project_material on Material {
    id
    sortNo
    itemNumber
    quantity
    singleSalesPrice
    singleNetPrice
    singleCost
    currency
    included
    type
    name
    packageName
  }

  fragment Project_projectShare on ProjectShare {
    id
    email
    permissions
  }

  fragment Project_ventilationConcept on VentilationConceptSystem {
    id
    name
    totalArea
    totalVolume
    occupancy
    windowlessRooms
    furnace
    higherRequirementsSound
    higherRequirementsAirQuality
    higherRequirementsEnergyEfficiency
    requiredAirflow
    infiltrationAirflow
    airflowStatus
    higherRequirementsStatus
    windowlessRoomsStatus
    furnaceStatus
    finalStatus
  }

  fragment Project_additionalDocuments on SystemPdf {
    id
    name
    pdf
    sortNo
  }
`;

export const queryMetaProduct = gql`
  query projectMetaProduct($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        ...project_Modules
      }
    }
  }

  fragment project_Modules on Modules {
    custom_tables {
      ...roomFloorTable
      ...roomTemplatesTable
      ...marketValveTypesTable
      ...roomsWithoutWindowsTable
      PipeDiameter {
        diameter
      }
      Market {
        market_name
        calculator
      }
    }
  }

  fragment roomFloorTable on Module_custom_tables {
    RoomFloor {
      key
      text_id
    }
  }

  fragment roomTemplatesTable on Module_custom_tables {
    RoomTemplates {
      key
      text_id
      market
      air_type
      default_height
      default_supply_air_factor
      supply_air_factor_range
      default_extract_airflow
      airflow_calculation
      min_airflow
      forced_min_airflow
      default_number_of_valves
    }
  }

  fragment marketValveTypesTable on Module_custom_tables {
    MarketValveTypes {
      market_name
      type
      name
    }
  }

  fragment roomsWithoutWindowsTable on Module_custom_tables {
    RoomsWithoutWindows {
      room_template_key
    }
  }
`;

export async function getProject(
  graphQlQuery: GraphQlUtils.GraphQlQueryFn,
  projectId: string
): Promise<Project.Project> {
  const projectResponse = await graphQlQuery<Project.ProjectQuery, Project.ProjectQueryVariables>(Project.query, {
    projectId,
  });

  if (!projectResponse?.project) {
    throw new Error(`Failed to query project: ${projectId}`);
  }
  return projectResponse.project;
}
