import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { Patch } from "@rvs/client-infra";
import { Calculators, Project, Texts, Markets } from "@rvs/shared";
import { isVentilationConceptStatus } from "@rvs/shared/src/project";
import * as ProjectState from "../../../project-state";
import {
  Alert,
  Button,
  Checkbox,
  NumberField,
  NumberValue,
  RadioButton,
  Spinner,
  Textfield,
} from "../../../../../elements";
import * as Images from "./images";
import { Part, RadioSelection, SelectionRow } from "./elements";

const ProjectAction = ProjectState.Action;
const texts = Texts.texts;
type VcSystem = Project.VentilationConceptSystem;
type VcProject = Project.VentilationConceptProject;

interface PartPropsSystem {
  readonly translate: Texts.TranslateFn;
  readonly readonly: boolean;
  readonly onChange: (patch: Omit<Patch<VcSystem>, "id">) => void;
}

interface PartPropsProject {
  readonly translate: Texts.TranslateFn;
  readonly readonly: boolean;
  readonly onChange: (patch: Omit<Patch<VcProject>, "id">) => void;
}

export function VentilationConceptProject(props: {
  readonly translate: Texts.TranslateFn;
  readonly projectState: ProjectState.State;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
}): JSX.Element {
  const { translate, dispatchProject, projectState } = props;
  const { project } = projectState;

  if (projectState.creatingVcIdsInProgress.length > 0) {
    return <Spinner />;
  }

  if (!project.ventilationConcept) {
    return <span />;
  }

  const vc = project.ventilationConcept;
  const isReadonly = Project.isProjectReadOnly(project);
  const partProps: PartPropsProject = {
    translate,
    readonly: isReadonly,
    onChange: (patch) => dispatchProject(ProjectAction.UpdateVentilationConceptProject({ ...patch, id: vc.id })),
  };

  const isWindy = project.projectPostalcode ? projectState.windyRegions.has(project.projectPostalcode) : undefined;
  const defaultWindConditions = isWindy !== undefined ? (isWindy ? "high" : "low") : undefined;

  return (
    <div className="text-left">
      {!isReadonly && !project.projectPostalcode && !project.ventilationConcept?.windConditions && (
        <Alert type="info" className="mb-16">
          <span>{translate(texts.auto_set_wind_conditions_info_note)}</span>
        </Alert>
      )}
      <div className="flex flex-col space-y-32">
        <PartWindConditions
          {...partProps}
          windConditions={vc.windConditions}
          defaultWindConditions={defaultWindConditions}
        />
        <PartBuildingType {...partProps} buildingType={vc.buildingType} />
        <PartTightnessBuilding
          {...partProps}
          buildingTightness={vc.buildingTightness}
          buildingTightnessInput={vc.buildingTightnessInput}
        />
        <PartThermalProtection {...partProps} thermalProtection={vc.thermalProtection} />
      </div>
    </div>
  );
}

export function VentilationConceptSystem(props: {
  readonly translate: Texts.TranslateFn;
  readonly projectState: ProjectState.State;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly systemId: string;
}): JSX.Element {
  const { translate, dispatchProject, projectState, systemId } = props;
  const { project } = projectState;
  const system = project.systems.find((s) => s.id === systemId);

  if (projectState.creatingVcIdsInProgress.length > 0) {
    return <Spinner />;
  }

  if (!system || !system.ventilationConcept) {
    return <p>{translate(texts.ventilation_concept_not_available)}</p>;
  }

  const vc = system.ventilationConcept;
  const isReadonly = Project.isProjectReadOnly(project);
  const partProps: PartPropsSystem = {
    translate,
    readonly: isReadonly,
    onChange: (patch) => dispatchProject(ProjectAction.UpdateVentilationConceptSystem({ ...patch, id: vc.id })),
  };

  const calcState = projectState.calculationStates[system.id];
  const [systemTotalArea, systemTotalVolume] =
    calcState?.result.type === "germany"
      ? [calcState.result.totalArea, calcState.result.totalVolume]
      : [undefined, undefined];

  return (
    <div className="text-left">
      <div className="flex flex-col space-y-32">
        <PartBuildingData
          {...partProps}
          name={vc.name}
          totalArea={vc.totalArea}
          totalVolume={vc.totalVolume}
          systemName={system.name}
          systemTotalArea={systemTotalArea}
          systemTotalVolume={systemTotalVolume}
        />
        <PartOccupancy {...partProps} occupancy={vc.occupancy} />
        <PartRoomsWithoutWindow {...partProps} windowlessRooms={vc.windowlessRooms} />
        <PartFurnace {...partProps} furnace={vc.furnace} />
        <PartHigherRequirements
          {...partProps}
          higherRequirementsSound={vc.higherRequirementsSound}
          higherRequirementsAirQuality={vc.higherRequirementsAirQuality}
          higherRequirementsEnergyEfficiency={vc.higherRequirementsEnergyEfficiency}
        />
        <PartResults
          {...partProps}
          requiredAirflow={vc.requiredAirflow}
          infiltrationAirflow={vc.infiltrationAirflow}
          airflowStatus={vc.airflowStatus}
          higherRequirementsStatus={vc.higherRequirementsStatus}
          windowlessRoomsStatus={vc.windowlessRoomsStatus}
          furnaceStatus={vc.furnaceStatus}
          finalStatus={vc.finalStatus}
        />
      </div>
    </div>
  );
}

export function useAutoUpdateEffect(
  market: Markets.Market,
  projectState: ProjectState.State,
  dispatchProject: Dispatch<ProjectState.Action>
): void {
  const isReadonly = Project.isProjectReadOnly(projectState.project);

  // A ventilation concept is required for this tab and it's possible a system is missing one
  React.useEffect(() => {
    if (
      market.ventilationConceptEnabled &&
      projectState.creatingVcIdsInProgress.length === 0 &&
      !isReadonly &&
      (projectState.project.ventilationConcept === null ||
        projectState.project.systems.some((s) => !s.ventilationConcept))
    ) {
      dispatchProject(ProjectAction.CreateVentilationConceptIfNotExists());
    }
  }, [
    market.ventilationConceptEnabled,
    projectState.creatingVcIdsInProgress,
    projectState.project,
    isReadonly,
    market,
  ]);

  // Auto select default settings
  React.useEffect(() => {
    if (
      market.ventilationConceptEnabled &&
      projectState.creatingVcIdsInProgress.length === 0 &&
      !isReadonly &&
      projectState.project.ventilationConcept &&
      projectState.project.ventilationConcept.windConditions === null
    ) {
      dispatchProject(ProjectAction.SetDefaultsVentilationConcept());
    }
  }, [
    market.ventilationConceptEnabled,
    projectState.creatingVcIdsInProgress,
    projectState.windyRegions,
    projectState.project.projectPostalcode,
    projectState.project.ventilationConcept,
    isReadonly,
  ]);
}

function PartBuildingData({
  readonly,
  translate,
  onChange,
  name,
  totalArea,
  totalVolume,
  systemName,
  systemTotalArea,
  systemTotalVolume,
}: PartPropsSystem &
  Pick<VcSystem, "name" | "totalArea" | "totalVolume"> & {
    readonly systemName: string;
    readonly systemTotalArea: number | undefined;
    readonly systemTotalVolume: number | undefined;
  }): JSX.Element {
  const buildingDataMatches =
    !systemTotalVolume ||
    !systemTotalArea ||
    (name === systemName && totalArea === systemTotalArea && totalVolume === systemTotalVolume);
  const numDecimals = Project.roundingDecimals;

  return (
    <Part title={translate(texts.ventilation_concept_title_building_data)}>
      <div className="flex flex-row items-end space-x-32">
        <SelectionRow className="justify-between w-[440px]">
          <div className="flex flex-col space-y-8">
            <div>{translate(texts.name)}</div>
            <Textfield value={name || ""} readOnly={readonly} onChange={(value) => onChange({ name: value })} />
          </div>
          <div className="flex flex-col space-y-8">
            <div>
              {translate(texts.result_total_area)}
              {" m²"}
            </div>
            <NumberField
              decimals={numDecimals}
              value={totalArea || undefined}
              readOnly={readonly}
              onChange={(value) => onChange({ totalArea: value })}
            />
          </div>
          <div className="flex flex-col space-y-8">
            <div>
              {translate(texts.result_total_volume)}
              {" m³"}
            </div>
            <NumberField
              value={totalVolume || undefined}
              decimals={numDecimals}
              readOnly={readonly}
              onChange={(value) => onChange({ totalVolume: value })}
            />
          </div>
        </SelectionRow>
        <div className="flex flex-col items-end h-full">
          <Button
            disabled={buildingDataMatches || readonly}
            label={translate(texts.vc_copy_building_data_from_system)}
            onClick={() =>
              onChange({ name: systemName, totalArea: systemTotalArea || null, totalVolume: systemTotalVolume || null })
            }
          />
        </div>
      </div>
    </Part>
  );
}

function PartThermalProtection({
  readonly,
  translate,
  onChange,
  thermalProtection,
}: PartPropsProject & Pick<VcProject, "thermalProtection">): JSX.Element {
  return (
    <Part title={translate(texts.ventilation_concept_title_thermal_protection)}>
      <SelectionRow>
        <RadioSelection
          image={<Images.InsulationHigh />}
          label={translate(texts.vc_thermal_protection_high)}
          description={translate(texts.vc_thermal_protection_high_description)}
          readonly={readonly}
          selected={thermalProtection === "high"}
          onChange={() => onChange({ thermalProtection: "high" })}
        />
        <RadioSelection
          image={<Images.InsulationLow />}
          label={translate(texts.vc_thermal_protection_low)}
          description={translate(texts.vc_thermal_protection_low_description)}
          readonly={readonly}
          selected={thermalProtection === "low"}
          onChange={() => onChange({ thermalProtection: "low" })}
        />
      </SelectionRow>
    </Part>
  );
}

function PartWindConditions({
  readonly,
  translate,
  onChange,
  windConditions,
  defaultWindConditions,
}: PartPropsProject &
  Pick<VcProject, "windConditions"> & { readonly defaultWindConditions: "low" | "high" | undefined }): JSX.Element {
  return (
    <Part title={translate(texts.ventilation_concept_title_wind_conditions)}>
      <SelectionRow>
        <RadioSelection
          image={<Images.WindWeak />}
          label={translate(texts.vc_wind_conditions_low)}
          readonly={readonly}
          selected={windConditions === "low"}
          onChange={() => onChange({ windConditions: "low" })}
        />
        <RadioSelection
          image={<Images.WindStrong />}
          label={translate(texts.vc_wind_conditions_high)}
          readonly={readonly}
          selected={windConditions === "high"}
          onChange={() => onChange({ windConditions: "high" })}
        />
        {!readonly && defaultWindConditions !== undefined && defaultWindConditions !== windConditions && (
          <Button
            large={true}
            label={translate(texts.ventilation_concept_update_using_postal_code)}
            onClick={() => onChange({ windConditions: defaultWindConditions })}
          />
        )}
      </SelectionRow>
    </Part>
  );
}

function PartBuildingType({
  readonly,
  translate,
  onChange,
  buildingType,
}: PartPropsProject & Pick<VcProject, "buildingType">): JSX.Element {
  return (
    <Part title={translate(texts.ventilation_concept_title_building_type)}>
      <SelectionRow>
        <RadioSelection
          image={<Images.BuildingCondo />}
          label={translate(texts.vc_building_type_single_floor)}
          readonly={readonly}
          selected={buildingType === "single_floor"}
          onChange={() => onChange({ buildingType: "single_floor" })}
        />
        <RadioSelection
          image={<Images.BuildingHouse />}
          label={translate(texts.vc_building_type_multi_floor)}
          readonly={readonly}
          selected={buildingType === "multi_floor"}
          onChange={() => onChange({ buildingType: "multi_floor" })}
        />
      </SelectionRow>
    </Part>
  );
}

function PartTightnessBuilding({
  readonly,
  translate,
  onChange,
  buildingTightness,
  buildingTightnessInput,
}: PartPropsProject & Pick<VcProject, "buildingTightness" | "buildingTightnessInput">): JSX.Element {
  const numDecimals = Project.roundingDecimals;

  return (
    <Part title={translate(texts.ventilation_concept_title_tightness_of_building)}>
      <SelectionRow>
        <RadioButton
          label={translate(texts.vc_building_tightness_manually)}
          checked={buildingTightnessInput === "manually"}
          disabled={readonly}
          checkedChanged={() => onChange({ buildingTightnessInput: "manually", buildingTightness: null })}
        />
        <RadioButton
          label={translate(texts.vc_building_tightness_standard)}
          checked={buildingTightnessInput === "standard"}
          disabled={readonly}
          checkedChanged={() =>
            onChange({
              buildingTightnessInput: "standard",
              buildingTightness:
                Calculators.GermanyCalculator.calculateBuildingTightness("standard", undefined) ?? null,
            })
          }
        />
        <div className="flex flex-col space-y-8">
          <div>
            <span>n</span>
            <sub>50</sub>
          </div>
          <NumberField
            value={buildingTightness || undefined}
            readOnly={readonly}
            decimals={numDecimals}
            disabled={buildingTightnessInput === "standard"}
            onChange={(value) => onChange({ buildingTightness: value })}
          />
        </div>
      </SelectionRow>
    </Part>
  );
}

function PartOccupancy({
  readonly,
  translate,
  onChange,
  occupancy,
}: PartPropsSystem & Pick<VcSystem, "occupancy">): JSX.Element {
  return (
    <Part title={translate(texts.ventilation_concept_title_occupancy)}>
      <SelectionRow>
        <SelectionRow>
          <RadioSelection
            image={<Images.OccupancyLow />}
            label={translate(texts.vc_occupany_low)}
            readonly={readonly}
            selected={occupancy === "low"}
            onChange={() => onChange({ occupancy: "low" })}
          />
          <RadioSelection
            image={<Images.OccupancyHigh />}
            label={translate(texts.vc_occupany_high)}
            readonly={readonly}
            selected={occupancy === "high"}
            onChange={() => onChange({ occupancy: "high" })}
          />
        </SelectionRow>
      </SelectionRow>
    </Part>
  );
}

function PartRoomsWithoutWindow({
  readonly,
  translate,
  onChange,
  windowlessRooms,
}: PartPropsSystem & Pick<VcSystem, "windowlessRooms">): JSX.Element {
  return (
    <Part title={translate(texts.ventilation_concept_title_rooms_without_windows)}>
      <SelectionRow>
        <SelectionRow>
          <RadioSelection
            image={<Images.NotApplicable />}
            label={translate(texts.ventilation_concept_no)}
            readonly={readonly}
            selected={windowlessRooms === false}
            onChange={() => onChange({ windowlessRooms: false })}
          />
        </SelectionRow>
        <RadioSelection
          image={<Images.Window />}
          label={translate(texts.ventilation_concept_yes)}
          readonly={readonly}
          selected={windowlessRooms === true}
          onChange={() => onChange({ windowlessRooms: true })}
        />
      </SelectionRow>
    </Part>
  );
}

function PartFurnace({
  readonly,
  translate,
  onChange,
  furnace,
}: PartPropsSystem & Pick<VcSystem, "furnace">): JSX.Element {
  return (
    <Part title={translate(texts.ventilation_concept_title_furnace)}>
      <SelectionRow>
        <SelectionRow>
          <RadioSelection
            image={<Images.NotApplicable />}
            label={translate(texts.ventilation_concept_no)}
            readonly={readonly}
            selected={furnace === false}
            onChange={() => onChange({ furnace: false })}
          />
        </SelectionRow>
        <RadioSelection
          image={<Images.Fireplace />}
          label={translate(texts.ventilation_concept_yes)}
          readonly={readonly}
          selected={furnace === true}
          onChange={() => onChange({ furnace: true })}
        />
      </SelectionRow>
    </Part>
  );
}

function PartHigherRequirements({
  readonly,
  translate,
  onChange,
  higherRequirementsSound,
  higherRequirementsAirQuality,
  higherRequirementsEnergyEfficiency,
}: PartPropsSystem &
  Pick<
    VcSystem,
    "higherRequirementsSound" | "higherRequirementsAirQuality" | "higherRequirementsEnergyEfficiency"
  >): JSX.Element {
  return (
    <Part title={translate(texts.ventilation_concept_title_higher_requirements)}>
      <SelectionRow>
        <Checkbox
          label={translate(texts.vc_higher_requirements_sound)}
          checked={!!higherRequirementsSound}
          disabled={readonly}
          onChange={(checked) => onChange({ higherRequirementsSound: checked })}
        />
        <Checkbox
          label={translate(texts.vc_higher_requirements_air_quality)}
          checked={!!higherRequirementsAirQuality}
          disabled={readonly}
          onChange={(checked) => onChange({ higherRequirementsAirQuality: checked })}
        />
        <Checkbox
          label={translate(texts.vc_higher_requirements_energy_efficiency)}
          checked={!!higherRequirementsEnergyEfficiency}
          disabled={readonly}
          onChange={(checked) => onChange({ higherRequirementsEnergyEfficiency: checked })}
        />
      </SelectionRow>
    </Part>
  );
}

function PartResults({
  translate,
  requiredAirflow,
  infiltrationAirflow,
  airflowStatus,
  higherRequirementsStatus,
  windowlessRoomsStatus,
  furnaceStatus,
  finalStatus,
}: PartPropsSystem &
  Pick<
    VcSystem,
    | "requiredAirflow"
    | "infiltrationAirflow"
    | "airflowStatus"
    | "higherRequirementsStatus"
    | "windowlessRoomsStatus"
    | "furnaceStatus"
    | "finalStatus"
  >): JSX.Element {
  const comments = [];
  if (isVentilationConceptStatus(airflowStatus) && airflowStatus !== "green") {
    comments.push({
      key: "airflowStatus",
      status: airflowStatus,
      comment: translate(texts.vc_status_infiltration_below_required),
    });
  }
  if (isVentilationConceptStatus(higherRequirementsStatus) && higherRequirementsStatus !== "green") {
    comments.push({
      key: "higherRequirementsStatus",
      status: higherRequirementsStatus,
      comment: translate(texts.vc_status_higher_requirements_selected),
    });
  }
  if (isVentilationConceptStatus(windowlessRoomsStatus) && windowlessRoomsStatus !== "green") {
    comments.push({
      key: "higherRequirementsStatus",
      status: windowlessRoomsStatus,
      comment: translate(texts.vc_status_rooms_without_windows),
    });
  }
  if (isVentilationConceptStatus(furnaceStatus) && furnaceStatus !== "green") {
    comments.push({
      key: "higherRequirementsStatus",
      status: furnaceStatus,
      comment: translate(texts.vc_status_building_has_a_furnace),
    });
  }
  // TODO: Use a table for airflow results, better alignment
  return (
    <Part title={translate(texts.ventilation_concept_title_results)}>
      <div className="flex flex-row space-x-32">
        <div className="flex flex-col space-y-16">
          <div className="flex flex-row space-x-8">
            <div className="min-w-700">{translate(texts.vc_status_airflow_for_moisture_protection)}</div>
            <NumberValue value={requiredAirflow} unit={"CubicMeterPerHour"} decimals={0} />
          </div>
          <div className="flex flex-row space-x-8">
            <div className="min-w-700">{translate(texts.vc_status_airflow_through_infiltration)}</div>
            <NumberValue value={infiltrationAirflow} unit={"CubicMeterPerHour"} decimals={0} />
          </div>
        </div>
        <div className="flex flex-row space-x-16">
          <div className="w-64 h-64" style={{ background: finalStatus ? finalStatus : "#777" }}></div>
          <div className="flex flex-col space-y-8">
            {comments.map(({ key, status, comment }) => (
              <div key={key} className="flex flex-row space-x-4">
                <div className="w-16 h-16" style={{ background: status ? status : "#777" }} />
                <div>{comment}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Part>
  );
}
