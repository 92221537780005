import { ValvePriceAndCost, ValvePriceAndCostTable, Item } from "./types";

export type QuantityPerValve = {
  readonly item: Item;
  readonly valveType: string;
  readonly quantity: number | undefined;
};

export function calculateQtyPerValve(
  valvePriceAndCostTable: ValvePriceAndCostTable,
  valveType: string
): ReadonlyArray<QuantityPerValve> {
  const columns = getColumnNames(valvePriceAndCostTable, valveType);

  const valveRows = valvePriceAndCostTable.filter((r) => r.type === "supply_valve" || r.type === "extract_valve");
  const totalNumValves = valveRows.reduce<number | undefined>((sum, row) => {
    const rowSum = getSumOfRow(row, columns);
    if (rowSum !== undefined) {
      sum = (sum || 0) + rowSum;
    }
    return sum;
  }, undefined);

  const quantities = valvePriceAndCostTable
    .filter((row) => !!row.item_number)
    .map((row) => {
      const rowSum = getSumOfRow(row, columns);
      const quantityPerValve =
        rowSum !== undefined && totalNumValves !== undefined ? rowSum / totalNumValves : undefined;
      const quantity = quantityPerValve !== undefined ? quantityPerValve : undefined;
      return {
        item: {
          name: row.name || "",
          itemNumber: row.item_number || "",
          type: row.type || "",
          maxAirflow: undefined,
        },
        valveType: valveType,
        quantity: quantity,
      };
    });

  return quantities;
}

function getSumOfRow(row: ValvePriceAndCost, columns: ReadonlyArray<string>): number | undefined {
  let sum: number | undefined = undefined;
  for (const column of columns) {
    const qty = Number.parseInt(row[column] || "", 10);
    if (Number.isFinite(qty)) {
      sum = (sum || 0) + qty;
    }
  }
  return sum;
}

function getColumnNames(valvePriceAndCostTable: ValvePriceAndCostTable, valveType: string): ReadonlyArray<string> {
  const row = valvePriceAndCostTable[0];
  if (!row) {
    return [];
  }
  const columns = [];
  for (let i = 1; i < 99; i++) {
    const name = `${valveType}${i}`;
    if (row[name] !== undefined) {
      columns.push(name);
    } else {
      break;
    }
  }
  return columns;
}
