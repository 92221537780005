import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { Texts, Markets, Images } from "@rvs/shared";
import gql from "graphql-tag";
import { SharedState } from "@rvs/client-infra";
import * as GQLOps from "../generated/generated-operations";
import { clientConfig } from "../client-config";

const query = gql`
  query headerMetaProduct($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        ...LangText_ModulesLanguages
        ...marketModules
        ...imageModules
      }
    }
  }
  ${Texts.LangText_ModulesLanguagesFragment}
  ${Markets.marketModulesFragment}
  ${Images.imageModulesFragment}
`;

// STATE

export interface State {
  readonly metaProduct: GQLOps.HeaderMetaProductQuery | undefined;
}

export const Action = ctorsUnion({
  ChangeLanguage: (newLang: Texts.LanguageCode) => ({ newLang }),
  ChangeMarket: (newLang: string) => ({ newLang }),
  MetaProductRecieved: (response: GQLOps.HeaderMetaProductQuery) => ({
    response,
  }),
  Logout: () => ({}),
});

export type Action = CtorsUnion<typeof Action>;

export function init(
  prevState: State | undefined,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?] {
  if (prevState) {
    return [prevState];
  }

  const initialState = { metaProduct: undefined };

  const gqlCmdProduct = sharedState.graphQLProductQuery<
    GQLOps.HeaderMetaProductQuery,
    GQLOps.HeaderMetaProductQueryVariables,
    Action
  >(query, { productId: clientConfig.promaster_meta_id }, (data) => {
    return Action.MetaProductRecieved(data);
  });

  return [initialState, gqlCmdProduct];
}

export function update(
  action: Action,
  state: State,
  _sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "Logout": {
      return [state, undefined, SharedState.SharedStateAction.Logout()];
    }
    case "ChangeLanguage": {
      return [state, undefined, SharedState.SharedStateAction.SetLanguage(action.newLang)];
    }
    case "ChangeMarket": {
      return [state, undefined, SharedState.SharedStateAction.SetMarket(action.newLang)];
    }
    case "MetaProductRecieved": {
      return [{ ...state, metaProduct: action.response }];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}
