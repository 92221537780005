import * as Project from "../../project";
import { graphQLQuery, QueryGenerator } from "../../query";
import { ReportQueryParams } from "..";
import { AdapterType, Response } from "./types";
import { BitmapImage, getPageProps, imageToBitmapImage } from "../common";
import { getMaterialsYield } from "../../materials";
import { Materials } from "../..";

export function* query(props: ReportQueryParams): QueryGenerator<Response> {
  const projectResponse = yield* graphQLQuery<Project.ProjectQuery, Project.ProjectQueryVariables>(Project.query, {
    projectId: props.projectId,
  });
  return {
    materialTables: yield* getMaterialsYield(props.market, props.metaProductId),
    projectResponse,
  };
}

export const execute: AdapterType = async (projectReportParams, common, response: Response) => {
  const pageProps = await getPageProps(projectReportParams, common);
  const system = response.projectResponse.project?.systems.find((system) => system.id === projectReportParams.systemId);

  const sortedMaterials =
    system && Materials.sortListUiOrder(projectReportParams.market, response.materialTables, system.materials);

  const systemWithArticles = system &&
    sortedMaterials && {
      system: system,
      materialsWithMeta: sortedMaterials.map((m) =>
        Materials.getMetaData(projectReportParams.market, response.materialTables, m)
      ),
    };

  const itemImagesPromise = systemWithArticles!.materialsWithMeta.map(async (art) => {
    const res = await fetch(
      `https://public-api.promaster.systemair.com/image?itemNumber=${art.material.itemNumber}&maxWidth=80&maxHeight=80&format=jpg`
    );

    const imgBuffer = await res.arrayBuffer();
    return {
      type: "blob",
      url: res.url,
      data: new Uint8Array(imgBuffer),
      headers: res.headers,
      item_number: art.material.itemNumber,
    };
  });

  const itemImages = await Promise.all(itemImagesPromise);
  const images = new Map<string, BitmapImage>();
  for (const image of itemImages) {
    const bitmapImg = await imageToBitmapImage(80, 80, {
      image: { image: image.url, type: null, name: null, file_name: null },
      imageData: { headers: image.headers, response: image.data },
    });

    if (bitmapImg) {
      images.set(image.item_number, bitmapImg);
    }
  }

  return {
    pageProps: pageProps,
    translate: projectReportParams.translate,
    systemWithArticles: systemWithArticles,
    itemImages: images,
  };
};
