import { isExtractRoom, Room, RoomTemplatesTable, System } from "../../../project";
import { logWarn } from "../../../logger";
import { calculateNominalAirflow } from "./room-airflow";
import { calculateForcedMinAirflow } from ".";

export interface RoomResult {
  readonly airflow: number;
}

export interface ExtractBalanceResult {
  readonly resultByRoomId: ReadonlyMap<string, RoomResult>;
  readonly requiredAirflow: number | undefined;
}

export function calculateBalanceExtractAirflows(
  roomTemplatesTable: RoomTemplatesTable,
  system: System,
  requiredAirflow: number | undefined
): ExtractBalanceResult | undefined {
  const extractRooms = system.rooms.filter((r) => isExtractRoom(r));
  if (extractRooms.length === 0) {
    return { resultByRoomId: new Map(), requiredAirflow: requiredAirflow };
  }

  const roomsWithMaybeNominalAf = extractRooms.map((room) => ({
    room,
    nominalAirflow: calculateNominalAirflow(roomTemplatesTable, room),
    forcedMinAirflow: calculateForcedMinAirflow(roomTemplatesTable, room),
  }));
  if (roomsWithMaybeNominalAf.some((r) => r.nominalAirflow === undefined)) {
    return undefined;
  }
  const roomsWithNominalAf = roomsWithMaybeNominalAf as ReadonlyArray<{
    readonly room: Room;
    readonly nominalAirflow: number;
    readonly forcedMinAirflow: number;
  }>;

  if (requiredAirflow === undefined) {
    logWarn(`requiredAirflow not calculated`);
    return undefined;
  }

  const NominalAfSum = roomsWithNominalAf.reduce((sofar, room) => sofar + room.nominalAirflow, 0);
  const airflowPerOne = requiredAirflow / NominalAfSum;
  const resultByRoomId = roomsWithNominalAf.reduce((sofar, room) => {
    const roomResult = {
      airflow:
        room.room.customAirFlow && room.room.airflow !== null
          ? room.room.airflow
          : Math.max(room.forcedMinAirflow, room.nominalAirflow * airflowPerOne),
    };
    sofar.set(room.room.id, roomResult);
    return sofar;
  }, new Map<string, RoomResult>());

  // Forced min airflow might increase the total required airflow
  const newRequiredAirflow = system.rooms.reduce((sofar, room) => {
    return sofar + (resultByRoomId.get(room.id)?.airflow || 0);
  }, 0);

  return {
    resultByRoomId,
    requiredAirflow: newRequiredAirflow,
  };
}
