import { ReportLevel } from "..";
import { Project } from "../..";
import { TranslateFn } from "../../lang-texts";
import { System } from "../../project";
import * as Common from "../common";
import { ReportModule } from "../types";

export const Name = "room";
export const reportLevel: ReportLevel = "system";

export type Response = {
  readonly project: Project.ProjectQuery;
};

export type CreatorProps = {
  readonly system: System | undefined;
  readonly pageProps: Common.CataloguePageProps;
  readonly translate: TranslateFn;
};

export type AdapterType = ReportModule<CreatorProps, Response>["execute"];
export type CreatorType = ReportModule<CreatorProps, Response>["create"];
