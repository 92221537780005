import gql from "graphql-tag";

export const schema = gql`
  extend type Query {
    projectList(
      filter: String
      searchProjectList: String
      defaultName: String
      pageCursor: String
      sortOrder: [String]
    ): ProjectList!
  }

  type ProjectList {
    projects: [Project!]!
    currentPage: ProjectListPage!
    previousPage: ProjectListPage
    nextPage: ProjectListPage
    pages: [ProjectListPage!]!
  }

  type ProjectListPage {
    page: Int!
    cursor: String!
  }
`;
