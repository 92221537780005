import { Patch, Room } from "../../project";
import { Input, Updates } from "../types";
import { Result } from "./types";

export function mapResultToUi(input: Input, result: Result): Updates {
  const patches: Array<Patch<Room>> = [];
  for (const room of input.system.rooms) {
    const roomResult = result.roomResults[room.id];
    if (!roomResult) {
      continue;
    }
    patches.push({
      id: room.id,
      airflow: roomResult.airflow ?? 0,
      floorArea: roomResult.area ?? null,
      width: roomResult.width ?? null,
      length: roomResult.length ?? null,
      people: roomResult.people ?? null,
      valves: roomResult.valves ?? null,
    });
  }
  return {
    roomPatches: patches,
  };
}

export function mapResultToProject(input: Input, result: Result): Updates {
  const patches: Array<Patch<Room>> = [];
  for (const room of input.system.rooms) {
    let patch = undefined;

    const newAirflow = result.roomResults[room.id]?.airflow;
    if (room.airflow !== newAirflow && newAirflow !== undefined) {
      patch = { ...(patch || {}), id: room.id, airflow: newAirflow };
    }

    const newValves = result.roomResults[room.id]?.valves;
    if (room.valves !== newValves && newValves !== undefined) {
      patch = { ...(patch || {}), id: room.id, valves: newValves };
    }

    if (patch) {
      patches.push(patch);
    }
  }
  return {
    roomPatches: patches,
  };
}
