export type KeyParams = { readonly [key: string]: string | number };

export type NormalizedText = {
  readonly type: "NormalizedText";
  readonly key: string;
  readonly params: KeyParams;
};

export const normalizedText = (key: string, params: KeyParams = {}): NormalizedText => ({
  type: "NormalizedText",
  key,
  params,
});
