import gql from "graphql-tag";
import { GraphQlUtils } from "../..";
import { QueryGenerator, graphQLProductQuery } from "../../query";
import * as GQLOps from "../../generated/generated-operations";
import { MaterialTables, ValvePriceAndCostTable } from "./types";
import { FixedValvePriceTable, Item } from ".";
import { createCategories } from "../shared/categories";

export const materialQuery = gql`
  query NorwayMaterialList_materialProduct($metaProductId: ID!, $materialProductId: ID!) {
    metaProduct: product(id: $metaProductId) {
      key
      modules {
        custom_tables {
          ...marketValveTypesTable
        }
      }
    }
    materialProduct: product(id: $materialProductId) {
      key
      modules {
        custom_tables {
          ...NorwayMaterialList_materialTables
        }
      }
    }
  }
  fragment marketValveTypesTable on Module_custom_tables {
    MarketValveTypes {
      market_name
      type
      name
    }
  }
  fragment NorwayMaterialList_materialTables on Module_custom_tables {
    Items {
      sort_no
      item_number
      name
      type
      max_airflow
    }
    ValvePriceAndCost {
      sort_no
      name
      item_number
      type
      normal1
      normal2
      normal3
      normal4
      normal5
      tube_f1
      tube_f2
      tube_f3
      tube_f4
      tube_f5
      firewall1
      firewall2
      firewall3
      firewall4
    }
    FixedValvePrice {
      system_type
      fixed_price
      discount_item_number
    }
    Categories {
      sort_no
      group
      category
    }
  }
`;

export async function getTables(
  graphQlProductQuery: GraphQlUtils.GraphQlQueryFn,
  metaProductId: string,
  materialProductId: string
): Promise<MaterialTables> {
  const materialProductResponse = await graphQlProductQuery<
    GQLOps.NorwayMaterialList_MaterialProductQuery,
    GQLOps.NorwayMaterialList_MaterialProductQueryVariables
  >(materialQuery, { metaProductId, materialProductId });
  return getTablesFromQuery(materialProductResponse);
}

export function* getTablesYield(metaProductId: string, materialProductId: string): QueryGenerator<MaterialTables> {
  const materialProductResponse = yield* graphQLProductQuery<
    GQLOps.NorwayMaterialList_MaterialProductQuery,
    GQLOps.NorwayMaterialList_MaterialProductQueryVariables
  >(materialQuery, { metaProductId, materialProductId });
  return getTablesFromQuery(materialProductResponse);
}

function getTablesFromQuery(
  materialProductResponse: GQLOps.NorwayMaterialList_MaterialProductQuery | undefined
): MaterialTables {
  const categoriesTable = materialProductResponse?.materialProduct?.modules.custom_tables.Categories || [];
  const itemsTable = materialProductResponse?.materialProduct?.modules.custom_tables.Items;
  const valvePriceAndCost = materialProductResponse?.materialProduct?.modules.custom_tables.ValvePriceAndCost;
  const fixedValvePrice = materialProductResponse?.materialProduct?.modules.custom_tables.FixedValvePrice;
  if (!itemsTable || !valvePriceAndCost || !fixedValvePrice) {
    throw new Error("Failed to query product data");
  }
  const valveTypes = (materialProductResponse.metaProduct?.modules.custom_tables.MarketValveTypes || []).map(
    (r) => r.type || ""
  );
  const items = itemsTable.reduce<{ [itemNumber: string]: Item }>((sofar, i) => {
    if (i.item_number === null) {
      return sofar;
    }
    sofar[i.item_number] = {
      type: i.type || "",
      itemNumber: i.item_number || "",
      maxAirflow: i.max_airflow || undefined,
    };
    return sofar;
  }, {});
  const sortNos = [
    ...itemsTable.filter((i) => i.type === "air_unit").map((i) => i.item_number || ""),
    ...valveTypes,
    ...itemsTable.filter((i) => i.type !== "air_unit").map((i) => i.item_number || ""),
  ].reduce((sofar, itemNumber, i) => {
    sofar.set(itemNumber, i);
    return sofar;
  }, new Map<string, number>());
  return {
    type: "norway",
    tables: {
      items: itemsTable,
      valvePriceAndCost: valvePriceAndCost as ValvePriceAndCostTable,
      fixedValvePrice: fixedValvePrice as FixedValvePriceTable,
    },
    items,
    valveTypes,
    sortNos,
    categories: createCategories(categoriesTable),
  };
}
