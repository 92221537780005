import * as React from "react";

export interface TabButtonProps {
  readonly label: string;
  readonly active?: boolean;
  readonly onClick?: () => void;
  readonly url?: string;
  readonly disabled?: boolean;
  readonly className?: string;
}

export function TabButton(props: TabButtonProps): React.ReactElement<TabButtonProps> {
  let className = "cursor-pointer btn btn-tiny btn-nav";
  if (props.disabled) {
    className += " btn-disabled";
  }
  if (props.className) {
    className += ` ${props.className}`;
  }
  return (
    <div
      className={
        props.active ? "inline-block border-brand-600 border-b-4" : "inline-block border-b-neutral-200 border-b-4"
      }
    >
      <a
        className={className}
        onClick={() => {
          if (!props.disabled && props.onClick) {
            props.onClick();
          }
        }}
        href={props.disabled ? undefined : props.url}
      >
        {props.label}
      </a>
    </div>
  );
}
