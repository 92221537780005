import { getMetaData } from "@rvs/shared/src/materials";
import { graphQLMutationWithAuth, InputPatch, SharedState } from "@rvs/client-infra";
import { Cmd } from "@typescript-tea/core";
import { v4 as uuid } from "uuid";
import { Materials, Project } from "@rvs/shared";
import { Action, doPriceUpdate, MaterialListUpdateState, State } from "../state";
import * as GQLOps from "../../../../generated/generated-operations";
import * as M from "../mutations";

export function UpdateMaterialListItem(
  action: ReturnType<typeof Action.UpdateMaterialListItem>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;

  const system = project.systems.find((s) => s.id === action.systemId);
  if (!system) {
    return [state];
  }
  const material = system.materials.find((m) => m.id === action.patch.id);
  if (!material) {
    return [state];
  }

  // const isIncluded =
  //   state.materialTables.items[material.itemNumber]?.type === "air_unit" && action.patch.quantity !== undefined
  //     ? action.patch.quantity > 0
  //     : material.included;
  //
  // const updatedMaterial: Project.Material = { ...material, included: isIncluded, ...action.patch };

  const updatedMaterial: Project.Material = { ...material, ...action.patch };
  const materialMeta = getMetaData(sharedState.market, state.materialTables, updatedMaterial);

  const materialsInGroup = system.materials.filter((material) => {
    const meta = getMetaData(sharedState.market, state.materialTables, material);
    return (
      state.materialTables.items[material.itemNumber]?.type &&
      meta.singleGroupSelection &&
      material.included &&
      meta.group === materialMeta.group &&
      meta.category === materialMeta.category
    );
  });
  const otherMaterials = materialsInGroup
    .filter((m) => m.id !== updatedMaterial.id)
    .map((m) => ({ ...m, included: false }));
  const updatedProject = Project.replaceMaterials(project, action.systemId, [updatedMaterial, ...otherMaterials]);
  return [
    { ...state, project: updatedProject },
    graphQLMutation<
      GQLOps.ProjectState_UpdateMaterialsMutation,
      GQLOps.ProjectState_UpdateMaterialsMutationVariables,
      Action
    >(
      M.updateMaterialsMutation,
      {
        input: {
          materials: [
            action.patch as InputPatch<Project.Material>,
            ...otherMaterials.map((m) => ({ id: m.id, included: m.included } as InputPatch<Project.Material>)),
          ],
        },
      },
      sharedState.market.name
    ),
  ];
}

export function UpdateCustomMaterial(
  action: ReturnType<typeof Action.UpdateCustomMaterial>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;
  if (project.locked) {
    return [state, undefined];
  }

  const system = project.systems.find((s) => s.id === action.systemId);
  if (!system) {
    return [state];
  }
  const material = system.materials.find((m) => m.id === action.materialId);
  if (!material) {
    return [state];
  }

  if (material.itemNumber === action.itemNumber) {
    return [state];
  }

  const updatedMaterial: Project.Material = { ...material, itemNumber: action.itemNumber };
  const updatedProject = Project.replaceMaterials(project, action.systemId, [updatedMaterial]);

  const disabledCustomMaterials = new Set<string>(state.disabledCustomMaterials);
  disabledCustomMaterials.add(action.materialId);

  return [
    { ...state, disabledCustomMaterials: disabledCustomMaterials, project: updatedProject },
    graphQLMutation<
      GQLOps.ProjectState_UpdateCustomMaterialMutation,
      GQLOps.ProjectState_UpdateCustomMaterialMutationVariables,
      Action
    >(
      M.updateCustomMaterialMutation,
      {
        input: {
          systemId: action.systemId,
          materialId: action.materialId,
          itemNumber: action.itemNumber,
          marketName: sharedState.market.name,
          language: sharedState.m3LanguageCode,
        },
      },
      sharedState.market.name,
      (mutationData) => Action.ReceivedUpdatedCustomMaterialPrice(mutationData)
    ),
  ];
}

export function CreateMaterialListForUnit(
  action: ReturnType<typeof Action.CreateMaterialListForUnit>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  return updateMaterialListState({ ...state, materialsState: {} }, sharedState, action.systemId, (system) =>
    Materials.createMaterialListForUnit(sharedState.market, state.materialTables, system, action.selectedAirUnit)
  );
}

export function UpdateMaterialListQuantities(
  action: ReturnType<typeof Action.UpdateMaterialListQuantities>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  return updateMaterialListState(state, sharedState, action.systemId, (system) =>
    Materials.updateMaterialListQuantities(sharedState.market, state.materialTables, system)
  );
}

export function UpdateMaterialListAddNewItems(
  action: ReturnType<typeof Action.UpdateMaterialListAddNewItems>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  return updateMaterialListState(state, sharedState, action.systemId, (system) =>
    Materials.updateMaterialListAddNewItems(sharedState.market, state.materialTables, system, action.selectedAirUnit)
  );
}

export function MaterialListUpdateDone(
  action: ReturnType<typeof Action.MaterialListUpdateDone>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const { project } = state;

  const updateState = state.materialsState[action.systemId];
  if (!updateState || updateState.type !== "list-update") {
    return [state];
  }
  const newUpdateState: MaterialListUpdateState =
    action.update === "created"
      ? { ...updateState, createdDone: true }
      : action.update === "updated"
      ? { ...updateState, updatedDone: true }
      : { ...updateState, removedDone: true };
  if (newUpdateState.removedDone && newUpdateState.updatedDone && newUpdateState.createdDone) {
    return doPriceUpdate(
      [action.systemId],
      project,
      {
        ...state,
        materialsState: {
          ...state.materialsState,
          [action.systemId]: undefined,
        },
      },
      sharedState,
      true
    );
  } else {
    return [
      {
        ...state,
        materialsState: {
          ...state.materialsState,
          [action.systemId]: newUpdateState,
        },
      },
    ];
  }
}

export function AddCustomMaterial(
  action: ReturnType<typeof Action.AddCustomMaterial>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;

  const system = project.systems.find((s) => s.id === action.systemId);
  if (!system) {
    return [state];
  }
  const material: Project.Material = {
    ...Project.createMaterial(uuid(), "custom", 0, ""),
    sortNo: Math.max(...system.materials.map((m) => m.sortNo)) + 1,
    included: true,
    quantity: 1,
    singleCost: 0,
    singleNetPrice: 0,
    singleSalesPrice: 0,
    currency: sharedState.market.currency,
  };
  const createMaterialCmd = graphQLMutation<
    GQLOps.ProjectState_CreateMaterialsMutation,
    GQLOps.ProjectState_CreateMaterialsMutationVariables,
    Action
  >(
    M.createMaterialsMutation,
    {
      input: {
        systemId: action.systemId,
        materials: [material],
      },
    },
    sharedState.market.name,
    () => Action.MaterialListUpdateDone(action.systemId, "created")
  );
  const updatedProject = Project.addMaterials(project, action.systemId, [material]);
  return [{ ...state, project: updatedProject }, createMaterialCmd];
}

export function RemoveMaterial(
  action: ReturnType<typeof Action.RemoveMaterial>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;

  const system = project.systems.find((s) => s.id === action.systemId);
  if (!system) {
    return [state];
  }
  const material = system.materials.find((m) => m.id === action.materialId);
  if (!material) {
    return [state];
  }
  const updatedProject = Project.removeMaterials(project, action.systemId, [material.id]);
  return [
    { ...state, project: updatedProject },
    graphQLMutation<
      GQLOps.ProjectState_RemoveMaterialsMutation,
      GQLOps.ProjectState_RemoveMaterialsMutationVariables,
      Action
    >(
      M.removeMaterialsMutation,
      {
        input: {
          ids: [material.id],
        },
      },
      sharedState.market.name
    ),
  ];
}

export function AddMaterialPackage(
  action: ReturnType<typeof Action.AddMaterialPackage>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  return updateMaterialListState(state, sharedState, action.systemId, (system) =>
    Materials.addPackage(sharedState.market, state.materialTables, system, action.packageName)
  );
}

export function RemoveMaterialPackage(
  action: ReturnType<typeof Action.RemoveMaterialPackage>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  return updateMaterialListState(state, sharedState, action.systemId, (system) =>
    Materials.removePackage(state.materialTables, system, action.packageName)
  );
}

export function ReceivedUpdatedPrices(
  action: ReturnType<typeof Action.ReceivedUpdatedPrices>,
  state: State,
  _: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const { project } = state;

  const { id: systemId, status, updatedMaterials /* , debugData */ } = action.mutation.updateSystemPrices;
  // eslint-disable-next-line no-console
  //console.log(debugData);
  const system = project.systems.find((s) => s.id === systemId);
  if (!system) {
    return [
      {
        ...state,
        materialsState: {
          ...state.materialsState,
          [systemId]: undefined,
        },
      },
    ];
  } else if (status !== "success") {
    const priceErrors = new Set([...state.priceErrors, systemId]);
    return [
      {
        ...state,
        priceErrors: priceErrors,
        materialsState: {
          ...state.materialsState,
          [systemId]: undefined,
        },
      },
    ];
  } else {
    const updatedMap = new Map(updatedMaterials.map((m) => [m.id, m]));
    const materialsToReplace = system.materials
      .filter((m) => updatedMap.has(m.id))
      .map((m) => ({
        ...m,
        singleCost: updatedMap.get(m.id)?.singleCost ?? null,
        singleSalesPrice: updatedMap.get(m.id)?.singleSalesPrice ?? null,
        singleNetPrice: updatedMap.get(m.id)?.singleNetPrice ?? null,
        currency: updatedMap.get(m.id)?.currency ?? null,
      }));
    const updatedProject = Project.replaceMaterials(project, systemId, materialsToReplace);
    const priceErrors = new Set([...state.priceErrors].filter((id) => id !== systemId));
    return [
      {
        ...state,
        project: updatedProject,
        priceErrors: priceErrors,
        materialsState: {
          ...state.materialsState,
          [systemId]: undefined,
        },
      },
    ];
  }
}

export function ReceivedUpdatedCustomMaterialPrice(
  action: ReturnType<typeof Action.ReceivedUpdatedCustomMaterialPrice>,
  state: State,
  _: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const { project } = state;

  const { systemId, materialId, status, updatedMaterial } = action.mutation.updateCustomMaterial;
  const disabledCustomMaterials = new Set<string>(state.disabledCustomMaterials);
  disabledCustomMaterials.delete(materialId);
  const system = project.systems.find((s) => s.id === systemId);
  if (!system) {
    return [{ ...state, disabledCustomMaterials }];
  }
  if (status !== "success") {
    return [{ ...state, disabledCustomMaterials }];
  }
  const currentMaterial = system.materials.find((m) => m.id === updatedMaterial?.id);
  if (!currentMaterial) {
    return [{ ...state, disabledCustomMaterials }];
  }
  const materialsToReplace: Project.Material = {
    ...currentMaterial,
    singleCost: updatedMaterial?.singleCost ?? null,
    singleSalesPrice: updatedMaterial?.singleSalesPrice ?? null,
    singleNetPrice: updatedMaterial?.singleNetPrice ?? null,
    currency: updatedMaterial?.currency ?? null,
    name: updatedMaterial?.name ?? currentMaterial.name,
  };
  const updatedProject = Project.replaceMaterials(project, systemId, [materialsToReplace]);
  return [{ ...state, disabledCustomMaterials, project: updatedProject }];
}

function updateMaterialListState(
  state: State,
  sharedState: SharedState.SharedState,
  systemId: string,
  doMaterialListUpdate: (system: Project.System) => Materials.UpdateListResult
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;

  const system = project.systems.find((s) => s.id === systemId);
  if (!system || !state.materialTables || state.materialsState[system.id]) {
    return [state];
  }

  const updatedListResult = doMaterialListUpdate(system);

  const deletedIds = [];
  const updated = [];
  const created = [];
  for (const material of system.materials) {
    const change = updatedListResult.changedMaterialIds.get(material.id);
    if (change === "deleted") {
      deletedIds.push(material.id);
    }
  }
  for (const material of updatedListResult.materials) {
    const change = updatedListResult.changedMaterialIds.get(material.id);
    if (change === "created") {
      created.push(material);
    } else if (change === "updated") {
      updated.push(material);
    }
  }

  let updatedProject = project;
  updatedProject = Project.removeMaterials(updatedProject, system.id, deletedIds);
  updatedProject = Project.addMaterials(updatedProject, system.id, created);
  updatedProject = Project.replaceMaterials(updatedProject, system.id, updated);

  const cmd = Cmd.batch([
    graphQLMutation<
      GQLOps.ProjectState_CreateMaterialsMutation,
      GQLOps.ProjectState_CreateMaterialsMutationVariables,
      Action
    >(
      M.createMaterialsMutation,
      {
        input: {
          systemId: system.id,
          materials: created,
        },
      },
      sharedState.market.name,
      () => Action.MaterialListUpdateDone(systemId, "created")
    ),
    graphQLMutation<
      GQLOps.ProjectState_RemoveMaterialsMutation,
      GQLOps.ProjectState_RemoveMaterialsMutationVariables,
      Action
    >(
      M.removeMaterialsMutation,
      {
        input: {
          ids: deletedIds,
        },
      },
      sharedState.market.name,
      () => Action.MaterialListUpdateDone(systemId, "deleted")
    ),
    graphQLMutation<
      GQLOps.ProjectState_UpdateMaterialsMutation,
      GQLOps.ProjectState_UpdateMaterialsMutationVariables,
      Action
    >(
      M.updateMaterialsMutation,
      {
        input: {
          materials: updated,
        },
      },
      sharedState.market.name,
      () => Action.MaterialListUpdateDone(systemId, "updated")
    ),
  ]);

  return [
    {
      ...state,
      project: updatedProject,
      materialsState: {
        ...state.materialsState,
        [system.id]: {
          type: "list-update",
          createdDone: false,
          updatedDone: false,
          removedDone: false,
        },
      },
    },
    cmd,
  ];
}
