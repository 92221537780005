import { RoomTemplatesTable, System } from "../../../project";
import { calculateBalanceExtractAirflows, ExtractBalanceResult } from "./balance-extract-airflow";
import { BalanceSupplyAirflowsResult, calculateBalanceSupplyAirflows } from "./balance-supply-airflow";
import { calculateRequiredAirflow } from "./system-airflow";

export function calculateBalancedAirflow(
  roomTemplates: RoomTemplatesTable,
  system: System
): {
  readonly balanceExtractResult: ExtractBalanceResult | undefined;
  readonly balanceSupplyResult: BalanceSupplyAirflowsResult | undefined;
  readonly requiredAirflow: number | undefined;
} {
  const requiredAirflow = calculateRequiredAirflow(roomTemplates, system);
  const balanceExtractResult =
    requiredAirflow !== undefined ? calculateBalanceExtractAirflows(roomTemplates, system, requiredAirflow) : undefined;
  const newRequiredAirflow = balanceExtractResult?.requiredAirflow;
  const balanceSupplyResult =
    newRequiredAirflow !== undefined ? calculateBalanceSupplyAirflows(system, newRequiredAirflow) : undefined;
  return {
    balanceExtractResult,
    balanceSupplyResult,
    requiredAirflow: newRequiredAirflow,
  };
}
