// eslint-disable-next-line functional/no-let
let textCanvas: HTMLCanvasElement | undefined = undefined;

export function getTextWidth(text: string, font: string): number {
  // re-use canvas object for better performance
  const canvas = textCanvas || (textCanvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  if (!context) {
    return 0;
  }
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

export function clampTextToWidth(text: string, width: number, font: string): string {
  const ellipsisWidth = getTextWidth("...", font);
  let textSoFar = "";
  for (const character of text) {
    const newTextEllipsis = `${textSoFar}${character}`.trim();
    const newTextWidth = getTextWidth(newTextEllipsis, font);
    if (newTextWidth > width - ellipsisWidth) {
      return textSoFar.trim() + "...";
    }
    textSoFar += character;
  }
  return textSoFar;
}
