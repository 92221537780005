import * as R from "ramda";
import { GraphQlUtils, SelectionToolApi, Project, Texts, Markets } from "..";
import { Category, MaterialTables, ValidationResult, UpdateListResult, GetItemPricesFn, MetaData } from "./types";
import { QueryGenerator } from "../query";
import * as G from "./germany";
import * as N from "./norway";
import * as Packages from "./shared/packages";
import { createCategoryName, getSortOrder } from "./shared/categories";

export async function getTables(
  market: Markets.Market,
  graphQlProductQuery: GraphQlUtils.GraphQlQueryFn,
  metaProductId: string
): Promise<MaterialTables> {
  if (market.materialList === "germany") {
    return G.getMaterials(graphQlProductQuery, market.materialProductId);
  } else if (market.materialList === "norway") {
    return N.getTables(graphQlProductQuery, metaProductId, market.materialProductId);
  } else {
    throw new Error(`Not a valid material list type, market: ${JSON.stringify(market)}`);
  }
}

export function* getMaterialsYield(market: Markets.Market, metaProductId: string): QueryGenerator<MaterialTables> {
  if (market.materialList === "germany") {
    return yield* G.getMaterialsYield(market.materialProductId);
  } else if (market.materialList === "norway") {
    return yield* N.getTablesYield(metaProductId, market.materialProductId);
  } else {
    throw new Error(`Not a valid material list type, market: ${JSON.stringify(market)}`);
  }
}

export function getSuitableAirUnits(
  market: Markets.Market,
  tables: MaterialTables,
  unitsFromSelectionTool: ReadonlyArray<SelectionToolApi.Unit>,
  requiredAirflow: number
): ReadonlyArray<Project.Material> {
  if (market.materialList === "germany" && tables.type === "germany") {
    return G.getSuitableAirUnits(requiredAirflow, tables.tables.airUnitsTable, unitsFromSelectionTool);
  } else if (market.materialList === "norway" && tables.type === "norway") {
    return N.getSuitableAirUnits(requiredAirflow, tables.tables.items, unitsFromSelectionTool);
  } else {
    throw new Error(`Not a valid material list type, market: ${JSON.stringify(market)}`);
  }
}

export function createMaterialListForUnit(
  market: Markets.Market,
  tables: MaterialTables,
  system: Project.System,
  selectedAirUnit: Project.Material
): UpdateListResult {
  if (market.materialList === "germany" && tables.type === "germany") {
    return G.createMaterialListForUnit(tables, system, selectedAirUnit);
  } else if (market.materialList === "norway" && tables.type === "norway") {
    return N.createMaterialListForUnit(tables, system, selectedAirUnit);
  } else {
    throw new Error(`Not a valid material list type, market: ${JSON.stringify(market)}`);
  }
}

export function updateMaterialListQuantities(
  market: Markets.Market,
  tables: MaterialTables,
  system: Project.System
): UpdateListResult {
  if (market.materialList === "germany" && tables.type === "germany") {
    return G.updateMaterialListQuantities(tables, system);
  } else if (market.materialList === "norway" && tables.type === "norway") {
    return N.updateMaterialListQuantities(tables, system);
  } else {
    throw new Error(`Not a valid material list type, market: ${JSON.stringify(market)}`);
  }
}

export function updateMaterialListAddNewItems(
  market: Markets.Market,
  tables: MaterialTables,
  system: Project.System,
  selectedAirUnit: Project.Material
): UpdateListResult {
  if (market.materialList === "germany" && tables.type === "germany") {
    return G.updateMaterialListAddNewItems(tables, system, selectedAirUnit);
  } else if (market.materialList === "norway" && tables.type === "norway") {
    return N.updateMaterialListAddNewItems(tables, system);
  } else {
    throw new Error(`Not a valid material list type, market: ${JSON.stringify(market)}`);
  }
}

export function addPackage(
  market: Markets.Market,
  tables: MaterialTables,
  system: Project.System,
  packageName: string
): UpdateListResult {
  if (market.materialList === "germany" && tables.type === "germany") {
    return Packages.addPackage(tables.itemPackages, tables.sortNos, system, packageName);
  } else if (market.materialList === "norway" && tables.type === "norway") {
    throw new Error(`Packages not supported by market: ${JSON.stringify(market)}`);
  } else {
    throw new Error(`Not a valid material list type, market: ${JSON.stringify(market)}`);
  }
}

export function removePackage(tables: MaterialTables, system: Project.System, packageName: string): UpdateListResult {
  return Packages.removePackage(system, tables.sortNos, packageName);
}

export function getPackageStatus(
  market: Markets.Market,
  tables: MaterialTables,
  system: Project.System
): ReadonlyArray<Packages.PackageStatus> {
  if (market.materialList === "germany" && tables.type === "germany") {
    return Packages.getPackageStatus(tables.itemPackages, system);
  } else {
    return [];
  }
}

export function validate(
  market: Markets.Market,
  metaProductQuery: Project.MetaProductQuery,
  tables: MaterialTables,
  system: Project.System
): ValidationResult {
  if (market.materialList === "germany" && tables.type === "germany") {
    return G.validateMaterialList(metaProductQuery, market.name, tables, system);
  } else if (market.materialList === "norway" && tables.type === "norway") {
    return N.validate(metaProductQuery, market, tables, system);
  } else {
    throw new Error(`Not a valid material list type, market: ${JSON.stringify(market)}`);
  }
}

export async function updatePrices(
  market: Markets.Market,
  materialTables: MaterialTables,
  materials: ReadonlyArray<Project.Material>,
  updateCustomItems: boolean,
  getItemPrices: GetItemPricesFn
): Promise<{ readonly materials: ReadonlyArray<Project.Material>; readonly debugData: string }> {
  if (market.materialList === "germany" && materialTables.type === "germany") {
    return { materials: await G.updatePrices(materials, updateCustomItems, getItemPrices), debugData: "" };
  } else if (market.materialList === "norway" && materialTables.type === "norway") {
    const tables = materialTables.tables;
    return N.updatePrices(
      tables.valvePriceAndCost,
      tables.fixedValvePrice,
      materials,
      updateCustomItems,
      getItemPrices
    );
  } else {
    throw new Error(`Not a valid material list type, market: ${JSON.stringify(market)}`);
  }
}

export function getMetaData(
  market: Markets.Market,
  materialTables: MaterialTables,
  material: Project.Material
): MetaData {
  if (market.materialList === "germany" && materialTables.type === "germany") {
    return G.getMetaData(materialTables, material);
  } else if (market.materialList === "norway" && materialTables.type === "norway") {
    return N.getMetaData(materialTables, material);
  } else {
    throw new Error(`Not a valid material list type, market: ${JSON.stringify(market)}`);
  }
}

export function categorizeMaterials(
  market: Markets.Market,
  materialTables: MaterialTables,
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<Category> {
  const metaDatas = materials.map((m) => getMetaData(market, materialTables, m));
  const categorized = categorizeMaterialMetaData(metaDatas);
  const sortedCategorized = categorized
    .map((c, i): [Category, number] => [c, i + 999999])
    .sort((a, b) => {
      const [aCategory, aOrder] = a;
      const [bCategory, bOrder] = b;
      const aSortNo = getSortOrder(materialTables.categories, aCategory.name, aOrder);
      const bSortNo = getSortOrder(materialTables.categories, bCategory.name, bOrder);
      return aSortNo - bSortNo;
    })
    .map(([category]) => category);
  return sortedCategorized;
}

export function getItemName(translate: Texts.TranslateFn, material: Project.Material): string {
  if (material.type === "valve") {
    return translate(Texts.texts.valve_type_name(material.itemNumber));
  } else if (material.type === "custom") {
    return material.name || "";
  } else {
    return translate(Texts.texts.itemName(material.itemNumber), "");
  }
}

export function getItemDescription(translate: Texts.TranslateFn, material: Project.Material): string | undefined {
  if (material.type === "valve") {
    return translate(Texts.texts.valve_type_desc(material.itemNumber));
  } else if (material.type === "custom") {
    return undefined;
  } else {
    return translate(Texts.texts.itemDescription(material.itemNumber), "") || undefined;
  }
}

export function getItemNumber(material: Project.Material): string {
  if (material.type === "valve") {
    return "B308";
  } else {
    return material.itemNumber;
  }
}

function categorizeMaterialMetaData(metaDatas: ReadonlyArray<MetaData>): ReadonlyArray<Category> {
  const groups = R.groupBy<MetaData>((item) => createCategoryName(item.group, item.category), metaDatas);
  const categoryNames = R.uniq(metaDatas.map((meta) => createCategoryName(meta.group, meta.category)));
  const categories = categoryNames.map((name) => {
    return {
      name: name,
      materials: (groups[name] || []).map((p) => p.material),
    };
  });
  return categories;
}
