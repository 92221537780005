import { withTw } from "./with-tw";

export type StyledInputProps = {
  readonly disabled?: boolean;
};
export const Input = withTw(
  "input",
  "w-full border py-4 px-4 h-24 focus:outline-none focus:shadow-btn-focus focus:border-primary-light spin-button-none",
  ({ disabled }: StyledInputProps) => (disabled ? "" : "hover:shadow-btn-hover")
);

export const TextArea = withTw(
  "textarea",
  "w-full border py-4 px-4 h-24 hover:shadow-btn-hover focus:outline-none focus:shadow-btn-focus focus:border-primary-light spin-button-none"
);

export type StyledSelectProps = {
  readonly disabled?: boolean;
  readonly isValid?: boolean;
};
export const Select = withTw(
  "select",
  "py-4 h-24 px-4 hover:shadow-btn-hover focus:outline-none focus:shadow-btn-focus focus:border-primary-light",
  ({ isValid = true }: StyledSelectProps) => (isValid ? "border" : "border border-danger"),
  ({ disabled = false }: StyledSelectProps) => (disabled ? "form-input-disabled" : "bg-white")
);

export const H1 = withTw("h1", "h1");
export const H2 = withTw("h2", "text-style-heading-h2");
export const H3 = withTw("h3", "text-style-heading-h3");
export const H4 = withTw("h3", "text-style-heading-h4");
