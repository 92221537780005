import { library, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import * as FalIcons from "./fal-icons";
//import * as FarIcons from "./far-icons";

export function registerFontAwesomeIcons(): void {
  const falIcons = Object.values(FalIcons) as ReadonlyArray<IconDefinition>;
  //const farIcons = Object.values(FarIcons) as ReadonlyArray<IconDefinition>;
  library.add(...falIcons);
  //library.add(...farIcons);
}
