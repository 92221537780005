import { MaterialTables } from ".";
import { Project } from "../..";
import { calculateSystemValveQuantity } from "./valves";

export function updateValveQuantities(
  { items, tables }: MaterialTables,
  materials: ReadonlyArray<Project.Material>,
  system: Project.System
): ReadonlyArray<Project.Material> {
  const { supplyValves, extractValves } = calculateSystemValveQuantity(system);
  const combinedValves = supplyValves + extractValves;
  return materials.map((m) => {
    if (items[m.itemNumber]?.type === "valve") {
      const row = tables.valveTable.find((r) => r.item_number === m.itemNumber);
      const isSupplyValve = row?.supply_air === "1";
      const isExtractValve = row?.extract_air === "1";
      const newQuantity =
        isSupplyValve && isExtractValve
          ? combinedValves
          : isSupplyValve
          ? supplyValves
          : isExtractValve
          ? extractValves
          : m.quantity;
      return {
        ...m,
        quantity: newQuantity,
      };
    } else {
      return m;
    }
  });
}
