import gql from "graphql-tag";

export const schema = gql`
  extend type Mutation {
    createQuoteRequest(input: CreateQuoteRequestInput!): CreateQuoteRequestPayload!
    removeQuoteRequest(input: RemoveQuoteRequestInput!): RemoveQuoteRequestPayload!
  }

  type QuoteRequest {
    id: ID!
    market: String!
    requestedBy: String!
    requestDate: Date!
  }

  input CreateQuoteRequestInput {
    id: ID!
    projectId: ID!
    market: String!
  }

  type CreateQuoteRequestPayload {
    quoteRequestSent: Boolean!
    quoteRequest: QuoteRequest
  }

  input RemoveQuoteRequestInput {
    projectId: ID!
    market: String!
  }

  type RemoveQuoteRequestPayload {
    id: ID
  }
`;
