import * as React from "react";
import Lightbox from "react-image-lightbox";

export interface ImageLightboxProps {
  readonly mainSrc: string;
  readonly isOpen: boolean;
  readonly close: () => void;
}

export function ImageLightbox(props: ImageLightboxProps): React.ReactElement<ImageLightboxProps> | null {
  return props.isOpen ? <Lightbox mainSrc={props.mainSrc} onCloseRequest={() => props.close()} /> : null;
}
