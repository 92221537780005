import * as React from "react";
import { AbstractDocJsx as ADX, AbstractDoc as AD } from "abstract-document";
import { createTextStyle } from "./standard-style";
import { TranslateFn, texts } from "../../lang-texts";

const { Group, Table, TableRow, TableCell, Image, Paragraph, TextRun, TextField, render } = ADX;

export function createFooter(
  normalImg: AD.ImageResource.ImageResource | undefined,
  translate: TranslateFn
  // eslint-disable-next-line functional/prefer-readonly-type
): AD.SectionElement.SectionElement[] {
  return [
    render(
      <Group>
        <Table columnWidths={[Infinity, 80]}>
          <TableRow>
            <TableCell />
            <TableCell>
              <Paragraph>
                {normalImg ? (
                  <Image
                    width={normalImg.abstractImage.size.width * 0.5}
                    height={normalImg.abstractImage.size.height * 0.5}
                    imageResource={normalImg}
                  />
                ) : (
                  <TextRun text="" />
                )}
              </Paragraph>
            </TableCell>
          </TableRow>
        </Table>
        <Group>
          <Paragraph style={AD.ParagraphStyle.create({ alignment: "Start" })}>
            <TextRun style={createTextStyle(false, false, 6, "black")} text={translate(texts.systemair_rvs)} />
            <TextRun
              style={createTextStyle(false, false, 6, "black")}
              text={` | ${new Date().toISOString().slice(0, 10)}`}
            />
          </Paragraph>
          <Paragraph style={AD.ParagraphStyle.create({ alignment: "End" })}>
            <TextField style={AD.TextStyle.create({ color: "#818181" })} fieldType="PageNumber" />
            <TextRun style={AD.TextStyle.create({ color: "#818181" })} text=" / " />
            <TextField style={AD.TextStyle.create({ color: "#818181" })} fieldType="TotalPages" />
          </Paragraph>
        </Group>
      </Group>
    ),
  ];
}

export function createFooterTOC(
  _logo: AD.ImageResource.ImageResource | undefined,
  translate: TranslateFn
  // eslint-disable-next-line functional/prefer-readonly-type
): AD.SectionElement.SectionElement[] {
  return [
    render(
      <Group>
        <Group>
          <Paragraph style={AD.ParagraphStyle.create({ alignment: "Start", position: "absolute" })}>
            <TextRun style={createTextStyle(false, false, 6, "black")} text={translate(texts.systemair_rvs)} />
            <TextRun
              style={createTextStyle(false, false, 6, "black")}
              text={` | ${new Date().toISOString().slice(0, 10)}`}
            />
          </Paragraph>
          <Paragraph style={AD.ParagraphStyle.create({ alignment: "End", position: "absolute" })}>
            <TextField style={AD.TextStyle.create({ color: "#818181" })} fieldType="PageNumber" />
            <TextRun style={AD.TextStyle.create({ color: "#818181" })} text=" / " />
            <TextField style={AD.TextStyle.create({ color: "#818181" })} fieldType="TotalPages" />
          </Paragraph>
        </Group>
      </Group>
    ),
  ];
}

export function createFooterFirst(
  logo: AD.ImageResource.ImageResource | undefined,
  _translate: TranslateFn
  // eslint-disable-next-line functional/prefer-readonly-type
): AD.SectionElement.SectionElement[] {
  return [
    render(
      <Group>
        <Group>
          <Paragraph
            style={AD.ParagraphStyle.create({
              alignment: "End",
            })}
          >
            {logo && <Image imageResource={logo} width={190} height={50} />}
          </Paragraph>
        </Group>
      </Group>
    ),
  ];
}
