import gql from "graphql-tag";
import { User } from "..";
import * as GqlOps from "../generated/generated-operations";
import { defaultLanguage, LanguageCode } from "../lang-texts";

export type MarketTable = GqlOps.MarketTableFragment["Market"];
export type MarketQuery = GqlOps.GetMarketsQuery;
export type MarketQueryVariables = GqlOps.GetMarketsQueryVariables;

export const marketModulesFragment = gql`
  fragment marketModules on Modules {
    custom_tables {
      ...marketTable
    }
  }

  fragment marketTable on Module_custom_tables {
    Market {
      market_name
      language
      division
      default_customer_number
      currency
      facility
      order_type
      gaeb_enabled
      cost_enabled
      discount_enabled
      ventilation_concept_enabled
      additional_documents_enabled
      calculator
      material_list
      material_list_product
      shop_language_code
      m3_language_code
      organization_shared_email
      organization_shared_email_rights
      request_quote_email
    }
  }
`;

export const marketQuery = gql`
  query getMarkets($productId: ID!) {
    product(id: $productId) {
      modules {
        ...marketModules
      }
    }
  }
  ${marketModulesFragment}
`;

export const defaultMarket: Market = {
  name: "market1",
  language: defaultLanguage,
  division: "0",
  defaultCustomerNumber: "",
  currency: "",
  facility: "",
  orderType: "",
  gaebEnabled: false,
  costEnabled: false,
  discountEnabled: false,
  ventilationConceptEnabled: false,
  additionalDocumentsEnabled: false,
  calculator: "germany",
  materialList: "germany",
  materialProductId: "e8969747-e3c6-4743-95a5-e6b429c466fb",
  shopLanguageCode: "en",
  m3LanguageCode: "EN",
  organizationSharedEmail: "",
  organizationSharedEmailRights: "",
  requestQuoteEmail: undefined,
};

export interface Market {
  readonly name: string;
  readonly language: LanguageCode;
  readonly division: string;
  readonly defaultCustomerNumber: string;
  readonly currency: string;
  readonly facility: string;
  readonly orderType: string;
  readonly gaebEnabled: boolean;
  readonly costEnabled: boolean;
  readonly discountEnabled: boolean;
  readonly ventilationConceptEnabled: boolean;
  readonly additionalDocumentsEnabled: boolean;
  readonly calculator: string;
  readonly materialList: string;
  readonly materialProductId: string;
  readonly shopLanguageCode: string;
  readonly m3LanguageCode: string;
  readonly organizationSharedEmail: string;
  readonly organizationSharedEmailRights: string;
  readonly requestQuoteEmail: string | undefined;
}

export function getMarkets(marketTable: MarketTable, activeUser: User.ActiveUser): ReadonlyArray<Market> {
  const markets = marketTable
    .map(
      (m): Market => ({
        name: m.market_name || "",
        language: (m.language || defaultLanguage) as LanguageCode,
        division: m.division || "",
        defaultCustomerNumber: m.default_customer_number || "",
        currency: m.currency || "",
        facility: m.facility || "",
        orderType: m.order_type || "",
        gaebEnabled: m.gaeb_enabled === "True",
        costEnabled: m.cost_enabled === 1,
        discountEnabled: m.discount_enabled === 1,
        ventilationConceptEnabled: m.ventilation_concept_enabled === 1,
        additionalDocumentsEnabled: m.additional_documents_enabled === 1,
        calculator: m.calculator || "germany",
        materialList: m.material_list || "germany",
        materialProductId: m.material_list_product || "",
        shopLanguageCode: m.shop_language_code || "en",
        m3LanguageCode: m.m3_language_code || "EN",
        organizationSharedEmail: m.organization_shared_email || "",
        organizationSharedEmailRights: m.organization_shared_email_rights || "",
        requestQuoteEmail: m.request_quote_email || undefined,
      })
    )
    .filter((m) => !!m.name)
    .filter((m) => {
      const emailParts = activeUser.email.split("@");
      const emailHost = emailParts[emailParts.length - 1];
      if (m.name === "Norway" && !(emailHost === "divid.se" || emailHost === "systemair.no")) {
        return false;
      } else {
        return true;
      }
    });
  if (markets.length > 0) {
    return markets;
  } else {
    return [defaultMarket];
  }
}

export function getMarketOrDefault(marketTable: MarketTable, marketName: string, activeUser: User.ActiveUser): Market {
  const markets = getMarkets(marketTable, activeUser);
  return markets.find((m) => m.name === marketName) || markets[0] || defaultMarket;
}

export function getMarket(
  marketTable: MarketTable,
  marketName: string,
  activeUser: User.ActiveUser
): Market | undefined {
  const markets = getMarkets(marketTable, activeUser);
  return markets.find((m) => m.name === marketName);
}

export function isEmailInOrganization(userEmail: string, market: Market): boolean {
  if (!market.organizationSharedEmail || !market.organizationSharedEmailRights) {
    return false;
  }
  return userEmail.endsWith(market.organizationSharedEmail);
}
