import * as Project from "../../project";
import { ReportQueryParams } from "..";
import { graphQLQuery, QueryGenerator } from "../../query";
import { AdapterType, Response } from "./types";
import { getPageProps } from "../common";

export function* query(params: ReportQueryParams): QueryGenerator<Response> {
  const projectData = yield* graphQLQuery<Project.ProjectQuery, Project.ProjectQueryVariables>(Project.query, {
    projectId: params.projectId,
  });
  return { projectData };
}

export const execute: AdapterType = async (projectReportParams, common, response) => {
  const pageProps = await getPageProps(projectReportParams, common);

  return {
    data: ["hello ", "world ", projectReportParams.projectId],
    projectData: response.projectData,
    pageProps: pageProps,
    translate: projectReportParams.translate,
  };
};
