import { Dispatch } from "@typescript-tea/core";
import { System } from "@rvs/shared/src/project";
import { SharedState } from "@rvs/client-infra";
import { Texts } from "@rvs/shared";
import React from "react";
import * as State from "./state";
import * as ProjectState from "../../project-state";
import { Alert, Expander, Icon, withTw } from "../../../../elements";

const texts = Texts.texts;

const StyledDiv = withTw("div", "cursor-pointer btn btn-primary text-xs btn-tiny");

export function View(props: {
  readonly state: State.State;
  readonly projectState: ProjectState.State;
  readonly sharedState: SharedState.SharedState;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
}): JSX.Element {
  const { state, projectState, sharedState, dispatch, dispatchProject } = props;
  const { translate } = sharedState;
  const { project } = projectState;

  return (
    <div className="flex flex-col space-y-16">
      <Alert type="info" className="mb-16">
        <span>{translate(texts.additional_documents_note)}</span>
      </Alert>
      {project.systems.map((system) => {
        const systemName = system.name || `${translate(texts.system)} ${system.sortNo}`;

        return (
          <div key={system.id} className="pb-24">
            <Expander
              translate={translate}
              header={systemName}
              closed={state.closedItems.has(system.id)}
              onToggleClosed={() => dispatch(State.Action.ToggleExpanded(system.id))}
            >
              <div className="ml-32">
                <SystemPdfs dispatchProject={dispatchProject} system={system} translate={translate} />
              </div>
            </Expander>
          </div>
        );
      })}
    </div>
  );
}

function SystemPdfs({
  dispatchProject,
  system,
  translate,
}: {
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly system: System;
  readonly translate: Texts.TranslateFn;
}): JSX.Element {
  return (
    <div>
      {system.additionalDocuments && system.additionalDocuments.length > 0 && (
        <table className="w-[50em]">
          <thead>
            <tr>
              <th className="w-[47em]">{translate(texts.name)}</th>
            </tr>
          </thead>
          <tbody>
            {system.additionalDocuments.map((document, index) => {
              const border = index === 0 ? "border-t-2" : "border-t-1";

              const editDocumentId = `browse_files_${document.id}`;

              return (
                <tr key={document.id}>
                  <td>{document.name}</td>
                  <td className={`p-8 ${border}`}>
                    <Icon
                      icon={"download"}
                      onClick={() => {
                        const linkSource = `data:application/pdf;base64,${document.pdf}`;
                        saveAs(linkSource, `RVS_additional_document_${document.name}`);
                      }}
                      message={translate(texts.download_document)}
                    />
                  </td>
                  <td className={`p-8 ${border}`}>
                    <PdfFileInput
                      uniqueId={editDocumentId}
                      onInput={(file, data) =>
                        dispatchProject(
                          ProjectState.Action.UpdateSystemPdf(system.id, {
                            id: document.id,
                            pdf: data,
                            name: file.name,
                          })
                        )
                      }
                    >
                      <Icon className="cursor-pointer" icon={"upload"} message={translate(texts.upload_document)} />
                    </PdfFileInput>
                  </td>

                  <td className={`p-8 ${border}`}>
                    <Icon
                      icon={"trash-alt"}
                      onClick={() => {
                        dispatchProject(ProjectState.Action.RemoveSystemPdf(system.id, document.id));
                      }}
                      message={translate(texts.remove_document)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      <div className="mt-16">
        <PdfFileInput
          uniqueId={`${system.id}_pdf_upload`}
          onInput={(file, data) => dispatchProject(ProjectState.Action.CreateSystemPdf(system.id, file.name, data))}
        >
          <StyledDiv>{translate(texts.upload_document)}</StyledDiv>
        </PdfFileInput>
      </div>
    </div>
  );
}

function PdfFileInput({
  uniqueId,
  onInput,
  children,
}: {
  readonly uniqueId: string;
  readonly onInput: (file: File, data: string) => void;
  readonly children: JSX.Element;
}): JSX.Element {
  return (
    <div>
      <input
        id={uniqueId}
        type="file"
        multiple={false}
        accept={".pdf"}
        hidden
        onChange={(e) => {
          const file = e.target.files?.item(0) || undefined;

          if (!file || !file.name.endsWith(".pdf")) {
            return;
          }

          getFileData(file).then((data) => {
            if (!data) {
              return;
            }
            onInput(file, data);
          });
        }}
      />
      <label htmlFor={uniqueId}>{children}</label>
    </div>
  );
}

async function getFileData(file: File): Promise<string | undefined> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function () {
      const result = reader.result;
      if (typeof result === "string") {
        resolve(result.split(",")[1]);
      } else {
        resolve(undefined);
      }
    };
    reader.readAsDataURL(file);
  });
}
