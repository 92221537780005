import React from "react";
import { Textfield } from "../../../../../elements/text-field";

export function InputRow({
  label,
  value,
  onChange,
  disabled,
}: {
  readonly label: string;
  readonly value: string | undefined | null;
  readonly onChange: (newValue: string | null) => void;
  readonly disabled: boolean;
}): JSX.Element {
  return (
    <div className="flex flex-row space-x-8">
      <div className="min-w-700">{label}</div>
      <Textfield
        disabled={disabled}
        value={value || ""}
        onChange={(v) => onChange(v === "" ? null : v)}
        debounce={true}
        debounceTime={500}
      />
    </div>
  );
}
