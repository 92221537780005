import { texts } from "../../lang-texts";
import { Error } from "./types";

export function validateRequired<T>(property: keyof T, obj: T): ReadonlyArray<Error<T>> {
  const value = obj[property];
  if (value === undefined || value === null || (typeof value === "string" && value === "")) {
    return [{ property, message: texts.error_value_required }];
  }
  return [];
}

export function validateNumber<T>(property: keyof T, obj: T): ReadonlyArray<Error<T>> {
  const value = obj[property];
  if (typeof value !== "number" || !Number.isFinite(value)) {
    return [{ property, message: texts.error_not_a_valid_number }];
  }
  if (typeof value === "number" && value < 0) {
    return [{ property, message: texts.error_number_must_be_positive }];
  }
  return [];
}

export function validateNumberNonZero<T>(property: keyof T, obj: T): ReadonlyArray<Error<T>> {
  const value = obj[property];
  if (typeof value === "number" && value === 0) {
    return [{ property, message: texts.error_cant_be_zero }];
  }
  return [];
}

export function validateOptions<T>(options: ReadonlyArray<string>, property: keyof T, obj: T): ReadonlyArray<Error<T>> {
  const value = obj[property];
  if (typeof value === "string" && !options.some((o) => o === value)) {
    return [{ property, message: texts.error_not_a_valid_option }];
  }
  return [];
}
