import * as Gql from "../generated/generated-operations";
import { Row, Table } from "../graphql-utils";

export type Project = Omit<
  Gql.Project,
  | "__typename"
  | "shares"
  | "ventilationConcept"
  | "additionalDocuments"
  | "userQuoteRequest"
  | "organizationQuoteRequests"
> & {
  readonly shares: ReadonlyArray<ProjectShare>;
  readonly ventilationConcept: VentilationConceptProject | null;
  readonly userQuoteRequest: QuoteRequest | null;
  readonly organizationQuoteRequests: ReadonlyArray<QuoteRequest>;
};
export type ProjectShare = Pick<Gql.ProjectShare, "id" | "email" | "permissions">;
export type QuoteRequest = Omit<Gql.QuoteRequest, "__typename">;
export type System = Omit<Gql.System, "__typename">;
export type SystemPdf = Omit<Gql.SystemPdf, "__typename">;
export type Room = Omit<Gql.Room, "__typename">;
export type Material = Omit<Gql.Material, "__typename">;
export type VentilationConceptSystem = Omit<Gql.VentilationConceptSystem, "__typename">;
export type VentilationConceptProject = Omit<Gql.VentilationConceptProject, "__typename">;
export type Permissions = "read" | "read-write";
export type ProjectStatus = {
  readonly id: Gql.ProjectStatus["id"];
  readonly exists: Gql.ProjectStatus["exists"];
  readonly access: Gql.ProjectStatus["access"];
};

export type VentilationConceptWindConditions = "low" | "high";
export function isVentilationConceptWindConditions(str: unknown): str is VentilationConceptWindConditions {
  return str === "low" || str === "high";
}

export type VentilationConceptBuildingType = "single_floor" | "multi_floor";
export function isVentilationConceptBuildingType(str: unknown): str is VentilationConceptBuildingType {
  return str === "single_floor" || str === "multi_floor";
}

export type VentilationConceptBuildingTightnessInput = "manually" | "standard";
export function isVentilationConceptBuildingTightnessInput(
  str: unknown
): str is VentilationConceptBuildingTightnessInput {
  return str === "manually" || str === "standard";
}

export type VentilationConceptThermalProtection = "low" | "high";
export function isVentilationConceptThermalProtection(str: unknown): str is VentilationConceptThermalProtection {
  return str === "low" || str === "high";
}

export type VentilationConceptOccupancy = "low" | "high";
export function isVentilationConceptOccupancy(str: unknown): str is VentilationConceptOccupancy {
  return str === "low" || str === "high";
}

export type VentilationConceptStatus = "red" | "orange" | "green";
export function isVentilationConceptStatus(str: unknown): str is VentilationConceptStatus {
  return str === "red" || str === "orange" || str === "green";
}

export type RoomFloor = Row<Gql.RoomFloor>;
export type RoomTemplates = Pick<
  Gql.RoomTemplates,
  | "key"
  | "text_id"
  | "market"
  | "air_type"
  | "default_height"
  | "default_supply_air_factor"
  | "supply_air_factor_range"
  | "default_extract_airflow"
  | "airflow_calculation"
  | "min_airflow"
  | "forced_min_airflow"
  | "default_number_of_valves"
>;
export type PipeDiameter = Row<Gql.PipeDiameter>;

export type RoomFloorTable = Table<RoomFloor>;
export type RoomTemplatesTable = Table<RoomTemplates>;
export type PipeDiameterTable = Table<PipeDiameter>;
export type MarketValveTypesTable = Table<Row<Gql.MarketValveTypes>>;

export type RoomType = "extract" | "supply" | "over_pressure";

export type MaterialType = "standard" | "valve" | "custom";
