import * as React from "react";
import { AbstractDocJsx as ADX, AbstractDoc as AD } from "abstract-document";
// import * as AC from "abstract-chart";
import * as AI from "abstract-image";
import { CreatorProps, CreatorType } from "./types";
import * as Common from "../common";
import * as GQLOps from "../../generated/generated-operations";
import { key, texts, TranslateFn } from "../../lang-texts";
import { Project } from "../..";
import { createNote, createText } from "../common";
import { h1 } from "../common/elements";
import { getTextWidth } from "../../utils";

const { AbstractDoc, Section, Paragraph, Image, Table, TableRow, TableCell, Group, TextRun, render } = ADX;

const maxImageWidth = 476;
const imagePadding = 40;
const maxHeight = 700;

export const create: CreatorType = (props) => {
  const styles = Common.styles();
  const numberingDefinitions = Common.numberingDefinitions(styles);
  const fonts = Common.fonts(props.pageProps.fonts);

  const [roof, roofHeight] = createRoof(props.translate, maxImageWidth);

  const system = props.projectResponse.project?.systems.find((sys) => sys.id === props.pageProps.systemId);
  const systemDrawings =
    system && system.rooms.length > 0 && createSystemDrawing(props.translate, system, props.floorData, roofHeight);

  const noteImage = createNote();

  const fontSize = AD.TextStyle.create({ fontSize: 9 });
  const headerFont = AD.TextStyle.create({ fontSize: 10, bold: true });

  const assemblyInfo = [
    <Paragraph key={"1"}>
      <TextRun style={headerFont} text={`${props.translate(texts.assembly_instructions)}:`} />
    </Paragraph>,
    <Paragraph key={"2"}>
      <TextRun style={fontSize} text={props.translate(texts.max_distance_pipes)} />
    </Paragraph>,
  ];

  const legend = createLegend(props);

  const doc = render(
    <AbstractDoc fonts={fonts} styles={styles} numberingDefinitions={numberingDefinitions}>
      <Section id={Common.pageId(props.pageProps)} page={Common.cataloguePage(props.pageProps)}>
        {h1(props.translate(texts.air_distribution))}

        {systemDrawings ? (
          <Group>
            <Paragraph>{roof}</Paragraph>
            {systemDrawings}
            <Table
              columnWidths={[noteImage.abstractImage.size.width + 5, 165, 15, Infinity]}
              style={AD.TableStyle.create({
                margins: AD.LayoutFoundation.create({ left: imagePadding / 2, right: imagePadding / 2 }),
                cellStyle: AD.TableCellStyle.create({ verticalAlignment: "Top" }),
              })}
            >
              <TableRow>
                <TableCell>
                  <Paragraph>
                    <Image
                      width={noteImage.abstractImage.size.width}
                      height={noteImage.abstractImage.size.height}
                      imageResource={noteImage}
                    />
                  </Paragraph>
                </TableCell>
                <TableCell>{assemblyInfo}</TableCell>

                <TableCell></TableCell>

                <TableCell>{legend}</TableCell>
              </TableRow>
            </Table>
          </Group>
        ) : (
          <Paragraph>
            <TextRun text={props.translate(texts.no_rooms_exists_on_this_system)} />
          </Paragraph>
        )}
      </Section>
    </AbstractDoc>
  );
  return doc;
};

function createLegend(props: CreatorProps): JSX.Element {
  const fontSize = AD.TextStyle.create({ fontSize: 9 });
  const headerFont = AD.TextStyle.create({ fontSize: 10, bold: true });
  const centeredParagrah = AD.ParagraphStyle.create({
    textStyle: fontSize,
    alignment: "Center",
  });

  const extractArrow = createArrowImage("extract");
  const supplyArrow = createArrowImage("supply");

  const cellBorders = AD.TableCellStyle.create({
    borders: AD.LayoutFoundation.create({ top: 0.5, left: 0.5, right: 0.5, bottom: 0.5 }),
  });

  const cellBorderBottom = AD.TableCellStyle.create({
    borders: AD.LayoutFoundation.create({ bottom: 0.5 }),
    padding: AD.LayoutFoundation.create({ top: 1, bottom: 1 }),
  });

  const cellPaddingTop = AD.TableCellStyle.create({
    padding: AD.LayoutFoundation.create({ top: 2 }),
  });

  return (
    <Group>
      <Paragraph>
        <TextRun style={headerFont} text={`${props.translate(texts.legend)}:`} />
      </Paragraph>
      <Table columnWidths={[85, 50, 95]}>
        <TableRow>
          <TableCell>
            <Table
              columnWidths={[40, 32 + 5]}
              style={AD.TableStyle.create({
                cellStyle: AD.TableCellStyle.create({ verticalAlignment: "Top" }),
              })}
            >
              <TableRow>
                <TableCell>
                  <Paragraph style={AD.ParagraphStyle.create({ margins: AD.LayoutFoundation.create({ top: 3 }) })}>
                    <TextRun style={fontSize} text={props.translate(texts.extract)} />
                  </Paragraph>
                </TableCell>

                <TableCell>
                  <Paragraph style={AD.ParagraphStyle.create({ margins: AD.LayoutFoundation.create({ top: 5 }) })}>
                    {extractArrow}
                  </Paragraph>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Paragraph>
                    <TextRun style={fontSize} text={props.translate(texts.supply)} />
                  </Paragraph>
                </TableCell>

                <TableCell>
                  <Paragraph>{supplyArrow}</Paragraph>
                </TableCell>
              </TableRow>
            </Table>
          </TableCell>
          <TableCell style={cellBorders}>
            <Paragraph style={centeredParagrah}>
              <TextRun text={props.translate(texts.air_flow)} />
            </Paragraph>
            <Paragraph style={centeredParagrah}>
              <TextRun text={`[m`} />
              <TextRun style={AD.TextStyle.create({ verticalPosition: 0, fontScale: 0.7 })} text={`3`} />
              <TextRun text={`/h]`} />
            </Paragraph>
          </TableCell>
          <TableCell style={cellBorders}>
            <Table columnWidths={[Infinity]}>
              <TableRow>
                <TableCell style={cellBorderBottom}>
                  <Paragraph style={centeredParagrah}>
                    <TextRun style={fontSize} text={props.translate(texts.room_name)} />
                  </Paragraph>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={cellPaddingTop}>
                  <Paragraph style={centeredParagrah}>
                    <TextRun style={fontSize} text={props.translate(texts.valve_number)} />
                  </Paragraph>
                </TableCell>
              </TableRow>
            </Table>
          </TableCell>
        </TableRow>
      </Table>
      <Paragraph style={AD.ParagraphStyle.create({ margins: AD.LayoutFoundation.create({ top: 2 }) })}>
        <TextRun style={AD.TextStyle.create({ fontSize: 8 })} text={props.translate(texts.legend_number_of_arrows)} />
      </Paragraph>
    </Group>
  );
}

function createSystemDrawing(
  translate: TranslateFn,
  system: GQLOps.Project_SystemFragment,
  floorData: GQLOps.RoomFloorTableFragment | undefined,
  yStart: number
): JSX.Element {
  const components = [];
  const floors =
    floorData?.RoomFloor.flatMap((floor) =>
      floor.key && floor.text_id ? [{ key: floor.key, text_id: floor.text_id }] : []
    ).reverse() ?? [];

  let yCurrent = yStart;
  for (const floorType of floors) {
    const roomsOnFloor = system.rooms.filter((room) => room.floorType === floorType.key);
    if (roomsOnFloor.length === 0) {
      continue;
    }
    const [floor, floorHeight] = createFloor(translate, roomsOnFloor, floorType.text_id, yCurrent);
    components.push(floor);
    yCurrent = floorHeight;
    if (yCurrent > maxHeight) {
      yCurrent = 0;
    }
  }

  return (
    <Table
      columnWidths={[Infinity, Infinity, 0]}
      style={AD.TableStyle.create({ margins: AD.LayoutFoundation.create({ bottom: 20, top: -5 }) })}
    >
      {components}
    </Table>
  );
}

function createFloor(
  translate: TranslateFn,
  rooms: readonly GQLOps.Project_RoomFragment[],
  floorType: string,
  yStart: number
): readonly [readonly JSX.Element[], number] {
  const supplyRooms = rooms.filter((room) => Project.isSupplyRoom(room));
  const extractRooms = rooms.filter((room) => Project.isExtractRoom(room));

  let supplySplit = getSplitIndexes(supplyRooms, yStart);
  let extractSplit = getSplitIndexes(extractRooms, yStart);

  if (supplySplit.resetY && !extractSplit.resetY) {
    extractSplit = getSplitIndexes(extractRooms, 0);
  } else if (!supplySplit.resetY && extractSplit.resetY) {
    supplySplit = getSplitIndexes(supplyRooms, 0);
  }

  const parts = [];
  let previousSupplySplitIndex = 0;
  let previousExtractSplitIndex = 0;

  const floorParts = Math.max(supplySplit.breakIndex.length + 1, extractSplit.breakIndex.length + 1, 1);

  for (let index = 0; index < floorParts; index++) {
    const supplySplitIndex = supplySplit.breakIndex[index] || supplyRooms.length;
    const extractSplitIndex = extractSplit.breakIndex[index] || extractRooms.length;

    const currSupplyRooms = supplyRooms.slice(previousSupplySplitIndex, supplySplitIndex);
    const currExtractRooms = extractRooms.slice(previousExtractSplitIndex, extractSplitIndex);

    parts.push(createFloorPart(translate, currSupplyRooms, currExtractRooms, floorType));

    previousSupplySplitIndex = supplySplitIndex;
    previousExtractSplitIndex = extractSplitIndex;
  }

  return [parts, Math.max(supplySplit.yCurrent, extractSplit.yCurrent) + 40];
}

function getSplitIndexes(
  roomList: readonly GQLOps.Project_RoomFragment[],
  yStart: number
): { readonly breakIndex: readonly number[]; readonly yCurrent: number; readonly resetY: boolean } {
  const estimateSmallRoomHeight = 50;
  const estimateBigRoomHeight = 60;
  let yCurrent = yStart;
  let roomsInPart = 0;
  let resetY = false;
  const breakIndex = [];
  for (let index = 0; index < roomList.length; index++) {
    const room = roomList[index];
    const maxNameWidth = 130;
    const roomHeight =
      getTextWidth(room.name, "7 Helvetica") > maxNameWidth ? estimateBigRoomHeight : estimateSmallRoomHeight;

    const acc = roomsInPart + (room.valves || 1) * roomHeight;
    if (acc + yCurrent > maxHeight && index !== 0) {
      breakIndex.push(index);
      roomsInPart = (room.valves || 1) * roomHeight;
      yCurrent = 0;
    } else if (acc + yCurrent > maxHeight && index === 0) {
      roomsInPart = acc;
      yCurrent = 0;
      resetY = true;
    } else {
      roomsInPart = acc;
    }
  }
  yCurrent += roomsInPart;
  return { breakIndex, yCurrent, resetY };
}

function createFloorPart(
  translate: TranslateFn,
  supplyRooms: readonly GQLOps.Project_RoomFragment[],
  extractRooms: readonly GQLOps.Project_RoomFragment[],
  floorType: string
): JSX.Element {
  const extractRoomRows = extractRooms.map((room) => createRoom(translate, room, "extract"));
  const supplyRoomsRows = supplyRooms.map((room) => createRoom(translate, room, "supply"));

  const extractCellStyle = AD.TableCellStyle.create({
    borders: { top: 1.5, bottom: 1.5, right: 1, left: 1.5 },
    padding: { top: 20, bottom: 10, right: 20, left: 20 },
    verticalAlignment: "Top",
  });
  const supplyCellStyle = AD.TableCellStyle.create({
    borders: { top: 1.5, bottom: 1.5, right: 1.5, left: 1 },
    padding: { top: 20, bottom: 10, right: 20, left: 20 },
    verticalAlignment: "Top",
  });

  const maxExtractRoomNameWidth = extractRooms.map((room) => getTextWidth(room.name, "7 Helvetica"));
  const maxSupplyRoomNameWidth = supplyRooms.map((room) => getTextWidth(room.name, "7 Helvetica"));
  const minNameWidth = 80;
  const maxNameWidth = 130;
  const extractNameWidth = Math.min(maxNameWidth, Math.max(minNameWidth, ...maxExtractRoomNameWidth));
  const supplyNameWidth = Math.min(maxNameWidth, Math.max(minNameWidth, ...maxSupplyRoomNameWidth));
  const airflowWidth = 30;
  const arrowWidth = 37;

  const floorName = translate(key(floorType));
  const floorNameWidth = getTextWidth(floorName, "7 Helvetica") + 10;

  return (
    <TableRow>
      <TableCell style={extractCellStyle}>
        <Table
          columnWidths={[extractNameWidth, airflowWidth, arrowWidth]}
          style={AD.TableStyle.create({ alignment: "Center" })}
        >
          {extractRoomRows}
        </Table>
      </TableCell>
      <TableCell style={supplyCellStyle}>
        <Table
          columnWidths={[arrowWidth, airflowWidth, supplyNameWidth]}
          style={AD.TableStyle.create({ alignment: "Center" })}
        >
          {supplyRoomsRows}
        </Table>
      </TableCell>
      <TableCell>
        <Table
          style={AD.TableStyle.create({
            position: "relative",
            margins: AD.LayoutFoundation.create({ left: -238 - floorNameWidth / 2, top: 5 }),
          })}
          columnWidths={[floorNameWidth]}
        >
          <TableRow>
            <TableCell
              style={AD.TableCellStyle.create({
                background: "#ffffff",
                borders: { top: 1, bottom: 1, left: 1, right: 1 },
              })}
            >
              <Paragraph
                style={AD.ParagraphStyle.create({
                  alignment: "Center",
                  margins: AD.LayoutFoundation.create({ top: 2 }),
                })}
              >
                <TextRun text={floorName} style={AD.TextStyle.create({ verticalPosition: 5 })} />
              </Paragraph>
            </TableCell>
          </TableRow>
        </Table>
      </TableCell>
    </TableRow>
  );
}

function createRoom(
  translate: TranslateFn,
  room: GQLOps.Project_RoomFragment,
  roomType: "extract" | "supply"
): readonly JSX.Element[] {
  const rows = [];

  for (let index = 0; index < (room.valves || 1); index++) {
    if (index > 0) {
      rows.push(createPaddingRow());
    }
    rows.push(createValveRoom(translate, room, roomType, index + 1));
  }
  rows.push(createPaddingRow());
  return rows;
}

function createPaddingRow(): JSX.Element {
  return (
    <TableRow>
      <TableCell style={AD.TableCellStyle.create({ padding: AD.LayoutFoundation.create({ top: 10 }) })}></TableCell>
    </TableRow>
  );
}

function createValveRoom(
  translate: TranslateFn,
  room: GQLOps.Project_RoomFragment,
  roomType: "extract" | "supply",
  valveNumber: number
): JSX.Element {
  const roomName = createRoomName(translate, room.name, valveNumber);
  const airflow = createAirflow(room.airflow, room.valves || 1);
  const airflowArrows = createAirflowArrows(room.ducts || 1, room.valves || 1, roomType);

  const cellStyle = AD.TableCellStyle.create({ borders: { top: 1, bottom: 1, right: 1, left: 1 } });

  if (roomType === "extract") {
    return (
      <TableRow>
        <TableCell style={cellStyle}>{roomName}</TableCell>
        <TableCell style={cellStyle}>{airflow}</TableCell>
        <TableCell>{airflowArrows}</TableCell>
      </TableRow>
    );
  } else {
    return (
      <TableRow>
        <TableCell>{airflowArrows}</TableCell>
        <TableCell style={cellStyle}>{airflow}</TableCell>
        <TableCell style={cellStyle}>{roomName}</TableCell>
      </TableRow>
    );
  }
}

function createRoomName(translate: TranslateFn, roomName: string, valveNumber: number): JSX.Element {
  const row = (text: string): JSX.Element => (
    <TableRow>
      <TableCell>
        <Paragraph style={AD.ParagraphStyle.create({ margins: AD.LayoutFoundation.create({ top: 2 }) })}>
          <TextRun text={text} style={AD.TextStyle.create({ alignment: "center" })} />
        </Paragraph>
      </TableCell>
    </TableRow>
  );
  return (
    <Table columnWidths={[Infinity]}>
      {row(roomName)}
      {row(`${translate(texts.valve)} ${valveNumber}`)}
    </Table>
  );
}

function createAirflow(airflow: number, valves: number): JSX.Element {
  return (
    <Paragraph style={AD.ParagraphStyle.create({ margins: AD.LayoutFoundation.create({ top: 2 }) })}>
      <TextRun text={`${(airflow / valves).toFixed(0)}`} style={AD.TextStyle.create({ alignment: "center" })} />
    </Paragraph>
  );
}
function createAirflowArrows(ducts: number, valves: number, roomType: "extract" | "supply"): JSX.Element {
  const arrowImg = createArrowImage(roomType);
  const arrowAmount = Math.ceil(ducts / valves);
  const arrows = Array(arrowAmount)
    .fill(undefined)
    .map((_, index) => (
      <TableRow key={index}>
        <TableCell>
          <Paragraph style={AD.ParagraphStyle.create({ alignment: "Center" })}>{arrowImg}</Paragraph>
        </TableCell>
      </TableRow>
    ));

  return <Table columnWidths={[Infinity]}>{arrows}</Table>;
}

function createRoof(translate: TranslateFn, maxWidth: number): readonly [JSX.Element, number] {
  const textUnderscoreWidth = 120;
  const textUnderscoreY = 22;
  const textUnderscoreX = 20;
  const fontY = 10;
  const roofHeight = 30;

  const components = [
    createText({
      x: textUnderscoreX + textUnderscoreWidth * 0.5,
      y: fontY,
      text: translate(texts.extract),
      fontSize: 18,
      fontWeight: "bold",
    }),
    createText({
      x: maxWidth - textUnderscoreX - textUnderscoreWidth * 0.5,
      y: fontY,
      text: translate(texts.supply),
      fontSize: 18,
      fontWeight: "bold",
    }),
    AI.createLine(
      AI.createPoint(textUnderscoreX, textUnderscoreY),
      AI.createPoint(textUnderscoreX + textUnderscoreWidth, textUnderscoreY),
      AI.yellow,
      3
    ),
    AI.createLine(
      AI.createPoint(maxWidth - textUnderscoreX - textUnderscoreWidth, textUnderscoreY),
      AI.createPoint(maxWidth - textUnderscoreX, textUnderscoreY),
      AI.red,
      3
    ),
    AI.createPolyLine(
      [
        AI.createPoint(0, roofHeight + textUnderscoreY),
        AI.createPoint(maxWidth * 0.5, textUnderscoreY),
        AI.createPoint(maxWidth, roofHeight + textUnderscoreY),
        AI.createPoint(0, roofHeight + textUnderscoreY),
      ],
      AI.black,
      2
    ),
  ];

  const height = roofHeight + textUnderscoreY;

  const imageResource = AD.ImageResource.create({
    id: "0",
    abstractImage: AI.createAbstractImage(AI.createPoint(0, 0), AI.createSize(maxWidth, height), AI.white, components),
  });

  return [
    <Image
      key={"Roof"}
      width={imageResource.abstractImage.size.width}
      height={imageResource.abstractImage.size.height}
      imageResource={imageResource}
    />,
    imageResource.abstractImage.size.height,
  ];
}

function createArrowImage(roomType: "extract" | "supply"): readonly JSX.Element[] {
  const arrowLength = 30;
  const arrowHeight = 6;
  const isExtract = roomType === "extract";

  const components = [
    AI.createPolygon(createArrow(0, 0, arrowLength, false), AI.black, 0.3, isExtract ? AI.yellow : AI.red),
  ];

  const imageResource = AD.ImageResource.create({
    id: "0",
    abstractImage: AI.createAbstractImage(
      AI.createPoint(0, 0),
      AI.createSize(arrowLength, arrowHeight),
      AI.white,
      components
    ),
  });

  return [
    <Image
      key={"ArrowImage"}
      width={imageResource.abstractImage.size.width}
      height={imageResource.abstractImage.size.height}
      imageResource={imageResource}
    />,
  ];
}

// eslint-disable-next-line functional/prefer-readonly-type
function createArrow(x: number, y: number, length: number, centered: boolean): AI.Point[] {
  const shaftWidthHalf = 1.75;
  const headWidthHalf = 4;
  const headLength = 6;
  y -= centered ? headWidthHalf : 0;
  return [
    AI.createPoint(x, y + headWidthHalf - shaftWidthHalf),
    AI.createPoint(x + length - headLength, y + headWidthHalf - shaftWidthHalf),
    AI.createPoint(x + length - headLength, y),
    AI.createPoint(x + length, y + headWidthHalf),
    AI.createPoint(x + length - headLength, y + headWidthHalf * 2),
    AI.createPoint(x + length - headLength, y + headWidthHalf + shaftWidthHalf),
    AI.createPoint(x, y + headWidthHalf + shaftWidthHalf),
    AI.createPoint(x, y + headWidthHalf - shaftWidthHalf),
  ];
}
