import { ReportLevel } from "..";
import { Materials, Project } from "../..";
import { TranslateFn } from "../../lang-texts";
import * as Common from "../common";
import { ReportModule } from "../types";

export const Name = "nominal-flow";
export const reportLevel: ReportLevel = "system";

export type Response = {
  readonly metaProduct: Project.MetaProductQuery;
  readonly project: Project.ProjectQuery;
  readonly materialTables: Materials.MaterialTables;
};

export type RoomResult = {
  readonly name: string;
  readonly type: string;
  readonly floor: string;
  readonly area: number | undefined;
  readonly airflow: number | undefined;
  readonly width: number | undefined;
  readonly length: number | undefined;
};

export type CalculationResult = {
  readonly rooms: ReadonlyArray<RoomResult>;
  readonly requiredAirflow: number | undefined;
  readonly totalArea: number | undefined;
  readonly airflowForTotalArea: number | undefined;
  readonly extractRoomsArea: number | undefined;
  readonly extractRoomsAirflow: number | undefined;
  readonly airflowResults: AirflowResults | undefined;
};

export type AirflowResult = {
  readonly airflow: number | undefined;
  readonly changeRate: number | undefined;
};

export type AirflowResults = {
  readonly moistureControlAirflow: AirflowResult | undefined;
  readonly reducedAirflow: AirflowResult;
  readonly nominalAirflow: AirflowResult;
  readonly intensiveAirflow: AirflowResult;
};

export type CreatorProps = {
  readonly pageProps: Common.CataloguePageProps;
  readonly translate: TranslateFn;
  readonly systemName: string | undefined;
  readonly result: CalculationResult | undefined;
};

export type AdapterType = ReportModule<CreatorProps, Response>["execute"];
export type CreatorType = ReportModule<CreatorProps, Response>["create"];
