import { Project } from "../..";
import { MaterialChange } from "..";

export function getListChanges(
  system: Project.System,
  materials: ReadonlyArray<Project.Material>
): ReadonlyMap<string, MaterialChange> {
  const changes = new Map<string, MaterialChange>();
  const currentMaterials = new Map(system.materials.map((m) => [m.id, m]));
  for (const m of materials) {
    const current = currentMaterials.get(m.id);
    if (current) {
      if (!Project.isMaterialEqual(current, m)) {
        changes.set(m.id, "updated");
      }
    } else {
      changes.set(m.id, "created");
    }
  }
  const resultMaterialIds = new Set<string>(materials.map((m) => m.id));
  for (const m of system.materials) {
    if (!resultMaterialIds.has(m.id)) {
      changes.set(m.id, "deleted");
    }
  }
  return changes;
}
