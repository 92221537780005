import { validateRequired } from "./shared";
import { Room, System, Project } from "../types";
import { RoomError, ProjectValidation, SystemValidation, ErrorMap, SystemError, Error } from "./types";
import { MetaProductQuery } from "../query";
import * as Calculators from "../../calculators";
import { MarketValveTypesTable } from "..";
import { validateOptions } from ".";
import { Materials } from "../..";

export function validateProject(
  metaProductQuery: MetaProductQuery,
  materialTables: Materials.MaterialTables,
  market: string,
  project: Project
): ProjectValidation | undefined {
  let roomErrors: { [roomId: string]: ReadonlyArray<RoomError> } = {};
  let systemErrors: { [systemId: string]: ReadonlyArray<SystemError> } = {};
  for (const system of project.systems) {
    const result = validateSystem(metaProductQuery, materialTables, market, system);
    if (!result) {
      return undefined;
    }
    roomErrors = { ...roomErrors, ...result.roomErrors };
    systemErrors = { ...systemErrors, [system.id]: result.systemErrors };
  }
  return {
    roomErrors,
    systemErrors,
  };
}

export function validateSystem(
  metaProductQuery: MetaProductQuery,
  materialTables: Materials.MaterialTables,
  market: string,
  system: System
): SystemValidation | undefined {
  let roomErrors: { [roomId: string]: ReadonlyArray<RoomError> } = {};
  const marketValveTypesTable = metaProductQuery.product?.modules.custom_tables.MarketValveTypes || [];
  for (const room of system.rooms) {
    const errors = validateRoomGeneral(marketValveTypesTable, market, room);
    if (errors.length > 0) {
      roomErrors[room.id] = errors;
    }
  }
  const input = Calculators.map(metaProductQuery, materialTables, system, market);
  const calcErrors = input && Calculators.validate(input);
  if (calcErrors === undefined) {
    return undefined;
  }
  roomErrors = mergeErrorMaps(calcErrors.roomErrors, roomErrors);
  return {
    roomErrors,
    systemErrors: calcErrors.systemErrors,
  };
}

function validateRoomGeneral(
  marketValveTypesTable: MarketValveTypesTable,
  market: string,
  room: Room
): ReadonlyArray<RoomError> {
  const errors: Array<RoomError> = [];

  errors.push(...validateRequired("name", room));

  errors.push(...validateRequired("roomType", room));
  errors.push(...validateRequired("airType", room));
  errors.push(...validateRequired("floorType", room));

  const valveTypes = marketValveTypesTable.filter((r) => r.market_name === market).map((r) => r.type || "");
  if (valveTypes.length > 0) {
    errors.push(...validateRequired("valveType", room));
    errors.push(...validateOptions(valveTypes, "valveType", room));
  }

  return errors;
}

function mergeErrorMaps<T>(map1: ErrorMap<T>, map2: ErrorMap<T>): ErrorMap<T> {
  const map: { [id: string]: ReadonlyArray<Error<T>> } = {};
  for (const id of [...Object.keys(map1), ...Object.keys(map2)]) {
    const errors1 = map1[id] || [];
    const errors2 = map2[id] || [];
    map[id] = [...errors1, ...errors2];
  }
  return map;
}
