import { texts } from "../../lang-texts";
import {
  Room,
  RoomTemplatesTable,
  validateNumber,
  validateNumberNonZero,
  RoomError,
  SystemError,
  isSupplyRoom,
  validateRequired,
} from "../../project";
import { Input, ValidationOutput } from "../types";

export function validate(input: Input): ValidationOutput {
  const systemErrors: Array<SystemError> = [];
  if (input.system.totalArea !== null) {
    systemErrors.push(...validateNumberNonZero("totalArea", input.system));
  }
  if (input.system.totalArea === null) {
    systemErrors.push(...validateRequired("totalArea", input.system));
  }
  const roomErrors: { [roomId: string]: ReadonlyArray<RoomError> } = {};
  for (const room of input.system.rooms) {
    const errors = validateRoom(room, input.market, input.roomTemplates);
    if (errors.length > 0) {
      roomErrors[room.id] = errors;
    }
  }
  return {
    hasErrors: Object.keys(roomErrors).length > 0,
    roomErrors,
    systemErrors,
  };
}

function validateRoom(room: Room, market: string, roomTemplatesTable: RoomTemplatesTable): ReadonlyArray<RoomError> {
  const errors: Array<RoomError> = [];

  errors.push(...validateNumber("ceilingHeight", room));
  errors.push(...validateNumber("airflow", room));

  const template = roomTemplatesTable.find((t) => t.key === room.roomType && t.market === market);
  if (isSupplyRoom(room) && template?.airflow_calculation === "area") {
    if (room.width === null && room.length === null) {
      errors.push(...validateNumber("floorArea", room));
      errors.push(...validateNumberNonZero("floorArea", room));
    } else if (room.floorArea === null) {
      errors.push(...validateNumber("length", room));
      errors.push(...validateNumber("width", room));
      errors.push(...validateNumberNonZero("length", room));
      errors.push(...validateNumberNonZero("width", room));
    }
  } else if (isSupplyRoom(room) && template?.airflow_calculation === "people") {
    errors.push(...validateNumber("people", room));
    errors.push(...validateNumberNonZero("people", room));
  }

  errors.push(...validateNumber("valves", room));

  errors.push(...validateTemplate(room, market, roomTemplatesTable));

  return errors;
}

function validateTemplate(
  room: Room,
  market: string,
  roomTemplatesTable: RoomTemplatesTable
): ReadonlyArray<RoomError> {
  const template = roomTemplatesTable.find((t) => t.key === room.roomType && t.market === market);
  if (!template) {
    return [{ property: "roomType", message: texts.error_template_not_found }];
  }
  return [];
}
