import * as R from "ramda";
import * as pdfjsLib from "pdfjs-dist";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
// @ts-ignore
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { AbstractDoc } from "abstract-document/lib/abstract-document";
import { PDFDocument, PDFEmbeddedPage, PDFPage, degrees, toDegrees } from "pdf-lib";
import { AbstractDoc as AD, AbstractDocJsx as ADX } from "abstract-document";
import { ProjectReportParams, QueryRunner, ReportQueryResponse, ReportResponse } from "./types";
import { getReportModule } from "./report-registry";
import * as Common from "./common";
import { Project } from "..";
import { throwIfUndefined } from "../utils/throw-if-undefined";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
export const FixedPdfToken = "==fixed-pdf-token==";

export function* runReportQuries(
  projectId: string,
  imageServiceUrl: string,
  reportParams: ReadonlyArray<ProjectReportParams>
): QueryRunner {
  const responses: Array<ReportResponse> = [];

  const projectData = yield* Common.Queries.projectQuery(projectId);
  const imageResponse = yield* Common.Queries.imageQuery();
  const fontData = yield* Common.Queries.fontQuery();
  const logoimage = yield* Common.Queries.getLogoImage(imageResponse, imageServiceUrl);
  const headings = reportParams.map((p) => ({
    reportId: p.reportType,
    systemId: p.systemId,
    systemName: p.systemName,
  }));

  const common = {
    fontData,
    logoImage: logoimage,
    imageResponse: imageResponse,
    headings: headings,
    projectResponse: projectData,
  };

  const queryCache = new Map();

  for (const params of reportParams) {
    const module = getReportModule(params.reportType);
    const generator = module.query({ ...params, imageResponse });
    let next = generator.next();
    while (!next.done) {
      const query = next.value;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let queryResult: any;
      const stringQuery = JSON.stringify(query);
      if (queryCache.has(stringQuery)) {
        queryResult = queryCache.get(stringQuery);
      } else {
        try {
          queryResult = yield query;
          queryCache.set(stringQuery, queryResult);
        } catch (e) {
          if (generator.throw) {
            generator.throw(e);
          } else {
            throw e;
          }
        }
      }
      next = generator.next(queryResult!);
    }
    responses.push(next.value);
  }

  return { commonResponse: common, reportResponses: responses };
}

export async function createDocument(
  project: Project.Project,
  responses: ReportQueryResponse,
  _reportParams: ReadonlyArray<ProjectReportParams>
): Promise<AD.AbstractDoc.AbstractDoc> {
  const { commonResponse } = responses;

  // Add blank pages for additional documents. Need one blank page for each page in every additional document in order for page numbering to work

  const reportParams: Array<ProjectReportParams> = [];
  const reportReponses: Array<ReportResponse> = [];

  for (let i = 0; i < _reportParams.length; i++) {
    const currentParam = _reportParams[i];
    const nextParam = _reportParams[i + 1];
    reportParams.push(currentParam);
    reportReponses.push(responses.reportResponses[i]);

    if (!currentParam.systemId) {
      continue;
    }

    // Add blank pages
    if (currentParam.systemId && (!nextParam || nextParam.systemId !== currentParam.systemId)) {
      const system = project.systems.find((s) => s.id === currentParam.systemId);
      throwIfUndefined(system);

      if (!system.additionalDocuments || system.additionalDocuments.length === 0) {
        continue;
      }

      for (const additionalDocument of system.additionalDocuments) {
        const arrayBuffer = base64ToArrayBuffer(additionalDocument.pdf);

        const pdf = await PDFDocument.load(arrayBuffer);
        const pages = pdf.getPages();

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const page of pages) {
          const pageSize = page.getSize();
          reportParams.push({
            ...currentParam,
            reportType: "fixed-pdf",
            orientation: pageSize.width > pageSize.height ? "Landscape" : "Portrait",
          });
          reportReponses.push({});
        }
      }
    }
  }

  const docList: Array<AD.AbstractDoc.AbstractDoc> = [];
  for (let i = 0; i < reportParams.length; i++) {
    const reportType = reportParams[i].reportType;
    const reportResponse = reportReponses[i];
    const module = getReportModule(reportType);
    const data = await module.execute({ ...reportParams[i] }, commonResponse, reportResponse);
    docList.push(module.create(data));
  }
  const doc = combineAbstractDocs(docList, false);
  return ADX.render(doc);
}

export type ExportPdf = (doc: PDFDocument) => Promise<void>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CreatePdf = (pdfKit: any, doc: AbstractDoc.AbstractDoc) => Promise<ArrayBuffer>;

export async function replaceFixedPages(
  project: Project.Project,
  pdfBuffer: ArrayBuffer,
  exportPdfToStream: ExportPdf
): Promise<void> {
  const pdfJsDoc = await pdfjsLib.getDocument({ data: pdfBuffer.slice(0) }).promise;
  const doc = await PDFDocument.load(pdfBuffer);
  const pages = doc.getPageCount();

  const fixedPageIndexes = [];
  for (let page = 1; page <= pages; page++) {
    const content = await (await pdfJsDoc.getPage(page)).getTextContent();
    const isFixedPage = content.items.find((item) => "str" in item && item.str.includes(FixedPdfToken));
    if (isFixedPage) {
      fixedPageIndexes.push(page - 1); // pdfjs starts on page 1 while pdf-lib starts on page 0
    }
  }

  if (fixedPageIndexes.length === 0) {
    await exportPdfToStream(doc);
    return;
  }

  let index = 0;
  for (const system of project.systems) {
    if (!system.additionalDocuments || system.additionalDocuments.length === 0) {
      continue;
    }
    for (const additionalDocument of system.additionalDocuments) {
      const arrayBuffer = base64ToArrayBuffer(additionalDocument.pdf);
      const pdf = await PDFDocument.load(arrayBuffer);

      const fixedPages = await doc.copyPages(pdf, pdf.getPageIndices());
      for (const fixedPage of fixedPages) {
        const embedded = await doc.embedPage(fixedPage);
        const page = doc.getPage(fixedPageIndexes[index]);
        const pdfRotation = toDegrees(fixedPage.getRotation());
        drawPage({ top: 0, bottom: 0, left: 0, right: 0 }, 0, pdfRotation, page, embedded);

        index++;
      }
    }
  }

  await exportPdfToStream(doc);
}

// eslint-disable-next-line functional/prefer-readonly-type
function combineAbstractDocs(docs: AD.AbstractDoc.AbstractDoc[], joinDocs: boolean): AD.AbstractDoc.AbstractDoc {
  const fonts = R.mergeAll(docs.map((d) => d.fonts || {})) as AD.Types.Indexer<AD.Font.Font>;
  const imageResources = R.mergeAll(
    docs.map((d) => d.imageResources || {})
  ) as AD.Types.Indexer<AD.ImageResource.ImageResource>;
  const styles = R.mergeAll(docs.map((d) => d.styles || {})) as AD.Types.Indexer<AD.Style.Style>;
  const numberings = R.mergeAll(docs.map((d) => d.numberings || {})) as AD.Types.Indexer<AD.Numbering.Numbering>;
  const numberingDefinitions = R.mergeAll(
    docs.map((d) => d.numberingDefinitions || {})
  ) as AD.Types.Indexer<AD.NumberingDefinition.NumberingDefinition>;

  let children = undefined;
  if (joinDocs) {
    const mergedChildren: Array<AD.Section.Section> = [];
    for (const doc of docs) {
      if (doc.children.length === 0) {
        continue;
      }

      const prevChild = mergedChildren.pop();
      if (!prevChild) {
        mergedChildren.push(...doc.children);
        continue;
      }

      const nextChild = doc.children[0];
      const mergedChild = {
        ...prevChild,
        children: [...prevChild.children, ...nextChild.children],
      };
      mergedChildren.push(mergedChild);
      mergedChildren.push(...doc.children.slice(1));
    }
    children = mergedChildren;
  } else {
    children = R.unnest(docs.map((d) => d.children)) as ReadonlyArray<AD.Section.Section>;
  }

  return AD.AbstractDoc.create(
    {
      fonts,
      imageResources,
      styles,
      numberings,
      numberingDefinitions,
    },
    children
  );
}

function base64ToArrayBuffer(base64: string): ArrayBuffer {
  let binaryString: string;
  if (typeof window !== "undefined" && typeof window.atob === "function") {
    // Browser environment
    binaryString = window.atob(base64);
  } else if (typeof Buffer !== "undefined") {
    // Node.js environment
    binaryString = Buffer.from(base64, "base64").toString("binary");
  } else {
    throw new Error("Environment not supported");
  }

  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

interface PdfEmbedMargin {
  readonly top: number;
  readonly bottom: number;
  readonly left: number;
  readonly right: number;
}
function drawPage(
  margin: PdfEmbedMargin,
  rotationParam: number,
  rotationPdfMeta: number,
  abstractPage: PDFPage,
  fixedPage: PDFEmbeddedPage
): void {
  const aw = abstractPage.getWidth() - margin.left - margin.right;
  const ah = abstractPage.getHeight() - margin.top - margin.bottom;
  const pw = fixedPage.width;
  const ph = fixedPage.height;

  const num90Rots = Math.round(-rotationPdfMeta - rotationParam / 90) % 4;
  const rotAdjustment = ah < aw === ph < pw && Math.abs(num90Rots % 2) === 1 ? 1 : 0;
  const adjusted90Rots = num90Rots + rotAdjustment;
  const rotation = (adjusted90Rots < 0 ? 4 + adjusted90Rots : adjusted90Rots) * 90;

  const scale = Math.min(Math.max(aw, ah) / Math.max(pw, ph), Math.min(aw, ah) / Math.min(pw, ph));
  const w = pw * scale;
  const h = ph * scale;

  const mid = vec2Create(w * 0.5, h * 0.5);
  const midRot = vec2Rotate(mid, (rotation / 180) * Math.PI);
  const x = margin.left + aw * 0.5 - midRot.x;
  const y = margin.bottom + ah * 0.5 - midRot.y;
  abstractPage.drawPage(fixedPage, { x, y, xScale: scale, yScale: scale, rotate: degrees(rotation) });
}

interface Vector2 {
  readonly x: number;
  readonly y: number;
}
function vec2Create(x: number, y: number): Vector2 {
  return {
    x: x,
    y: y,
  };
}
function vec2Rotate(a: Vector2, angle: number): Vector2 {
  const sin = Math.sin(angle);
  const cos = Math.cos(angle);
  const x = a.x * cos - a.y * sin;
  const y = a.x * sin + a.y * cos;
  return vec2Create(x, y);
}
