import { Room } from "../../../project";
import { logWarn } from "../../../logger";

export function calculateTotalArea(rooms: ReadonlyArray<Room>): number | undefined {
  let area = 0;
  for (const room of rooms) {
    const roomFloorArea = calculateRoomFloorArea(room);
    if (roomFloorArea === undefined || roomFloorArea === 0) {
      logWarn(`No floor area for room ${room.id}`);
      return undefined;
    }
    area += roomFloorArea;
  }
  return area;
}

export function calculateRoomFloorArea(room: Room): number | undefined {
  if (room.width !== null && room.length !== null) {
    return room.width * room.length;
  } else {
    return room.floorArea ?? undefined;
  }
}
