import { getUnitName, Unit } from "@rvs/shared/src/units";
import * as React from "react";

export function NumberValue({
  value,
  errorMessage,
  decimals,
  className,
  unitText,
  unit,
}: {
  readonly value: number | null | undefined;
  readonly errorMessage?: string;
  readonly decimals?: number;
  readonly className?: string;
  readonly unitText?: string;
  readonly unit?: Unit;
}): JSX.Element {
  const renderedValue =
    value === null || value === undefined ? "-" : decimals !== undefined ? value.toFixed(decimals) : value.toString();
  const unitPart = unitText || (unit && getUnitName(unit));
  return (
    <span className={`${className} ${errorMessage ? "border  border-danger" : ""}`} title={errorMessage}>
      {unitPart ? `${renderedValue} ${unitPart}` : renderedValue}
    </span>
  );
}
