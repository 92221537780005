import { Room, RoomTemplatesTable } from "../../../project";
import { logWarn } from "../../../logger";

export function calculateNumberOfValves(roomTemplateTable: RoomTemplatesTable, room: Room): number | undefined {
  const template = roomTemplateTable.find((r) => r.key === room.roomType);
  if (!template) {
    logWarn(`calculateNumberOfPeople: Not room template found for room type ${room.roomType}`);
    return undefined;
  }

  if (template.default_number_of_valves !== null && room.valves === null) {
    return template.default_number_of_valves;
  } else if (room.valves === null) {
    return 1;
  } else {
    return room.valves;
  }
}
