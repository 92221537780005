export type Patch<T> = Partial<Omit<T, "id" | "__typename">> & { readonly id: string };
export type InputPatch<T> = PartialOrNull<Omit<T, "id" | "__typename">> & { readonly id: string };

export type Partial<T> = {
  readonly [P in keyof T]?: T[P];
};

export type PartialOrNull<T> = {
  readonly [P in keyof T]: T[P] | null;
};

export function variablesFromPatch<T>(
  id: string,
  patch: Omit<Patch<T>, "id">
): {
  readonly input: T & { readonly id: string };
} {
  return {
    input: {
      ...(patch as unknown as T),
      id,
    },
  };
}

export function inputFromPatch<T>(id: string, patch: Partial<T>): T & { readonly id: string } {
  return { id, ...(patch as unknown as T) };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObject = { readonly [key: string]: any };

export function patchResponse<Response extends AnyObject, Input extends AnyObject>(
  old: Response,
  patch: Patch<Input>
): Response {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const patched: any = {};
  for (const key of Object.keys(old)) {
    patched[key] = patch[key] !== undefined ? patch[key] : old[key];
  }
  return patched as Response;
}

export function createDiffPatch<T extends AnyObject & { readonly id: string }>(
  current: T,
  updated: T
): Patch<T> | undefined {
  if (current.id !== updated.id) {
    return undefined;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let patch: any | undefined = undefined;
  for (const key of Object.keys(current)) {
    if (key === "id") {
      continue;
    }
    if (current[key] !== updated[key]) {
      patch = !patch ? { id: current.id } : patch;
      patch[key] = updated[key];
    }
  }
  return patch as Patch<T> | undefined;
}
