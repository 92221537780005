export interface Request {
  readonly market?: string;
  readonly locale?: string;
  readonly project: string;
  readonly systems: ReadonlyArray<string>;
  readonly gaebInfo: ExportInfo;
}

export interface Response {
  readonly message: string;
}

export interface ExportInfo {
  readonly gaebformat: FormatOption;
  readonly projectAddress: ProjectAddress;
}

export interface ProjectAddress {
  readonly addressname: string;
  readonly street: string;
  readonly postalcode: string;
  readonly city: string;
  readonly country: string;
}

export interface FormatOption {
  readonly format: GaebFormat;
  readonly name: string;
  readonly extension: string;
  readonly mime: string;
}

export type GaebFormat = "gaeb2000" | "gaeb90" | "gaebxml32" | "gaebxml31" | "gaebxml30";

export interface Item {
  readonly ItemDescription: string;
  readonly ItemDescriptionShort: string;
  readonly Qty: number;
  readonly UM: string;
  readonly SinglePrice: number;
  readonly DiscountPercent: number;
  readonly DiscountGroup: string;
  readonly LineamountNet: number;
  readonly WebpageURL: string;
}

export interface Category {
  readonly CategoryName: string;
  readonly Items: ReadonlyArray<Item>;
}

export interface Project {
  readonly Name: string;
  readonly NameLong: string;
  readonly Address: {
    readonly Name: string;
    readonly Street: string;
    readonly Country: string;
    readonly PostalCode: string;
    readonly City: string;
  };
  readonly Categories: ReadonlyArray<Category>;
  readonly Items: ReadonlyArray<Item>;
  readonly Date: string; // YYYY-MM-DD
}

export interface Gaeb {
  readonly OutputFormat: GaebFormat;
  readonly Project: Project;
}

export type GaebResult = ResultSucess | ResultFailure;

export interface ResultSucess {
  readonly type: "success";
  readonly blob: Blob;
}

export interface ResultFailure {
  readonly type: "failure";
  readonly reason: string;
}
