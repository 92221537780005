import { logWarn } from "../logger";
import * as GQLOps from "../generated/generated-operations";
import { Input, Result, Updates, ValidationOutput } from "./types";
import { System } from "../project";
import * as Global from "./global";
import * as Germany from "./germany";
import * as Norway from "./norway";
import { Calculator, CalculatorName, Fields } from ".";
import { Materials } from "..";

const calculators: Record<string, Calculator> = {
  germany: Germany,
  norway: Norway,
  global: Global,
};

export function map(
  queryResponse: GQLOps.ProjectMetaProductQuery,
  materialTables: Materials.MaterialTables,
  system: System,
  market: string
): Input | undefined {
  const roomTemplates = queryResponse.product?.modules.custom_tables.RoomTemplates;
  const marketTable = queryResponse.product?.modules.custom_tables.Market;
  if (!roomTemplates || !marketTable) {
    logWarn("No RoomTemplates, Market table");
    return undefined;
  }
  const ductTable = materialTables.type === "germany" ? materialTables.tables.ductTable : [];
  const roomTemplatesForMarket = roomTemplates.filter((t) => t.market === market);
  const calculator = (marketTable.find((m) => m.market_name === market)?.calculator || "germany") as CalculatorName;
  return {
    calculator: calculator,
    market: market,
    system: system,
    roomTemplates: roomTemplatesForMarket,
    ductTable: ductTable,
  };
}

export function calculate(input: Input): Result | undefined {
  const calculator = calculators[input.calculator];
  if (calculator) {
    return calculator.calculate(input);
  } else {
    logWarn(`Calculator not found, tried to calculated with: '${input.calculator}'`);
    return undefined;
  }
}

export function validate(input: Input): ValidationOutput {
  const calculator = calculators[input.calculator];
  if (calculator) {
    return calculator.validate(input);
  } else {
    return { roomErrors: {}, systemErrors: [], hasErrors: false };
  }
}

export function getFields(input: Input): Fields {
  const calculator = calculators[input.calculator];
  if (calculator) {
    return calculator.getFields(input);
  } else {
    return {
      roomFields: {},
      systemFields: [],
    };
  }
}

export function mapResultToUi(input: Input, result: Result): Updates {
  const calculator = calculators[input.calculator];
  if (calculator) {
    return calculator.mapResultToUi(input, result);
  } else {
    return {
      roomPatches: [],
    };
  }
}

export function mapResultToProject(input: Input, result: Result): Updates {
  const calculator = calculators[input.calculator];
  if (calculator) {
    return calculator.mapResultToProject(input, result);
  } else {
    return {
      roomPatches: [],
    };
  }
}
