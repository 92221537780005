import { v4 as uuid } from "uuid";
import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { SharedState } from "@rvs/client-infra";
import { getAvailableRoomColumns } from "@rvs/shared/src/project";
import { Texts, Calculators, Project, UserSettings, Markets } from "@rvs/shared";
import { roundTo } from "@rvs/shared/src/utils";
import { convertTo, Quantities, Quantity, Units } from "@rvs/shared/src/units";
import { selectUnit, selectUnitName } from "@rvs/shared/src/user-settings";
import * as State from "./state";
import { Action } from "./state";
import * as GQLOps from "../../../../generated/generated-operations";
import {
  Alert,
  Button,
  ConfirmRemoveButton,
  DropdownButton,
  Expander,
  Icon,
  Label,
  NumberField,
  NumberValue,
  Selector,
  Spinner,
} from "../../../../elements";
import { Room } from "./components";
import { RoomHeader } from "./components/room-header";
import { AcceptDeclineTextField } from "../../../../elements/accept-cancel-textfield";
import * as ProjectState from "../../project-state";

const ProjectAction = ProjectState.Action;
const texts = Texts.texts;

export function View(props: {
  readonly state: State.State;
  readonly projectState: ProjectState.State;
  readonly sharedState: SharedState.SharedState;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
}): JSX.Element {
  const { state, projectState, sharedState, dispatch, dispatchProject } = props;
  const { project, metaProduct, materialTables } = projectState;
  const translate = sharedState.translate;
  const isReadonly = Project.isProjectReadOnly(project);
  const market = sharedState.market;
  const userSettings = sharedState.userSettings;
  const roomFloorTable = metaProduct.product?.modules.custom_tables.RoomFloor;
  const roomTemplatesTable = metaProduct.product?.modules.custom_tables.RoomTemplates;
  const pipeDiameterTable = metaProduct.product?.modules.custom_tables.PipeDiameter;
  const marketValveTypesTable = metaProduct.product?.modules.custom_tables.MarketValveTypes;
  const productResponse = metaProduct;

  //save reference for dragItem and dragOverItem
  const dragSystemItem = React.useRef<string>("");
  const dragSystemOverItem = React.useRef<string>("");

  const [tempListSystem, setTempListSystem] = React.useState<ReadonlyArray<Project.System>>();

  //handle drag sorting
  const handleSortSystems = (system: ReadonlyArray<Project.System>): void => {
    //duplicate rooms
    const _projectSystems = [...system];

    //reset the position ref
    dragSystemItem.current = "";
    dragSystemOverItem.current = "";

    //update the actual array
    const projectSystemDrag = _projectSystems.map((system, index) => ({
      sortNo: index,
      id: system.id,
    }));

    for (const systemDrag of projectSystemDrag) {
      dispatchProject(ProjectAction.UpdateSystem({ id: systemDrag.id, sortNo: systemDrag.sortNo }));
    }
  };

  if (!roomFloorTable || !roomTemplatesTable || !productResponse || !pipeDiameterTable || !marketValveTypesTable) {
    return <Spinner />;
  }

  return (
    <div className="w-full flex flex-col space-y-16 ">
      <div>
        <Button
          label={translate(texts.add_system)}
          disabled={isReadonly}
          onClick={() => {
            const systemName = `${translate(texts.system)} ${
              project.systems.length === 0 ? 1 : Math.max(...project.systems.map((x) => x.sortNo)) + 1
            }`;
            props.dispatchProject(ProjectAction.CreateSystem(systemName));
          }}
        />
      </div>

      {(tempListSystem || project.systems).map((system) => {
        const calcState = projectState.calculationStates[system.id];
        const systemValidation = Project.validateSystem(productResponse, materialTables, market.name, system);
        const updatedSystem = Project.applyRoomPatchesToSystem(system, calcState?.uiUpdates || []);
        return (
          <div
            className={
              dragSystemItem.current === system.id
                ? "opacity-0 hover:opacity-100 border-l-2 border-primary hover:border-opacity-100"
                : "border-l-2  border-primary border-opacity-0 hover:border-opacity-100"
            }
            onDragOver={(e) => {
              if (!tempListSystem) {
                return;
              }

              const tempSystem = [...tempListSystem];

              const dragSystemIndex = tempSystem.findIndex((s) => s.id === dragSystemItem.current);

              if (dragSystemIndex === -1) {
                return;
              }

              const targetSystemIndex = tempSystem.findIndex((s) => s.id === system.id);

              if (targetSystemIndex === -1) {
                return;
              }
              e.preventDefault();
              tempSystem[dragSystemIndex] = tempListSystem[targetSystemIndex];

              tempSystem[targetSystemIndex] = tempListSystem[dragSystemIndex];

              setTempListSystem([...tempSystem]);
            }}
            onDragStart={() => {
              setTempListSystem([...project.systems]);
              dragSystemItem.current = system.id;
            }}
            // onDragEnter={() => {
            //   undefined;
            // }}
            onDragEnd={() => {
              if (!tempListSystem) {
                return;
              }
              handleSortSystems(tempListSystem);
              setTempListSystem(undefined);
            }}
            key={system.id}
          >
            <System
              key={system.id}
              isReadonly={isReadonly}
              dispatch={dispatch}
              dispatchProject={dispatchProject}
              translate={translate}
              system={updatedSystem}
              state={state}
              projectState={projectState}
              market={market}
              userSettings={userSettings}
              roomFloorTable={roomFloorTable}
              roomTemplatesTable={roomTemplatesTable}
              pipeDiameterTable={pipeDiameterTable}
              marketValveTypesTable={marketValveTypesTable}
              result={calcState?.result}
              roomErrors={systemValidation?.roomErrors || {}}
              roomFields={calcState?.fields.roomFields || {}}
              systemErrors={systemValidation?.systemErrors || []}
              systemFields={calcState?.fields.systemFields || []}
            />
          </div>
        );
      })}
    </div>
  );
}

function System({
  system,
  dispatch,
  dispatchProject,
  translate,
  state,
  projectState,
  isReadonly,
  market,
  userSettings,
  roomFloorTable,
  roomTemplatesTable,
  pipeDiameterTable,
  marketValveTypesTable,
  result,
  roomErrors,
  roomFields,
  systemErrors,
  systemFields,
}: {
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly translate: Texts.TranslateFn;
  readonly system: GQLOps.Project_SystemFragment;
  readonly state: State.State;
  readonly projectState: ProjectState.State;
  readonly isReadonly: boolean;
  readonly market: Markets.Market;
  readonly userSettings: UserSettings.UserSettings;
  readonly roomFloorTable: Project.RoomFloorTable;
  readonly roomTemplatesTable: Project.RoomTemplatesTable;
  readonly pipeDiameterTable: Project.PipeDiameterTable;
  readonly marketValveTypesTable: Project.MarketValveTypesTable;
  readonly result: Calculators.Result | undefined;
  readonly roomErrors: Project.RoomErrors;
  readonly roomFields: Calculators.RoomFieldsById;
  readonly systemErrors: ReadonlyArray<Project.SystemError>;
  readonly systemFields: Calculators.SystemFields;
  readonly isMoving?: boolean;
  readonly isMovingSystem?: boolean;
}): JSX.Element {
  const systemName = system.name || `${translate(texts.system)} ${system.sortNo}`;
  const availableRoomColumns = getAvailableRoomColumns(marketValveTypesTable, market.name, roomFields);

  const sortWeights = roomFloorTable.map((x, index) => ({ sortNo: index, name: x.key }));
  const systemRooms = [...system.rooms].sort((a, b) => {
    const aSortNo = sortWeights.find((x) => x.name === a.floorType)?.sortNo || 0;
    const bSortNo = sortWeights.find((x) => x.name === b.floorType)?.sortNo || 0;
    return aSortNo === bSortNo ? 0 : aSortNo < bSortNo ? -1 : 1;
  });

  const systemFloors = sortWeights.map((floor) => systemRooms.filter((room) => room.floorType === floor.name));

  const airflowUnit = selectUnit(userSettings, Quantity.Airflow);
  const convertedUnit = convertTo(result?.requiredAirflow, Units.CubicMeterPerHour, airflowUnit);
  const roundedUnit = convertedUnit && roundTo(convertedUnit, 2);
  const title = state.expandedItems.has(system.id)
    ? `${systemName} (${translate(texts.air_flow)}: ${roundedUnit} ${airflowUnit.unitName})`
    : systemName;

  const totalAreaField = systemFields.find((f) => f.field === "totalArea");
  const airChangeRateField = systemFields.find((f) => f.field === "airChangeRate");

  //Save reference for dragItem and dragOverItem
  const dragRoomItem = React.useRef<string>("");
  const dragRoomOverItem = React.useRef<string>("");
  const dragRoomFloorType = React.useRef<string>("");

  const [tempList, setTempList] = React.useState<ReadonlyArray<Project.Room>>();

  //handle drag sorting
  const handleSortRooms = (floor: ReadonlyArray<Project.Room>): void => {
    if (dragRoomFloorType.current !== floor[0].floorType) {
      return;
    }

    //duplicate rooms
    const _projectRooms = [...floor];

    //reset the position ref
    dragRoomItem.current = "";
    dragRoomOverItem.current = "";

    //update the actual array
    const projectRoomDrag = _projectRooms.map((room, index) => ({
      sortNo: index,
      id: room.id,
    }));

    dispatchProject(ProjectAction.UpdateRoomSo(system.id, projectRoomDrag));
  };

  return (
    <Expander
      translate={translate}
      header={
        state.expandedItems.has(`${system.id}-rename`) ? (
          <AcceptDeclineTextField
            value={systemName}
            onAccept={(v: string) => {
              dispatchProject(ProjectAction.UpdateSystem({ id: system.id, name: v || "" }));
              dispatch(Action.ToggleExpanded(`${system.id}-rename`));
            }}
            disabled={isReadonly}
            onDecline={() => dispatch(Action.ToggleExpanded(`${system.id}-rename`))}
          />
        ) : (
          title
        )
      }
      closed={state.expandedItems.has(system.id)}
      onToggleClosed={() => dispatch(Action.ToggleExpanded(system.id))}
      extraHeader={
        !state.expandedItems.has(`${system.id}-rename`) && (
          <div className="flex flex-row space-x-24 items-center">
            <Icon
              icon={"edit"}
              onClick={() => dispatch(Action.ToggleExpanded(`${system.id}-rename`))}
              message={translate(Texts.texts.rename_system)}
              disabled={isReadonly}
            />
            <ConfirmRemoveButton
              disabled={isReadonly}
              oneLiner={true}
              onClick={() => dispatchProject(ProjectAction.RemoveSystem(system.id))}
              confirmMessage={translate(Texts.texts.confirm_delete_system)}
              removeText={translate(Texts.texts.remove_system)}
              cancelText={translate(Texts.texts.cancel)}
              iconMessage={translate(Texts.texts.remove_system)}
            />
            {projectState.systemsBeingDuplicated.has(system.id) ? (
              <Spinner debounce={false} />
            ) : (
              <Icon
                icon={"copy"}
                key={"duplicate"}
                disabled={isReadonly}
                onClick={() => {
                  const newSystemId = uuid();

                  dispatchProject(ProjectAction.DuplicateSystem(system.id, newSystemId));
                  if (state.expandedItems.has(system.id)) {
                    dispatch(Action.ToggleExpanded(newSystemId));
                  }
                }}
                message={translate(Texts.texts.duplicate_system)}
              />
            )}
          </div>
        )
      }
    >
      <div className="flex flex-col space-y-24">
        <table className="w-auto max-w-screen-4xl">
          <thead>
            <tr>
              <th className="px-8 border-none w-24"></th>
              <th className="p-8 border-none" colSpan={9}>
                <div className="flex flex-row space-x-24 items-center">
                  <Button
                    disabled={isReadonly}
                    label={translate(texts.add_room)}
                    onClick={() => {
                      dispatchProject(ProjectAction.CreateRoom(system.id, translate(texts.new_room)));
                    }}
                  />

                  {totalAreaField && (
                    <NumericInput
                      label={texts.total_area}
                      value={system.totalArea}
                      onChange={(v) => dispatchProject(ProjectAction.UpdateSystem({ id: system.id, totalArea: v }))}
                      translate={translate}
                      readOnly={isReadonly}
                      systemErrors={systemErrors}
                      field={totalAreaField}
                      userSettings={userSettings}
                      unitQuantity={Quantity.Area}
                    />
                  )}
                  {!systemFields.find((f) => f.field === "pipeDiameter")?.hidden ? (
                    <PipeDiameterSelector
                      system={system}
                      isReadonly={isReadonly}
                      onChange={(newDiameter) =>
                        dispatchProject(ProjectAction.UpdateSystem({ id: system.id, pipeDiameter: newDiameter }))
                      }
                      pipeDiameterTable={pipeDiameterTable}
                      translate={translate}
                    />
                  ) : undefined}
                  {availableRoomColumns.has("valveType") ? (
                    <SetAllValveTypeButton
                      system={system}
                      isReadonly={isReadonly}
                      marketValveTypesTable={marketValveTypesTable}
                      market={market.name}
                      dispatchProject={dispatchProject}
                      translate={translate}
                    />
                  ) : undefined}
                  {airChangeRateField && (
                    <NumericInput
                      label={texts.air_change_rate}
                      value={system.airChangeRate}
                      onChange={(v) => dispatchProject(ProjectAction.UpdateSystem({ id: system.id, airChangeRate: v }))}
                      translate={translate}
                      readOnly={isReadonly}
                      systemErrors={systemErrors}
                      field={airChangeRateField}
                      userSettings={userSettings}
                      unitQuantity={Quantity.DimensionlessPerDuration}
                    />
                  )}
                </div>
              </th>
            </tr>
          </thead>
          {systemRooms.length > 0 ? (
            <React.Fragment>
              <RoomHeader translate={translate} availableColumns={availableRoomColumns} userSettings={userSettings} />
              {systemFloors
                .filter((floor) => floor.length > 0)
                .map((floor, floorIndex) => {
                  const floorRender = floor.some((f) => f.id === dragRoomItem.current) && tempList ? tempList : floor;

                  return (
                    <React.Fragment key={`${floorIndex}_empty_space`}>
                      {floorIndex !== 0 && floor.length > 0 ? (
                        <tbody key={`${floorIndex}_empty_space`}>
                          <tr className="h-32">
                            <td className="border-none"></td>
                            <td className="border-t-1" colSpan={100}></td>
                          </tr>
                        </tbody>
                      ) : null}
                      {floorRender.map((room) => {
                        return (
                          <Room
                            isMoving={dragRoomItem.current === room.id}
                            onDragOver={() => {
                              if (!tempList) {
                                return;
                              }
                              const temp = [...tempList];

                              const dragIndex = temp.findIndex((r) => r.id === dragRoomItem.current);

                              if (dragIndex === -1) {
                                return;
                              }

                              const targetIndex = temp.findIndex((r) => r.id === room.id);

                              if (targetIndex === -1) {
                                return;
                              }

                              temp[dragIndex] = tempList[targetIndex];

                              temp[targetIndex] = tempList[dragIndex];

                              const tempListIds = tempList.map((e) => e.id);

                              const tempIds = temp.map((e) => e.id);

                              if (JSON.stringify(tempListIds) === JSON.stringify(tempIds)) {
                                return;
                              }

                              setTempList([...temp]);
                            }}
                            onDragStart={() => {
                              setTempList([...floor]);
                              dragRoomItem.current = room.id;
                              dragRoomFloorType.current = room.floorType;
                            }}
                            onDragEnter={() => {
                              undefined;
                            }}
                            onDragEnd={() => {
                              if (!tempList) {
                                return;
                              }
                              handleSortRooms(tempList);
                              setTempList(undefined);
                            }}
                            key={room.id}
                            isFirst={floorIndex === 0}
                            systemId={system.id}
                            room={room}
                            isReadonly={isReadonly}
                            dispatch={dispatch}
                            dispatchProject={dispatchProject}
                            translate={translate}
                            market={market.name}
                            userSettings={userSettings}
                            roomFloorTable={roomFloorTable}
                            roomTemplatesTable={roomTemplatesTable}
                            marketValveTypesTable={marketValveTypesTable}
                            expandedItems={state.expandedItems}
                            errors={roomErrors[room.id] || []}
                            fields={roomFields[room.id] || []}
                            availableColumns={availableRoomColumns}
                            isNewRoom={!state.initialRooms.has(room.id)}
                          />
                        );
                      })}
                    </React.Fragment>
                  );
                })}
            </React.Fragment>
          ) : null}
        </table>
        {result && <SystemResults result={result} translate={translate} userSettings={userSettings} />}
      </div>
    </Expander>
  );
}

function SetAllValveTypeButton({
  system,
  isReadonly,
  dispatchProject,
  market,
  marketValveTypesTable,
  translate,
}: {
  readonly system: Project.System;
  readonly isReadonly: boolean;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly market: string;
  readonly marketValveTypesTable: Project.MarketValveTypesTable;
  readonly translate: Texts.TranslateFn;
}): JSX.Element {
  const valveTypes = marketValveTypesTable.filter((r) => r.market_name === market);
  if (!valveTypes) {
    return <span />;
  }
  const items = valveTypes.map((r) => ({
    value: r.type || "",
    label: translate(Texts.key(r.name || "")),
    onClick: () => {
      if (r.type) {
        for (const room of system.rooms) {
          if (room.valveType !== r.type) {
            dispatchProject(ProjectAction.UpdateRoom(system.id, { id: room.id, valveType: r.type }));
          }
        }
      }
    },
  }));
  return <DropdownButton label={translate(texts.set_valve_type_all_rooms)} items={items} disabled={isReadonly} />;
}

function SystemResults({
  result,
  translate,
  userSettings,
}: {
  readonly result: Calculators.Result;
  readonly translate: Texts.TranslateFn;
  readonly userSettings: UserSettings.UserSettings;
}): JSX.Element {
  const airflowDiffOk = (result && Calculators.checkSupplyExtractAfDiff(result)) ?? true;
  return (
    <div className="flex flex-col space-y-24 max-w-4xl">
      {!airflowDiffOk && (
        <table>
          <tbody>
            <tr>
              <td className="px-8 border-none w-24" />
              <td className="border-none">
                <Alert type="warning">{translate(texts.airflow_difference_warning)}</Alert>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      <div className="flex flex-row space-x-24">
        <div className="basis-1/2">
          <CalculationSummary result={result} translate={translate} userSettings={userSettings} />
        </div>
        <div className="basis-1/2">
          <MaterialSummary result={result} translate={translate} />
        </div>
      </div>
    </div>
  );
}

function CalculationSummary({
  result,
  translate,
  userSettings,
}: {
  readonly result: Calculators.Result;
  readonly translate: Texts.TranslateFn;
  readonly userSettings: UserSettings.UserSettings;
}): JSX.Element | null {
  const airflowUnit = selectUnit(userSettings, Quantity.Airflow);
  const perDurationUnit = selectUnit(userSettings, Quantity.DimensionlessPerDuration);
  const results: ReadonlyArray<readonly [string, number | undefined, string]> =
    result.type === "germany"
      ? [
          ["result_total_area", result.totalArea, "m²"],
          ["result_total_volume", result.totalVolume, "m³"],
          [
            "result_required_airflow",
            convertTo(result.requiredAirflow, Units.CubicMeterPerHour, airflowUnit),
            airflowUnit.unitName,
          ],
          ["result_air_change_rate", result.ventilationChangeRates?.nominal.changeRate, "1/h"],
        ]
      : result.type === "norway"
      ? [
          [
            "result_floor_airflow",
            convertTo(result.floorAirflow, Units.CubicMeterPerHour, airflowUnit),
            airflowUnit.unitName,
          ],
          [
            "result_supply_airflow",
            convertTo(result.supplyAirflow, Units.CubicMeterPerHour, airflowUnit),
            airflowUnit.unitName,
          ],
          [
            "result_extract_airflow",
            convertTo(result.extractAirflow, Units.CubicMeterPerHour, airflowUnit),
            airflowUnit.unitName,
          ],
          [
            "result_required_airflow",
            convertTo(result.requiredAirflow, Units.CubicMeterPerHour, airflowUnit),
            airflowUnit.unitName,
          ],
        ]
      : result.type === "global"
      ? [
          ["result_total_area", result.totalArea, "m²"],
          ["result_total_volume", result.totalVolume, "m³"],
          [
            "result_required_airflow",
            convertTo(result.requiredAirflow, Units.CubicMeterPerHour, airflowUnit),
            airflowUnit.unitName,
          ],
          [
            "result_air_change_rate",
            convertTo(result.airChangeRate, Units.OnePerHour, perDurationUnit),
            perDurationUnit.unitName,
          ],
        ]
      : [];
  if (results.length === 0) {
    return null;
  }
  return (
    <table>
      <thead>
        <tr>
          <th className="px-8 border-none w-24"></th>
          <th>{translate(texts.calculation_summary)}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {results.map(([key, value, unit]) => (
          <tr key={key}>
            <td className="border-none" />
            <td>{translate(Texts.key(key))}</td>
            <td>
              <NumberValue value={value} decimals={2} unitText={unit} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function MaterialSummary({
  result,
  translate,
}: {
  readonly result: Calculators.Result;
  readonly translate: Texts.TranslateFn;
}): JSX.Element | null {
  const materialResults: ReadonlyArray<readonly [string, number | undefined]> =
    result.type === "germany" || result.type === "global"
      ? [
          ["number_of_supply_valves", result.quantities.supplyValves],
          ["number_of_extract_valves", result.quantities.extractValves],
        ]
      : [];
  return (
    (materialResults.length > 0 && (
      <table>
        <thead>
          <tr>
            <th className="px-8 border-none w-24"></th>
            <th>{translate(texts.material_summary)}</th>
            <th>{translate(texts.quantity)}</th>
          </tr>
        </thead>
        <tbody>
          {materialResults.map(([key, value]) => (
            <tr key={key}>
              <td className="border-none" />
              <td>{translate(Texts.key(key))}</td>
              <td colSpan={2}>
                <NumberValue value={value} decimals={0} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )) ||
    null
  );
}

function PipeDiameterSelector({
  system,
  isReadonly,
  onChange,
  pipeDiameterTable,
  translate,
}: {
  readonly system: Project.System;
  readonly isReadonly: boolean;
  readonly onChange: (diameter: number) => void;
  readonly pipeDiameterTable: Project.PipeDiameterTable;
  readonly translate: Texts.TranslateFn;
}): JSX.Element {
  const diameters = pipeDiameterTable.map((r) => r.diameter || 0);
  if (!diameters.some((d) => d === system.pipeDiameter)) {
    diameters.push(system.pipeDiameter);
  }
  const options = diameters.map((diameter) => ({ id: diameter.toString(), name: `${diameter}mm`, diameter }));
  return (
    <div className="flex flex-row space-x-8 items-center">
      <Label>{translate(texts.pipe_diameter)}</Label>
      <Selector
        readOnly={isReadonly}
        options={options}
        value={options.find((o) => o.diameter === system.pipeDiameter) || options[0]}
        onChange={(o) => onChange(o.diameter)}
      />
    </div>
  );
}

function NumericInput<T extends Quantities>({
  label,
  value,
  onChange,
  translate,
  readOnly,
  systemErrors,
  field,
  userSettings,
  unitQuantity,
}: {
  readonly label: Texts.TextKey;
  readonly value: number | null | undefined;
  readonly onChange?: (value: number) => void;
  readonly translate: Texts.TranslateFn;
  readonly readOnly: boolean;
  readonly systemErrors: ReadonlyArray<Project.SystemError>;
  readonly field: Calculators.SystemField | undefined;
  readonly userSettings?: UserSettings.UserSettings;
  readonly unitQuantity?: T;
}): JSX.Element | null {
  const errorMessageKey = systemErrors.find((e) => e.property === field?.field)?.message;
  const errorMessage = errorMessageKey && translate(errorMessageKey);
  const numDecimals = field?.decimals ?? Project.roundingDecimals;
  const labelText = translate(label);
  if (field?.hidden) {
    return null;
  } else if (field?.readOnly) {
    return (
      <div className="flex flex-row space-x-8 items-center">
        {userSettings && unitQuantity ? (
          <Label>{`${labelText} (${selectUnitName(userSettings, unitQuantity)})`}</Label>
        ) : (
          <Label>{labelText}</Label>
        )}
        <div className="max-w-64">
          <NumberValue
            errorMessage={errorMessage}
            value={value}
            decimals={numDecimals}
            className={"w-full inline-block px-4"}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-row space-x-8 items-center">
        {userSettings && unitQuantity ? (
          <Label>{`${labelText} (${selectUnitName(userSettings, unitQuantity)})`}</Label>
        ) : (
          <Label>{labelText}</Label>
        )}
        <div className="max-w-64">
          <NumberField
            readOnly={readOnly}
            value={value ?? undefined}
            errorMessage={errorMessage}
            onChange={(v) => (onChange ? onChange(v) : undefined)}
            decimals={numDecimals}
            showSpinButton={field?.spinButton}
          />
        </div>
      </div>
    );
  }
}
