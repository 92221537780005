import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { Reports, UserSettings, Query, Project } from "@rvs/shared";
import { SharedState, Routes, HttpFetch, graphQLQueryWithAuth } from "@rvs/client-infra";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import gql from "graphql-tag";
import * as GQLOps from "../../../../generated/generated-operations";
import { clientConfig } from "../../../../client-config";
import * as ProjectState from "../../project-state";

export const query = gql`
  query Printout_metaProduct($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        custom_tables {
          Reports {
            ...Printout_metaTables
          }
        }
      }
    }
  }
  fragment Printout_metaTables on Reports {
    market_name
    report
  }
`;

export const Printing = ctorsUnion({
  Idle: () => ({}),
  Fetching: () => ({}),
  Loading: (response: Reports.ReportQueryResponse) => ({ response }),
});
export type Printing = CtorsUnion<typeof Printing>;
// STATE

export interface State {
  readonly availableReports: readonly GQLOps.Printout_MetaTablesFragment[] | undefined;
  readonly reportQueryRunner: Reports.QueryRunner | undefined;
  readonly reportsBeingPrinted: ReadonlyArray<Reports.ReportType>;
  readonly selectAllReports: boolean;
  readonly printing: Printing;
}

export const Action = ctorsUnion({
  MetaProductRecieved: (response: GQLOps.Printout_MetaProductQuery) => ({
    response,
  }),

  SelectReports: (reports: ReadonlyArray<Reports.ReportType>) => ({ reports }),
  SelectAllReports: () => ({}),
  UnselectReports: (reports: ReadonlyArray<Reports.ReportType>) => ({ reports }),
  CreatePrintout: (reports: ReadonlyArray<Reports.ReportType>) => ({ reports }),
  ReportDataReceived: (data: unknown) => ({ data }),
  ReportFinishedLoading: () => ({}),
});

export type Action = CtorsUnion<typeof Action>;

export function init(
  _location: Routes.ProjectLocation,
  sharedState: SharedState.SharedState,
  prevState: State | undefined
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  if (prevState) {
    return [prevState];
  } else {
    const gqlCmd = sharedState.graphQLProductQuery<
      GQLOps.Printout_MetaProductQuery,
      GQLOps.Printout_MetaProductQueryVariables,
      Action
    >(query, { productId: clientConfig.promaster_meta_id }, (data) => {
      return Action.MetaProductRecieved(data);
    });
    return [
      {
        availableReports: undefined,
        reportQueryRunner: undefined,
        reportsBeingPrinted: [],
        selectAllReports: false,
        printing: Printing.Idle(),
      },
      gqlCmd,
    ];
  }
}

export function update(
  action: Action,
  state: State,
  projectState: ProjectState.State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const { project } = projectState;
  switch (action.type) {
    case "MetaProductRecieved": {
      const metaTables = action.response.product?.modules.custom_tables;
      const selectedReports = UserSettings.selectSelectedReports(sharedState.userSettings);
      const availableReports = metaTables?.Reports.filter((report) => report.market_name === sharedState.market.name);
      return [
        {
          ...state,
          selectAllReports: selectedReports.length === metaTables?.Reports.length,
          availableReports: availableReports,
        },
      ];
    }

    case "SelectAllReports": {
      const allReports =
        state.availableReports
          ?.map((report) => report.report)
          .filter((report): report is Reports.ReportType => !!report) || [];

      return [
        { ...state, selectAllReports: !state.selectAllReports },
        undefined,
        SharedState.SharedStateAction.UpdateUserSettings(
          UserSettings.setSelectedReports(sharedState.userSettings, !state.selectAllReports ? allReports : [])
        ),
      ];
    }

    case "SelectReports": {
      const selectedReports = [
        ...UserSettings.selectSelectedReports(sharedState.userSettings).filter(
          (currentSelected) => !action.reports.some((toBeSeletced) => currentSelected === toBeSeletced)
        ),
        ...action.reports,
      ];
      return [
        { ...state, selectAllReports: selectedReports.length === state.availableReports?.length },
        undefined,
        SharedState.SharedStateAction.UpdateUserSettings(
          UserSettings.setSelectedReports(sharedState.userSettings, selectedReports)
        ),
      ];
    }

    case "UnselectReports": {
      const selectedReports = UserSettings.selectSelectedReports(sharedState.userSettings).filter(
        (currentSelected) => !action.reports.some((toBeSeletced) => currentSelected === toBeSeletced)
      );
      return [
        { ...state, selectAllReports: selectedReports.length === state.availableReports?.length },
        undefined,
        SharedState.SharedStateAction.UpdateUserSettings(
          UserSettings.setSelectedReports(sharedState.userSettings, selectedReports)
        ),
      ];
    }

    case "CreatePrintout": {
      const reports =
        state.availableReports
          ?.map((report) => report.report)
          .filter((report): report is Reports.ReportType => report !== null)
          .filter((report) => report && action.reports.includes(report)) || [];
      const reportParams = createReportParams(project, reports, sharedState);
      const queryRunner = Reports.runReportQuries(project.id, clientConfig.image_service_url, reportParams);
      const newState = {
        ...state,
        reportQueryRunner: queryRunner,
        reportsBeingPrinted: reports,
      };
      return handleReportQueryAction(action, newState, sharedState);
    }

    case "ReportDataReceived": {
      if (!state.reportQueryRunner) {
        return [state];
      }
      return handleReportQueryAction(action, state, sharedState);
    }

    case "ReportFinishedLoading": {
      return [{ ...state, printing: Printing.Idle() }];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

function handleReportQueryAction(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?] {
  if (
    state.reportsBeingPrinted.length === 0 ||
    !state.reportQueryRunner ||
    (action.type !== "CreatePrintout" && action.type !== "ReportDataReceived")
  ) {
    return [state];
  }
  const result =
    action.type === "CreatePrintout" ? state.reportQueryRunner.next() : state.reportQueryRunner.next(action.data);
  if (result.done) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    return [{ ...state, printing: Printing.Loading(result.value), reportQueryRunner: undefined }];
  } else {
    return [{ ...state, printing: Printing.Fetching() }, requestsToCommand(result.value, sharedState)];
  }
}

export function createReportParams(
  project: Project.Project,
  reportsBeingPrinted: ReadonlyArray<Reports.ReportType>,
  sharedState: SharedState.SharedState
): ReadonlyArray<Reports.ProjectReportParams> {
  const sharedParams: Omit<Reports.ProjectReportParams, "reportType"> = {
    imageServiceUrl: clientConfig.image_service_url,
    market: sharedState.market,
    metaProductId: clientConfig.promaster_meta_id,
    projectId: project.id,
    systemId: undefined,
    systemName: undefined,
    translate: sharedState.translate,
  };
  const reportParams = [];
  reportParams.push(
    ...reportsBeingPrinted
      .filter((report) => Reports.reportSections.some((r) => r.Name === report && r.reportLevel === "project"))
      .map((reportType) => ({ ...sharedParams, reportType }))
  );
  for (const system of project.systems) {
    if (system.rooms.length === 0) {
      //Don't show systems without rooms
      continue;
    }
    reportParams.push(
      ...reportsBeingPrinted
        .filter((report) => Reports.reportSections.some((r) => r.Name === report && r.reportLevel === "system"))
        .map((reportType) => ({ ...sharedParams, reportType, systemId: system.id, systemName: system.name }))
    );
  }
  return reportParams;
}

function requestsToCommand(query: Query.Query, sharedState: SharedState.SharedState): Cmd<Action> {
  switch (query.type) {
    case "HttpBlobQuery": {
      return HttpFetch.fetchOne({}, query.url, "blob", (data) => {
        return Action.ReportDataReceived(data);
      });
    }
    case "HttpBlobQueryMultiple": {
      return HttpFetch.fetchMultiple({}, query.url, "blob", (data) => {
        return Action.ReportDataReceived(data);
      });
    }
    case "GraphQLProductQuery": {
      return sharedState.graphQLProductQuery<unknown, unknown, Action>(query.query, query.variables, (data) => {
        return Action.ReportDataReceived(data);
      });
    }
    case "GraphQLQuery": {
      return graphQLQueryWithAuth(sharedState.activeUser)<unknown, unknown, Action>(
        query.query,
        query.variables,
        sharedState.market.name,
        (data) => {
          return Action.ReportDataReceived(data);
        }
      );
    }
    default:
      exhaustiveCheck(query);
      throw new Error("Not implemented");
  }
}
