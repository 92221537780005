import { ReportLevel } from "..";
import { ImageData } from "../../images";
import { TranslateFn } from "../../lang-texts";
import * as Common from "../common";
import { BitmapImage } from "../common";
import { ReportModule } from "../types";

export const Name = "front-page";
export const reportLevel: ReportLevel = "project";

export type Response = { readonly images: readonly ImageData[] };

export type CreatorProps = {
  readonly pageProps: Common.CataloguePageProps;
  readonly translate: TranslateFn;
  readonly coverImage: BitmapImage | undefined;
};

export type AdapterType = ReportModule<CreatorProps, Response>["execute"];
export type CreatorType = ReportModule<CreatorProps, Response>["create"];
