import { exhaustiveCheck } from "ts-exhaustive-check";
import { FormatOption, GaebFormat } from "./types";

const allFormats: ReadonlyArray<FormatOption> = [
  {
    format: "gaebxml32" as GaebFormat,
    name: "GAEB XML 3.2",
    extension: "X81",
    mime: "application/xml",
  },
  {
    format: "gaebxml31" as GaebFormat,
    name: "GAEB XML 3.1",
    extension: "X81",
    mime: "application/xml",
  },
  {
    format: "gaebxml30" as GaebFormat,
    name: "GAEB XML 3.0",
    extension: "X81",
    mime: "application/xml",
  },
  {
    format: "gaeb2000" as GaebFormat,
    name: "GAEB 2000",
    extension: "P81",
    mime: "text/plain",
  },
  {
    format: "gaeb90" as GaebFormat,
    name: "GAEB 90",
    extension: "D81",
    mime: "text/plain",
  },
];

export function getGaebFormats(): ReadonlyArray<FormatOption> {
  return allFormats;
}

export function getDefaultGaebFormat(): FormatOption {
  return allFormats[0];
}

export function getGaebFormat(format: string): FormatOption | undefined {
  return allFormats.find((f) => f.format === format) ?? getDefaultGaebFormat();
}

export function getMaxCategoryNameLength(format: GaebFormat): number {
  switch (format) {
    case "gaeb90":
      return 40;
    case "gaebxml32":
    case "gaebxml31":
    case "gaebxml30":
    case "gaeb2000":
      return 100;
    default:
      return exhaustiveCheck(format);
  }
}
