import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { SharedState, Routes } from "@rvs/client-infra";
import { Project, Texts, Gaeb, Utils, UserSettings, User } from "@rvs/shared";
import { TranslateFn } from "@rvs/shared/src/lang-texts";
import { Permissions } from "@rvs/shared/src/project";
import { ExportResponse } from "@rvs/shared/src/crm";
import * as State from "./state";
import * as ProjectState from "../../project-state";
import { Action } from "./state";
import * as GQLOps from "../../../../generated/generated-operations";
import {
  Textfield,
  Alert,
  Button,
  ConfirmRemoveButton,
  H3,
  Icon,
  Selector,
  Spinner,
  Toolbar,
  withTw,
} from "../../../../elements";

const ThLeft = withTw("th", "text-left pr-20 select-none");
const Row = withTw("div", "flex flex-row justify-between w-340 items-center h-36 gap-3-px");
const FormContainer = withTw("div", "flex flex-col space-y-8");
const ShowReminder = withTw("span", "text-[#ff0000]");
const VoluntarilyCustomNumber = withTw("div", "hidden");

const texts = Texts.texts;

const ProjectAction = ProjectState.Action;

export function view(props: {
  readonly state: State.State;
  readonly projectState: ProjectState.State;
  readonly sharedState: SharedState.SharedState;
  readonly exportingToCrm: boolean;
  readonly crmExportResponse: ExportResponse | undefined;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
}): JSX.Element {
  const { state, projectState, sharedState, exportingToCrm, crmExportResponse, dispatch, dispatchProject } = props;
  const { project } = projectState;
  const translate = sharedState.translate;
  const isReadonly = Project.isProjectReadOnly(project);

  const hasSystemWithMaterials = !!project.systems.find((s) => s.materials.length > 0);

  const crmCustomerNumber = sharedState.crmParams?.crmCustomerNumber;
  const approvedCustomerNumber = User.getApprovedCustomerNumber(sharedState.activeUser);
  if (state.countries === undefined) {
    return <span></span>;
  }
  return (
    <div className="flex flex-col space-y-16">
      {!Utils.isSystemairEmail(sharedState.activeUser.email) ? (
        <CustomerNumberAlert activeUser={sharedState.activeUser} translate={translate} />
      ) : null}
      <Toolbar>
        <ConfirmRemoveButton
          onClick={() => dispatchProject(ProjectAction.RemoveProject())}
          confirmMessage={translate(project.template ? texts.confirm_delete_template : texts.confirm_delete_project)}
          removeText={translate(project.template ? texts.remove_template : texts.remove_project)}
          cancelText={translate(Texts.texts.cancel)}
          iconMessage={translate(project.template ? texts.remove_template : texts.remove_project)}
        />
        {project.template && (
          <Button
            type={"secondary"}
            label={translate(texts.create_project_from_template)}
            disabled={state.createdFromTemplateProjectId === "waiting"}
            onClick={() => dispatch(Action.CreateFromTemplate())}
          />
        )}

        {!project.template && (
          <Button
            type={"secondary"}
            label={translate(texts.save_as_template)}
            onClick={() => {
              props.dispatch(Action.SaveAsTemplate());
            }}
          />
        )}

        {!project.template && sharedState.market.gaebEnabled && (
          <Button
            type={"secondary"}
            disabled={
              !Gaeb.isExportInfoOk(Gaeb.getExportInfo(project, state.gaebFormat, sharedState.translate)) ||
              !hasSystemWithMaterials
            }
            onClick={() => dispatch(State.Action.ExportToGaeb())}
            label={translate(texts.download_gaeb)}
          />
        )}

        {!project.template && !!sharedState.crmParams && (
          <Button
            type="secondary"
            label={translate(texts.export_to_crm)}
            onClick={() => dispatchProject(ProjectState.Action.ExportToCrm(false))}
            disabled={exportingToCrm || !hasSystemWithMaterials}
          />
        )}

        {!project.template && !!sharedState.crmParams && UserSettings.selectShowAdvanced(sharedState.userSettings) && (
          <Button
            type="secondary"
            label={"Download CRM envelope"}
            onClick={() => dispatchProject(ProjectState.Action.ExportToCrm(true))}
            disabled={exportingToCrm || !hasSystemWithMaterials}
          />
        )}

        {!project.template && project.locked && (
          <Button
            type={"secondary"}
            disabled={!project.owned}
            label={translate(texts.unlock_project)}
            onClick={() => dispatch(Action.UnlockProject("confirm"))}
          />
        )}

        {!project.template && !project.locked && (
          <Button
            type={"secondary"}
            disabled={!project.owned}
            label={translate(texts.lock_project)}
            onClick={() => {
              dispatchProject(ProjectState.Action.UpdateProjectLockState(true));
            }}
          />
        )}

        {exportingToCrm ? <Spinner /> : undefined}
      </Toolbar>

      {state.savedTemplateId ? (
        <Alert type="success">
          <div>
            {translate(texts.template_saved) + `: `}
            <a
              href={Routes.buildUrl(
                Routes.RootLocation.MainLocation(
                  Routes.MainLocation.Project(state.savedTemplateId, Routes.ProjectLocation.General())
                )
              )}
            >
              {translate(texts.go_to_template)}
            </a>
          </div>
        </Alert>
      ) : undefined}
      {state.createdFromTemplateProjectId && state.createdFromTemplateProjectId !== "waiting" ? (
        <Alert type="success">
          <div>
            {translate(texts.project_created) + `: `}
            <a
              href={Routes.buildUrl(
                Routes.RootLocation.MainLocation(
                  Routes.MainLocation.Project(state.createdFromTemplateProjectId, Routes.ProjectLocation.General())
                )
              )}
            >
              {translate(texts.go_to_project)}
            </a>
          </div>
        </Alert>
      ) : undefined}
      {state.confirmUnlock ? (
        <Alert type="danger">
          <span>{translate(texts.unlock_project_note)}</span>
          <Toolbar align="right">
            <Button
              type="danger"
              label={translate(texts.unlock_project)}
              onClick={() => {
                dispatch(Action.UnlockProject("done"));
                dispatchProject(ProjectState.Action.UpdateProjectLockState(false));
              }}
            />
            <Button
              type="secondary"
              label={translate(texts.cancel)}
              onClick={() => dispatch(Action.UnlockProject("done"))}
            />
          </Toolbar>
        </Alert>
      ) : undefined}

      {crmExportResponse && crmExportResponse.status === 200 ? (
        <Alert type="success">
          <span>{translate(texts.sucessfully_exported_to_crm)}</span>
        </Alert>
      ) : crmExportResponse && crmExportResponse.status !== 200 ? (
        <Alert type="danger">
          <p>{translate(texts.failed_to_export_crm_message)}</p>
          <p>{crmExportResponse.message}</p>
        </Alert>
      ) : undefined}

      <FormContainer>
        <Row>
          <Label label={translate(texts.name)} />
          <Textfield
            value={project.name || ""}
            readOnly={isReadonly}
            onChange={(v) => dispatchProject(ProjectState.Action.UpdateProject({ name: v }))}
            debounce={true}
            debounceTime={500}
          />
          <ShowReminder
            className={
              project.name === "" && project.customerCountry === "country_germany" ? "opacity-100" : "opacity-0"
            }
          >
            *
          </ShowReminder>
        </Row>
        {(Utils.isSystemairEmail(sharedState.activeUser.email) ||
          sharedState.activeUser.email.endsWith("@divid.se")) && (
          <div className="flex flex-row space-x-8 items-center">
            <Row>
              <Label label={translate(texts.customer_number)} info={translate(texts.customer_number_override)} />
              <Textfield
                value={project.customerNumber || ""}
                readOnly={true}
                onChange={(v) => dispatchProject(ProjectState.Action.UpdateProject({ customerNumber: v }))}
                debounce={true}
                debounceTime={500}
              />
            </Row>
            {project.customerNumber && crmCustomerNumber && project.customerNumber !== crmCustomerNumber ? (
              <Button
                label={translate(texts.change_to_crm_customer_number)}
                disabled={isReadonly}
                onClick={() =>
                  dispatchProject(ProjectState.Action.UpdateProject({ customerNumber: crmCustomerNumber }))
                }
              />
            ) : project.customerNumber &&
              approvedCustomerNumber &&
              project.customerNumber !== approvedCustomerNumber ? (
              <Button
                label={translate(texts.change_to_account_customer_number)}
                disabled={isReadonly}
                onClick={() =>
                  dispatchProject(ProjectState.Action.UpdateProject({ customerNumber: approvedCustomerNumber }))
                }
              />
            ) : undefined}
          </div>
        )}
        {sharedState.market.name === "Germany" && (
          <GaebFormatSelector selectedFormat={state.gaebFormat} dispatch={dispatch} translate={translate} />
        )}
      </FormContainer>

      <DetailsForm
        countries={state.countries}
        project={project}
        dispatchProject={dispatchProject}
        translate={translate}
      />

      {project.template ? undefined : (
        <>
          <H3>{translate(texts.shares)}</H3>
          <div>
            {project.owned ? (
              <ShareProject
                dispatchProject={dispatchProject}
                translate={translate}
                project={project}
                activeUserEmail={sharedState.activeUser.email}
              />
            ) : undefined}
          </div>
          {project.shares.length > 0 ? (
            <SharesTable
              dispatchProject={dispatchProject}
              translate={translate}
              project={project}
              activeUserEmail={sharedState.activeUser.email}
            />
          ) : undefined}
        </>
      )}
    </div>
  );
}

function Label({ label, info }: { readonly label: string; readonly info?: string }): JSX.Element {
  return (
    <div className="flex items-center w-1/2">
      <span>{label}</span>
      {info && <Icon className="ml-4" icon="info-circle" message={info} />}
    </div>
  );
}

function DetailsForm({
  translate,
  dispatchProject,
  project,
  countries,
}: {
  readonly project: Project.Project;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly translate: TranslateFn;
  readonly countries: ReadonlyArray<string>;
}): JSX.Element {
  const detailsOptions: ReadonlyArray<{ readonly id: "project" | "customer"; readonly name: string }> = [
    { id: "project", name: translate(texts.project_details) },
    { id: "customer", name: translate(texts.customer_details) },
  ];
  const [detailsType, setDetailsType] = React.useState(detailsOptions[0]);

  return (
    <div>
      <h3>{translate(texts.details)}</h3>
      <FormContainer>
        <Row>
          <Label label={"Details"} />
          <Selector
            className="w-1/2"
            value={detailsType}
            onChange={(newValue) => setDetailsType(newValue)}
            options={detailsOptions}
          />
          <ShowReminder className={"opacity-0"}>*</ShowReminder>
        </Row>

        {detailsType.id === "project" && (
          <>
            <AddressRow
              addressField={"projectAddressname"}
              project={project}
              dispatchProject={dispatchProject}
              label={translate(texts.name)}
            >
              <ShowReminder
                className={
                  project.projectAddressname === "" && project.customerCountry === "country_germany"
                    ? "opacity-100"
                    : "opacity-0"
                }
              >
                *
              </ShowReminder>
            </AddressRow>

            <AddressRow
              addressField={"projectStreet"}
              project={project}
              dispatchProject={dispatchProject}
              label={translate(texts.street)}
            >
              <ShowReminder
                className={
                  project.projectStreet === "" && project.customerCountry === "country_germany"
                    ? "opacity-100"
                    : "opacity-0"
                }
              >
                *
              </ShowReminder>
            </AddressRow>

            <AddressRow
              addressField={"projectPostalcode"}
              project={project}
              dispatchProject={dispatchProject}
              label={translate(texts.postal_code)}
            >
              <ShowReminder
                className={
                  project.projectPostalcode === "" && project.customerCountry === "country_germany"
                    ? "opacity-100"
                    : "opacity-0"
                }
              >
                *
              </ShowReminder>
            </AddressRow>

            <AddressRow
              addressField={"projectCity"}
              project={project}
              dispatchProject={dispatchProject}
              label={translate(texts.city)}
            >
              <ShowReminder
                className={
                  project.projectCity === "" && project.customerCountry === "country_germany"
                    ? "opacity-100"
                    : "opacity-0"
                }
              >
                *
              </ShowReminder>
            </AddressRow>

            <Row>
              <Label label={translate(texts.country)} />
              <SelectorInput
                project={project}
                addressField={"projectCountry"}
                dispatchProject={dispatchProject}
                countries={countries}
                translate={translate}
              />

              <ShowReminder
                className={
                  project.projectCountry === "" && project.customerCountry === "country_germany"
                    ? "opacity-100"
                    : "opacity-0"
                }
              >
                *
              </ShowReminder>
            </Row>
          </>
        )}
        {detailsType.id === "customer" && (
          <>
            <AddressRow
              addressField={"customerAddressname"}
              project={project}
              dispatchProject={dispatchProject}
              label={translate(texts.name)}
            >
              <ShowReminder
                className={
                  project.customerAddressname === "" && project.customerCountry === "country_germany"
                    ? "opacity-100"
                    : "opacity-0"
                }
              >
                *
              </ShowReminder>
            </AddressRow>

            <AddressRow
              addressField={"customerStreet"}
              project={project}
              dispatchProject={dispatchProject}
              label={translate(texts.street)}
            >
              <ShowReminder
                className={
                  project.customerStreet === "" && project.customerCountry === "country_germany"
                    ? "opacity-100"
                    : "opacity-0"
                }
              >
                *
              </ShowReminder>
            </AddressRow>

            <AddressRow
              addressField={"customerPostalcode"}
              project={project}
              dispatchProject={dispatchProject}
              label={translate(texts.postal_code)}
            >
              <ShowReminder
                className={
                  project.customerPostalcode === "" && project.customerCountry === "country_germany"
                    ? "opacity-100"
                    : "opacity-0"
                }
              >
                *
              </ShowReminder>
            </AddressRow>

            <AddressRow
              addressField={"customerCity"}
              project={project}
              dispatchProject={dispatchProject}
              label={translate(texts.city)}
            >
              <ShowReminder
                className={
                  project.customerCity === "" && project.customerCountry === "country_germany"
                    ? "opacity-100"
                    : "opacity-0"
                }
              >
                *
              </ShowReminder>
            </AddressRow>

            <Row>
              <Label label={translate(texts.country)} />
              <SelectorInput
                project={project}
                addressField="customerCountry"
                dispatchProject={dispatchProject}
                countries={countries}
                translate={translate}
              ></SelectorInput>
            </Row>
            <AddressRow
              addressField={"customerCustomerNumber"}
              project={project}
              dispatchProject={dispatchProject}
              label={translate(texts.customer_number)}
            >
              <VoluntarilyCustomNumber>
                <ShowReminder>*</ShowReminder>
              </VoluntarilyCustomNumber>
            </AddressRow>
          </>
        )}
      </FormContainer>
    </div>
  );
}

function GaebFormatSelector({
  selectedFormat,
  translate,
  dispatch,
}: {
  readonly selectedFormat: string | undefined;
  readonly translate: TranslateFn;
  readonly dispatch: Dispatch<State.Action>;
}): JSX.Element {
  const allFormats = Gaeb.getGaebFormats();

  const maybeFormat = allFormats.find((format) => format.format === selectedFormat);
  const selectorFormat = maybeFormat ?? Gaeb.getDefaultGaebFormat();

  const options = allFormats.map((f) => ({
    id: f.format,
    name: f.name,
  }));
  return (
    <Row>
      <Label label={translate(texts.gaeb_format)} />
      <Selector
        className="w-1/2"
        value={{ id: selectorFormat.format, name: selectorFormat.name }}
        onChange={(s) => dispatch(Action.SetGaebFormat(allFormats.find((f) => f.format === s.id)?.format))}
        options={options}
      />
      <ShowReminder className={"opacity-0"}>*</ShowReminder>
    </Row>
  );
}

function AddressRow({
  label,
  addressField,
  project,
  dispatchProject,
  children,
}: {
  readonly label: string;
  readonly addressField: keyof Project.Project;
  readonly project: Project.Project;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly children?: JSX.Element;
}): JSX.Element {
  const projectField = project[addressField];
  const value = typeof projectField === "string" ? projectField : "";

  return (
    <Row>
      <Label label={label} />
      <Textfield
        className="w-1/2"
        value={value}
        onChange={(v) => dispatchProject(ProjectState.Action.UpdateProject({ [addressField]: v }))}
        debounce={true}
        debounceTime={500}
        readOnly={project.locked}
      />
      {children}
    </Row>
  );
}

function SelectorInput({
  addressField,
  project,
  dispatchProject,
  countries,

  translate,
}: {
  // readonly label: string;
  readonly addressField: keyof Project.Project;
  readonly project: Project.Project;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly countries: ReadonlyArray<string>;

  readonly translate: Texts.TranslateFn;
}): JSX.Element {
  const selectorOptions = countries?.map((o) => ({
    id: o,
    name: o ? translate(Texts.key(o)) : "",
  }));
  const selectorOptionsCountry = [{ id: "", name: "" }, ...selectorOptions];

  const country = project[addressField];
  const matchingValue = selectorOptions.find((e) => e.id === country);
  const definedValue = matchingValue || selectorOptionsCountry[0];

  return (
    <Selector
      className="w-1/2"
      onChange={(newValue) => dispatchProject(ProjectState.Action.UpdateProject({ [addressField]: newValue.id }))}
      options={selectorOptionsCountry}
      value={definedValue}
    />
  );
}

function SharesTable({
  dispatchProject,
  translate,
  project,
  activeUserEmail,
}: {
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly translate: Texts.TranslateFn;
  readonly project: GQLOps.Project_ProjectFragment;
  readonly activeUserEmail: string;
}): JSX.Element {
  return (
    <table>
      <thead>
        <tr>
          <ThLeft className="w-1/4">{translate(texts.shared_with)}</ThLeft>
          <ThLeft className="w-1/12">{translate(texts.permissions)}</ThLeft>
          <ThLeft />
        </tr>
      </thead>
      <tbody>
        {project.shares.map((s) => (
          <tr key={s.id}>
            <td>{s.email}</td>
            <td>{translate(Texts.texts.permission_name(s.permissions as Project.Permissions))}</td>
            <td>
              <Icon
                icon={"trash-alt"}
                disabled={!project.owned && s.email !== activeUserEmail}
                onClick={() => {
                  s.email && dispatchProject(ProjectAction.RemoveShare(project.id, s.email));
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function ShareProject({
  dispatchProject,
  translate,
  project,
  activeUserEmail,
}: {
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly translate: Texts.TranslateFn;
  readonly project: GQLOps.Project_ProjectFragment;
  readonly activeUserEmail: string;
}): JSX.Element {
  const [email, setEmail] = React.useState<string>("");
  const [showEmailValidation, setShowEmailValidation] = React.useState<boolean>(false);
  const [permissions, setPermissions] = React.useState("read" as Permissions);
  const permissionsOptions: ReadonlyArray<{ id: Permissions; name: string }> = [
    { id: "read", name: translate(Texts.texts.permission_name("read")) },
    { id: "read-write", name: translate(Texts.texts.permission_name("read-write")) },
  ];
  const currentShares = project.shares.map((share) => share.email);

  return (
    <div className="h-[3rem] flex ">
      <Toolbar>
        <div className="whitespace-nowrap flex flex-row items-center">{`${translate(texts.email)}`}</div>
        <Textfield
          value={email}
          onChange={(v) => {
            return isValidEmail(v, activeUserEmail, currentShares)
              ? [setEmail(v), setShowEmailValidation(false)]
              : [setEmail(v), setShowEmailValidation(true)];
          }}
          debounce={true}
          debounceTime={700}
          // eslint-disable-next-line no-console
          onBlur={(v) => !isValidEmail(v, activeUserEmail, currentShares) && setShowEmailValidation(true)}
          errorMessage={showEmailValidation && email !== "" ? "Not a valid Email" : undefined}
        />
        <div className="flex flex-row items-center">
          <Selector
            value={permissionsOptions.find((o) => o.id === permissions) || permissionsOptions[0]}
            options={permissionsOptions}
            onChange={(o) => setPermissions(o.id)}
          />
        </div>
        <Button
          className="flex flex-row items-center"
          type={"secondary"}
          label={translate(project.template ? texts.share_template : texts.share_project)}
          onClick={() => {
            setEmail("");
            setPermissions("read");
            dispatchProject(ProjectAction.ShareProject(email, permissions));
          }}
          disabled={!isValidEmail(email, activeUserEmail, currentShares)}
        />
        {email === activeUserEmail && (
          <Alert key="alert" type="danger" className="p-[3px]">
            {translate(texts.cant_share_with_yourself)}
          </Alert>
        )}
        {currentShares.includes(email) && (
          <Alert key="alert" type="danger" className="p-[3px]">
            {translate(texts.email_already_has_share)}
          </Alert>
        )}
      </Toolbar>
    </div>
  );
}

const emailValidator = new RegExp(".+@.+[.].+");

function isValidEmail(email: string, activeUserEmail: string, currentShares: readonly (string | null)[]): boolean {
  return emailValidator.test(email) && email !== activeUserEmail && !currentShares.includes(email);
}

function CustomerNumberAlert({
  activeUser,
  translate,
}: {
  readonly activeUser: User.ActiveUser;
  readonly translate: Texts.TranslateFn;
}): JSX.Element | null {
  const customerNumber = activeUser.customerNumber;
  const customerNumberApproved = activeUser.customerNumberApproved;
  const url = new URL(window.location.href);
  return !customerNumber ? (
    <Alert type="info">
      <p className="font-bold m-0">{translate(texts.customer_number_status)}</p>
      <p className="m-0">{translate(texts.no_customer_number_message)}</p>
      <a
        href={`https://sso-info.aks.systemair.divid.se?redirect_to=${encodeURIComponent(
          `${url.protocol}//${url.host}`
        )}&missing_claims=sys_sso_customer_number`}
        target={"_self"}
      >
        {translate(texts.add_customer_number)}
      </a>
    </Alert>
  ) : customerNumber && !customerNumberApproved ? (
    <Alert type="info">
      <p className="font-bold m-0">{translate(texts.customer_number_status)}</p>
      {translate(texts.customer_number_processed_message)}
    </Alert>
  ) : null;
}
