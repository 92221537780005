import { AbstractDoc as AD } from "abstract-document";
import { TranslateFn } from "../lang-texts";
import * as GQLOps from "../generated/generated-operations";
//import { Project } from "../project";
import * as FrontPage from "./front-page-section";
import * as AirDistribution from "./air-distribution-section";
import * as MaterialList from "./material-list-section";
import * as NominalFlow from "./nominal-flow-section";
import * as ProjectData from "./project-data-section";
import * as Result from "./result-section";
import * as Room from "./room-section";
import * as Services from "./services-section";
import * as Ventilation from "./ventilation-section";
import * as VolumeFlow from "./volume-flow-section";
import * as TableOfContent from "./table-of-content-section";
import * as ProjectDetails from "./project-details";
import * as FixedPdf from "./fixed-pdf";
import { QueryGenerator } from "../query";
import { ImageData } from "../images";
import { Markets } from "..";

export const reportSections: ReadonlyArray<ReportModule<CreatorProp, ReportResponse>> = [
  AirDistribution,
  FrontPage,
  MaterialList,
  NominalFlow,
  ProjectData,
  Result,
  Room,
  Services,
  Ventilation,
  VolumeFlow,
  TableOfContent,
  ProjectDetails,
  FixedPdf,
];

export type CreatorProp =
  | AirDistribution.CreatorProps
  | FrontPage.CreatorProps
  | MaterialList.CreatorProps
  | NominalFlow.CreatorProps
  | ProjectData.CreatorProps
  | Result.CreatorProps
  | Room.CreatorProps
  | Services.CreatorProps
  | Ventilation.CreatorProps
  | VolumeFlow.CreatorProps
  | TableOfContent.CreatorProps
  | ProjectDetails.CreatorProps
  | FixedPdf.CreatorProps;

export type ReportResponse =
  | AirDistribution.Response
  | FrontPage.Response
  | MaterialList.Response
  | NominalFlow.Response
  | ProjectData.Response
  | Result.Response
  | Room.Response
  | Services.Response
  | Ventilation.Response
  | VolumeFlow.Response
  | TableOfContent.Response
  | ProjectDetails.Response
  | FixedPdf.Response;

export type ReportType =
  | typeof AirDistribution.Name
  | typeof FrontPage.Name
  | typeof MaterialList.Name
  | typeof NominalFlow.Name
  | typeof ProjectData.Name
  | typeof Result.Name
  | typeof Room.Name
  | typeof Services.Name
  | typeof Ventilation.Name
  | typeof VolumeFlow.Name
  | typeof TableOfContent.Name
  | typeof ProjectDetails.Name
  | typeof FixedPdf.Name;

export type ReportLevel = "project" | "system";

export type ReportModule<TInput, TResponse> = {
  readonly Name: ReportType;
  readonly reportLevel: ReportLevel;
  readonly query: (params: ReportQueryParams) => QueryGenerator<TResponse>;
  readonly execute: (
    params: ProjectReportParams,
    commonResponse: CommonResponse,
    response: TResponse
  ) => Promise<TInput>;
  readonly create: (params: TInput) => AD.AbstractDoc.AbstractDoc;
};

export interface ProjectReportParams {
  readonly reportType: ReportType;
  readonly imageServiceUrl: string;
  readonly market: Markets.Market;
  readonly metaProductId: string;
  readonly projectId: string;
  readonly systemId: string | undefined;
  readonly systemName: string | undefined;
  readonly translate: TranslateFn;
  readonly orientation?: "Landscape" | "Portrait";
}

export interface ReportQueryParams extends ProjectReportParams {
  readonly imageResponse: GQLOps.Image_Module_ImagesFragment["image"] | undefined;
}

export type HeadingsWithSystem = ReadonlyArray<{
  readonly reportId: string;
  readonly systemId: string | undefined;
  readonly systemName: string | undefined;
}>;

export type CommonResponse = {
  readonly fontData: FontData;
  readonly logoImage: ReadonlyArray<ImageData>;
  readonly headings: HeadingsWithSystem;
  readonly projectResponse: GQLOps.ReportCommonProject_ProjectQuery;
};

export interface FontData {
  readonly normal: Uint8Array;
  readonly medium: Uint8Array;
  readonly bold: Uint8Array;
  readonly italic: Uint8Array;
  readonly boldItalic: Uint8Array;
}

export type ReportQueryResponse = {
  readonly commonResponse: CommonResponse;
  readonly reportResponses: ReadonlyArray<ReportResponse>;
};

export type QueryRunner = QueryGenerator<ReportQueryResponse>;
