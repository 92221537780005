import gql from "graphql-tag";
import { defaultLanguage, LanguageCode } from "../lang-texts";
import * as GqlOps from "../generated/generated-operations";
import { log } from "..";
import { ReportType } from "../reports";
import { findUnit, Quantities, QuantityTypes, Units, UnitTypes } from "../units";

type ValueOf<T> = T[keyof T];
const defaultUnits = {
  airflow: Units.CubicMeterPerHour,
  length: Units.Meter,
  area: Units.SquareMeter,
  volume: Units.CubicMeter,
  dimensionless_per_duration: Units.OnePerHour,
};

export const userSettingsQuery = gql`
  query GetUserSettings {
    userSettings {
      name
      value
    }
  }
`;

export const userSettingMutation = gql`
  mutation UpdateUserSettings($input: [UpdateUserSettingInput!]!) {
    updateUserSettings(input: $input) {
      usersetting {
        name
        value
      }
    }
  }
`;

export type UserSettings = { readonly [key: string]: unknown };

export function updateWithResponse(userSettings: UserSettings, response: GqlOps.GetUserSettingsQuery): UserSettings {
  const fromResponse: { [key: string]: string } = {};
  for (const row of response.userSettings) {
    if (row.value !== undefined && row.value !== null) {
      try {
        fromResponse[row.name] = JSON.parse(row.value);
      } catch {
        log(`Failed to parse: ${row.value}`);
      }
    }
  }
  return {
    ...userSettings,
    ...fromResponse,
  };
}

export function createGqlMutations(
  userSetting: UserSettings,
  prevUserSettings: UserSettings
): readonly [typeof userSettingMutation, ReadonlyArray<GqlOps.UpdateUserSettingInput>] {
  const inputs: Array<GqlOps.UpdateUserSettingInput> = [];
  for (const key of Object.keys(userSetting)) {
    const value = JSON.stringify(userSetting[key]);
    const prevValue = JSON.stringify(prevUserSettings[key]);
    if (value !== prevValue) {
      inputs.push({
        name: key,
        value: value,
      });
    }
  }
  return [userSettingMutation, inputs];
}

export function selectMarket(userSettings: UserSettings): string {
  return (userSettings["market"] as string) || "Global";
}

export function setMarket(userSettings: UserSettings, market: string): UserSettings {
  return { ...userSettings, market };
}

export function selectLanguage(userSettings: UserSettings): LanguageCode {
  return (userSettings["language"] as LanguageCode) || defaultLanguage;
}

export function setLanguage(userSettings: UserSettings, language: LanguageCode): UserSettings {
  return { ...userSettings, language };
}

export function selectUnit<T extends Quantities>(userSettings: UserSettings, quantity: T): ValueOf<T["units"]> {
  const unitName = ((userSettings["units"] || {}) as Record<QuantityTypes, string>)[
    quantity.type
  ] as UnitTypes["unitName"];
  const unit = findUnit(unitName, quantity);
  return unit || (defaultUnits[quantity.type] as unknown as ValueOf<T["units"]>);
}

export function selectUnitName<T extends Quantities>(userSettings: UserSettings, quantity: T): string {
  const unitName = ((userSettings["units"] || {}) as Record<QuantityTypes, string>)[
    quantity.type
  ] as UnitTypes["unitName"];
  const unit = findUnit(unitName, quantity);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ((unit || (defaultUnits[quantity.type] as unknown as ValueOf<T["units"]>)) as any).unitName;
}

export function setUnit(
  userSettings: UserSettings,
  quantity: Quantities,
  unit: Partial<UnitTypes["unitName"]>
): UserSettings {
  return {
    ...userSettings,
    units: { ...(userSettings["units"] as Record<QuantityTypes, string>), [quantity.type]: unit },
  };
}

export function selectShowAdvanced(userSettings: UserSettings): boolean {
  return userSettings["show_advanced"] === "1";
}

export function setShowAdvanced(userSettings: UserSettings, showAdvanced: boolean): UserSettings {
  return { ...userSettings, show_advanced: showAdvanced ? "1" : "0" };
}

export function selectShowImages(userSettings: UserSettings): boolean {
  return userSettings["show_images"] === undefined || userSettings["show_images"] === "1";
}

export function setShowImages(userSettings: UserSettings, showImages: boolean): UserSettings {
  return { ...userSettings, show_images: showImages ? "1" : "0" };
}

export function setSelectedReports(
  userSettings: UserSettings,
  selectedReports: ReadonlyArray<ReportType>
): UserSettings {
  return { ...userSettings, selectedReports };
}

export function selectSelectedReports(userSettings: UserSettings): ReadonlyArray<ReportType> {
  return (userSettings["selectedReports"] as ReadonlyArray<ReportType>) || [];
}
