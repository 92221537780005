import { schema as root } from "./root";
import { schema as project } from "./project";
import { schema as projectShare } from "./project-share";
import { schema as projectList } from "./project-list";
import { schema as system } from "./system";
import { schema as room } from "./room";
import { schema as userSetting } from "./user-setting";
import { schema as material } from "./material";
import { schema as ventilationConcept } from "./ventilation-concept";
import { schema as quoteRequest } from "./quote-request";
import { schema as additionalDocuments } from "./system-pdf";

export const schema = [
  root,
  project,
  projectShare,
  projectList,
  system,
  room,
  userSetting,
  material,
  ventilationConcept,
  quoteRequest,
  additionalDocuments,
];
