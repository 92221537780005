import { v4 as uuid } from "uuid";
import { ItemsTable } from ".";
import { Project } from "../..";
import { MaterialTables } from "./types";

export function getAirUnitMaterials(itemsTable: ItemsTable): ReadonlyArray<Project.Material> {
  const airUnitMaterials = itemsTable
    .filter((i) => i.type === "air_unit" && !!i.item_number)
    .map((i) => ({
      ...Project.createMaterial(uuid(), "standard", 0, i.item_number!),
      quantity: 1,
      included: true,
    }));
  return airUnitMaterials;
}

export function getStandardMaterials(materialTables: MaterialTables): ReadonlyArray<Project.Material> {
  const standardMaterials = materialTables.tables.items
    .filter((i) => i.type !== "air_unit" && !!i.item_number)
    .map((i) => ({
      ...Project.createMaterial(uuid(), "standard", 0, i.item_number!),
      quantity: 1,
    }));
  return standardMaterials;
}
