import * as Gql from "../../generated/generated-operations";
import { Row, Table } from "../../graphql-utils";

export interface RoomResult {
  readonly airflow: number | undefined;
  readonly nominalAirflow: number | undefined;
  readonly volume: number | undefined;
  readonly area: number | undefined;
  readonly width: number | undefined;
  readonly length: number | undefined;
  readonly valves: number | undefined;
  readonly ducts: number | undefined;
}

export type ResultByRoomId = { readonly [roomId: string]: RoomResult };

export interface Result {
  readonly type: "germany";
  readonly requiredAirflow: number | undefined;
  readonly totalArea: number | undefined;
  readonly airflowForTotalArea: number | undefined;
  readonly roomResults: ResultByRoomId;
  readonly extractRoomsTotalArea: number | undefined;
  readonly extractRoomsTotalNominalAirflow: number | undefined;
  readonly supplyAirflow: number | undefined;
  readonly extractAirflow: number | undefined;
  readonly totalVolume: number | undefined;
  readonly ventilationChangeRates: VentilationChangeRates | undefined;
  readonly quantities: Quantities;
}

export interface VentilationChangeRate {
  readonly airflow: number;
  readonly changeRate: number;
}

export interface VentilationChangeRates {
  readonly moistureControl: VentilationChangeRate | undefined;
  readonly reduced: VentilationChangeRate;
  readonly nominal: VentilationChangeRate;
  readonly intensive: VentilationChangeRate;
}

export interface Quantities {
  readonly supplyDucts: number | undefined;
  readonly extractDucts: number | undefined;
  readonly supplyValves: number | undefined;
  readonly extractValves: number | undefined;
}

export type RoomsWithoutWindowsTable = Table<Row<Gql.RoomsWithoutWindows>>;
