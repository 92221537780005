import { Cmd } from "@typescript-tea/core";
import gql from "graphql-tag";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { v4 as uuid } from "uuid";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { User } from "@rvs/shared";
import { texts } from "@rvs/shared/src/lang-texts";
import {
  graphQLMutationWithAuth,
  graphQLQueryWithAuth,
  NavigationEffectManager,
  Routes,
  SharedState,
} from "@rvs/client-infra";
import * as GQLOps from "../../generated/generated-operations";

const projectListQuery = gql`
  query projectList(
    $filter: String
    $searchProjectList: String
    $pageCursor: String
    $defaultName: String
    $sortOrder: [String]
  ) {
    projectList(
      filter: $filter
      searchProjectList: $searchProjectList
      pageCursor: $pageCursor
      sortOrder: $sortOrder
      defaultName: $defaultName
    ) {
      projects {
        ...projectList_project
      }
      currentPage {
        ...projectList_page
      }
      nextPage {
        ...projectList_page
      }
      previousPage {
        ...projectList_page
      }
      pages {
        ...projectList_page
      }
    }
  }
  fragment projectList_page on ProjectListPage {
    page
    cursor
  }
  fragment projectList_project on Project {
    id
    name
    permissions
    locked
    created
    edited
    owner
  }
`;

const createProjectMutation = gql`
  mutation ProjectList_createProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      project {
        id
        name
      }
    }
  }
`;

const removeProjectMutation = gql`
  mutation ProjectList_removeProject($input: RemoveProjectInput!) {
    removeProject(input: $input) {
      id
    }
  }
`;

const removeTemplateMutation = gql`
  mutation ProjectList_removeTemplate($input: RemoveTemplateInput!) {
    removeTemplate(input: $input) {
      id
    }
  }
`;

const removeShareShareMutation = gql`
  mutation ProjectList_removeShare($input: RemoveProjectShareInput!) {
    removeProjectShare(input: $input) {
      id
    }
  }
`;

const createProjectFromTemplateMutation = gql`
  mutation EditProject_createProjectFromTemplate($input: CreateProjectFromTemplateInput!) {
    createProjectFromTemplate(input: $input) {
      id
    }
  }
`;

export type SortOrder = {
  readonly key: "NAME" | "CREATED" | "CREATED BY" | "LAST EDITED" | "LOCKED" | "PERMISSIONS";
  readonly value: "ASC" | "DESC";
};

export type State = {
  readonly location: Routes.ProjectListLocation;
  readonly projectListResponse: GQLOps.ProjectListQuery | undefined;
  readonly currentProjectList: Routes.ProjectListLocation["type"];
  readonly searchProjectList: string;
  readonly sortOrder: SortOrder;
  readonly isCreatingProject: boolean;
};

export const Action = ctorsUnion({
  ProjectsRecieved: (response: GQLOps.ProjectListQuery) => ({
    response,
  }),
  CreateNewProject: () => ({}),
  ProjectCreated: (result: GQLOps.ProjectList_CreateProjectMutation, projectId: string) => ({ result, projectId }),
  SearchProjectList: (searchResult: string) => ({ searchResult }),
  RemoveProject: (projectId: string) => ({ projectId }),
  RemoveShare: (projectId: string, email: string) => ({ projectId, email }),
  SortOrder: (key: SortOrder["key"], value: SortOrder["value"]) => ({ key, value }),
  CreateFromTemplate: (projectId: string) => ({ projectId }),
  CreatedFromTemplate: (response: GQLOps.EditProject_CreateProjectFromTemplateMutation) => ({ response }),
  GotoPage: (cursor: string) => ({ cursor }),
  GetList: (removedId: string) => ({ removedId }),
  RemoveTemplate: (projectId: string) => ({ projectId }),
});
export type Action = CtorsUnion<typeof Action>;

export function init(
  location: Routes.ProjectListLocation,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?] {
  const { activeUser } = sharedState;
  const initialState: State = {
    projectListResponse: undefined,
    currentProjectList: location.type,
    searchProjectList: "",
    location: location,
    sortOrder: { key: "CREATED", value: "DESC" },
    isCreatingProject: false,
  };
  return [
    initialState,
    queryProjectList(
      activeUser,
      sharedState.market.name,
      initialState.currentProjectList,
      initialState.searchProjectList,
      initialState.sortOrder,
      sharedState.translate(texts.no_name),
      undefined
    ),
  ];
}

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "ProjectsRecieved": {
      return [{ ...state, projectListResponse: action.response }];
    }

    case "CreateNewProject": {
      const newProjectId = uuid();
      const cmd = graphQLMutationWithAuth(sharedState.activeUser)<
        GQLOps.ProjectList_CreateProjectMutation,
        GQLOps.ProjectList_CreateProjectMutationVariables,
        Action
      >(
        createProjectMutation,
        {
          input: {
            id: newProjectId,
          },
        },
        sharedState.market.name,
        (result) => Action.ProjectCreated(result, newProjectId)
      );
      return [{ ...state, isCreatingProject: true }, cmd];
    }

    case "ProjectCreated": {
      const newUrl = Routes.buildUrl(
        Routes.RootLocation.MainLocation(
          Routes.MainLocation.Project(action.projectId, Routes.ProjectLocation.General())
        )
      );
      return [{ ...state, isCreatingProject: false }, NavigationEffectManager.pushUrl(newUrl)];
    }

    case "SearchProjectList": {
      const newState = {
        ...state,
        searchProjectList: action.searchResult,
      };
      return [
        newState,
        queryProjectList(
          sharedState.activeUser,
          sharedState.market.name,
          newState.currentProjectList,
          action.searchResult,
          newState.sortOrder,
          sharedState.translate(texts.no_name),
          undefined
        ),
      ];
    }

    case "RemoveProject": {
      return [
        state,
        graphQLMutationWithAuth(sharedState.activeUser)<
          GQLOps.ProjectList_RemoveProjectMutation,
          GQLOps.ProjectList_RemoveProjectMutationVariables,
          Action
        >(
          removeProjectMutation,
          {
            input: {
              id: action.projectId,
            },
          },
          sharedState.market.name,
          (_response) => Action.GetList(action.projectId)
        ),
      ];
    }

    case "RemoveShare": {
      return [
        state,
        graphQLMutationWithAuth(sharedState.activeUser)<
          GQLOps.ProjectList_RemoveShareMutation,
          GQLOps.ProjectList_RemoveShareMutationVariables,
          Action
        >(
          removeShareShareMutation,
          {
            input: {
              id: action.projectId,
              email: action.email,
            },
          },
          sharedState.market.name,
          (_response) => Action.GetList(action.projectId)
        ),
      ];
    }

    case "SortOrder": {
      const newSort = { key: action.key, value: action.value };
      const newState = {
        ...state,
        sortOrder: newSort,
      } as State;

      return [
        newState,
        queryProjectList(
          sharedState.activeUser,
          sharedState.market.name,
          newState.currentProjectList,
          newState.searchProjectList,
          newSort,
          sharedState.translate(texts.no_name),
          undefined
        ),
      ];
    }

    case "CreateFromTemplate": {
      return [
        { ...state, isCreatingProject: true },
        graphQLMutationWithAuth(sharedState.activeUser)<
          GQLOps.EditProject_CreateProjectFromTemplateMutation,
          GQLOps.EditProject_CreateProjectFromTemplateMutationVariables,
          Action
        >(
          createProjectFromTemplateMutation,
          {
            input: {
              id: uuid(),
              templateId: action.projectId,
            },
          },
          sharedState.market.name,
          Action.CreatedFromTemplate
        ),
      ];
    }

    case "GotoPage": {
      return [
        state,
        queryProjectList(
          sharedState.activeUser,
          sharedState.market.name,
          state.currentProjectList,
          state.searchProjectList,
          state.sortOrder,
          sharedState.translate(texts.no_name),
          action.cursor
        ),
      ];
    }

    case "CreatedFromTemplate": {
      const newUrl = Routes.buildUrl(
        Routes.RootLocation.MainLocation(
          Routes.MainLocation.Project(action.response.createProjectFromTemplate.id, Routes.ProjectLocation.General())
        )
      );
      return [{ ...state, isCreatingProject: false }, NavigationEffectManager.pushUrl(newUrl)];
    }

    case "GetList": {
      const cursor = getCurrentPageCursor(state.projectListResponse, action.removedId);
      return [
        state,
        queryProjectList(
          sharedState.activeUser,
          sharedState.market.name,
          state.currentProjectList,
          state.searchProjectList,
          state.sortOrder,
          sharedState.translate(texts.no_name),
          cursor
        ),
      ];
    }

    case "RemoveTemplate": {
      return [
        state,
        graphQLMutationWithAuth(sharedState.activeUser)<
          GQLOps.ProjectList_RemoveTemplateMutation,
          GQLOps.ProjectList_RemoveTemplateMutationVariables,
          Action
        >(
          removeTemplateMutation,
          {
            input: { id: action.projectId },
          },
          sharedState.market.name,
          (_response) => Action.GetList(action.projectId)
        ),
      ];
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

function queryProjectList(
  activeUser: User.ActiveUser,
  marketName: string,
  projectList: Routes.ProjectListLocation["type"],
  searchProjectList: string,
  sortOrder: SortOrder,
  defaultName: string,
  pageCursor: string | undefined
): Cmd<Action> {
  let filter;
  switch (projectList) {
    case "ProjectList":
      filter = "user";
      break;
    case "SharedProjectList":
      filter = "shared";
      break;
    case "SharedByOrganisation":
      filter = "organisation";
      break;
    case "RequestedForQuote":
      filter = "quote_request";
      break;
    case "TemplateProjectList":
      filter = "templates";
      break;
    default:
      filter = "user";
  }
  return graphQLQueryWithAuth(activeUser)<GQLOps.ProjectListQuery, GQLOps.ProjectListQueryVariables, Action>(
    projectListQuery,
    {
      filter,
      searchProjectList,
      pageCursor: pageCursor || null,
      defaultName: defaultName,
      sortOrder: [sortOrder.key, sortOrder.value],
    },
    marketName,
    (data) => {
      return Action.ProjectsRecieved(data);
    }
  );
}

function getCurrentPageCursor(projectList: GQLOps.ProjectListQuery | undefined, removedId: string): string | undefined {
  if (!projectList) {
    return undefined;
  }

  if (projectList.projectList.currentPage.cursor === removedId) {
    if (projectList.projectList.projects.length - 1 === 0) {
      return projectList.projectList.previousPage?.cursor;
    }
    return projectList.projectList.projects[1].id;
  }
  const currentPage = projectList.projectList.currentPage;
  return currentPage?.cursor;
}
