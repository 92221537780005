import React from "react";
import { H4, RadioButton, withTw } from "../../../../../elements";

export const SelectionRow = withTw("div", "flex flex-row space-x-32 items-center");

export function Part({
  title,
  description,
  children,
}: {
  readonly title: string;
  readonly description?: string;
  readonly children?: React.ReactNode;
}): JSX.Element {
  return (
    <div>
      <H4>{title}</H4>
      <div>{children}</div>
      {description && <p className="mt-16 max-w-xl">{description}</p>}
    </div>
  );
}

export function RadioSelection({
  image,
  label,
  description,
  readonly,
  selected,
  onChange,
}: {
  readonly image?: React.ReactNode;
  readonly label: string;
  readonly description?: string;
  readonly readonly: boolean;
  readonly selected: boolean;
  readonly onChange: (selected: boolean) => void;
}): JSX.Element {
  return (
    <Item onClick={() => onChange(true)} readonly={readonly}>
      <div className="flex flex-row space-x-16 items-center">
        <RadioButton checked={selected} checkedChanged={(checked) => onChange(checked)} disabled={readonly} />
        {image && <div className="w-64 h-64">{image}</div>}
        <div className="flex flex-col items-start">
          <div className="text-style-label-small-bold">{label}</div>
          {description && <p className="text-xs">{description}</p>}
        </div>
      </div>
    </Item>
  );
}

export function Item({
  children,
  onClick,
  readonly,
}: {
  readonly children?: React.ReactNode;
  readonly onClick: () => void;
  readonly readonly: boolean;
}): JSX.Element {
  return (
    <div className="item-shadow p-16 w-340" onClick={() => (!readonly ? onClick() : undefined)}>
      {children}
    </div>
  );
}
