import { logWarn } from "..";
import { Material } from "../project";

export interface MaterialPrice {
  readonly quantity: number;
  readonly singleCost: number | undefined;
  readonly singleSalesPrice: number | undefined;
  readonly singlePrice: number | undefined;
  readonly singleMargin: number | undefined;
  readonly totalCost: number | undefined;
  readonly totalSalesPrice: number | undefined;
  readonly totalPrice: number | undefined;
  readonly totalMargin: number | undefined;
  readonly discount: number | undefined;
  readonly currency: string | undefined;
}

export interface TotalPrice {
  readonly totalCost: number | undefined;
  readonly totalSalesPrice: number | undefined;
  readonly totalPrice: number | undefined;
  readonly totalMargin: number | undefined;
  readonly discount: number | undefined;
  readonly currency: string | undefined;
}

export function calculateMaterialPrice(material: Material): MaterialPrice {
  const quantity = material.quantity;
  const currency = material.currency || undefined;
  const singleSalesPrice = material.singleSalesPrice ?? undefined;
  const singlePrice = material.singleNetPrice ?? undefined;
  const singleCost = material.singleCost ?? undefined;
  const singleMargin = singlePrice !== undefined && singleCost !== undefined ? singlePrice - singleCost : undefined;
  const totalSalesPrice = singleSalesPrice !== undefined ? singleSalesPrice * quantity : undefined;
  const totalPrice = singlePrice !== undefined ? singlePrice * quantity : undefined;
  const totalCost = singleCost !== undefined ? singleCost * quantity : undefined;
  const totalMargin = totalPrice !== undefined && totalCost !== undefined ? totalPrice - totalCost : undefined;
  const discount = calculateDiscountPercentage(singlePrice, singleSalesPrice);
  return {
    quantity,
    singleCost,
    singleSalesPrice,
    singlePrice,
    singleMargin,
    totalCost,
    totalSalesPrice,
    totalPrice,
    totalMargin,
    currency,
    discount,
  };
}

export function calculateMaterialListTotalPrice(materials: ReadonlyArray<Material>): TotalPrice {
  if (new Set(materials.filter((m) => !!m.currency).map((m) => m.currency)).size > 1) {
    logWarn("Can't calculate total, multiple currencies");
    return {
      totalCost: undefined,
      totalSalesPrice: undefined,
      totalPrice: undefined,
      totalMargin: undefined,
      currency: undefined,
      discount: undefined,
    };
  }
  const currency = materials.find((m) => m.currency)?.currency || undefined;

  let totalCost = 0;
  let totalSalesPrice = 0;
  let totalPrice = 0;
  let totalMargin = 0;
  for (const m of materials) {
    const materialPrice = calculateMaterialPrice(m);
    if (m.included) {
      totalCost += materialPrice.totalCost ?? 0;
      totalSalesPrice += materialPrice.totalSalesPrice ?? 0;
      totalPrice += materialPrice.totalPrice ?? 0;
      totalMargin += materialPrice.totalMargin ?? 0;
    }
  }

  const discount = calculateDiscountPercentage(totalPrice, totalSalesPrice);

  return {
    totalCost,
    totalSalesPrice,
    totalPrice,
    totalMargin,
    currency,
    discount,
  };
}

export function calculateDiscountPercentage(
  netPrice: number | undefined,
  salesPrice: number | undefined
): number | undefined {
  if (salesPrice === undefined || netPrice === undefined) {
    return undefined;
  }
  const discount = ((salesPrice - netPrice) / salesPrice) * 100;
  if (Number.isFinite(discount)) {
    return discount;
  } else {
    return undefined;
  }
}

export function calculateNetPrice(
  salesPrice: number | undefined,
  discountPercent: number | undefined
): number | undefined {
  if (salesPrice === undefined || discountPercent === undefined) {
    return undefined;
  }
  const netPrice = salesPrice * (1 - discountPercent / 100);
  if (Number.isFinite(netPrice)) {
    return netPrice;
  } else {
    return undefined;
  }
}

export function renderPrice(
  price: number | undefined,
  currency: string | undefined,
  hideCurrencySymbol: boolean = false
): string {
  if (price === undefined || (currency === undefined && !hideCurrencySymbol)) {
    return "-";
  }
  const currencySymbol = hideCurrencySymbol
    ? ""
    : currency === "EUR"
    ? " €"
    : currency === "NOK"
    ? " kr"
    : ` ${currency}`;
  return price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") + currencySymbol;
}
