import * as React from "react";
import { AbstractDocJsx as ADX, AbstractDoc as AD } from "abstract-document";
import { CreatorType } from "./types";
import { Materials, Price, Project, Utils } from "../..";
import * as Common from "../common";
import { TextKey, texts, TranslateFn } from "../../lang-texts";
import { style } from "../../style";
import { h1 } from "../common/elements";

const { AbstractDoc, Section, Paragraph, Image, TextRun, Table, TableRow, TableCell, Group, render } = ADX;

export const create: CreatorType = (props) => {
  const styles = Common.styles();
  const numberingDefinitions = Common.numberingDefinitions(styles);
  const fonts = Common.fonts(props.pageProps.fonts);
  const sys =
    props.systemWithArticles && props.systemWithArticles.materialsWithMeta.length > 0
      ? props.systemWithArticles
      : undefined;

  const doc = render(
    <AbstractDoc fonts={fonts} styles={styles} numberingDefinitions={numberingDefinitions}>
      <Section id={Common.pageId(props.pageProps)} page={Common.cataloguePage(props.pageProps)}>
        {h1(props.translate(texts.material_list))}
        {sys ? (
          <Group key={sys.system.id}>
            <Table
              columnWidths={[30, Infinity, 50, 60, 60]}
              style={AD.TableStyle.create({
                cellStyle: AD.TableCellStyle.create({
                  padding: { top: 5, bottom: 0, left: 5, right: 5 },
                  borders: { top: 1, bottom: 1, left: 1, right: 1 },
                  verticalAlignment: "Top",
                }),
              })}
            >
              {tableHeadRow(props.translate)}
              {sys.materialsWithMeta
                .filter((i) => !!i.material.included)
                .map((i) => tableRow(props.translate, i.material, sys.system.id, props.itemImages))}
            </Table>
            <Table
              columnWidths={[Infinity, 50, 60, 60]}
              style={AD.TableStyle.create({
                cellStyle: AD.TableCellStyle.create({
                  padding: { top: 5, bottom: 0, left: 5, right: 5 },
                  verticalAlignment: "Top",
                }),
              })}
            >
              {materialSum(props.translate, sys.system.materials)}
            </Table>
          </Group>
        ) : (
          []
        )}
      </Section>
    </AbstractDoc>
  );
  return doc;
};

function tableHeadRow(translate: TranslateFn): JSX.Element {
  const columns: TextKey[] = [texts.pos, texts.article, texts.quantity, texts.single_price, texts.total_price];
  const tableHeaderStyle = AD.TableCellStyle.create({
    background: style.primaryColor,
    borders: { top: 1, bottom: 1, left: 1, right: 1 },
  });
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell key={column.key} style={tableHeaderStyle}>
          <Paragraph style={AD.ParagraphStyle.create({ alignment: "Center" })}>
            <TextRun text={translate(column)} style={AD.TextStyle.create({ bold: true, color: "#ffffff" })} />
          </Paragraph>
        </TableCell>
      ))}
    </TableRow>
  );
}

function tableRow(
  translate: TranslateFn,
  material: Project.Material,
  systemId: string,
  itemImages: ReadonlyMap<string, Common.BitmapImage>
): JSX.Element {
  const price = Price.calculateMaterialPrice(material);
  const singlePrice = Price.renderPrice(price.singlePrice, price.currency, true);
  const totalPrice = Price.renderPrice(price.totalPrice, price.currency, true);

  const img = itemImages.has(material.itemNumber)
    ? Common.createImageResource(itemImages.get(material.itemNumber)!)
    : undefined;

  return (
    <TableRow key={`${systemId}_${material.id}`}>
      <TableCell>
        <Paragraph>
          <TextRun text={material.sortNo.toString()} />
        </Paragraph>
      </TableCell>
      <TableCell>
        <Table
          columnWidths={[40, Infinity]}
          style={AD.TableStyle.create({
            cellStyle: AD.TableCellStyle.create({
              verticalAlignment: "Top",
            }),
          })}
        >
          <TableRow>
            <TableCell>
              {img && (
                <Paragraph>
                  <Image width={40} height={30} imageResource={img} />
                </Paragraph>
              )}
            </TableCell>
            <TableCell style={AD.TableCellStyle.create({ padding: AD.LayoutFoundation.create({ left: 7 }) })}>
              <Table
                columnWidths={[Infinity]}
                style={AD.TableStyle.create({
                  cellStyle: AD.TableCellStyle.create({
                    verticalAlignment: "Top",
                  }),
                })}
              >
                <TableRow>
                  <TableCell>
                    <Paragraph>
                      <TextRun text={Materials.getItemName(translate, material)}></TextRun>
                    </Paragraph>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Paragraph>
                      <TextRun text={Materials.getItemDescription(translate, material) || ""}></TextRun>
                    </Paragraph>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Paragraph>
                      <TextRun text={`${translate(texts.item_number)}: ${Materials.getItemNumber(material)}`}></TextRun>
                    </Paragraph>
                  </TableCell>
                </TableRow>
              </Table>
            </TableCell>
          </TableRow>
        </Table>
      </TableCell>
      <TableCell>
        <Paragraph>
          <TextRun text={Utils.numberToString(material.quantity, 4)} />
        </Paragraph>
      </TableCell>
      <TableCell>
        <Paragraph>
          <TextRun text={singlePrice} style={AD.TextStyle.create({ alignment: "right" })} />
        </Paragraph>
      </TableCell>
      <TableCell>
        <Paragraph>
          <TextRun text={totalPrice} style={AD.TextStyle.create({ alignment: "right" })} />
        </Paragraph>
      </TableCell>
    </TableRow>
  );
}

function materialSum(translate: TranslateFn, materials: readonly Project.Material[]): JSX.Element {
  const onlyTop = AD.TableCellStyle.create({ borders: { top: 0, bottom: 0, left: 0, right: 0 } });
  const boldStyle = AD.TextStyle.create({ bold: true });
  const rightBoldStyle = AD.TextStyle.create({ bold: true, alignment: "right" });
  const rightSide = AD.ParagraphStyle.create({ alignment: "End" });
  const price = Price.calculateMaterialListTotalPrice(materials);
  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell columnSpan={2} style={onlyTop}>
        <Paragraph>
          <TextRun text={`${translate(texts.sum)} ${price.currency ? ` [${price.currency}]` : ""}`} style={boldStyle} />
        </Paragraph>
      </TableCell>
      <TableCell style={onlyTop}>
        <Paragraph style={rightSide}>
          <TextRun text={Price.renderPrice(price.totalPrice, price.currency, true)} style={rightBoldStyle} />
        </Paragraph>
      </TableCell>
    </TableRow>
  );
}
