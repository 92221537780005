import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { SharedState } from "@rvs/client-infra";
import { Project, Texts } from "@rvs/shared";
import * as State from "./state";
import * as ProjectState from "../../project-state";
import { Expander } from "../../../../elements";
import { useAutoUpdateEffect, VentilationConceptProject, VentilationConceptSystem } from "./ventilation-concept";

const Action = State.Action;
const texts = Texts.texts;

export function View(props: {
  readonly state: State.State;
  readonly project: Project.Project;
  readonly sharedState: SharedState.SharedState;
  readonly projectState: ProjectState.State;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
}): JSX.Element {
  const { state, sharedState, project, dispatch, dispatchProject, projectState } = props;
  const { translate } = sharedState;

  useAutoUpdateEffect(sharedState.market, projectState, dispatchProject);

  if (project.systems.length === 0) {
    return <div>{translate(texts.no_systems)}</div>;
  }

  return (
    <div className="text-left">
      {sharedState.market.ventilationConceptEnabled && (
        <div className="pb-24">
          <Expander
            translate={translate}
            header={translate(texts.project)}
            closed={state.closedItems.has(project.id)}
            onToggleClosed={() => dispatch(Action.ToggleExpanded(project.id))}
          >
            <VentilationConceptProject
              dispatchProject={dispatchProject}
              projectState={projectState}
              translate={translate}
            />
          </Expander>
        </div>
      )}
      {project.systems.map((system) => {
        const vcName = system.ventilationConcept?.name || "";
        const header =
          vcName && system.name && system.name !== vcName
            ? `${vcName} (${system.name})`
            : vcName
            ? vcName
            : system.name
            ? system.name
            : translate(texts.no_name);
        return (
          <div key={system.id} className="pb-24">
            <Expander
              translate={translate}
              header={header}
              closed={state.closedItems.has(system.id)}
              onToggleClosed={() => dispatch(Action.ToggleExpanded(system.id))}
            >
              <VentilationConceptSystem
                translate={translate}
                projectState={projectState}
                dispatchProject={dispatchProject}
                systemId={system.id}
              />
            </Expander>
          </div>
        );
      })}
    </div>
  );
}
