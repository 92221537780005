import { logWarn } from "../../..";
import { isExtractRoom, isSupplyRoom, Room } from "../../../project";

export function calculateTotalSupplyVolume(rooms: ReadonlyArray<Room>): number | undefined {
  return calculateTotalVolume(rooms.filter((r) => isSupplyRoom(r)));
}

export function calculateTotalExtractVolume(rooms: ReadonlyArray<Room>): number | undefined {
  return calculateTotalVolume(rooms.filter((r) => isExtractRoom(r)));
}

export function calculateTotalVolume(rooms: ReadonlyArray<Room>): number | undefined {
  let volume = 0;
  for (const room of rooms) {
    const roomVolume = calculateRoomVolume(room);
    if (roomVolume === undefined || roomVolume === 0) {
      logWarn(`No volume for room ${room.id}`);
      return undefined;
    }
    volume += roomVolume;
  }
  return volume;
}

export function calculateRoomVolume(room: Room): number | undefined {
  if (!room.ceilingHeight) {
    return undefined;
  }
  if (room.width !== null && room.length !== null) {
    return room.width * room.length * room.ceilingHeight;
  } else if (room.width === null && room.length === null && room.floorArea !== null) {
    return room.floorArea * room.ceilingHeight;
  } else {
    return undefined;
  }
}
