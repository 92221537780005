import * as Project from "../../project";
import * as Calculators from "../../calculators";
import { CommonResponse, ProjectReportParams, ReportQueryParams } from "..";
import { Response, CreatorProps } from "./types";
import { getPageProps } from "../common";
import { graphQLProductQuery, graphQLQuery, QueryGenerator } from "../../query";
import { CalculationResult } from ".";
import { logWarn, Materials } from "../..";
import { isExtractRoom } from "../../project";

export function* query(params: ReportQueryParams): QueryGenerator<Response> {
  const metaResponse = yield* graphQLProductQuery<Project.MetaProductQuery, Project.MetaProductQueryVariables>(
    Project.queryMetaProduct,
    { productId: params.metaProductId }
  );
  const projectResponse = yield* graphQLQuery<Project.ProjectQuery, Project.ProjectQueryVariables>(Project.query, {
    projectId: params.projectId,
  });
  const materialTablesResponse = yield* Materials.getMaterialsYield(params.market, params.metaProductId);
  return {
    metaProduct: metaResponse,
    project: projectResponse,
    materialTables: materialTablesResponse,
  };
}

export async function execute(
  params: ProjectReportParams,
  common: CommonResponse,
  response: Response
): Promise<CreatorProps> {
  const pageProps = await getPageProps(params, common);
  const system = response.project.project?.systems.find((s) => s.id === params.systemId);
  if (!system) {
    logWarn(`System not found with id: ${params.systemId}`);
  }
  const result = system && getResults(params, response, system);
  return {
    pageProps: pageProps,
    translate: params.translate,
    systemName: system?.name || params.systemId,
    result: result,
  };
}

function getResults(
  params: ProjectReportParams,
  response: Response,
  system: Project.System
): CalculationResult | undefined {
  const input = Calculators.map(response.metaProduct, response.materialTables, system, params.market.name);
  const validation = input && Calculators.validate(input);
  const result = input && Calculators.calculate(input);
  if (!result || !validation || validation.hasErrors) {
    return undefined;
  }
  switch (result.type) {
    case "germany": {
      return {
        requiredAirflow: result.requiredAirflow,
        totalArea: result.totalArea,
        airflowForTotalArea: result.airflowForTotalArea,
        extractRoomsArea: result.extractRoomsTotalArea,
        extractRoomsAirflow: result.extractRoomsTotalNominalAirflow,
        rooms: system.rooms
          .filter((room) => isExtractRoom(room))
          .map((room) => ({
            name: room.name,
            type: room.roomType,
            floor: room.floorType,
            area: result.roomResults[room.id]?.area,
            airflow: result.roomResults[room.id]?.nominalAirflow,
            width: result.roomResults[room.id]?.width,
            length: result.roomResults[room.id]?.length,
          })),
        airflowResults: result.ventilationChangeRates
          ? {
              moistureControlAirflow: result.ventilationChangeRates.moistureControl,
              reducedAirflow: result.ventilationChangeRates.reduced,
              nominalAirflow: result.ventilationChangeRates.nominal,
              intensiveAirflow: result.ventilationChangeRates.intensive,
            }
          : undefined,
      };
    }
    case "norway": {
      return {
        requiredAirflow: result.requiredAirflow,
        totalArea: undefined,
        airflowForTotalArea: undefined,
        extractRoomsArea: undefined,
        extractRoomsAirflow: undefined,
        rooms: system.rooms
          .filter((room) => isExtractRoom(room))
          .map((room) => ({
            name: room.name,
            type: room.roomType,
            floor: room.floorType,
            area: result.roomResults[room.id]?.area,
            airflow: result.roomResults[room.id]?.airflow,
            width: result.roomResults[room.id]?.width,
            length: result.roomResults[room.id]?.length,
          })),
        airflowResults: undefined,
      };
    }
    default:
      return undefined;
  }
}
