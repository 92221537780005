import { AbstractDoc as AD } from "abstract-document";
import * as AI from "abstract-image";
import { BitmapImage } from "./types";

export function createImageResource(image: BitmapImage): AD.ImageResource.ImageResource {
  const width = image.width;
  const height = image.height;

  const bitmap = AI.createBinaryImage(
    AI.createPoint(0, 0),
    AI.createPoint(width, height),
    image.format.toLocaleLowerCase() as AI.BinaryFormat,
    image.data
    //undefined,
    //true
  );
  const components = [bitmap];
  const abstractImage = AI.createAbstractImage(
    AI.createPoint(0, 0),
    AI.createSize(width, height),
    AI.white,
    components
  );
  const imageResource = AD.ImageResource.create({
    id: image.file_name,
    abstractImage: abstractImage,
  });
  return imageResource;
}
