import * as Gql from "../../generated/generated-operations";
import { Row, Table } from "../../graphql-utils";

export interface RoomResult {
  readonly airflow: number | undefined;
  readonly volume: number | undefined;
  readonly area: number | undefined;
  readonly width: number | undefined;
  readonly length: number | undefined;
  readonly valves: number | undefined;
  readonly ducts: number | undefined;
}

export type ResultByRoomId = { readonly [roomId: string]: RoomResult };

export interface Result {
  readonly type: "global";
  readonly requiredAirflow: number | undefined;
  readonly airChangeRate: number | undefined;
  readonly totalArea: number | undefined;
  readonly totalVolume: number | undefined;
  readonly roomResults: ResultByRoomId;
  readonly supplyAirflow: number | undefined;
  readonly extractAirflow: number | undefined;
  readonly quantities: Quantities;
}

export interface Quantities {
  readonly supplyDucts: number | undefined;
  readonly extractDucts: number | undefined;
  readonly supplyValves: number | undefined;
  readonly extractValves: number | undefined;
}

export type RoomsWithoutWindowsTable = Table<Row<Gql.RoomsWithoutWindows>>;
