import { Project } from "../..";
import { texts } from "../../lang-texts";
import {
  isSupplyRoom,
  Room,
  validateNumber,
  validateNumberNonZero,
  RoomError,
  RoomTemplates,
  isExtractRoom,
} from "../../project";
import { Input, ValidationOutput } from "../types";
import { calculateBalancedAirflow } from "./calculation/balanace-airflow";

export function validate(input: Input): ValidationOutput {
  const roomErrors: { [roomId: string]: ReadonlyArray<RoomError> } = {};
  const systemWithoutCustomAirflows: Project.System = {
    ...input.system,
    rooms: input.system.rooms.map(
      (r): Project.Room => (isExtractRoom(r) ? { ...r, airflow: 0, customAirFlow: null } : r)
    ),
  };
  const { balanceExtractResult } = calculateBalancedAirflow(input.roomTemplates, systemWithoutCustomAirflows);
  for (const room of input.system.rooms) {
    const roomTemplate = input.roomTemplates.find((t) => t.key === room.roomType && t.market === input.market);
    const calculatedAirflow = balanceExtractResult?.resultByRoomId.get(room.id)?.airflow;
    const errors = validateRoom(room, roomTemplate, calculatedAirflow);
    if (errors.length > 0) {
      roomErrors[room.id] = errors;
    }
  }
  return {
    hasErrors: Object.keys(roomErrors).length > 0,
    roomErrors,
    systemErrors: [],
  };
}

function validateRoom(
  room: Room,
  roomTemplate: RoomTemplates | undefined,
  calculatedAirFlow: number | undefined
): ReadonlyArray<RoomError> {
  const errors: Array<RoomError> = [];

  if (isSupplyRoom(room)) {
    errors.push(...validateNumber("supplyAirFactor", room));
  }

  errors.push(...validateNumber("ceilingHeight", room));

  errors.push(...validateNumber("airflow", room));

  errors.push(...validateNumber("valves", room));

  if (room.width === null && room.length === null && room.floorArea === null) {
    errors.push(...validateNumber("volume", room));
    errors.push(...validateNumberNonZero("volume", room));
  } else if (room.width === null && room.length === null) {
    errors.push(...validateNumber("floorArea", room));
    errors.push(...validateNumberNonZero("floorArea", room));
  } else if (room.floorArea === null) {
    errors.push(...validateNumber("length", room));
    errors.push(...validateNumber("width", room));
    errors.push(...validateNumberNonZero("length", room));
    errors.push(...validateNumberNonZero("width", room));
  }

  errors.push(...validateTemplate(room, roomTemplate));

  if (roomTemplate) {
    errors.push(...validateAirflowMinimum(room, roomTemplate, calculatedAirFlow));
  }
  return errors;
}

function validateTemplate(room: Room, template: RoomTemplates | undefined): ReadonlyArray<RoomError> {
  if (!template) {
    return [{ property: "roomType", message: texts.error_template_not_found }];
  }

  if (isSupplyRoom(room)) {
    const defaultAirFactor = template.default_supply_air_factor;
    const airFactorRange = template.supply_air_factor_range;
    const supplyAirFactor = room.supplyAirFactor;
    if (defaultAirFactor === null || airFactorRange === null || supplyAirFactor === null) {
      return [];
    }

    const minFactor = defaultAirFactor - airFactorRange;
    const maxFactor = defaultAirFactor + airFactorRange;
    if (supplyAirFactor < minFactor || supplyAirFactor > maxFactor) {
      return [
        { property: "supplyAirFactor" && "airflow", message: texts.error_supply_factor_range(minFactor, maxFactor) },
      ];
    }
  }

  return [];
}

function validateAirflowMinimum(
  room: Room,
  template: RoomTemplates,
  calculatedAirFlow: number | undefined
): ReadonlyArray<RoomError> {
  const airFlow = room["airflow"];
  if (typeof airFlow !== "number") {
    return [];
  }
  const minAirFlow = template.min_airflow || 0;
  if (isSupplyRoom(room)) {
    if (airFlow < minAirFlow) {
      return [
        {
          property: "airflow",
          message: texts.error_supply_airflow_at_least(minAirFlow),
        },
      ];
    }
  } else if (isExtractRoom(room)) {
    if (airFlow < minAirFlow) {
      return [
        {
          property: "airflow",
          message: texts.error_exhaust_airflow_at_least(minAirFlow),
        },
      ];
    }
    // According to DIN standard custom air flow can't go below 50% of the calculcated air flow
    const minCustomAirFlow = calculatedAirFlow !== undefined ? calculatedAirFlow * 0.5 : undefined;
    const roundedMinCustomAirFlow =
      minCustomAirFlow !== undefined ? Math.round(minCustomAirFlow * 100) / 100 : undefined;
    if (roundedMinCustomAirFlow !== undefined && airFlow < roundedMinCustomAirFlow) {
      return [
        {
          property: "airflow",
          message: texts.error_custom_exhaust_airflow_at_least(roundedMinCustomAirFlow),
        },
      ];
    }
  }
  return [];
}
