import { ReportQueryParams } from "..";
import { QueryGenerator } from "../../query";
import { AdapterType, Response } from "./types";
import { getPageProps } from "../common";

export function* query(_props: ReportQueryParams): QueryGenerator<Response> {
  return {};
}

export const execute: AdapterType = async (projectReportParams, common) => {
  const pageProps = await getPageProps(projectReportParams, common);

  return {
    pageProps: pageProps,
  };
};
