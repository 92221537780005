import { CtorsUnion, ctorsUnion } from "ctors-union";
import { Texts, UserSettings, User, Markets } from "@rvs/shared";
import { graphQLProductQueryWithAuth } from "../graphql";

export interface CrmParams {
  readonly crmQuoteLanguage: string;
  readonly crmQuoteId: string;
  readonly crmCustomerNumber: string | undefined;
  readonly crmApi: "new" | "old";
}
export interface SharedState {
  readonly activeUser: User.ActiveUser;
  readonly translate: Texts.TranslateFn;
  readonly userSettings: UserSettings.UserSettings;
  readonly crmParams: CrmParams | undefined;
  readonly m3LanguageCode: string;
  readonly graphQLProductQuery: ReturnType<typeof graphQLProductQueryWithAuth>;
  readonly market: Markets.Market;
}

// export type PropertyFormats = { readonly [propertyName: string]: SelectableFormat };

export const SharedStateAction = ctorsUnion({
  Logout: () => ({}),
  SetLanguage: (newLang: Texts.LanguageCode) => ({ newLang }),
  SetMarket: (newMarket: string) => ({ newMarket }),
  SetCrm: (newCrm: CrmParams | undefined) => ({ newCrm }),
  UpdateUserSettings: (userSettings: UserSettings.UserSettings) => ({ userSettings }),
});
export type SharedStateAction = CtorsUnion<typeof SharedStateAction>;
