import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { SharedState } from "@rvs/client-infra";
import { Price, Project, Texts, Materials, Utils, Markets, UserSettings, Calculators } from "@rvs/shared";
import { selectShowImages, selectUnit } from "@rvs/shared/src/user-settings";
import { convertTo, Quantity, Units } from "@rvs/shared/src/units";
import { ExportResponse } from "@rvs/shared/src/crm";
import { roundTo } from "@rvs/shared/src/utils";
import * as State from "./state";
import {
  Alert,
  Button,
  Checkbox,
  Icon,
  NumberField,
  SelectButton,
  Spinner,
  Textfield,
  Toolbar,
  withTw,
  Expander,
} from "../../../../elements";
import { Action } from ".";
import { ImageLightbox } from "../../../../elements/imageLightbox";
import { clientConfig } from "../../../../client-config";
import * as ProjectState from "../../project-state";

const texts = Texts.texts;

const PriceTh = withTw("th", "text-right");
const PriceTd = withTw("td", "tabular-nums text-right");

const ProjectAction = ProjectState.Action;

export function View(props: {
  readonly state: State.State;
  readonly projectState: ProjectState.State;
  readonly sharedState: SharedState.SharedState;
  readonly exportingToCrm: boolean;
  readonly crmExportResponse: ExportResponse | undefined;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
}): JSX.Element {
  const { state, projectState, sharedState, exportingToCrm, crmExportResponse, dispatch, dispatchProject } = props;
  const { translate } = sharedState;
  const { project, metaProduct } = projectState;
  const { systemStates } = state;

  const system = project.systems[0];
  if (!system) {
    return <div>{translate(texts.no_systems)}</div>;
  }

  const hasSystemWithMaterials = !!project.systems.find((s) => s.materials.length > 0);

  const isReadonly = Project.isProjectReadOnly(project);
  return (
    <div className="flex flex-col space-y-16">
      {ImageLightbox({
        mainSrc: state.openLightbox.imgUrl,
        isOpen: state.openLightbox.isOpen,
        close: () => dispatch(Action.OpenLightbox(false, "")),
      })}
      {!project.template && !!sharedState.crmParams && (
        <div className="flex flex-row space-x-8">
          <Button
            type="secondary"
            label={translate(texts.export_to_crm)}
            onClick={() => dispatchProject(ProjectState.Action.ExportToCrm(false))}
            disabled={exportingToCrm || !hasSystemWithMaterials}
          />

          {UserSettings.selectShowAdvanced(sharedState.userSettings) && (
            <Button
              type="secondary"
              label={"Download CRM envelope"}
              onClick={() => dispatchProject(ProjectState.Action.ExportToCrm(true))}
              disabled={exportingToCrm || !hasSystemWithMaterials}
            />
          )}
        </div>
      )}
      {crmExportResponse && crmExportResponse.status === 200 ? (
        <Alert type="success">
          <span>{translate(texts.sucessfully_exported_to_crm)}</span>
        </Alert>
      ) : crmExportResponse && crmExportResponse.status !== 200 ? (
        <Alert type="danger">
          <p>{translate(texts.failed_to_export_crm_message)}</p>
          <p>{crmExportResponse.message}</p>
        </Alert>
      ) : undefined}
      {project.systems.map((system) => {
        const calcState = projectState.calculationStates[system.id];
        const materialsState = projectState.materialsState[system.id];
        const waitingForPrices = materialsState?.type === "price-update";
        const listIsUpdating = materialsState?.type === "list-update";
        const isBusy = State.isBusyState(state.systemStates.get(system.id), materialsState);
        return (
          <System
            key={system.id}
            system={system}
            expandedItems={state.expandedItems}
            systemState={systemStates.get(system.id)}
            materialsState={materialsState}
            isReadonly={isReadonly}
            isBusy={isBusy}
            translate={translate}
            dispatch={dispatch}
            dispatchProject={dispatchProject}
            materialTables={projectState.materialTables}
            metaProductQuery={metaProduct}
            market={sharedState.market}
            costEnabled={sharedState.market.costEnabled}
            discountEnabled={sharedState.market.discountEnabled}
            userSettings={sharedState.userSettings}
            disabledCustomMaterials={projectState.disabledCustomMaterials}
            waitingForPrices={waitingForPrices}
            listIsUpdating={listIsUpdating}
            calculationResult={calcState?.result}
            calculationValidation={calcState?.validation}
            priceError={projectState.priceErrors.has(system.id)}
          />
        );
      })}
    </div>
  );
}

function System({
  system,
  systemState,
  materialsState,
  isReadonly,
  isBusy,
  translate,
  dispatch,
  dispatchProject,
  materialTables,
  expandedItems,
  metaProductQuery,
  market,
  costEnabled,
  discountEnabled,
  userSettings,
  disabledCustomMaterials,
  waitingForPrices,
  listIsUpdating,
  calculationResult,
  calculationValidation,
  priceError,
}: {
  readonly system: Project.System;
  readonly systemState: State.SystemState | undefined;
  readonly materialsState: ProjectState.MaterialsState | undefined;
  readonly isReadonly: boolean;
  readonly isBusy: boolean;
  readonly translate: Texts.TranslateFn;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly materialTables: Materials.MaterialTables;
  readonly expandedItems: ReadonlySet<string>;
  readonly metaProductQuery: Project.MetaProductQuery;
  readonly market: Markets.Market;
  readonly costEnabled: boolean;
  readonly discountEnabled: boolean;
  readonly userSettings: UserSettings.UserSettings;
  readonly disabledCustomMaterials: ReadonlySet<string>;
  readonly waitingForPrices: boolean;
  readonly listIsUpdating: boolean;
  readonly calculationResult: Calculators.Result | undefined;
  readonly calculationValidation: Calculators.ValidationOutput | undefined;
  readonly priceError: boolean;
}): JSX.Element {
  const airUnitIsSelected = system.materials.length !== 0;
  const airUnitMaterial = system.materials.find(
    (m) => Materials.getMetaData(market, materialTables, m)?.group === "air_unit"
  );
  const validationResult = Materials.validate(market, metaProductQuery, materialTables, system);
  const requiredAirflow = calculationResult?.requiredAirflow === undefined;
  const hasCalculationError =
    requiredAirflow === undefined ||
    !!calculationValidation?.hasErrors ||
    !calculationResult?.supplyAirflow ||
    !calculationResult.extractAirflow;
  const airflowUnit = selectUnit(userSettings, Quantity.Airflow);
  const convertedUnit = convertTo(calculationResult?.requiredAirflow, Units.CubicMeterPerHour, airflowUnit);
  const roundedUnit = convertedUnit && roundTo(convertedUnit, 2);
  const title =
    `${system.name} (${translate(texts.air_flow)}: ${roundedUnit} ${airflowUnit.unitName})` ||
    `${translate(texts.system)} ${system.sortNo}`;
  const showSystemSpinner = systemState?.type === "searching-unit" || listIsUpdating || waitingForPrices;

  return (
    <Expander
      translate={translate}
      header={title}
      closed={expandedItems.has(system.id)}
      onToggleClosed={() => dispatch(Action.ToggleExpanded(system.id))}
      extraHeader={<div>{showSystemSpinner ? <Spinner /> : undefined}</div>}
      closedContent={
        <MaterialListOneRow
          system={system}
          translate={translate}
          materialTables={materialTables}
          waitingForPrices={waitingForPrices}
          market={market}
          costEnabled={costEnabled}
          discountEnabled={discountEnabled}
        />
      }
    >
      {systemState?.type === "selecting-unit" && (
        <div className="text-sm pl-[1px]">{translate(texts.select_air_unit)}</div>
      )}

      {systemState?.type !== "selecting-unit" ? (
        <Toolbar>
          <Button
            disabled={isBusy || isReadonly || hasCalculationError}
            label={translate(airUnitIsSelected ? texts.change_air_unit : texts.select_air_unit)}
            onClick={() => dispatch(State.Action.RequestSaveUnits(system.id))}
          />
        </Toolbar>
      ) : (
        <Toolbar>
          <Button label={translate(texts.cancel)} onClick={() => dispatch(State.Action.SelectUnitDone(system.id))} />
        </Toolbar>
      )}

      <div className="mt-8 mb-8">
        {systemState?.type !== "selecting-unit" && (
          <Messages
            systemState={systemState}
            materialsState={materialsState}
            dispatchProject={dispatchProject}
            translate={translate}
            systemId={system.id}
            isEditDisabled={isReadonly || isBusy}
            airUnit={airUnitMaterial}
            hasCalculationError={hasCalculationError}
            materialErrors={validationResult.errors}
            airUnitMaterial={airUnitMaterial}
            priceError={priceError}
          />
        )}
      </div>

      {systemState?.type === "selecting-unit" && (
        <div className="mt-8 mb-8">
          <AirUnitSelector
            market={market}
            materialTables={materialTables}
            system={system}
            systemState={systemState}
            dispatch={dispatch}
            dispatchProject={dispatchProject}
            translate={translate}
            userSettings={userSettings}
          />
        </div>
      )}

      {airUnitIsSelected && systemState?.type !== "selecting-unit" && (
        <MaterialList
          isReadonly={isReadonly}
          isBusy={isBusy}
          system={system}
          dispatch={dispatch}
          dispatchProject={dispatchProject}
          translate={translate}
          materialTables={materialTables}
          validationResult={validationResult}
          waitingForPrices={waitingForPrices}
          market={market}
          costEnabled={costEnabled}
          discountEnabled={discountEnabled}
          userSettings={userSettings}
          disabledCustomMaterials={disabledCustomMaterials}
        />
      )}
    </Expander>
  );
}

function Messages({
  systemState,
  dispatchProject,
  translate,
  systemId,
  isEditDisabled,
  airUnit,
  hasCalculationError,
  materialErrors,
  airUnitMaterial,
  priceError,
}: {
  readonly systemState: State.SystemState | undefined;
  readonly materialsState: ProjectState.MaterialsState | undefined;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly translate: Texts.TranslateFn;
  readonly systemId: string;
  readonly isEditDisabled: boolean;
  readonly airUnit: Project.Material | undefined;
  readonly hasCalculationError: boolean;
  readonly materialErrors: ReadonlyArray<Materials.ValidationError>;
  readonly airUnitMaterial: Project.Material | undefined;
  readonly priceError: boolean;
}): JSX.Element {
  const errorTypes = new Set(materialErrors.filter((e) => e.category).map((e) => e.category));

  const alerts = [];
  if (hasCalculationError) {
    alerts.push(
      <Alert key="has_calculation_error" type="error">
        {translate(texts.unable_to_calculate_system)}
      </Alert>
    );
  }

  if (errorTypes.has("wrong_air_unit") && !hasCalculationError) {
    const airUnitError = airUnit && materialErrors.find((e) => e.materialId === airUnit.id);
    alerts.push(
      <Alert key="wrong_air_unit" type="error">
        {airUnitError ? translate(airUnitError.message) : translate(texts.air_unit_not_compatible)}
      </Alert>
    );
  }

  if (errorTypes.has("quantity") && !hasCalculationError) {
    alerts.push(
      <Alert key="updated_quantity_needed" type="error">
        <p>{translate(texts.quantities_not_matching_requirements)}</p>
        {airUnitMaterial && systemState?.type !== "selecting-unit" && (
          <Toolbar align="right">
            <Button
              disabled={isEditDisabled || hasCalculationError}
              label={translate(texts.update_quantities)}
              type={"primary"}
              onClick={() => airUnitMaterial && dispatchProject(ProjectAction.UpdateMaterialListQuantities(systemId))}
            />
          </Toolbar>
        )}
      </Alert>
    );
  }

  if (errorTypes.has("new_item_available") && !hasCalculationError) {
    alerts.push(
      <Alert key="new_items_available" type="info">
        <p>{translate(texts.new_items_available)}</p>
        <Toolbar align="right">
          <Button
            disabled={isEditDisabled || hasCalculationError}
            label={translate(texts.add_new_items)}
            type={"primary"}
            onClick={() =>
              airUnitMaterial && dispatchProject(ProjectAction.UpdateMaterialListAddNewItems(systemId, airUnitMaterial))
            }
          />
        </Toolbar>
      </Alert>
    );
  }

  if (errorTypes.has("duct_diameter") && !hasCalculationError) {
    alerts.push(
      <Alert key="duct_diameter" type="error">
        <p>{translate(texts.material_list_not_compatible_with_duct)}</p>
        <Toolbar align="right">
          <Button
            disabled={isEditDisabled}
            label={translate(texts.update_material_list)}
            onClick={() => airUnit && dispatchProject(ProjectAction.CreateMaterialListForUnit(systemId, airUnit))}
          />
        </Toolbar>
      </Alert>
    );
  }

  if (errorTypes.has("too_many_selections") && !hasCalculationError) {
    alerts.push(
      <Alert key="too_many_selections" type="error">
        <p>{translate(texts.too_many_selections_message)}</p>
      </Alert>
    );
  }

  if (systemState?.type === "unit-search-error") {
    alerts.push(
      <Alert key="unit-search-error" type="error">
        {translate(texts.error_when_requesting_air_units)}
      </Alert>
    );
  } else if (systemState?.type === "no-units-found") {
    alerts.push(
      <Alert key="no-units-found" type="info">
        {translate(texts.no_air_units_found)}
      </Alert>
    );
  } else if (priceError) {
    alerts.push(
      <Alert key="price-error" type="error">
        {translate(texts.error_when_requesting_prices)}
      </Alert>
    );
  }

  return <div className="space-y-8">{alerts}</div>;
}

function AirUnitSelector({
  market,
  materialTables,
  system,
  systemState,
  dispatch,
  dispatchProject,
  translate,
  userSettings,
}: {
  readonly market: Markets.Market;
  readonly materialTables: Materials.MaterialTables;
  readonly system: Project.System;
  readonly systemState: State.SelectingUnit;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly translate: Texts.TranslateFn;
  readonly userSettings: UserSettings.UserSettings;
}): JSX.Element {
  const checkAirflow = systemState.suitableAirUnits[0]?.type === "norway";
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>{translate(texts.m3_item_number)}</th>
            <th className="w-52 min-w-32"></th>
            <th>{translate(texts.name)}</th>
            {checkAirflow && <th>{translate(texts.max_airflow)}</th>}
            <th />
          </tr>
        </thead>
        <tbody>
          {systemState.suitableAirUnits.map((unit) => {
            const metaData = Materials.getMetaData(market, materialTables, unit);
            const airflow = metaData.type === "norway" && metaData.maxAirFlow ? metaData.maxAirFlow : undefined;
            const airflowExceeded = !!airflow && systemState.requiredAirflow > airflow;

            const airflowUnit = selectUnit(userSettings, Quantity.Airflow);
            const convertedUnit = airflow && convertTo(airflow, Units.CubicMeterPerHour, airflowUnit);
            const roundedUnit = convertedUnit && roundTo(convertedUnit, 2);

            return (
              <tr key={unit.itemNumber}>
                <td>{unit.itemNumber}</td>
                <td>
                  <img
                    src={`${clientConfig.image_service_url}?itemNumber=${unit.itemNumber}&maxWidth=50&maxHeight=50&format=jpg`}
                    className="h-24"
                    style={{ cursor: "pointer" }}
                    alt={""}
                    onClick={() => {
                      dispatch(
                        Action.OpenLightbox(
                          true,
                          `${clientConfig.image_service_url}?itemNumber=${unit.itemNumber}&maxWidth=1000&maxHeight=1000&format=jpg`
                        )
                      );
                    }}
                  />
                </td>
                <ItemNameTd translate={translate} material={unit} />
                {checkAirflow &&
                  (airflow ? (
                    <td>
                      <div className="flex flex-row space-x-8">
                        {
                          <span>
                            {roundedUnit} {airflowUnit.unitName}
                          </span>
                        }
                        {airflowExceeded ? (
                          <Icon
                            icon={"exclamation-circle"}
                            colorClass="text-danger"
                            message={translate(texts.error_air_unit_max_airflow(systemState.requiredAirflow, airflow))}
                          />
                        ) : (
                          <div />
                        )}
                      </div>
                    </td>
                  ) : (
                    <td />
                  ))}
                <td>
                  <Button
                    type={"secondary"}
                    label={translate(texts.select)}
                    onClick={() => {
                      dispatchProject(ProjectAction.CreateMaterialListForUnit(system.id, unit));
                      dispatch(State.Action.SelectUnitDone(system.id));
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function MaterialList({
  isReadonly,
  isBusy,
  system,
  dispatch,
  dispatchProject,
  translate,
  materialTables,
  validationResult,
  waitingForPrices,
  market,
  costEnabled,
  discountEnabled,
  userSettings,
  disabledCustomMaterials,
}: {
  readonly isReadonly: boolean;
  readonly isBusy: boolean;
  readonly system: Project.System;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly translate: Texts.TranslateFn;
  readonly materialTables: Materials.MaterialTables;
  readonly validationResult: Materials.ValidationResult;
  readonly waitingForPrices: boolean;
  readonly market: Markets.Market;
  readonly costEnabled: boolean;
  readonly discountEnabled: boolean;
  readonly userSettings: UserSettings.UserSettings;
  readonly disabledCustomMaterials: ReadonlySet<string>;
}): JSX.Element {
  if (system.materials.length === 0) {
    return <div>{translate(texts.material_list_empty)}</div>;
  }
  const totalPrice = Price.calculateMaterialListTotalPrice(system.materials);
  const categories = Materials.categorizeMaterials(market, materialTables, system.materials);
  return (
    <table className="relative">
      <thead className="sticky top-0 bg-white ">
        <tr>
          <th className="min-w-24" />
          <th className="min-w-32" />
          <th className="w-64">{translate(texts.position)}</th>
          {selectShowImages(userSettings) ? <th className="w-44 min-w-32"></th> : null}
          <th className="w-104">{translate(texts.item_number)}</th>
          <th>{translate(texts.name)}</th>
          <th className="w-104">{translate(texts.quantity)}</th>
          {costEnabled && <PriceTh className="w-104">{translate(texts.single_cost)}</PriceTh>}
          <PriceTh className="w-104">{translate(texts.single_price)}</PriceTh>
          {costEnabled && <PriceTh className="w-104">{translate(texts.total_cost)}</PriceTh>}
          <PriceTh className="w-104">{translate(texts.total_price)}</PriceTh>
          {discountEnabled && <PriceTh className="w-85">{translate(texts.discount)}</PriceTh>}
          {costEnabled && <PriceTh className="w-104">{translate(texts.margin)}</PriceTh>}
        </tr>
        {/* Fix border when sticky */}
        <tr className="max-h-0 border-b-4 border-gray-100" />
      </thead>
      {categories.map((category, index) => (
        <MaterialListCategory
          market={market}
          materialTables={materialTables}
          key={category.name}
          isReadonly={isReadonly}
          isBusy={isBusy}
          systemId={system.id}
          dispatch={dispatch}
          dispatchProject={dispatchProject}
          translate={translate}
          validationResult={validationResult}
          category={category}
          waitingForPrices={waitingForPrices}
          costEnabled={costEnabled}
          discountEnabled={discountEnabled}
          showImages={selectShowImages(userSettings)}
          index={index}
          disabledCustomMaterials={disabledCustomMaterials}
        />
      ))}
      <tbody>
        <tr>
          <td colSpan={getColSpan("category_title", costEnabled, discountEnabled)}>
            <div className="flex flex-row space-x-32">
              <Button
                disabled={isReadonly || isBusy}
                label={translate(texts.add_custom_item)}
                onClick={() => dispatchProject(ProjectAction.AddCustomMaterial(system.id))}
              />
              <PackageButtons
                isReadonly={isReadonly || isBusy}
                system={system}
                dispatchProject={dispatchProject}
                translate={translate}
                materialTables={materialTables}
                market={market}
              />
            </div>
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr className="font-bold" key={"total"}>
          <td colSpan={getColSpan("total_row", costEnabled, discountEnabled)} />
          {costEnabled && (
            <PriceTd>{renderPrice(totalPrice?.totalCost, totalPrice?.currency, waitingForPrices)}</PriceTd>
          )}
          <PriceTd>{renderPrice(totalPrice?.totalPrice, totalPrice?.currency, waitingForPrices)}</PriceTd>
          {discountEnabled && <PriceTd>{renderPrice(totalPrice?.discount, "%", waitingForPrices)}</PriceTd>}
          {costEnabled && (
            <PriceTd>{renderPrice(totalPrice?.totalMargin, totalPrice?.currency, waitingForPrices)}</PriceTd>
          )}
        </tr>
      </tbody>
    </table>
  );
}

function getColSpan(type: "total_row" | "category_title", costEnabled: boolean, discountEnabled: boolean): number {
  if (type === "total_row") {
    return 8 + (costEnabled ? 1 : 0);
  } else if (type === "category_title") {
    // Total number of columns
    return 9 + (costEnabled ? 3 : 0) + (discountEnabled ? 1 : 0);
  } else {
    return 0;
  }
}

function MaterialListCategory({
  market,
  materialTables,
  isReadonly,
  isBusy,
  systemId,
  dispatch,
  dispatchProject,
  translate,
  validationResult,
  category,
  waitingForPrices,
  costEnabled,
  discountEnabled,
  showImages,
  index,
  disabledCustomMaterials,
}: {
  readonly market: Markets.Market;
  readonly materialTables: Materials.MaterialTables;
  readonly isReadonly: boolean;
  readonly isBusy: boolean;
  readonly systemId: string;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly translate: Texts.TranslateFn;
  readonly validationResult: Materials.ValidationResult;
  readonly category: Materials.Category;
  readonly waitingForPrices: boolean;
  readonly costEnabled: boolean;
  readonly discountEnabled: boolean;
  readonly showImages: boolean;
  readonly index: number;
  readonly disabledCustomMaterials: ReadonlySet<string>;
}): JSX.Element {
  return (
    <tbody>
      <tr>
        <td colSpan={getColSpan("category_title", costEnabled, discountEnabled)} className="p-0">
          <div className={`text-sm mt-4 ${index === 0 ? "" : "pt-24"}`}>
            {translate(Texts.texts.material_category(category.name))}
          </div>
        </td>
      </tr>
      {category.materials.map((material) => (
        <MaterialRow
          key={material.id}
          material={material}
          market={market}
          materialTables={materialTables}
          isReadonly={isReadonly || disabledCustomMaterials.has(material.id)}
          isBusy={isBusy}
          systemId={systemId}
          dispatch={dispatch}
          dispatchProject={dispatchProject}
          translate={translate}
          validationResult={validationResult}
          waitingForPrices={waitingForPrices}
          costEnabled={costEnabled}
          discountEnabled={discountEnabled}
          showImages={showImages}
        />
      ))}
    </tbody>
  );
}

function MaterialRow({
  material,
  market,
  materialTables,
  isReadonly,
  isBusy,
  systemId,
  dispatch,
  dispatchProject,
  translate,
  validationResult,
  waitingForPrices,
  costEnabled,
  discountEnabled,
  showImages,
}: {
  readonly material: Project.Material;
  readonly market: Markets.Market;
  readonly materialTables: Materials.MaterialTables;
  readonly isReadonly: boolean;
  readonly isBusy: boolean;
  readonly systemId: string;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly translate: Texts.TranslateFn;
  readonly validationResult: Materials.ValidationResult;
  readonly waitingForPrices: boolean;
  readonly costEnabled: boolean;
  readonly discountEnabled: boolean;
  readonly showImages: boolean;
}): JSX.Element {
  const price = Price.calculateMaterialPrice(material);
  const meta = Materials.getMetaData(market, materialTables, material);
  const error = validationResult.errorsById[material.id];
  return (
    <tr key={material.id}>
      <td className="p-0">
        {error ? (
          <Icon
            icon={error.category === "item_has_been_removed" ? "triangle-exclamation" : "exclamation-circle"}
            colorClass={error.category === "item_has_been_removed" ? "text-warning" : "text-danger"}
            message={translate(error.message)}
          />
        ) : undefined}
      </td>
      <td className="p-0">
        <Checkbox
          disabled={isBusy || isReadonly}
          checked={material.included}
          onChange={(checked) =>
            dispatchProject(ProjectAction.UpdateMaterialListItem(systemId, { id: material.id, included: checked }))
          }
        />
      </td>
      <td className="p-0">{material.sortNo}</td>
      {showImages ? (
        <td className="p-0">
          {material.itemNumber && (
            <img
              src={`${clientConfig.image_service_url}?itemNumber=${material.itemNumber}&maxWidth=50&maxHeight=50&format=jpg`}
              className="h-24"
              style={{ cursor: "pointer" }}
              alt={""}
              onClick={() => {
                dispatch(
                  Action.OpenLightbox(
                    true,
                    `${clientConfig.image_service_url}?itemNumber=${material.itemNumber}&maxWidth=1000&maxHeight=1000&format=jpg`
                  )
                );
              }}
            />
          )}
        </td>
      ) : null}
      {material.type === "custom" ? (
        <td className="p-0">
          <Textfield
            isRequiredMessage={translate(texts.item_number_required)}
            disabled={isBusy}
            readOnly={isReadonly}
            value={material.itemNumber || ""}
            onChange={(v) => dispatchProject(ProjectAction.UpdateCustomMaterial(systemId, material.id, v))}
            className="mr-8"
          />
        </td>
      ) : (
        <td className="p-0">{Materials.getItemNumber(material)}</td>
      )}

      {material.type === "custom" ? (
        <td className="p-0">
          <Textfield
            disabled={isBusy}
            readOnly={isReadonly}
            className="mr-8"
            value={material.name || ""}
            onChange={(v) =>
              dispatchProject(ProjectAction.UpdateMaterialListItem(systemId, { id: material.id, name: v }))
            }
          />
        </td>
      ) : (
        <ItemNameTd translate={translate} material={material} />
      )}
      <td className="p-0">
        <div className="flex flex-row items-center justify-between">
          {meta.readOnlyQuantity ? (
            <span>{Utils.numberToString(material.quantity, 4)}</span>
          ) : (
            <NumberField
              className="max-w-52"
              disabled={isBusy}
              readOnly={isReadonly}
              value={material.quantity}
              errorMessage={undefined}
              decimals={4}
              onChange={(v) =>
                dispatchProject(ProjectAction.UpdateMaterialListItem(systemId, { id: material.id, quantity: v }))
              }
            />
          )}
          {(material.packageName || material.type === "custom") && (
            <Icon
              icon={"trash-alt"}
              disabled={isBusy || isReadonly}
              onClick={() => {
                dispatchProject(ProjectAction.RemoveMaterial(systemId, material.id));
              }}
              message={translate(Texts.texts.remove_custom_material)}
              className="mr-8"
            />
          )}
        </div>
      </td>
      {costEnabled && (
        <PriceTd className="p-0">{renderPrice(price.singleCost, price.currency, waitingForPrices)}</PriceTd>
      )}
      {material.type === "custom" ? (
        <td>
          <div className="flex flex-row space-x-4 ml-8">
            <NumberField
              disabled={isBusy}
              readOnly={isReadonly}
              value={material.singleNetPrice ?? undefined}
              onChange={(v) =>
                dispatchProject(
                  ProjectAction.UpdateMaterialListItem(systemId, {
                    id: material.id,
                    singleNetPrice: v,
                    singleSalesPrice: v,
                  })
                )
              }
            />
            <span>{getCurrencySymbol(material.currency || market.currency)}</span>
          </div>
        </td>
      ) : (
        <PriceTd className="p-0">{renderPrice(price.singlePrice, price.currency, waitingForPrices)}</PriceTd>
      )}
      {costEnabled && <PriceTd className="p-0">{renderPrice(price.totalCost, price.currency, false)}</PriceTd>}
      <PriceTd>{renderPrice(price.totalPrice, price.currency, false)}</PriceTd>
      {discountEnabled && <PriceTd className="p-0">{renderPrice(price.discount, "%", false)}</PriceTd>}
      {costEnabled && <PriceTd className="p-0">{renderPrice(price.totalMargin, price.currency, false)}</PriceTd>}
    </tr>
  );
}

function ItemNameTd({
  material,
  translate,
}: {
  readonly material: Project.Material;
  readonly translate: Texts.TranslateFn;
}): JSX.Element {
  return (
    <td className="p-0">
      <div className="flex flex-row justify-between items-center">
        <div>
          <div>{Materials.getItemName(translate, material)}</div>
          <div className="text-neutral-700">{Materials.getItemDescription(translate, material)}</div>
        </div>
        {material.packageName ? (
          <Icon
            className={"pr-24"}
            icon={"cube"}
            message={translate(texts.package_name(material.packageName), material.packageName)}
          />
        ) : null}
      </div>
    </td>
  );
}

function MaterialListOneRow({
  system,
  translate,
  waitingForPrices,
  costEnabled,
  discountEnabled,
}: {
  readonly system: Project.System;
  readonly translate: Texts.TranslateFn;
  readonly materialTables: Materials.MaterialTables;
  readonly waitingForPrices: boolean;
  readonly market: Markets.Market;
  readonly costEnabled: boolean;
  readonly discountEnabled: boolean;
}): JSX.Element {
  if (system.materials.length === 0) {
    return <div>{translate(texts.material_list_empty)}</div>;
  }
  const totalPrice = Price.calculateMaterialListTotalPrice(system.materials);
  return (
    <div className="flex flex-row items-center align-middle space-x-16">
      <div className="flex flex-col text-sm">
        {costEnabled && (
          <div className="flex flex-row">
            <span className="w-104 mr-4">{`${translate(texts.total_cost)}:`}</span>
            {renderPrice(totalPrice?.totalCost, totalPrice?.currency, waitingForPrices)}
          </div>
        )}
        <div className="flex flex-row">
          <span className="w-104 mr-4">{`${translate(texts.total_price)}:`}</span>
          {renderPrice(totalPrice?.totalPrice, totalPrice?.currency, waitingForPrices)}
        </div>
        {discountEnabled && (
          <div className="flex flex-row">
            <span className="w-104 mr-4">{`${translate(texts.discount)}:`}</span>
            {renderPrice(totalPrice?.discount, "%", waitingForPrices)}
          </div>
        )}
        {costEnabled && (
          <div className="flex flex-row">
            <span className="w-104 mr-4">{`${translate(texts.margin)}:`}</span>
            {renderPrice(totalPrice?.totalMargin, totalPrice?.currency, waitingForPrices)}
          </div>
        )}
      </div>
    </div>
  );
}

function renderPrice(
  price: number | undefined,
  currency: string | undefined,
  waitingForPrices: boolean
): string | JSX.Element {
  if (price === undefined || currency === undefined) {
    if (waitingForPrices) {
      return (
        <div className="flex justify-end">
          <Spinner small={true} debounce={false} />
        </div>
      );
    } else {
      return "-";
    }
  }
  return price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") + getCurrencySymbol(currency);
}

function getCurrencySymbol(currency: string): string {
  return currency === "EUR" ? " €" : currency === "NOK" ? " kr" : ` ${currency}`;
}

function PackageButtons({
  isReadonly,
  system,
  dispatchProject,
  translate,
  materialTables,
  market,
}: {
  readonly isReadonly: boolean;
  readonly system: Project.System;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly translate: Texts.TranslateFn;
  readonly materialTables: Materials.MaterialTables;
  readonly market: Markets.Market;
}): JSX.Element | null {
  const packageStatus = Materials.getPackageStatus(market, materialTables, system);
  if (packageStatus.length === 0) {
    return null;
  }
  return (
    <Toolbar>
      {packageStatus.map((ps) => (
        <SelectButton
          key={ps.packageName}
          disabled={isReadonly}
          selected={ps.added}
          label={translate(texts.package_name(ps.packageName), ps.packageName)}
          onClick={(newAdded) =>
            newAdded
              ? dispatchProject(ProjectAction.AddMaterialPackage(system.id, ps.packageName))
              : dispatchProject(ProjectAction.RemoveMaterialPackage(system.id, ps.packageName))
          }
        />
      ))}
    </Toolbar>
  );
}
