import { Project } from "../../..";
import {
  isVentilationConceptBuildingTightnessInput,
  isVentilationConceptBuildingType,
  isVentilationConceptOccupancy,
  isVentilationConceptThermalProtection,
  isVentilationConceptWindConditions,
  VentilationConceptSystem,
  VentilationConceptProject,
  VentilationConceptBuildingTightnessInput,
  VentilationConceptBuildingType,
  VentilationConceptOccupancy,
  VentilationConceptStatus,
  VentilationConceptThermalProtection,
  VentilationConceptWindConditions,
} from "../../../project";
import { Result, RoomsWithoutWindowsTable } from "../types";

interface ValidatedProjectInput {
  readonly windConditions: VentilationConceptWindConditions;
  readonly buildingType: VentilationConceptBuildingType;
  readonly buildingTightnessInput: VentilationConceptBuildingTightnessInput;
  readonly buildingTightness: number;
  readonly thermalProtection: VentilationConceptThermalProtection;
}

interface ValidatedSystemInput {
  readonly totalArea: number;
  readonly totalVolume: number;
  readonly occupancy: VentilationConceptOccupancy;
  readonly windowlessRooms: boolean;
  readonly furnace: boolean;
  readonly higherRequirementsSound: boolean;
  readonly higherRequirementsAirQuality: boolean;
  readonly higherRequirementsEnergyEfficiency: boolean;
}

// DIN 1946-6 / Tabelle 4, fws coefficient
interface MoistureProtectionCoefficient {
  readonly occupancy: "low" | "high";
  readonly thermalProtection: "low" | "high";
  readonly fws: number;
}
const moistureProtectionCoefficients: ReadonlyArray<MoistureProtectionCoefficient> = [
  { occupancy: "low", thermalProtection: "high", fws: 0.2 },
  { occupancy: "low", thermalProtection: "low", fws: 0.3 },
  { occupancy: "high", thermalProtection: "high", fws: 0.3 },
  { occupancy: "high", thermalProtection: "low", fws: 0.4 },
];

// DIN 1946-6 / Tabelle 5, ez,konzept coefficient
interface InfiltrationCoefficient {
  readonly buildingType: "single_floor" | "multi_floor";
  readonly windConditions: "low" | "high";
  readonly ez: number;
}
const infiltrationCoefficients: ReadonlyArray<InfiltrationCoefficient> = [
  { buildingType: "single_floor", windConditions: "low", ez: 0.04 },
  { buildingType: "single_floor", windConditions: "high", ez: 0.08 },
  { buildingType: "multi_floor", windConditions: "low", ez: 0.06 },
  { buildingType: "multi_floor", windConditions: "high", ez: 0.09 },
];

export interface VentilationConceptResult {
  readonly requiredAirflow: number;
  readonly infiltrationAirflow: number;
  readonly airflowStatus: VentilationConceptStatus;
  readonly higherRequirementsStatus: VentilationConceptStatus;
  readonly windowlessRoomsStatus: VentilationConceptStatus;
  readonly furnaceStatus: VentilationConceptStatus;
  readonly finalStatus: VentilationConceptStatus;
  readonly buildingTightness: number;
}

export function createDefaultVentilationConceptProject({
  ventilationConceptId,
  defaultWindConditions,
}: {
  readonly ventilationConceptId: string;
  readonly defaultWindConditions: "high" | "low" | null;
}): VentilationConceptProject {
  const ventilationConcept: Project.VentilationConceptProject = {
    id: ventilationConceptId,
    windConditions: defaultWindConditions,
    buildingType: null,
    buildingTightnessInput: "standard",
    buildingTightness: calculateBuildingTightness("standard", undefined) ?? null,
    thermalProtection: null,
  };
  return ventilationConcept;
}

export function createDefaultVentilationConceptSystem({
  roomsWithoutWindows,
  ventilationConceptId,
  system,
  result,
}: {
  readonly roomsWithoutWindows: RoomsWithoutWindowsTable;
  readonly ventilationConceptId: string;
  readonly system: Project.System;
  readonly result: Result | undefined;
}): VentilationConceptSystem {
  const windowlessRooms = system.rooms.some((room) =>
    roomsWithoutWindows.some((r) => r.room_template_key === room.roomType)
  );
  const ventilationConcept: Project.VentilationConceptSystem = {
    id: ventilationConceptId,
    name: system.name,
    totalArea: result?.totalArea || null,
    totalVolume: result?.totalVolume || null,
    occupancy: null,
    windowlessRooms: windowlessRooms || null,
    furnace: null,
    higherRequirementsSound: null,
    higherRequirementsAirQuality: null,
    higherRequirementsEnergyEfficiency: null,
    requiredAirflow: null,
    infiltrationAirflow: null,
    airflowStatus: null,
    higherRequirementsStatus: null,
    windowlessRoomsStatus: null,
    furnaceStatus: null,
    finalStatus: null,
  };
  return ventilationConcept;
}

export function calculateVentilationConcept(
  ventilationConceptProject: VentilationConceptProject,
  ventilationConceptSystem: VentilationConceptSystem
): VentilationConceptResult | undefined {
  if (!isValidatedProjectInput(ventilationConceptProject) || !isValidatedSystemInput(ventilationConceptSystem)) {
    return undefined;
  }
  const { windConditions, buildingType, buildingTightnessInput, buildingTightness, thermalProtection } =
    ventilationConceptProject;
  const {
    totalArea,
    totalVolume,
    occupancy,
    windowlessRooms,
    furnace,
    higherRequirementsSound,
    higherRequirementsAirQuality,
    higherRequirementsEnergyEfficiency,
  } = ventilationConceptSystem;

  const requiredAirflow = totalArea
    ? calculateRequiredAirflowForMoistureProction(totalArea, occupancy, thermalProtection)
    : undefined;
  if (requiredAirflow === undefined) {
    return undefined;
  }

  const calculatedBuildingTightness = calculateBuildingTightness(buildingTightnessInput, buildingTightness);
  if (calculatedBuildingTightness === undefined) {
    return undefined;
  }

  const infiltrationAirflow = totalVolume
    ? calculateInfiltrationAirflow(totalVolume, buildingType, windConditions, calculatedBuildingTightness)
    : undefined;
  if (infiltrationAirflow === undefined) {
    return undefined;
  }

  const airflowStatus = infiltrationAirflow < requiredAirflow ? "red" : "green";
  const higherRequirementsStatus =
    !!higherRequirementsAirQuality || !!higherRequirementsEnergyEfficiency || !!higherRequirementsSound
      ? "red"
      : "green";
  const windowlessRoomsStatus = windowlessRooms ? "orange" : "green";
  const furnaceStatus = furnace ? "orange" : "green";
  const statuses = [airflowStatus, higherRequirementsStatus, windowlessRoomsStatus, furnaceStatus];
  const finalStatus = (statuses.find((s) => s === "red") ||
    statuses.find((s) => s === "orange") ||
    statuses.find((s) => s === "green")) as VentilationConceptStatus;

  return {
    requiredAirflow,
    infiltrationAirflow,
    airflowStatus,
    higherRequirementsStatus,
    windowlessRoomsStatus,
    furnaceStatus,
    finalStatus,
    buildingTightness: calculatedBuildingTightness,
  };
}

export function calculateRequiredAirflowForMoistureProction(
  totalArea: number,
  occupancy: VentilationConceptOccupancy,
  thermalProtection: VentilationConceptThermalProtection
): number | undefined {
  const fws = moistureProtectionCoefficients.find(
    (r) => r.occupancy === occupancy && r.thermalProtection === thermalProtection
  )?.fws;
  if (!fws || !totalArea) {
    return undefined;
  }
  const minAirflow = fws * (-0.002 * totalArea ** 2 + 1.15 * totalArea + 11);
  return minAirflow;
}

export function calculateInfiltrationAirflow(
  totalVolume: number,
  buildingType: VentilationConceptBuildingType,
  windConditions: VentilationConceptWindConditions,
  buildingTightness: number
): number | undefined {
  const ez = infiltrationCoefficients.find(
    (r) => r.buildingType === buildingType && r.windConditions === windConditions
  )?.ez;
  const vne = totalVolume;
  const n50 = buildingTightness;
  if (!ez || !vne) {
    return undefined;
  }
  const infiltrationAirflow = ez * vne * n50;
  return infiltrationAirflow;
}

export function calculateBuildingTightness(
  buildingTightnessInput: VentilationConceptBuildingTightnessInput,
  buildingTightness: number | undefined | null
): number | undefined {
  if (buildingTightnessInput === "standard") {
    return 0.7;
  } else if (buildingTightnessInput === "manually" && buildingTightness !== null && buildingTightness !== undefined) {
    return buildingTightness;
  } else {
    // TODO: In specifications: "The selection and detailed specification will follow later."
    return undefined;
  }
}

function isValidatedProjectInput(vc: VentilationConceptProject | ValidatedProjectInput): vc is ValidatedProjectInput {
  if (
    vc.windConditions === null ||
    vc.buildingType === null ||
    vc.buildingTightnessInput === null ||
    vc.thermalProtection === null
  ) {
    return false;
  }
  if (
    !isVentilationConceptWindConditions(vc.windConditions) ||
    !isVentilationConceptBuildingType(vc.buildingType) ||
    !isVentilationConceptBuildingTightnessInput(vc.buildingTightnessInput) ||
    !isVentilationConceptThermalProtection(vc.thermalProtection)
  ) {
    return false;
  }
  if (vc.buildingTightnessInput === "manually" && vc.buildingTightness === null) {
    return false;
  }
  return true;
}

function isValidatedSystemInput(vc: VentilationConceptSystem | ValidatedSystemInput): vc is ValidatedSystemInput {
  if (
    vc.totalArea === null ||
    vc.totalArea <= 0 ||
    vc.totalVolume === null ||
    vc.totalVolume <= 0 ||
    vc.occupancy === null ||
    vc.windowlessRooms === null ||
    vc.furnace === null
  ) {
    return false;
  }
  if (!isVentilationConceptOccupancy(vc.occupancy)) {
    return false;
  }
  return true;
}
