import * as R from "ramda";
import { isExtractRoom, isSupplyRoom, RoomTemplatesTable, System } from "../../project";
import { Result, Input, RoomField, Fields, RoomFields, SystemFields } from "../types";
import { calculateNumberOfPeople } from "./calculation";
import { calculateRoomAirflow, calculateSystemAirflow } from "./calculation/airflow";
import { calculateRoomFloorArea } from "./calculation/floor-area";
import { calculateNumberOfValves } from "./calculation/valves";
import { ResultByRoomId, RoomResult } from "./types";

export const name = "norway";

export function calculate(input: Input): Result {
  const systemAirflow = calculateSystemAirflow(input.roomTemplates, input.system);
  const roomResults = calculateRoomResults(input.roomTemplates, input.system);
  return {
    type: name,
    floorAirflow: systemAirflow.floorAirflow,
    supplyAirflow: systemAirflow.supplyAirflow,
    extractAirflow: systemAirflow.extractAirflow,
    requiredAirflow: systemAirflow.requiredAirflow,
    roomResults: roomResults,
  };
}

function calculateRoomResults(roomTemplatesTable: RoomTemplatesTable, system: System): ResultByRoomId {
  const roomResults: Array<[string, RoomResult]> = system.rooms.map((room) => {
    const area = calculateRoomFloorArea(roomTemplatesTable, room);
    const people = calculateNumberOfPeople(roomTemplatesTable, room);
    const airflow = calculateRoomAirflow(roomTemplatesTable, room);
    const width = room.width || undefined;
    const length = room.length || undefined;
    const valves = calculateNumberOfValves(roomTemplatesTable, room);
    return [room.id, { airflow, area, width, length, people, valves }];
  });
  const resultById = R.fromPairs(roomResults);
  return resultById;
}

const sharedFields: ReadonlyArray<RoomField> = [
  {
    field: "roomType",
    readOnly: false,
    hidden: false,
    onChange: (v: string) => ({ roomType: v, customAirFlow: false, valves: null }),
  },
  { field: "airType", readOnly: true, hidden: false },
  { field: "floorType", readOnly: false, hidden: false },
  { field: "supplyAirFactor", readOnly: false, hidden: true },
  {
    field: "airflow",
    readOnly: false,
    hidden: false,
    onChange: (v: number) => ({ airflow: v, customAirFlow: true }),
    clearOverride: (room) => (room.customAirFlow ? { customAirFlow: null } : undefined),
  },
  { field: "ceilingHeight", readOnly: false, hidden: false },
];

const supplyPeopleFields: ReadonlyArray<RoomField> = [
  { field: "floorArea", readOnly: false, hidden: true },
  { field: "width", readOnly: false, hidden: true },
  { field: "length", readOnly: false, hidden: true },
  { field: "people", readOnly: false, hidden: false, decimals: 0 },
];

const supplyAreaFields: ReadonlyArray<RoomField> = [
  {
    field: "floorArea",
    readOnly: false,
    hidden: false,
    onChange: (v: number) => ({ floorArea: v, width: null, length: null }),
  },
  { field: "width", readOnly: false, hidden: false, onChange: (v: number) => ({ width: v, floorArea: null }) },
  { field: "length", readOnly: false, hidden: false, onChange: (v: number) => ({ length: v, floorArea: null }) },
  { field: "people", readOnly: true, hidden: false, decimals: 0 },
];

const extractRoomFields: RoomFields = [
  { field: "floorArea", readOnly: false, hidden: true },
  { field: "width", readOnly: false, hidden: true },
  { field: "length", readOnly: false, hidden: true },
  { field: "people", readOnly: false, hidden: true, decimals: 0 },
];

const valvesField: RoomField = { field: "valves", readOnly: false, hidden: false, decimals: 0 };

export function getFields(input: Input): Fields {
  const fields: { [id: string]: ReadonlyArray<RoomField> } = {};
  for (const room of input.system.rooms) {
    const template = input.roomTemplates.find((r) => r.key === room.roomType);
    if (isSupplyRoom(room) && template?.airflow_calculation === "people") {
      fields[room.id] = [...sharedFields, ...supplyPeopleFields, valvesField];
    } else if (isSupplyRoom(room) && template?.airflow_calculation === "area") {
      fields[room.id] = [...sharedFields, ...supplyAreaFields, valvesField];
    } else if (isExtractRoom(room)) {
      fields[room.id] = [...sharedFields, ...extractRoomFields, valvesField];
    } else {
      fields[room.id] = [...sharedFields, valvesField];
    }
  }
  const systemFields: SystemFields = [
    { field: "totalArea", readOnly: false, hidden: false },
    { field: "pipeDiameter", readOnly: false, hidden: true },
  ];
  return { roomFields: fields, systemFields };
}
