export const requestTemplate = {
  ecomUrl: "https://shop.systemair.com/en/api/selectiontoolpriceprovider",
  searchProductId: "64F4BB90-5DA0-11E7-9A6A-4941B404A253",
  market: "005",
  language: "en",
  attributes: [],
  properties: [
    {
      market: {
        type: "integer",
        value: 5,
      },
      exchangertype: {
        type: "integer",
        value: -1,
      },
      supplyside: {
        type: "integer",
        value: -1,
      },
      mountingtype: {
        type: "integer",
        value: -1,
      },
    },
  ],
  calcParams: {
    airDensityCalculationMethod: {
      type: "integer",
      value: 0,
    },
    airDensity: {
      type: "amount",
      value: {
        value: 1.204,
        unit: {
          name: "KilogramPerCubicMeter",
          quantity: "Density",
          unitInfo: {
            quantity: "Density",
            type: "product",
            elements: [
              {
                unit: {
                  name: "Kilogram",
                  quantity: "Mass",
                  unitInfo: {
                    quantity: "Mass",
                    type: "base",
                    symbol: "kg",
                  },
                },
                pow: 1,
              },
              {
                unit: {
                  name: "Meter",
                  quantity: "Length",
                  unitInfo: {
                    quantity: "Length",
                    type: "base",
                    symbol: "m",
                  },
                },
                pow: -3,
              },
            ],
          },
        },
        decimalCount: 3,
      },
    },
    airDensityPressure: {
      type: "amount",
      value: {
        value: 101325,
        unit: {
          name: "Pascal",
          quantity: "Pressure",
          unitInfo: {
            quantity: "Pressure",
            type: "product",
            elements: [
              {
                unit: {
                  name: "Kilogram",
                  quantity: "Mass",
                  unitInfo: {
                    quantity: "Mass",
                    type: "base",
                    symbol: "kg",
                  },
                },
                pow: 1,
              },
              {
                unit: {
                  name: "Meter",
                  quantity: "Length",
                  unitInfo: {
                    quantity: "Length",
                    type: "base",
                    symbol: "m",
                  },
                },
                pow: -1,
              },
              {
                unit: {
                  name: "Second",
                  quantity: "Duration",
                  unitInfo: {
                    quantity: "Duration",
                    type: "base",
                    symbol: "s",
                  },
                },
                pow: -2,
              },
            ],
          },
        },
        decimalCount: 0,
      },
    },
    airDensitySeaLevel: {
      type: "amount",
      value: {
        value: 0,
        unit: {
          name: "Meter",
          quantity: "Length",
          unitInfo: {
            quantity: "Length",
            type: "base",
            symbol: "m",
          },
        },
        decimalCount: 0,
      },
    },
    airDensityTemperature: {
      type: "amount",
      value: {
        value: 20,
        unit: {
          name: "Celsius",
          quantity: "Temperature",
          unitInfo: {
            quantity: "Temperature",
            type: "transformed",
            parentUnit: {
              name: "Kelvin",
              quantity: "Temperature",
              unitInfo: {
                quantity: "Temperature",
                type: "base",
                symbol: "K",
              },
            },
            toParentUnitConverter: {
              type: "offset",
              offset: 273.15,
            },
          },
        },
        decimalCount: 0,
      },
    },
    season: {
      type: "integer",
      value: 0,
    },
    freshTemperatureWinter: {
      type: "amount",
      value: {
        value: -20,
        unit: {
          name: "Celsius",
          quantity: "Temperature",
          unitInfo: {
            quantity: "Temperature",
            type: "transformed",
            parentUnit: {
              name: "Kelvin",
              quantity: "Temperature",
              unitInfo: {
                quantity: "Temperature",
                type: "base",
                symbol: "K",
              },
            },
            toParentUnitConverter: {
              type: "offset",
              offset: 273.15,
            },
          },
        },
        decimalCount: 0,
      },
    },
    freshHumidityWinter: {
      type: "amount",
      value: {
        value: 90,
        unit: {
          name: "PercentHumidity",
          quantity: "RelativeHumidity",
          unitInfo: {
            quantity: "RelativeHumidity",
            type: "transformed",
            parentUnit: {
              name: "HumidityFactor",
              quantity: "RelativeHumidity",
              unitInfo: {
                quantity: "RelativeHumidity",
                type: "base",
                symbol: "r.H.",
              },
            },
            toParentUnitConverter: {
              type: "factor",
              factor: 0.01,
            },
          },
        },
        decimalCount: 0,
      },
    },
    extractTemperatureWinter: {
      type: "amount",
      value: {
        value: 22,
        unit: {
          name: "Celsius",
          quantity: "Temperature",
          unitInfo: {
            quantity: "Temperature",
            type: "transformed",
            parentUnit: {
              name: "Kelvin",
              quantity: "Temperature",
              unitInfo: {
                quantity: "Temperature",
                type: "base",
                symbol: "K",
              },
            },
            toParentUnitConverter: {
              type: "offset",
              offset: 273.15,
            },
          },
        },
        decimalCount: 0,
      },
    },
    extractHumidityWinter: {
      type: "amount",
      value: {
        value: 40,
        unit: {
          name: "PercentHumidity",
          quantity: "RelativeHumidity",
          unitInfo: {
            quantity: "RelativeHumidity",
            type: "transformed",
            parentUnit: {
              name: "HumidityFactor",
              quantity: "RelativeHumidity",
              unitInfo: {
                quantity: "RelativeHumidity",
                type: "base",
                symbol: "r.H.",
              },
            },
            toParentUnitConverter: {
              type: "factor",
              factor: 0.01,
            },
          },
        },
        decimalCount: 0,
      },
    },
    freshTemperatureSummer: {
      type: "amount",
      value: {
        value: 27,
        unit: {
          name: "Celsius",
          quantity: "Temperature",
          unitInfo: {
            quantity: "Temperature",
            type: "transformed",
            parentUnit: {
              name: "Kelvin",
              quantity: "Temperature",
              unitInfo: {
                quantity: "Temperature",
                type: "base",
                symbol: "K",
              },
            },
            toParentUnitConverter: {
              type: "offset",
              offset: 273.15,
            },
          },
        },
        decimalCount: 0,
      },
    },
    freshHumiditySummer: {
      type: "amount",
      value: {
        value: 60,
        unit: {
          name: "PercentHumidity",
          quantity: "RelativeHumidity",
          unitInfo: {
            quantity: "RelativeHumidity",
            type: "transformed",
            parentUnit: {
              name: "HumidityFactor",
              quantity: "RelativeHumidity",
              unitInfo: {
                quantity: "RelativeHumidity",
                type: "base",
                symbol: "r.H.",
              },
            },
            toParentUnitConverter: {
              type: "factor",
              factor: 0.01,
            },
          },
        },
        decimalCount: 0,
      },
    },
    extractTemperatureSummer: {
      type: "amount",
      value: {
        value: 22,
        unit: {
          name: "Celsius",
          quantity: "Temperature",
          unitInfo: {
            quantity: "Temperature",
            type: "transformed",
            parentUnit: {
              name: "Kelvin",
              quantity: "Temperature",
              unitInfo: {
                quantity: "Temperature",
                type: "base",
                symbol: "K",
              },
            },
            toParentUnitConverter: {
              type: "offset",
              offset: 273.15,
            },
          },
        },
        decimalCount: 0,
      },
    },
    extractHumiditySummer: {
      type: "amount",
      value: {
        value: 40,
        unit: {
          name: "PercentHumidity",
          quantity: "RelativeHumidity",
          unitInfo: {
            quantity: "RelativeHumidity",
            type: "transformed",
            parentUnit: {
              name: "HumidityFactor",
              quantity: "RelativeHumidity",
              unitInfo: {
                quantity: "RelativeHumidity",
                type: "base",
                symbol: "r.H.",
              },
            },
            toParentUnitConverter: {
              type: "factor",
              factor: 0.01,
            },
          },
        },
        decimalCount: 0,
      },
    },
    supplyAirFlow: {
      type: "amount",
      value: {
        value: 90,
        unit: {
          name: "CubicMeterPerHour",
          quantity: "VolumeFlow",
          unitInfo: {
            quantity: "VolumeFlow",
            type: "product",
            elements: [
              {
                unit: {
                  name: "Meter",
                  quantity: "Length",
                  unitInfo: {
                    quantity: "Length",
                    type: "base",
                    symbol: "m",
                  },
                },
                pow: 3,
              },
              {
                unit: {
                  name: "Hour",
                  quantity: "Duration",
                  unitInfo: {
                    quantity: "Duration",
                    type: "transformed",
                    parentUnit: {
                      name: "Minute",
                      quantity: "Duration",
                      unitInfo: {
                        quantity: "Duration",
                        type: "transformed",
                        parentUnit: {
                          name: "Second",
                          quantity: "Duration",
                          unitInfo: {
                            quantity: "Duration",
                            type: "base",
                            symbol: "s",
                          },
                        },
                        toParentUnitConverter: {
                          type: "factor",
                          factor: 60,
                        },
                      },
                    },
                    toParentUnitConverter: {
                      type: "factor",
                      factor: 60,
                    },
                  },
                },
                pow: -1,
              },
            ],
          },
        },
        decimalCount: 0,
      },
    },
    extractAirFlow: {
      type: "amount",
      value: {
        value: 90,
        unit: {
          name: "CubicMeterPerHour",
          quantity: "VolumeFlow",
          unitInfo: {
            quantity: "VolumeFlow",
            type: "product",
            elements: [
              {
                unit: {
                  name: "Meter",
                  quantity: "Length",
                  unitInfo: {
                    quantity: "Length",
                    type: "base",
                    symbol: "m",
                  },
                },
                pow: 3,
              },
              {
                unit: {
                  name: "Hour",
                  quantity: "Duration",
                  unitInfo: {
                    quantity: "Duration",
                    type: "transformed",
                    parentUnit: {
                      name: "Minute",
                      quantity: "Duration",
                      unitInfo: {
                        quantity: "Duration",
                        type: "transformed",
                        parentUnit: {
                          name: "Second",
                          quantity: "Duration",
                          unitInfo: {
                            quantity: "Duration",
                            type: "base",
                            symbol: "s",
                          },
                        },
                        toParentUnitConverter: {
                          type: "factor",
                          factor: 60,
                        },
                      },
                    },
                    toParentUnitConverter: {
                      type: "factor",
                      factor: 60,
                    },
                  },
                },
                pow: -1,
              },
            ],
          },
        },
        decimalCount: 0,
      },
    },
    supplyPressure: {
      type: "amount",
      value: {
        value: 100,
        unit: {
          name: "Pascal",
          quantity: "Pressure",
          unitInfo: {
            quantity: "Pressure",
            type: "product",
            elements: [
              {
                unit: {
                  name: "Kilogram",
                  quantity: "Mass",
                  unitInfo: {
                    quantity: "Mass",
                    type: "base",
                    symbol: "kg",
                  },
                },
                pow: 1,
              },
              {
                unit: {
                  name: "Meter",
                  quantity: "Length",
                  unitInfo: {
                    quantity: "Length",
                    type: "base",
                    symbol: "m",
                  },
                },
                pow: -1,
              },
              {
                unit: {
                  name: "Second",
                  quantity: "Duration",
                  unitInfo: {
                    quantity: "Duration",
                    type: "base",
                    symbol: "s",
                  },
                },
                pow: -2,
              },
            ],
          },
        },
        decimalCount: 0,
      },
    },
    extractPressure: {
      type: "amount",
      value: {
        value: 100,
        unit: {
          name: "Pascal",
          quantity: "Pressure",
          unitInfo: {
            quantity: "Pressure",
            type: "product",
            elements: [
              {
                unit: {
                  name: "Kilogram",
                  quantity: "Mass",
                  unitInfo: {
                    quantity: "Mass",
                    type: "base",
                    symbol: "kg",
                  },
                },
                pow: 1,
              },
              {
                unit: {
                  name: "Meter",
                  quantity: "Length",
                  unitInfo: {
                    quantity: "Length",
                    type: "base",
                    symbol: "m",
                  },
                },
                pow: -1,
              },
              {
                unit: {
                  name: "Second",
                  quantity: "Duration",
                  unitInfo: {
                    quantity: "Duration",
                    type: "base",
                    symbol: "s",
                  },
                },
                pow: -2,
              },
            ],
          },
        },
        decimalCount: 0,
      },
    },
    market: {
      type: "integer",
      value: 5,
    },
    exchangertype: {
      type: "integer",
      value: -1,
    },
    supplyside: {
      type: "integer",
      value: -1,
    },
    mountingtype: {
      type: "integer",
      value: -1,
    },
  },
  accessories: [],
  crm: false,
  overallSortParams: {
    sortPath: "sort_no",
    descending: false,
  },
};
