import { calculateNominalAirflow } from ".";
import { isExtractRoom, Room, System, RoomTemplatesTable } from "../../../project";
import { calculateTotalArea } from "./floor-area";

export function calculateRequiredAirflow(roomTemplatesTable: RoomTemplatesTable, system: System): number | undefined {
  const calculatedTotalArea = calculateTotalArea(system.rooms);
  const totalArea = calculatedTotalArea !== undefined ? Math.max(calculatedTotalArea, 20) : undefined;
  const airflowForArea = totalArea !== undefined ? calculateAirflowForArea(totalArea) : undefined;

  let adjustedAirflow = undefined;
  if (system.rooms.some((r) => isExtractRoom(r))) {
    const airflowExtract = calculateTotalNominalExtractAirflow(roomTemplatesTable, system.rooms);
    adjustedAirflow =
      airflowForArea !== undefined && airflowExtract !== undefined
        ? calculateAdjustedAirflow(airflowForArea, airflowExtract)
        : undefined;
  } else {
    adjustedAirflow = airflowForArea;
  }

  const requiredAirflow =
    adjustedAirflow !== undefined && airflowForArea !== undefined
      ? Math.max(airflowForArea, adjustedAirflow)
      : undefined;

  return requiredAirflow;
}

export function calculateAirflowForArea(area: number): number | undefined {
  if (area <= 210) {
    return -0.002 * area * area + 1.15 * area + 11;
  } else {
    return 165 + (area - 210) * 0.4;
  }
}

export function calculateTotalNominalExtractAirflow(
  roomTemplates: RoomTemplatesTable,
  rooms: ReadonlyArray<Room>
): number | undefined {
  const extractRooms = rooms.filter((r) => isExtractRoom(r));
  let totalExtractAf = 0;
  for (const room of extractRooms) {
    const nominalAf = calculateNominalAirflow(roomTemplates, room);
    if (nominalAf === undefined) {
      return undefined;
    }
    totalExtractAf += nominalAf;
  }
  return totalExtractAf;
}

function calculateAdjustedAirflow(airflowForArea: number, airflowExtract: number): number {
  if (airflowExtract > airflowForArea * 1.2) {
    return airflowForArea * 1.2;
  } else {
    return airflowExtract;
  }
}
