import { isSupplyRoom, System } from "../../../project";
import { logWarn } from "../../../logger";

export interface RoomResult {
  readonly airflow: number;
}

export interface BalanceSupplyAirflowsResult {
  readonly resultByRoomId: ReadonlyMap<string, RoomResult>;
}

export function calculateBalanceSupplyAirflows(
  system: System,
  requiredAirflow: number | undefined
): BalanceSupplyAirflowsResult | undefined {
  const supplyRooms = system.rooms.filter((r) => isSupplyRoom(r));
  if (supplyRooms.length === 0) {
    return { resultByRoomId: new Map() };
  }

  if (supplyRooms.some((r) => r.supplyAirFactor === null || r.supplyAirFactor <= 0)) {
    logWarn(`Some supply rooms has incorrect or missing supply air factor`);
    return undefined;
  }

  if (requiredAirflow === undefined) {
    logWarn(`requiredAirflow not calculated`);
    return undefined;
  }

  const factorSum = supplyRooms.reduce((sofar, room) => sofar + (room.supplyAirFactor || 0), 0);
  const airflowPerOne = requiredAirflow / factorSum;
  const resultByRoomId = supplyRooms.reduce((sofar, room) => {
    const roomResult = {
      airflow: (room.supplyAirFactor || 0) * airflowPerOne,
    };
    sofar.set(room.id, roomResult);
    return sofar;
  }, new Map<string, RoomResult>());

  return {
    resultByRoomId,
  };
}
