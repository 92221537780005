import * as React from "react";

export function Spinner({
  small,
  debounce,
}: {
  readonly small?: boolean;
  readonly debounce?: boolean;
}): React.ReactElement<{}> {
  const [showSpinner, setShowSpinner] = React.useState(debounce ?? true ? false : true);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(true);
    }, 750);
    return () => clearTimeout(timer);
  });
  return (
    <div className="flex flex-row items-center">
      {showSpinner ? (
        <svg className={`animate-spin ${small ? "w-16 h-16" : "w-24 h-24"}`} fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : undefined}
    </div>
  );
}
