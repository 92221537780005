import * as React from "react";
import { AbstractDocJsx as ADX } from "abstract-document";
import { CreatorType } from "./types";
import * as Common from "../common";

const { AbstractDoc, Section, Paragraph, TextRun, Image, render } = ADX;

export const create: CreatorType = (props) => {
  const styles = Common.styles();
  const numberingDefinitions = Common.numberingDefinitions(styles);
  const fonts = Common.fonts(props.pageProps.fonts);
  const imageLogo = props.pageProps.logo ? Common.createImageResource(props.pageProps.logo) : undefined;

  const doc = render(
    <AbstractDoc fonts={fonts} styles={styles} numberingDefinitions={numberingDefinitions}>
      <Section id={Common.pageId(props.pageProps)} page={Common.cataloguePage(props.pageProps)}>
        <Paragraph>
          {props.data.map((m) => (
            <TextRun text={m} style={{ fontSize: 24, type: "TextStyle" }}></TextRun>
          ))}
        </Paragraph>
        <Paragraph>
          {imageLogo ? <Image width={200} height={568} imageResource={imageLogo}></Image> : <TextRun text="" />}
        </Paragraph>
      </Section>
    </AbstractDoc>
  );
  return doc;
};
