import gql from "graphql-tag";
import { httpBlobQueryMultiple, graphQLProductQuery, graphQLQuery, QueryGenerator } from "../../query";
import { FontData } from "../types";
import * as GQLOps from "../../generated/generated-operations";
import { fetchImageData, ImageData } from "../../images";

const productImageQuery = gql`
  query Report_ProductImages($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        images {
          ...Image_Module_images
        }
      }
    }
  }
  fragment Image_Module_images on Module_images {
    image {
      image
      type
      name
      file_name
    }
  }
`;

export const glProjectQuery = gql`
  query ReportCommonProject_project($projectId: ID!) {
    project(id: $projectId) {
      ...ReportCommonProject_project
    }
  }
  fragment ReportCommonProject_project on Project {
    id
    created
    edited
    locked
    name
    owned
    permissions
    template
    projectAddressname
    projectStreet
    projectPostalcode
    projectCity
    projectCountry
    customerAddressname
    customerStreet
    customerPostalcode
    customerCity
    customerCountry
    customerCustomerNumber
    gaebformat
  }
`;

export function* projectQuery(projectId: string): QueryGenerator<GQLOps.ReportCommonProject_ProjectQuery> {
  const projectResponse = yield* graphQLQuery<
    GQLOps.ReportCommonProject_ProjectQuery,
    GQLOps.ReportCommonProject_ProjectQueryVariables
  >(glProjectQuery, { projectId: projectId });
  return projectResponse;
}

export function* fontQuery(): QueryGenerator<FontData> {
  const fonts = yield* httpBlobQueryMultiple([
    "/fonts/DaxlineOffcPro.ttf",
    "/fonts/DaxlineOffcPro-Bold.ttf",
    "/fonts/DaxlineOffcPro-Medium.ttf",
    "/fonts/DaxlineOffcPro-Italic.ttf",
    "/fonts/DaxlineOffcPro-BoldItalic.ttf",
  ]);
  const normal = fonts[0].response;
  const medium = fonts[1].response;
  const bold = fonts[2].response;
  const italic = fonts[3].response;
  const boldItalic = fonts[4].response;
  return {
    normal,
    medium,
    bold,
    italic,
    boldItalic,
  };
}

export function* imageQuery(): QueryGenerator<GQLOps.Image_Module_ImagesFragment["image"] | undefined> {
  const imageResponse = yield* graphQLProductQuery<
    GQLOps.Report_ProductImagesQuery,
    GQLOps.Report_ProductImagesQueryVariables
  >(productImageQuery, { productId: "54b874d6-ee08-430b-b83f-f1f6809b3d0c" });
  return imageResponse.product?.modules.images.image;
}

export function* getLogoImage(
  imageTable: GQLOps.Image_Module_ImagesFragment["image"] | undefined,
  imageServiceUrl: string
): QueryGenerator<ReadonlyArray<ImageData>> {
  const cover = imageTable?.filter((image) => image.file_name === "systemair-logo.svg");
  return yield* fetchImageData(cover || [], imageServiceUrl, 800, 800);
}
