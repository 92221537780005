import { graphQLMutationWithAuth, patchResponse, SharedState, variablesFromPatch } from "@rvs/client-infra";
import { Cmd } from "@typescript-tea/core";
import { Project } from "@rvs/shared";
import { v4 as uuid } from "uuid";
import { Action, calculateSystem, createRoomUpdateMutations, State } from "../state";
import * as GQLOps from "../../../../generated/generated-operations";
import * as M from "../mutations";

export function CreateSystem(
  action: ReturnType<typeof Action.CreateSystem>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const pipeDiameter = state.metaProduct.product?.modules.custom_tables.PipeDiameter;
  if (!pipeDiameter) {
    return [state];
  }
  const newSystem = Project.createSystem(
    pipeDiameter,
    uuid(),
    action.name || "",
    Project.getNextSystemSortNo(state.project)
  );
  if (!newSystem) {
    return [state];
  }
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const newProject = Project.addSystemLast(state.project, newSystem);
  const { updatedCalcState, updatedProject } = calculateSystem(
    sharedState.market.name,
    state.metaProduct,
    state.materialTables,
    newProject,
    state.calculationStates,
    newSystem.id
  );
  return [
    { ...state, calculationStates: updatedCalcState, project: updatedProject },
    graphQLMutation<
      GQLOps.ProjectState_CreateSystemMutation,
      GQLOps.ProjectState_CreateSystemMutationVariables,
      Action
    >(
      M.createSystemMutation,
      {
        input: {
          id: newSystem.id,
          sortNo: newSystem.sortNo,
          name: newSystem.name,
          projectId: state.project.id,
          pipeDiameter: newSystem.pipeDiameter,
          totalArea: newSystem.totalArea,
          airChangeRate: newSystem.airChangeRate,
        },
      },
      sharedState.market.name,
      Action.NoOp
    ),
  ];
}

export function UpdateSystem(
  action: ReturnType<typeof Action.UpdateSystem>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const system = state.project.systems.find((s) => s.id === action.patch.id);
  if (!system) {
    return [state];
  }
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { updatedCalcState, updatedProject, roomPatches } = calculateSystem(
    sharedState.market.name,
    state.metaProduct,
    state.materialTables,
    Project.replaceSystem(state.project, patchResponse(system, action.patch)),
    state.calculationStates,
    system.id
  );
  const updateSystemCmd = graphQLMutation<
    GQLOps.ProjectState_UpdateSystemMutation,
    GQLOps.ProjectState_UpdateSystemMutationVariables,
    Action
  >(M.updateSystemMutation, variablesFromPatch(action.patch.id, action.patch), sharedState.market.name, Action.NoOp);
  const roomUpdateMoutations = createRoomUpdateMutations(
    graphQLMutation,
    roomPatches,
    system.id,
    sharedState.market.name
  );
  return [
    { ...state, calculationStates: updatedCalcState, project: updatedProject },
    Cmd.batch([updateSystemCmd, roomUpdateMoutations]),
  ];
}

export function DuplicateSystem(
  action: ReturnType<typeof Action.DuplicateSystem>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const system = state.project.systems.find((s) => s.id === action.systemId);

  // const newSystem = state.project.systems.find((s) => s.id === action.newSystemId);

  if (!system) {
    return [state];
  }
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);

  const duplicateSystemCmd = graphQLMutation<
    GQLOps.ProjectState_DuplicateSystemMutation,
    GQLOps.ProjectState_DuplicateSystemMutationVariables,
    Action
  >(
    M.duplicateSystemMutation,
    {
      input: {
        id: system.id,
        newSortNo: Project.getNextSystemSortNo(state.project),
        projectId: state.project.id,
        newId: action.newSystemId,
      },
    },
    sharedState.market.name,
    (res) => Action.DuplicateSystemPostAction(res, system.id)
  );

  const newSystemsBeingDuplicated = new Set<string>(state.systemsBeingDuplicated);
  newSystemsBeingDuplicated.add(system.id);

  return [{ ...state, systemsBeingDuplicated: newSystemsBeingDuplicated }, duplicateSystemCmd];
}

export function DuplicateSystemPostAction(
  action: ReturnType<typeof Action.DuplicateSystemPostAction>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const { project } = state;

  const newSystem = action.newSystem.duplicateSystem.system;
  let updatedProject = project;

  const insertResult = Project.insertSystemAfter(updatedProject, "After", action.oldSystemId, newSystem);

  updatedProject = insertResult.updatedProjects;

  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const updateSystemCmd = insertResult.patches.map((e) =>
    graphQLMutation<
      GQLOps.ProjectState_UpdateSystemMutation,
      GQLOps.ProjectState_UpdateSystemMutationVariables,
      Action
    >(M.updateSystemMutation, variablesFromPatch(e.id, e), sharedState.market.name, Action.NoOp)
  );

  // Duplicate calculation state
  // updateCalculationState returns room updates and normally those would have to be applied to the
  // porject but in this case those can be ignored because those should already be applied to the
  // system we just copied.
  const updateCalcResult = calculateSystem(
    sharedState.market.name,
    state.metaProduct,
    state.materialTables,
    updatedProject,
    state.calculationStates,
    newSystem.id
  );
  updatedProject = updateCalcResult.updatedProject;

  const newSystemsBeingDuplicated = new Set<string>(state.systemsBeingDuplicated);
  if (newSystemsBeingDuplicated.has(action.oldSystemId)) {
    newSystemsBeingDuplicated.delete(action.oldSystemId);
  }

  return [
    {
      ...state,
      calculationStates: updateCalcResult.updatedCalcState,
      project: updatedProject,
      systemsBeingDuplicated: newSystemsBeingDuplicated,
    },
    Cmd.batch(updateSystemCmd),
  ];
}

export function RemoveSystem(
  action: ReturnType<typeof Action.RemoveSystem>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const system = state.project.systems.find((s) => s.id === action.systemId);
  if (!system) {
    return [state];
  }
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const updatedProject = Project.removeSystem(state.project, action.systemId);
  return [
    { ...state, project: updatedProject },
    graphQLMutation<
      GQLOps.ProjectState_RemoveSystemMutation,
      GQLOps.ProjectState_RemoveSystemMutationVariables,
      Action
    >(
      M.removeSystemMutation,
      {
        input: {
          id: action.systemId,
        },
      },
      sharedState.market.name,
      Action.NoOp
    ),
  ];
}
