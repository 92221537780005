import React from "react";

/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable @typescript-eslint/no-var-requires */

export const BuildingCondo = createSvgComponent(require("../../../../../../html/images/vc_building_condo.svg"));
export const BuildingHouse = createSvgComponent(require("../../../../../../html/images/vc_building_house.svg"));
export const Fireplace = createSvgComponent(require("../../../../../../html/images/vc_fireplace.svg"));
export const InsulationHigh = createSvgComponent(require("../../../../../../html/images/vc_insulation_high.svg"));
export const InsulationLow = createSvgComponent(require("../../../../../../html/images/vc_insulation_low.svg"));
export const NotApplicable = createSvgComponent(require("../../../../../../html/images/vc_not_applicable.svg"));
export const OccupancyHigh = createSvgComponent(require("../../../../../../html/images/vc_occupancy_high.svg"));
export const OccupancyLow = createSvgComponent(require("../../../../../../html/images/vc_occupancy_low.svg"));
export const WindStrong = createSvgComponent(require("../../../../../../html/images/vc_wind_strong.svg"));
export const WindWeak = createSvgComponent(require("../../../../../../html/images/vc_wind_weak.svg"));
export const Window = createSvgComponent(require("../../../../../../html/images/vc_window.svg"));

function createSvgComponent(svg: string): () => JSX.Element {
  return () => <div dangerouslySetInnerHTML={{ __html: svg }} />;
}
