import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { SharedState, Routes } from "@rvs/client-infra";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import * as ProjectState from "../../project-state";

// STATE

export interface State {
  readonly expandedItems: ReadonlySet<string>;
  readonly initialRooms: ReadonlySet<string>; // For animating any new rooms
}

export const Action = ctorsUnion({
  ToggleExpanded: (id: string) => ({ id }),
  NoOp: () => ({}),
});

export type Action = CtorsUnion<typeof Action>;

export function init(
  _location: Routes.ProjectLocation,
  _sharedState: SharedState.SharedState,
  projectState: ProjectState.State,
  prevState: State | undefined
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const roomIds = projectState.project.systems.flatMap((system) => system.rooms.map((room) => room.id));
  const initialRooms = new Set(roomIds);
  if (prevState) {
    return [
      {
        ...prevState,
        initialRooms,
      },
    ];
  } else {
    return [
      {
        expandedItems: new Set(),
        initialRooms,
      },
    ];
  }
}

export function update(
  action: Action,
  state: State,
  _projectState: ProjectState.State,
  _sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "NoOp": {
      return [state];
    }
    case "ToggleExpanded": {
      const newExpanded = new Set<string>(state.expandedItems);
      if (newExpanded.has(action.id)) {
        newExpanded.delete(action.id);
      } else {
        newExpanded.add(action.id);
      }
      return [{ ...state, expandedItems: newExpanded }];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}
