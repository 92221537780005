import { VentilationChangeRate, VentilationChangeRates } from "..";

export function calculateVentilationChangeRates(
  requiredAirflowForMoistureProtection: number | undefined,
  systemNominalAirflow: number,
  totalSystemVolume: number
): VentilationChangeRates {
  const moistureControl =
    requiredAirflowForMoistureProtection !== undefined
      ? calculateChangeRate(requiredAirflowForMoistureProtection, totalSystemVolume, 1)
      : undefined;
  const reduced = calculateChangeRate(systemNominalAirflow, totalSystemVolume, 0.7);
  const nominal = calculateChangeRate(systemNominalAirflow, totalSystemVolume, 1.0);
  const intensive = calculateChangeRate(systemNominalAirflow, totalSystemVolume, 1.3);
  return {
    moistureControl,
    reduced,
    nominal,
    intensive,
  };
}

function calculateChangeRate(
  systemNominalAirflow: number,
  totalSystemVolume: number,
  airflowFactor: number
): VentilationChangeRate {
  const airflow = systemNominalAirflow * airflowFactor;
  const changeRate = airflow / totalSystemVolume;
  return {
    airflow,
    changeRate,
  };
}
