import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { createRoute, NoParams, UrlMatch } from "../route";

/**
 * How the routing works:
 *
 * The basic building block is a "Route" object which only has to pure functions:
 * - parseUrl(string) => Location
 * - buildUrl(Location) => string
 * Location is a union type of all locations available in the application.
 * Each location type is a data-only object that represents a location within the application.
 * Each location can have its own params which are url-segments or query params.
 *
 * As the application grows, having global routes is not practical becuse we need to
 * match all locations in all parts of the application. Therefore we nest the locations
 * so a root location may have child locations. A nested location has a location prop that
 * will contain the child location. So the root location object will be a wrapper for the
 * child location object. However the Route objects are not nested but they can create a nested location.
 * The URLs are always matched as root urls by the parseUrl/buildUrl functions in the Route
 * but the function can produce a nested/wrapped location.
 * The nested location object helps in the fractal style of nested in of views/state so a parent
 * view can pass the location inside the nested location down to a child view.
 * TL;DR: The locations are nested but the Routes are NOT nested.
 *
 * The parsing and the building of the URL can be generalized (but the parameters handling cannot
 * since the parameters are different for every route).
 * So we only need to provide functions that maps Params->Location and vice versa.
 *
 * Only the top-level init() and update(UrlChanged) should handle the url as a string and parse it.
 * Once it is parsed the rest of the application will only see it as a Location.
 * The location can be passed down in child init() functions and be unwrapped if nested.
 * The child location should not be stored in the child state becuase then we don't have a single source of truth.
 * Instead the location can be stored in sharedstate and each child can have a function to retrieve its location.
 *
 * When the url is changed (regardless of how it was changed) this occurs:
 * 1. The url that is being navigated to is parsed into a Location.
 * 2. The location is stored in state.
 * 3. The view renders based on location stored in state.
 * When you want to build an Url for a location, eg. for use in a <a href="xxx">
 * you call the buildUrl() function passing the location you want.
 *
 */

export const RootLocation = ctorsUnion({
  LoginCallback: () => ({}),
  LoggedOut: () => ({}),
  MainLocation: (location: MainLocation) => ({ location }),
});
export type RootLocation = CtorsUnion<typeof RootLocation>;

export const MainLocation = ctorsUnion({
  UserSettings: () => ({}),
  ProjectList: (location: ProjectListLocation) => ({ location }),
  Project: (projectId: string, location: ProjectLocation) => ({ params: { projectId }, location }),
});
export type MainLocation = CtorsUnion<typeof MainLocation>;

export const ProjectLocation = ctorsUnion({
  General: () => ({}),
  VentilationConcept: () => ({}),
  Edit: () => ({}),
  MaterialList: () => ({}),
  RequestQuote: () => ({}),
  AdditionalDocuments: () => ({}),
  Printout: () => ({}),
});
export type ProjectLocation = CtorsUnion<typeof ProjectLocation>;

export const ProjectListLocation = ctorsUnion({
  ProjectList: () => ({}),
  SharedProjectList: () => ({}),
  SharedByOrganisation: () => ({}),
  RequestedForQuote: () => ({}),
  TemplateProjectList: () => ({}),
});
export type ProjectListLocation = CtorsUnion<typeof ProjectListLocation>;

// This object cannot have an explicit type becuse then type inference for each key is lost
// Each key in this map contains a "Route" which is just a parse and buildUrl function for that route
const rootRoutes = {
  LoginCallback: createRoute("/login-callback", RootLocation.LoginCallback, NoParams),
  LoggedOut: createRoute("/logged-out", RootLocation.LoggedOut, NoParams),
  MainLocation: createRoute(
    "/:rest(.*)",
    (params) => {
      // The rest of the url should be parsed by the main parser....
      const match = parseMainUrl(params["rest"]);
      return match?.location && RootLocation.MainLocation(match.location);
    },
    (location) => {
      const mainUrl = buildMainUrl(location.location);
      return { rest: mainUrl };
    },
    (a) => a,
    false
  ),
};

// This object cannot have an explicit type becuse then type inference for each key is lost
// Each key in this map contains a "Route" which is just a parse and buildUrl function for that route
const mainRoutes = {
  UserSettings: createRoute(
    "user-settings",
    () => MainLocation.UserSettings(),
    () => ({})
  ),
  ProjectList: createRoute(
    "project-list/:rest(.*)",
    (params) => {
      const match = parseProjectListUrl(params["rest"]);
      return match?.location && MainLocation.ProjectList(match.location);
    },
    (location) => {
      const mainUrl = buildProjectListUrl(location.location);
      return { rest: mainUrl };
    }
  ),
  Project: createRoute(
    "project/:projectId/:rest(.*)",
    (params) => {
      // The rest of the url should be parsed by the unit editor parser....
      const match = parseProjectUrl(params["rest"]);
      return match?.location && MainLocation.Project(params["projectId"], match.location);
    },
    (location) => {
      const ueUrl = buildProjectUrl(location.location);
      return { projectId: location.params.projectId, rest: ueUrl };
    },
    (a) => a,
    false
  ),
};

// This object cannot have an explicit type becuse then type inference for each key is lost
// The functions are used to map the params to and from a location
const projectRoutes = {
  General: createRoute(
    "general",
    (_params) => {
      return ProjectLocation.General();
    },
    (_location) => ({})
  ),
  VentilationConcept: createRoute(
    "ventilation-concept",
    (_params) => {
      return ProjectLocation.VentilationConcept();
    },
    (_location) => ({})
  ),
  Edit: createRoute(
    "edit",
    (_params) => {
      return ProjectLocation.Edit();
    },
    (_location) => ({})
  ),
  MaterialList: createRoute(
    "material-list",
    (_params) => {
      return ProjectLocation.MaterialList();
    },
    (_location) => ({})
  ),
  RequestQuote: createRoute(
    "request-quote",
    (_params) => {
      return ProjectLocation.RequestQuote();
    },
    (_location) => ({})
  ),
  AdditionalDocuments: createRoute(
    "additional-documents",
    (_params) => ProjectLocation.AdditionalDocuments(),
    (_location) => ({})
  ),
  Printout: createRoute(
    "printout",
    (_params) => ProjectLocation.Printout(),
    (_location) => ({})
  ),
};

const projectListRoutes = {
  ProjectList: createRoute(
    "my-projects",
    () => ProjectListLocation.ProjectList(),
    () => ({})
  ),
  SharedProjectList: createRoute(
    "shared-by-others",
    () => ProjectListLocation.SharedProjectList(),
    () => ({})
  ),
  SharedByOrganisation: createRoute(
    "shared-by-organisation",
    () => ProjectListLocation.SharedByOrganisation(),
    () => ({})
  ),
  RequestedForQuote: createRoute(
    "requested-for-quote",
    () => ProjectListLocation.RequestedForQuote(),
    () => ({})
  ),
  TemplateProjectList: createRoute(
    "templates",
    () => ProjectListLocation.TemplateProjectList(),
    () => ({})
  ),
};

export function buildProjectListUrl(location: ProjectListLocation): string {
  switch (location.type) {
    case "ProjectList":
      return projectListRoutes.ProjectList.buildUrl(location);
    case "SharedProjectList":
      return projectListRoutes.SharedProjectList.buildUrl(location);
    case "TemplateProjectList":
      return projectListRoutes.TemplateProjectList.buildUrl(location);
    case "SharedByOrganisation":
      return projectListRoutes.SharedByOrganisation.buildUrl(location);
    case "RequestedForQuote":
      return projectListRoutes.RequestedForQuote.buildUrl(location);
    default:
      return exhaustiveCheck(location, true);
  }
}

function buildProjectUrl(location: ProjectLocation): string {
  switch (location.type) {
    case "General":
      return projectRoutes.General.buildUrl(location);
    case "VentilationConcept":
      return projectRoutes.VentilationConcept.buildUrl(location);
    case "Edit":
      return projectRoutes.Edit.buildUrl(location);
    case "MaterialList":
      return projectRoutes.MaterialList.buildUrl(location);
    case "RequestQuote":
      return projectRoutes.RequestQuote.buildUrl(location);
    case "AdditionalDocuments":
      return projectRoutes.AdditionalDocuments.buildUrl(location);
    case "Printout":
      return projectRoutes.Printout.buildUrl(location);
    default:
      return exhaustiveCheck(location, true);
  }
}

function parseProjectUrl(url: string): UrlMatch<ProjectLocation> | undefined {
  for (const p of Object.values(projectRoutes).map((v) => v.parseUrl)) {
    const parseResult = p(url);
    if (parseResult) {
      return parseResult;
    }
  }
  return undefined;
}

function parseProjectListUrl(url: string): UrlMatch<ProjectListLocation> | undefined {
  for (const p of Object.values(projectListRoutes).map((v) => v.parseUrl)) {
    const parseResult = p(url);
    if (parseResult) {
      return parseResult;
    }
  }
  return undefined;
}

function parseMainUrl(url: string): UrlMatch<MainLocation> | undefined {
  for (const p of Object.values(mainRoutes).map((v) => v.parseUrl)) {
    const parseResult = p(url);
    if (parseResult) {
      return parseResult;
    }
  }
  return undefined;
}

export function parseUrl(url: string): UrlMatch<RootLocation> | undefined {
  for (const p of Object.values(rootRoutes).map((v) => v.parseUrl)) {
    const parseResult = p(url);
    if (parseResult) {
      return parseResult;
    }
  }
  return undefined;
}

export function buildUrl(location: RootLocation): string {
  switch (location.type) {
    case "LoggedOut":
      return rootRoutes.LoggedOut.buildUrl(location);
    case "LoginCallback":
      return rootRoutes.LoginCallback.buildUrl(location);
    case "MainLocation":
      return rootRoutes.MainLocation.buildUrl(location);
    default:
      return exhaustiveCheck(location, true);
  }
}

export function buildMainUrl(location: MainLocation): string {
  switch (location.type) {
    case "UserSettings":
      return mainRoutes.UserSettings.buildUrl(location);
    case "ProjectList":
      return mainRoutes.ProjectList.buildUrl(location);
    case "Project":
      return mainRoutes.Project.buildUrl(location);
    default:
      return exhaustiveCheck(location, true);
  }
}
