type ValueOf<T> = T[keyof T];

export const airflowUnits = {
  CubicMeterPerHour: { type: "CubicMeterPerHour", unitName: "m³/h" } as const,
  LiterPerSecond: { type: "LiterPerSecond", unitName: "l/s" } as const,
};
export const lengthUnits = { Meter: { type: "Meter", unitName: "m" } as const };
export const areaUnits = { SquareMeter: { type: "SquareMeter", unitName: "m²" } as const };
export const volumeUnits = { CubicMeter: { type: "CubicMeter", unitName: "m³" } as const };
export const dimensionlessPerDurationUnits = { OnePerHour: { type: "OnePerHour", unitName: "1/h" } as const };

export const Units = {
  ...airflowUnits,
  ...lengthUnits,
  ...areaUnits,
  ...volumeUnits,
  ...dimensionlessPerDurationUnits,
};

export type UnitTypes = ValueOf<typeof Units>;
export type QuantityUnion =
  | typeof airflowUnits
  | typeof lengthUnits
  | typeof areaUnits
  | typeof volumeUnits
  | typeof dimensionlessPerDurationUnits;
export type Unit = keyof typeof Units;
