import { isSupplyRoom, Room, RoomTemplatesTable } from "../../../project";
import { logWarn } from "../../../logger";
import { calculateRoomFloorArea } from "./floor-area";

export function calculateNumberOfPeople(roomTemplateTable: RoomTemplatesTable, room: Room): number | undefined {
  if (!isSupplyRoom(room)) {
    return undefined;
  }

  const template = roomTemplateTable.find((r) => r.key === room.roomType);
  if (!template) {
    logWarn(`calculateNumberOfPeople: Not room template found for room type ${room.roomType}`);
    return undefined;
  }

  if (template.airflow_calculation === "people") {
    return room.people ?? undefined;
  } else if (template.airflow_calculation === "area") {
    const roomArea = calculateRoomFloorArea(roomTemplateTable, room);
    if (roomArea === undefined) {
      return undefined;
    }
    const people = Math.ceil(roomArea / 20);
    return people;
  } else {
    return undefined;
  }
}
