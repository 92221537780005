import { graphQLMutationWithAuth, SharedState } from "@rvs/client-infra";
import { addSystemPdf, removeSystemPdf, replaceSystemPdf } from "@rvs/shared/src/project";
import { Cmd } from "@typescript-tea/core";
import { v4 as uuid } from "uuid";
import { Project } from "@rvs/shared";
import { Action, State } from "../state";
import * as GQLOps from "../../../../generated/generated-operations";
import * as M from "../mutations";

export function CreateSystemPdf(
  action: ReturnType<typeof Action.CreateSystemPdf>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;
  const system = project.systems.find((s) => s.id === action.systemId);
  if (!system) {
    return [state];
  }

  const systemId = action.systemId;
  const newPdf: Project.SystemPdf = {
    id: uuid(),
    name: action.name,
    pdf: action.pdf,
    sortNo: system.additionalDocuments ? system.additionalDocuments.length : 0,
  };

  const updatedProject = addSystemPdf(state.project, systemId, newPdf);

  const newSystemPdfCmd = graphQLMutation<
    GQLOps.ProjectState_CreateSystemPdfMutation,
    GQLOps.ProjectState_CreateSystemPdfMutationVariables,
    Action
  >(
    M.createSystemPdf,
    {
      input: { ...newPdf, systemId },
    },
    sharedState.market.name,
    Action.NoOp
  );
  return [{ ...state, project: updatedProject }, newSystemPdfCmd];
}

export function UpdateSystemPdf(
  action: ReturnType<typeof Action.UpdateSystemPdf>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;
  const system = project.systems.find((s) => s.id === action.systemId);
  if (!system) {
    return [state];
  }
  const pdf = system.additionalDocuments?.find((r) => r.id === action.patch.id);
  if (!pdf) {
    return [state];
  }

  console.log(system.additionalDocuments);

  const systemId = action.systemId;
  const updatedPdf: Project.SystemPdf = { ...pdf, ...action.patch };
  const updatedProject = replaceSystemPdf(project, systemId, updatedPdf);

  const newSystemPdfCmd = graphQLMutation<
    GQLOps.ProjectState_UpdateSystemPdfMutation,
    GQLOps.ProjectState_UpdateSystemPdfMutationVariables,
    Action
  >(
    M.updateSystemPdf,
    {
      input: { ...updatedPdf, system: systemId },
    },
    sharedState.market.name,
    Action.NoOp
  );

  return [{ ...state, project: updatedProject }, newSystemPdfCmd];
}

export function RemoveSystemPdf(
  action: ReturnType<typeof Action.RemoveSystemPdf>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;

  const system = project.systems.find((s) => s.id === action.systemId);
  if (!system) {
    return [state];
  }
  const systemId = action.systemId;
  const updatedProject = removeSystemPdf(project, systemId, action.pdfId);

  const removeCmd = graphQLMutation<
    GQLOps.ProjectState_RemoveSystemPdfMutation,
    GQLOps.ProjectState_RemoveSystemPdfMutationVariables,
    Action
  >(
    M.removeSystemPdf,
    {
      input: {
        id: action.pdfId,
        systemId: action.systemId,
      },
    },
    sharedState.market.name,
    Action.NoOp
  );

  return [{ ...state, project: updatedProject }, removeCmd];
}
