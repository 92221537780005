import { getRoomType, Room } from "../../../project";

export function calculateRoomValveQuantity(room: Room, airflow: number | undefined): number {
  const roomType = getRoomType(room);
  if (room.customValves && room.valves !== null) {
    return room.valves;
  } else if (airflow && (roomType === "extract" || roomType === "supply")) {
    return Math.ceil(airflow / 50);
  } else {
    return 0;
  }
}
