import {
  AirUnitItem,
  calculateDuctRollSystemQuantity,
  Distributor,
  getMaterialsForAirUnit,
  isCompatibleWithDuctDiameter,
  Item,
  ItemType,
} from ".";
import { Calculators, Project, Texts } from "../..";
import { isNoNull, NoNull } from "../../graphql-utils";
import { calculateDistributorSystemQuantity } from "./distributor";
import { MaterialTables } from "./types";
import { ValidationResult, ValidationError } from "../types";
import { calculateSystemValveQuantity } from "./valves";
import { validateChangedMaterialListData } from "../shared/validate-material-list-data";

export function validateMaterialList(
  metaProductQuery: Project.MetaProductQuery,
  market: string,
  materialTables: MaterialTables,
  system: Project.System
): ValidationResult {
  const standardMaterials = system.materials.filter((m) => m.type !== "custom");
  const errors = [];
  const input = Calculators.map(metaProductQuery, materialTables, system, market);
  const requiredAirflow = input && Calculators.calculate(input)?.requiredAirflow;
  for (const material of standardMaterials) {
    const item = materialTables.items[material.itemNumber];
    if (item?.type === "air_unit") {
      errors.push(...validateAirUnit(requiredAirflow, material.id, item));
    }
    if (item) {
      errors.push(...validatePipeDiameter(item, material, system));
    }
  }
  errors.push(...validateValveQuantity(materialTables, system, standardMaterials));
  errors.push(...validateConnectorQuantity(materialTables, standardMaterials));
  errors.push(...validateDuctQuantity(materialTables, system, standardMaterials));
  errors.push(...validateDistributorQuantity(materialTables, system, standardMaterials));
  errors.push(...validateRequiredItems(materialTables, standardMaterials));

  /* TODO: should number of used duct pieces for each room be validated? 
  if (ductQuantity > Number.parseInt(valveConnector.number_of_connections, 10)) {
    logWarn(`Too many ducts for connector, ${ductQuantity}, ${JSON.stringify(valveConnector)}`);
    return undefined;
  }
  */

  errors.push(...validateCustomMaterials(system.materials));

  errors.push(...validateChangedData(materialTables, system));

  const errorsById = errors
    .filter((e) => !!e.materialId)
    .reduce<{ [materialId: string]: ValidationError }>((sofar, error) => {
      sofar[error.materialId!] = error;
      return sofar;
    }, {});

  return {
    errors,
    errorsById,
  };
}

function validateChangedData(materialTables: MaterialTables, system: Project.System): ReadonlyArray<ValidationError> {
  const airUnit = system.materials.find((m) => materialTables.items[m.itemNumber]?.type === "air_unit");
  if (!airUnit) {
    return [];
  }
  const airUnitItemNos = materialTables.tables.airUnitsTable.map((r) => r.item_number || "");
  const materialsForUnit = getMaterialsForAirUnit(materialTables, airUnit, system).map((r) => r.itemNumber);
  const addedPackageItems = new Set(system.materials.filter((m) => !!m.packageName).map((m) => m.itemNumber));
  const packageItems = materialTables.tables.packagesTable
    .filter((i) => addedPackageItems.has(i.item_number || ""))
    .map((i) => i.item_number || "");
  const availableItems = [...materialsForUnit, ...packageItems];
  return validateChangedMaterialListData(airUnitItemNos, availableItems, system);
}

function validateCustomMaterials(materials: ReadonlyArray<Project.Material>): ReadonlyArray<ValidationError> {
  const errors: Array<ValidationError> = [];
  for (const m of materials) {
    if (m.type === "custom" && !m.itemNumber) {
      errors.push({
        category: "missing_item_number",
        materialId: m.id,
        message: Texts.texts.item_number_required,
      });
    }
  }
  return errors;
}

function validateDistributorQuantity(
  { items }: MaterialTables,
  system: Project.System,
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<ValidationError> {
  const ductMaterial = materials.find((m) => items[m.itemNumber]?.type === "duct");
  const duct = ductMaterial && items[ductMaterial.itemNumber];
  if (!duct || duct.type !== "duct" || !isNoNull(duct)) {
    return [];
  }
  const errors: Array<ValidationError> = [];
  for (const material of materials) {
    const item = items[material.itemNumber];
    if (!item) {
      continue;
    }
    if (
      item.type === "distributor" &&
      isNoNull(item) &&
      material.included &&
      calculateDistributorSystemQuantity(item as NoNull<Distributor>, system) !== material.quantity
    ) {
      errors.push({
        category: "quantity",
        materialId: material.id,
        message: Texts.texts.error_distributor_quantity(
          calculateDistributorSystemQuantity(item as NoNull<Distributor>, system)
        ),
      });
    }
  }
  return errors;
}

function validateRequiredItems(
  { items }: MaterialTables,
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<ValidationError> {
  const requiredTypes: ReadonlyArray<ItemType> = ["valve", "valve_connector", "distributor", "air_unit", "duct"];
  const typesIncluded = new Set(
    materials
      .filter((m) => m.included && m.quantity > 0)
      .map((m) => items[m.itemNumber]?.type)
      .filter((type) => !!type)
  );
  const errors: Array<ValidationError> = [];
  for (const material of materials) {
    const item = items[material.itemNumber];
    if (!item) {
      continue;
    }
    if (requiredTypes.some((required) => item.type === required) && !typesIncluded.has(item.type)) {
      errors.push({
        category: "quantity",
        materialId: material.id,
        message: Texts.texts.error_item_is_required,
      });
    }
  }
  return errors;
}

function validateDuctQuantity(
  { items }: MaterialTables,
  system: Project.System,
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<ValidationError> {
  const ductMaterial = materials.find((m) => items[m.itemNumber]?.type === "duct");
  const ductItem = ductMaterial && items[ductMaterial.itemNumber];
  if (ductItem?.type !== "duct" || !isNoNull(ductItem)) {
    return [];
  }
  const quantity = calculateDuctRollSystemQuantity(ductItem, system);
  if (quantity !== undefined && ductMaterial && ductMaterial.quantity !== quantity) {
    return [
      {
        category: "quantity",
        materialId: ductMaterial.id,
        message: Texts.texts.error_duct_quantity(quantity),
      },
    ];
  } else {
    return [];
  }
}

function validateConnectorQuantity(
  { items }: MaterialTables,
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<ValidationError> {
  const numConnectors = materials.reduce((sofar, m) => {
    return items[m.itemNumber]?.type === "valve_connector" && m.included ? sofar + m.quantity : sofar;
  }, 0);
  const numValves = materials.reduce((sofar, m) => {
    return items[m.itemNumber]?.type === "valve" && m.included ? sofar + m.quantity : sofar;
  }, 0);
  if (numConnectors !== numValves) {
    return materials
      .filter((m) => items[m.itemNumber]?.type === "valve_connector" && m.included)
      .map((m) => ({
        category: "quantity",
        materialId: m.id,
        message: Texts.texts.error_valve_connector_quantity(numValves),
      }));
  } else {
    return [];
  }
}

function validateValveQuantity(
  { items }: MaterialTables,
  system: Project.System,
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<ValidationError> {
  const numExtractValves = materials.reduce((sofar, m) => {
    const item = items[m.itemNumber];
    return checkValveType(item, m, false, true) ? sofar + m.quantity : sofar;
  }, 0);

  const numSupplyValves = materials.reduce((sofar, m) => {
    const item = items[m.itemNumber];
    return checkValveType(item, m, true, false) ? sofar + m.quantity : sofar;
  }, 0);

  const numCombinedValves = materials.reduce((sofar, m) => {
    const item = items[m.itemNumber];
    return checkValveType(item, m, true, true) ? sofar + m.quantity : sofar;
  }, 0);

  const requiredQuantity = calculateSystemValveQuantity(system);

  const diffSupplyValves = requiredQuantity.supplyValves - numSupplyValves;
  const diffExtractValves = requiredQuantity.extractValves - numExtractValves;

  const requiredCombined = Math.max(diffSupplyValves, 0) + Math.max(diffExtractValves, 0);
  const diffCombined = numCombinedValves - requiredCombined;

  const quantityOk =
    (diffExtractValves === 0 && diffSupplyValves === 0 && diffCombined === 0) ||
    ((diffExtractValves !== 0 || diffSupplyValves !== 0) && diffCombined === 0);

  if (!quantityOk) {
    return materials
      .filter((m) => items[m.itemNumber]?.type === "valve" && m.included)
      .map((m) => ({
        category: "quantity",
        materialId: m.id,
        message: Texts.texts.error_valve_quantity(requiredQuantity.supplyValves, requiredQuantity.extractValves),
      }));
  } else {
    return [];
  }
}

function checkValveType(
  item: Item | undefined,
  material: Project.Material,
  requireSupply: boolean,
  requireExtract: boolean
): boolean {
  if (item?.type !== "valve" || !material.included) {
    return false;
  }
  if (requireSupply && requireExtract) {
    return item.supply_air === "1" && item.extract_air === "1";
  } else if (requireSupply) {
    return item.supply_air === "1" && item.extract_air === "0";
  } else if (requireExtract) {
    return item.supply_air === "0" && item.extract_air === "1";
  } else {
    return false;
  }
}

function validatePipeDiameter(
  item: Item,
  material: Project.Material,
  system: Project.System
): ReadonlyArray<ValidationError> {
  if (!isCompatibleWithDuctDiameter(item, system.pipeDiameter)) {
    return [
      {
        category: "duct_diameter",
        materialId: material.id,
        message: Texts.texts.error_wrong_duct_diameter,
      },
    ];
  } else {
    return [];
  }
}

function validateAirUnit(
  requiredAirflow: number | undefined,
  materialId: string,
  airUnit: AirUnitItem
): ReadonlyArray<ValidationError> {
  if (airUnit.min_airflow === null || airUnit.max_airflow === null) {
    return [];
  }

  if (requiredAirflow === undefined) {
    return [];
  }

  if (requiredAirflow < airUnit.min_airflow || requiredAirflow > airUnit.max_airflow) {
    return [
      {
        category: "wrong_air_unit",
        materialId,
        message: Texts.texts.error_air_unit_range(airUnit.min_airflow, airUnit.max_airflow),
      },
    ];
  }

  return [];
}
