import * as R from "ramda";
import gql from "graphql-tag";
import { GraphQlUtils } from "../..";
import { QueryGenerator, graphQLProductQuery } from "../../query";
import * as GQLOps from "../../generated/generated-operations";
import { MaterialTables } from "./types";
import { Item } from ".";
import { PackageItem } from "..";
import { createCategories } from "../shared/categories";

export const materialQuery = gql`
  query MaterialList_materialProduct($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        custom_tables {
          ...MaterialList_materialTables
        }
      }
    }
  }
  fragment MaterialList_materialTables on Module_custom_tables {
    AirUnits {
      item_number
      min_airflow
      max_airflow
    }
    AirUnitAlternatives {
      air_unit_item_number
      alternative_air_unit_item_number
    }
    AirUnitArticles {
      air_unit_item_number
      article_item_number
      required
      quantity
    }
    Accessory {
      item_number
      duct_63
      duct_75
      category
    }
    Distributor {
      item_number
      duct_63
      duct_75
      number_of_connections
    }
    Duct {
      item_number
      duct_63
      duct_75
      max_airflow
      quantity
    }
    Valve {
      item_number
      duct_63
      duct_75
      supply_air
      extract_air
    }
    ValveConnector {
      item_number
      duct_63
      duct_75
      number_of_connections
    }
    Packages {
      package_name
      item_number
      group
      category
      quantity
    }
    Categories {
      sort_no
      group
      category
    }
  }
`;

export async function getMaterials(
  graphQlProductQuery: GraphQlUtils.GraphQlQueryFn,
  materialProductId: string
): Promise<MaterialTables> {
  const materialProductResponse = await graphQlProductQuery<
    GQLOps.MaterialList_MaterialProductQuery,
    GQLOps.MaterialList_MaterialProductQueryVariables
  >(materialQuery, { productId: materialProductId });
  return getTables(materialProductResponse);
}

export function* getMaterialsYield(materialProductId: string): QueryGenerator<MaterialTables> {
  const materialProductResponse = yield* graphQLProductQuery<
    GQLOps.MaterialList_MaterialProductQuery,
    GQLOps.MaterialList_MaterialProductQueryVariables
  >(materialQuery, { productId: materialProductId });
  return getTables(materialProductResponse);
}

function getTables(materialProductResponse: GQLOps.MaterialList_MaterialProductQuery | undefined): MaterialTables {
  const categoriesTable = materialProductResponse?.product?.modules.custom_tables.Categories || [];
  const airUnitsTable = materialProductResponse?.product?.modules.custom_tables.AirUnits;
  const airUnitAlternativesTable = materialProductResponse?.product?.modules.custom_tables.AirUnitAlternatives;
  const airUnitArticlesTable = materialProductResponse?.product?.modules.custom_tables.AirUnitArticles;
  const accessoryTable = materialProductResponse?.product?.modules.custom_tables.Accessory;
  const distributorTable = materialProductResponse?.product?.modules.custom_tables.Distributor;
  const ductTable = materialProductResponse?.product?.modules.custom_tables.Duct;
  const valveTable = materialProductResponse?.product?.modules.custom_tables.Valve;
  const valveConnectorTable = materialProductResponse?.product?.modules.custom_tables.ValveConnector;
  const packagesTable = materialProductResponse?.product?.modules.custom_tables.Packages;
  if (
    !airUnitsTable ||
    !airUnitArticlesTable ||
    !accessoryTable ||
    !distributorTable ||
    !ductTable ||
    !valveTable ||
    !valveConnectorTable ||
    !airUnitAlternativesTable ||
    !packagesTable
  ) {
    throw new Error("Failed to query product data");
  }
  const items: { [itemNumber: string]: Item } = {};
  airUnitsTable.forEach((i) => (items[i.item_number || ""] = { type: "air_unit", ...i }));
  accessoryTable.forEach((i) => (items[i.item_number || ""] = { type: "accessory", ...i }));
  distributorTable.forEach((i) => (items[i.item_number || ""] = { type: "distributor", ...i }));
  ductTable.forEach((i) => (items[i.item_number || ""] = { type: "duct", ...i }));
  valveTable.forEach((i) => (items[i.item_number || ""] = { type: "valve", ...i }));
  valveConnectorTable.forEach((i) => (items[i.item_number || ""] = { type: "valve_connector", ...i }));
  const itemPackages = R.groupBy(
    (r) => r.packageName,
    packagesTable
      .filter((p) => !!p.package_name && !!p.item_number)
      .map(
        (p, i): PackageItem => ({
          sortNo: i,
          packageName: p.package_name || "",
          itemNumber: p.item_number || "",
          group: p.group || undefined,
          category: p.category || undefined,
          quantity: p.quantity || 0,
        })
      )
  );

  const sortNos = [
    ...airUnitsTable,
    ...valveTable,
    ...valveConnectorTable,
    ...distributorTable,
    ...ductTable,
    ...accessoryTable,
    ...packagesTable,
  ].reduce((sofar, item, i) => {
    sofar.set(item.item_number || "", i);
    return sofar;
  }, new Map<string, number>());

  return {
    type: "germany",
    tables: {
      airUnitsTable,
      airUnitArticlesTable,
      airUnitAlternativesTable,
      accessoryTable,
      distributorTable,
      ductTable,
      valveTable,
      valveConnectorTable,
      packagesTable,
    },
    items,
    itemPackages,
    sortNos,
    categories: createCategories(categoriesTable),
  };
}
