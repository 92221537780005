import * as React from "react";
import { AbstractDocJsx as ADX, AbstractDoc as AD } from "abstract-document";
import * as Texts from "../../lang-texts";
import * as Common from "../common";
import { CreatorProps } from "./types";
import { boldText, textRun, h1, h2 } from "../common/elements";
import { renderValue } from "../../calculators/shared/render-value";
import { table, tableHeadingWithUnit } from "../common";

const texts = Texts.texts;
const { AbstractDoc, Section, Paragraph, TextRun, Table, TableRow, TableCell, render } = ADX;

export function create(props: CreatorProps): AD.AbstractDoc.AbstractDoc {
  const styles = Common.styles();
  const numberingDefinitions = Common.numberingDefinitions(styles);
  const fonts = Common.fonts(props.pageProps.fonts);
  const { result, translate } = props;
  const { rooms, requiredAirflow, totalArea, airflowForTotalArea, extractRoomsArea, extractRoomsAirflow } =
    result || {};

  // Extract rooms table
  const roomColumns: ReadonlyArray<Common.TableCellHeading> = [
    { value: translate(texts.report_room_type_description), width: Infinity, bold: true },
    { value: translate(texts.floor), width: 75, align: "Start", bold: true },
    { value: tableHeadingWithUnit(translate(texts.room_area), "m²"), width: 75 },
    { value: tableHeadingWithUnit(translate(texts.air_flow), "m³/h"), width: 75 },
  ];
  const roomRows = (rooms || []).map(
    (room): ReadonlyArray<Common.TableCell> => [
      { value: `${translate(Texts.key(room.type))}${room.name ? ` (${room.name})` : ""}` },
      { value: translate(Texts.key(room.floor)) },
      { value: renderValue(room.area), align: "Center" },
      { value: renderValue(room.airflow), align: "Center" },
    ]
  );
  const roomSumRow: ReadonlyArray<Common.TableCell> = [
    { value: "" },
    { value: "" },
    { value: renderValue(extractRoomsArea), bold: true, align: "Center" },
    { value: renderValue(extractRoomsAirflow), bold: true, align: "Center" },
  ];

  // Airflows table
  const airflowResults = props.result?.airflowResults;
  const afColumns: ReadonlyArray<Common.TableCellHeading> = [
    { value: translate(texts.report_ventilation_type), width: Infinity, bold: false },
    { value: translate(texts.report_ventilation_moisture), width: 75, align: "Center", bold: false },
    { value: translate(texts.report_ventilation_reduced), width: 75, align: "Center", bold: false },
    { value: translate(texts.report_ventilation_nominal), width: 75, align: "Center", bold: true },
    { value: translate(texts.report_ventilation_intensive), width: 75, align: "Center", bold: false },
  ];
  const afRows: ReadonlyArray<ReadonlyArray<Common.TableCellHeading>> = [
    [
      { value: `${translate(texts.report_airflow)} [m³/h]` },
      { value: renderValue(airflowResults?.moistureControlAirflow?.airflow, 0), align: "Center" },
      { value: renderValue(airflowResults?.reducedAirflow.airflow, 0), align: "Center" },
      { value: renderValue(airflowResults?.nominalAirflow.airflow, 0), align: "Center" },
      { value: renderValue(airflowResults?.intensiveAirflow.airflow, 0), align: "Center" },
    ],
    [
      { value: `${translate(texts.report_air_change)} [1/h]` },
      { value: renderValue(airflowResults?.moistureControlAirflow?.changeRate), align: "Center" },
      { value: renderValue(airflowResults?.reducedAirflow.changeRate), align: "Center" },
      { value: renderValue(airflowResults?.nominalAirflow.changeRate), align: "Center" },
      { value: renderValue(airflowResults?.intensiveAirflow.changeRate), align: "Center" },
    ],
  ];

  const doc = render(
    <AbstractDoc fonts={fonts} styles={styles} numberingDefinitions={numberingDefinitions}>
      <Section id={Common.pageId(props.pageProps)} page={Common.cataloguePage(props.pageProps)}>
        {h1(translate(texts.report_nominal_airflow_heading))}
        {!result ? textRun(translate(texts.report_calculation_error_note)) : <Paragraph />}
        {h2(translate(texts.report_airflow_area_note))}
        {dataRow(translate(texts.area), totalArea, "m²")}
        {dataRow(translate(texts.report_nominal_airflow), airflowForTotalArea, "m³/h")}
        {h2(translate(texts.report_exhaust_airflow_sum_note))}
        {table(roomColumns, [...roomRows, roomSumRow], "standard")}
        {h2(translate(texts.report_ventilation_results_heading))}
        {table(afColumns, afRows, "standard")}
        {h2(translate(texts.report_unit_airflow_heading))}
        {dataRow(translate(texts.report_unit_airflow), requiredAirflow, "m³/h")}
        {boldText(translate(texts.report_ventilation_nominal_af_note))}
      </Section>
    </AbstractDoc>
  );
  return doc;
}

function dataRow(parameter: string, value: number | undefined, unit: string, decimals?: number): JSX.Element {
  return (
    <Table columnWidths={[Infinity, 100]}>
      <TableRow>
        <TableCell>
          <Paragraph>
            <TextRun text={parameter} />
          </Paragraph>
        </TableCell>
        <TableCell>
          <Paragraph style={AD.ParagraphStyle.create({ alignment: "End" })}>
            <TextRun text={`${renderValue(value, decimals)}${unit ? ` ${unit}` : ""}`} />
          </Paragraph>
        </TableCell>
      </TableRow>
    </Table>
  );
}
