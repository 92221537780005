import * as React from "react";
import { AbstractDocJsx as ADX, AbstractDoc as AD } from "abstract-document";
import { CreatorType } from "./types";
import * as Common from "../common";
import { createNote, table, tableMultHeadings } from "../common";
import { texts, TranslateFn } from "../../lang-texts";
import { Project, Texts } from "../..";
import { isExtractRoom, isSupplyRoom, Room } from "../../project";
import { h1, h2, textRun } from "../common/elements";
import { roundTo } from "../../utils";

const { AbstractDoc, Section, Paragraph, TextRun, Group, Table, TableRow, TableCell, Image, Markdown, render } = ADX;

type RoomProperty = keyof Project.Room;

export const create: CreatorType = ({ pageProps, translate, system }) => {
  const styles = Common.styles();
  const numberingDefinitions = Common.numberingDefinitions(styles);
  const fonts = Common.fonts(pageProps.fonts);

  const margin = (
    <Paragraph style={AD.ParagraphStyle.create({ margins: AD.LayoutFoundation.create({ top: 5 }) })}>
      <TextRun text="" />
    </Paragraph>
  );

  const doc = render(
    <AbstractDoc fonts={fonts} styles={styles} numberingDefinitions={numberingDefinitions}>
      <Section id={Common.pageId(pageProps)} page={Common.cataloguePage(pageProps)}>
        {h1(translate(texts.room))}

        <Group keepTogether={true}>
          {h2(translate(texts.supply))}
          {margin}
          {system ? createSystemTable(translate, "supply", system) : []}
          {margin}
          {margin}
          {h2(translate(texts.extract))}
          {margin}
          {system ? createSystemTable(translate, "extract", system) : []}
        </Group>
        {margin}
        {margin}

        <Group keepTogether={true}>
          {h2(translate(texts.over_pressure))}
          {margin}
          {overPressureTable(translate)}
          {textRun(translate(texts.over_pressure_note))}
          {margin}
          {infoNote(translate)}
        </Group>
      </Section>
    </AbstractDoc>
  );
  return doc;
};

function createSystemTable(translate: TranslateFn, type: "supply" | "extract", system: Project.System): JSX.Element {
  const rooms = system.rooms.filter((room) => (type === "supply" ? isSupplyRoom(room) : isExtractRoom(room)));

  const hasWidth = !!rooms.find((room) => room.width);
  const hasLength = !!rooms.find((room) => room.length);
  const hasPeople = !!rooms.find((room) => room.people);
  const hasSupply = !!rooms.find((room) => room.supplyAirFactor);

  const roomHeader = getRoomHeaderRow(translate, hasWidth, hasLength, hasPeople, hasSupply);
  const roomRows = rooms.map((room) => createRoomRow(translate, room, hasWidth, hasLength, hasPeople, hasSupply));
  const totalHeader = roomHeader.map((header) => header.width || Infinity);
  const totalRow = calculateTotals(rooms, hasSupply, translate) || [];
  return (
    <Group>
      {table(roomHeader, roomRows, "noMargin")}
      {tableMultHeadings([], [totalRow], totalHeader, "noMarginBorderTop")}
    </Group>
  );
}

function createRoomRow(
  translate: TranslateFn,
  room: Room,
  hasWidth: boolean,
  hasLength: boolean,
  hasPeople: boolean,
  hasSupply: boolean
): ReadonlyArray<Common.TableCell> {
  const roomRow = [
    { key: "name", value: room.name },
    { key: "roomType", value: translate(Texts.key(room.roomType)) },
    { key: "airType", value: translate(Texts.key(room.airType)) },
    { key: "floorType", value: translate(Texts.key(room.floorType)) },
    { key: "supplyAirFactor", value: room.supplyAirFactor?.toString() || "" },
    { key: "airflow", value: roundTo(room.airflow, 2).toString() },
    { key: "floorArea", value: room.floorArea?.toString() || "" },
    { key: "ceilingHeight", value: room.ceilingHeight.toString() },
    { key: "width", value: room.width?.toString() || "" },
    { key: "length", value: room.length?.toString() || "" },
    { key: "people", value: room.people?.toString() || "" },
    { key: "valves", value: room.valves?.toString() || "" },
  ]
    .filter(
      (h) =>
        (hasSupply ? true : h.key !== "supplyAirFactor") &&
        (hasWidth ? true : h.key !== "width") &&
        (hasLength ? true : h.key !== "length") &&
        (hasPeople ? true : h.key !== "people")
    )
    .map((cell) => ({ ...cell, padding: { top: 2, left: 2 } }));
  return roomRow;
}

function calculateTotals(
  rooms: ReadonlyArray<Room>,
  hasSupply: boolean,
  translate: TranslateFn
): ReadonlyArray<Common.TableCell> {
  const floorArea = rooms.reduce((sum, room) => (room.floorArea || 0) + sum, 0);
  const airflow = rooms.reduce((sum, room) => (room.airflow || 0) + sum, 0);

  return [
    { value: translate(Texts.texts.sum), colSpan: hasSupply ? 5 : 4, noBackground: true },
    { value: roundTo(airflow, 2).toString(), padding: { top: 2, left: 2 }, noBackground: true },
    { value: roundTo(floorArea, 2).toString(), padding: { top: 2, left: 2 }, noBackground: true },
    { value: "", colSpan: 2, noBackground: true },
  ];
}

function getRoomHeaderRow(
  translate: TranslateFn,
  hasWidth: boolean,
  hasLength: boolean,
  hasPeople: boolean,
  hasSupply: boolean
): ReadonlyArray<Common.TableCellHeading> {
  const translateLabel = (property: RoomProperty): string => translate(texts.property(property));

  const roomHeader = [
    { key: "name", value: translateLabel("name"), width: Infinity },
    { key: "roomType", value: translateLabel("roomType"), width: Infinity },
    { key: "airType", value: translateLabel("airType"), width: Infinity },
    { key: "floorType", value: translateLabel("floorType"), width: Infinity },
    { key: "supplyAirFactor", value: translateLabel("supplyAirFactor"), width: Infinity },
    { key: "airflow", value: translateLabel("airflow"), width: Infinity },
    { key: "floorArea", value: translateLabel("floorArea"), width: Infinity },
    { key: "ceilingHeight", value: translateLabel("ceilingHeight"), width: Infinity },
    { key: "width", value: translateLabel("width"), width: Infinity },
    { key: "length", value: translateLabel("length"), width: Infinity },
    { key: "people", value: translateLabel("people"), width: Infinity },
    { key: "valves", value: translateLabel("valves"), width: Infinity },
  ]
    .filter(
      (h) =>
        (hasSupply ? true : h.key !== "supplyAirFactor") &&
        (hasWidth ? true : h.key !== "width") &&
        (hasLength ? true : h.key !== "length") &&
        (hasPeople ? true : h.key !== "people")
    )
    .map((header) => ({ ...header, padding: { top: 2, left: 2 } }));

  return roomHeader;
}

function overPressureTable(translate: TranslateFn): JSX.Element {
  const padding = { top: 3, bottom: 1 };
  const headerRows: readonly Common.TableCellHeading[] = [
    {
      value: translate(texts.over_pressure_flow),
      width: Infinity,
      align: "Start",
      padding: padding,
      verticalAlign: "Middle",
    },
    { value: "10", width: 40, align: "Start", padding: padding, verticalAlign: "Middle" },
    { value: "20", width: 40, align: "Start", padding: padding, verticalAlign: "Middle" },
    { value: "30", width: 40, align: "Start", padding: padding, verticalAlign: "Middle" },
    { value: "40", width: 40, align: "Start", padding: padding, verticalAlign: "Middle" },
    { value: "50", width: 40, align: "Start", padding: padding, verticalAlign: "Middle" },
    { value: "60", width: 40, align: "Start", padding: padding, verticalAlign: "Middle" },
  ];
  const cells: readonly Common.TableCell[][] = [
    [
      {
        noBackground: true,
        padding: { left: 0, right: 0 },
        value: tableMultHeadings(
          [],
          [
            [
              { value: translate(texts.doors_with_seals), align: "Start", padding: padding, verticalAlign: "Middle" },
              {
                value: translate(texts.free_minimum_area_cm2),
                rowSpan: 2,
                align: "Start",
                padding: padding,
                verticalAlign: "Middle",
              },
            ],
            [
              {
                value: translate(texts.doors_without_seals),
                align: "Start",
                padding: padding,
                verticalAlign: "Middle",
                backgroundColor: "#ffffff",
              },
            ],
          ],
          [Infinity, Infinity],
          "noMargin"
        ),
      },
      {
        noBackground: true,
        padding: { left: 0, right: 0 },
        colSpan: 6,
        value: tableMultHeadings(
          [],
          [
            [
              { value: "25", align: "Start", padding: padding, verticalAlign: "Middle" },
              { value: "50", align: "Start", padding: padding, verticalAlign: "Middle" },
              { value: "75", align: "Start", padding: padding, verticalAlign: "Middle" },
              { value: "100", align: "Start", padding: padding, verticalAlign: "Middle" },
              { value: "125", align: "Start", padding: padding, verticalAlign: "Middle" },
              { value: "150", align: "Start", padding: padding, verticalAlign: "Middle" },
            ],
            [
              { value: "0", align: "Start", padding: padding, verticalAlign: "Middle" },
              { value: "25", align: "Start", padding: padding, verticalAlign: "Middle" },
              { value: "50", align: "Start", padding: padding, verticalAlign: "Middle" },
              { value: "75", align: "Start", padding: padding, verticalAlign: "Middle" },
              { value: "100", align: "Start", padding: padding, verticalAlign: "Middle" },
              { value: "125", align: "Start", padding: padding, verticalAlign: "Middle" },
            ],
          ],
          [40, 40, 40, 40, 40, 40],
          "noMargin"
        ),
      },
    ],
    [],
  ];
  return table(headerRows, cells, "standard");
}

function infoNote(translate: TranslateFn): JSX.Element {
  const noteImage = createNote();
  return (
    <Table
      columnWidths={[10, 70, Infinity]}
      style={AD.TableStyle.create({ margins: AD.LayoutFoundation.create({ top: 10 }) })}
    >
      <TableRow>
        <TableCell></TableCell>
        <TableCell>
          <Paragraph>
            <Image
              imageResource={noteImage}
              width={noteImage.abstractImage.size.width}
              height={noteImage.abstractImage.size.height}
            />
          </Paragraph>
        </TableCell>
        <TableCell>
          <Markdown text={translate(texts.positioning_supply_exhaust_diffusers)} />
        </TableCell>
      </TableRow>
    </Table>
  );
}
