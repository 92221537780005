import gql from "graphql-tag";

export const schema = gql`
  extend type Mutation {
    createProjectShare(input: CreateProjectShareInput!): CreateProjectSharePayload!
    removeProjectShare(input: RemoveProjectShareInput!): RemoveProjectSharePayload!
    updateProjectShare(input: UpdateProjectShareInput!): UpdateProjectSharePayload!
  }

  type ProjectShare {
    id: ID!
    project: ID!
    email: String
    permissions: String
  }

  input CreateProjectShareInput {
    id: ID!
    projectId: ID!
    email: String!
    permissions: String!
  }

  type CreateProjectSharePayload {
    projectShare: ProjectShare!
  }

  input RemoveProjectShareInput {
    id: ID!
    email: String!
  }

  type RemoveProjectSharePayload {
    id: ID!
  }

  input UpdateProjectShareInput {
    id: ID!
    email: String!
    permissions: String!
  }

  type UpdateProjectSharePayload {
    id: ID!
  }
`;
