import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { saveAs } from "file-saver";
import { Cmd } from "@typescript-tea/core";
import { Calculators, Crm, Markets, Materials, Project } from "@rvs/shared";
import { SharedState, graphQLMutationWithAuth, Patch, HttpFetch } from "@rvs/client-infra";
import * as GQLOps from "../../../generated/generated-operations";
import * as M from "./mutations";
import {
  MaterialActions,
  ProjectActions,
  RoomActions,
  SystemActions,
  SystemPdfActions,
  VentilationConceptActions,
  QuoteRequestActions,
} from "./actions";

export interface SystemCalculationState {
  readonly input: Calculators.Input;
  readonly validation: Calculators.ValidationOutput;
  readonly result: Calculators.Result;
  readonly fields: Calculators.Fields;
  readonly uiUpdates: ReadonlyArray<Project.Patch<Project.Room>>;
}

export interface MaterialListUpdateState {
  readonly type: "list-update";
  readonly createdDone: boolean;
  readonly updatedDone: boolean;
  readonly removedDone: boolean;
}

export interface MaterialPriceUpdateState {
  readonly type: "price-update";
}

export type MaterialsState = MaterialListUpdateState | MaterialPriceUpdateState;

export interface State {
  readonly metaProduct: Project.MetaProductQuery;
  readonly materialTables: Materials.MaterialTables;
  readonly project: Project.Project;

  // System duplicate button
  readonly systemsBeingDuplicated: ReadonlySet<string>;

  // Calculation
  readonly calculationStates: { readonly [systemId: string]: SystemCalculationState };

  // Crm
  readonly exportingToCrm: boolean;
  readonly crmExportResponse: Crm.ExportResponse | undefined;

  // Ventilation concept
  readonly creatingVcIdsInProgress: ReadonlyArray<string>;
  readonly windyRegions: ReadonlySet<string>;

  // Materials
  readonly disabledCustomMaterials: ReadonlySet<string>;
  readonly priceErrors: ReadonlySet<string>;
  readonly materialsState: {
    readonly [systemId: string]: MaterialsState | undefined;
  };
  readonly lastPriceUpdate: number;
  readonly lastPriceUpdateMarket: Markets.Market | undefined;
  readonly lastPriceUpdateCustomerNumber: string | null | undefined;

  // Request quote
  readonly requestQuoteStatus: "not_sent" | "sending" | "sent_ok" | "sent_error";
}

type ProjectPatch = Omit<Patch<Project.Project>, "id">;
interface RoomListSo {
  readonly id: string;
  readonly sortNo: number;
}

export const Action = ctorsUnion({
  // Project
  RemoveProject: () => ({}),
  RemoveProjectResponse: () => ({}),
  UpdateProject: (patch: ProjectPatch) => ({ patch }),
  UpdateProjectLockState: (locked: boolean) => ({ locked }),
  ProjectUnlockResponse: (locked: boolean, permissions: Project.Permissions) => ({ locked, permissions }),

  // Project share
  ShareProject: (email: string, permissions: Project.Permissions) => ({ email, permissions }),
  RemoveShare: (projectId: string, shareEmail: string) => ({ projectId, shareEmail }),

  // System
  CreateSystem: (name: string | undefined) => ({ name }),
  RemoveSystem: (systemId: string) => ({ systemId }),
  UpdateSystem: (patch: Patch<GQLOps.UpdateSystemInput>) => ({ patch }),
  DuplicateSystem: (systemId: string, newSystemId: string) => ({ systemId, newSystemId }),
  DuplicateSystemPostAction: (newSystem: GQLOps.ProjectState_DuplicateSystemMutation, oldSystemId: string) => ({
    newSystem,
    oldSystemId,
  }),

  // Room
  CreateRoom: (systemId: string, name: string | undefined) => ({ systemId, name }),
  RemoveRoom: (systemId: string, roomId: string) => ({ systemId, roomId }),
  UpdateRoom: (systemId: string, patch: Patch<GQLOps.Room>) => ({
    systemId,
    patch,
  }),
  DuplicateRoom: (systemId: string, roomId: string) => ({ systemId, roomId }),

  UpdateRoomSo: (systemId: string, roomList: ReadonlyArray<RoomListSo>) => ({
    systemId,
    roomList,
  }),

  // Materials
  UpdateMaterialListItem: (systemId: string, patch: Patch<Project.Material>) => ({ systemId, patch }),
  UpdateCustomMaterial: (systemId: string, materialId: string, itemNumber: string) => ({
    systemId,
    materialId,
    itemNumber,
  }),
  CreateMaterialListForUnit: (systemId: string, selectedAirUnit: Project.Material) => ({ systemId, selectedAirUnit }),
  UpdateMaterialListQuantities: (systemId: string) => ({
    systemId,
  }),
  UpdateMaterialListAddNewItems: (systemId: string, selectedAirUnit: Project.Material) => ({
    systemId,
    selectedAirUnit,
  }),
  MaterialListUpdateDone: (systemId, update: "created" | "updated" | "deleted") => ({ systemId, update }),
  UpdatePrices: (systemId: string) => ({ systemId }),
  ReceivedUpdatedPrices: (mutation: GQLOps.ProjectState_UpdateSystemPricesMutation) => ({ mutation }),
  ReceivedUpdatedCustomMaterialPrice: (mutation: GQLOps.ProjectState_UpdateCustomMaterialMutation) => ({ mutation }),
  AddCustomMaterial: (systemId: string) => ({ systemId }),
  RemoveMaterial: (systemId: string, materialId: string) => ({ systemId, materialId }),
  AddMaterialPackage: (systemId: string, packageName: string) => ({ systemId, packageName }),
  RemoveMaterialPackage: (systemId: string, packageName: string) => ({ systemId, packageName }),

  // Ventilation concept
  SetDefaultsVentilationConcept: () => ({}),
  CreateVentilationConceptIfNotExists: () => ({}),
  CreateVentilationConceptIfNotExistsResponse: (vcId: string) => ({ vcId }),
  UpdateVentilationConceptProject: (patch: Patch<Project.VentilationConceptProject>) => ({ patch }),
  UpdateVentilationConceptSystem: (patch: Patch<Project.VentilationConceptSystem>) => ({ patch }),

  // System PDF
  CreateSystemPdf: (systemId: string, name: string, pdf: string) => ({ systemId, name, pdf }),
  RemoveSystemPdf: (systemId: string, pdfId: string) => ({ systemId, pdfId }),
  UpdateSystemPdf: (systemId: string, patch: Patch<GQLOps.SystemPdf>) => ({
    systemId,
    patch,
  }),

  // Crm
  ExportToCrm: (envelopeOnly: boolean) => ({ envelopeOnly }),
  HandleCrmResponse: (response: Crm.Response) => ({ response }),

  // Request quote
  RequestQuote: () => ({}),
  RequestQuoteResponseReceived: (mutation: GQLOps.ProjectState_CreateQuoteRequestMutation) => ({ mutation }),

  NoOp: () => ({}),
});
export type Action = CtorsUnion<typeof Action>;

export function init(
  sharedState: SharedState.SharedState,
  project: Project.Project,
  metaProduct: Project.MetaProductQuery,
  windyRegions: ReadonlySet<string>,
  materialTables: Materials.MaterialTables
): readonly [State, Cmd<Action>?] {
  let calcuationStates = {};
  for (const system of project.systems) {
    const { updatedCalcState, roomPatches } = calculateSystem(
      sharedState.market.name,
      metaProduct,
      materialTables,
      project,
      calcuationStates,
      system.id
    );
    calcuationStates = updatedCalcState;
    if (roomPatches.length > 0 && !Project.isProjectReadOnly(project)) {
      // The project is supposed to be recalculated before this function is called.
      // Throw an exception because the client state doesn't match the server state.
      throw new Error("Project not initialized correctly");
    }
  }

  const state: State = {
    project,
    metaProduct,
    materialTables,
    calculationStates: calcuationStates,
    exportingToCrm: false,
    crmExportResponse: undefined,
    creatingVcIdsInProgress: [],
    disabledCustomMaterials: new Set(),
    materialsState: {},
    lastPriceUpdate: 0,
    lastPriceUpdateMarket: undefined,
    lastPriceUpdateCustomerNumber: undefined,
    systemsBeingDuplicated: new Set(),
    priceErrors: new Set(),
    windyRegions: windyRegions,
    requestQuoteStatus: "not_sent",
  };

  const [stateWithUpdate, priceCmd] = doPriceUpdate(
    project.systems.map((s) => s.id),
    project,
    state,
    sharedState,
    false
  );

  return [stateWithUpdate, priceCmd];
}

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const { project } = state;
  switch (action.type) {
    case "RemoveProject": {
      return ProjectActions.RemoveProject(action, state, sharedState);
    }
    case "RemoveProjectResponse": {
      return ProjectActions.RemoveProjectResponse(action, state, sharedState);
    }
    case "UpdateProject": {
      return ProjectActions.UpdateProject(action, state, sharedState);
    }
    case "UpdateProjectLockState": {
      return ProjectActions.UpdateProjectLockState(action, state, sharedState);
    }
    case "ProjectUnlockResponse": {
      return [{ ...state, project: { ...project, locked: action.locked, permissions: action.permissions } }];
    }
    case "ShareProject": {
      return ProjectActions.ShareProject(action, state, sharedState);
    }
    case "RemoveShare": {
      return ProjectActions.RemoveShare(action, state, sharedState);
    }
    case "CreateSystem": {
      return SystemActions.CreateSystem(action, state, sharedState);
    }
    case "RemoveSystem": {
      return SystemActions.RemoveSystem(action, state, sharedState);
    }
    case "UpdateSystem": {
      return SystemActions.UpdateSystem(action, state, sharedState);
    }
    case "DuplicateSystem": {
      return SystemActions.DuplicateSystem(action, state, sharedState);
    }
    case "DuplicateSystemPostAction": {
      return SystemActions.DuplicateSystemPostAction(action, state, sharedState);
    }
    case "CreateRoom": {
      return RoomActions.CreateRoom(action, state, sharedState);
    }
    case "RemoveRoom": {
      return RoomActions.RemoveRoom(action, state, sharedState);
    }
    case "UpdateRoom": {
      return RoomActions.UpdateRoom(action, state, sharedState);
    }
    case "DuplicateRoom": {
      return RoomActions.DuplicateRoom(action, state, sharedState);
    }
    case "UpdateMaterialListItem": {
      return MaterialActions.UpdateMaterialListItem(action, state, sharedState);
    }
    case "UpdateCustomMaterial": {
      return MaterialActions.UpdateCustomMaterial(action, state, sharedState);
    }
    case "CreateMaterialListForUnit": {
      return MaterialActions.CreateMaterialListForUnit(action, state, sharedState);
    }
    case "UpdateMaterialListQuantities": {
      return MaterialActions.UpdateMaterialListQuantities(action, state, sharedState);
    }
    case "UpdateMaterialListAddNewItems": {
      return MaterialActions.UpdateMaterialListAddNewItems(action, state, sharedState);
    }
    case "MaterialListUpdateDone": {
      return MaterialActions.MaterialListUpdateDone(action, state, sharedState);
    }
    case "UpdatePrices": {
      return doPriceUpdate([action.systemId], project, state, sharedState, true);
    }
    case "ReceivedUpdatedPrices": {
      return MaterialActions.ReceivedUpdatedPrices(action, state, sharedState);
    }
    case "ReceivedUpdatedCustomMaterialPrice": {
      return MaterialActions.ReceivedUpdatedCustomMaterialPrice(action, state, sharedState);
    }
    case "AddCustomMaterial": {
      return MaterialActions.AddCustomMaterial(action, state, sharedState);
    }
    case "RemoveMaterial": {
      return MaterialActions.RemoveMaterial(action, state, sharedState);
    }
    case "AddMaterialPackage": {
      return MaterialActions.AddMaterialPackage(action, state, sharedState);
    }
    case "RemoveMaterialPackage": {
      return MaterialActions.RemoveMaterialPackage(action, state, sharedState);
    }
    case "SetDefaultsVentilationConcept": {
      return VentilationConceptActions.SetDefaultsVentilationConcept(action, state, sharedState);
    }
    case "CreateVentilationConceptIfNotExists": {
      return VentilationConceptActions.CreateVentilationConceptIfNotExists(action, state, sharedState);
    }
    case "CreateVentilationConceptIfNotExistsResponse": {
      return VentilationConceptActions.CreateVentilationConceptIfNotExistsResponse(action, state, sharedState);
    }
    case "UpdateVentilationConceptProject": {
      return VentilationConceptActions.UpdateVentilationConceptProject(action, state, sharedState);
    }
    case "UpdateVentilationConceptSystem": {
      return VentilationConceptActions.UpdateVentilationConceptSystem(action, state, sharedState);
    }
    case "CreateSystemPdf": {
      return SystemPdfActions.CreateSystemPdf(action, state, sharedState);
    }
    case "RemoveSystemPdf": {
      return SystemPdfActions.RemoveSystemPdf(action, state, sharedState);
    }
    case "UpdateSystemPdf": {
      return SystemPdfActions.UpdateSystemPdf(action, state, sharedState);
    }
    case "ExportToCrm": {
      if (!sharedState.crmParams) {
        return [state];
      }
      const { project } = state;
      const req: Crm.Request = {
        quoteId: sharedState.crmParams.crmQuoteId,
        market: sharedState.market.name,
        shopLanguageCode: sharedState.market.shopLanguageCode,
        locale: sharedState.crmParams.crmQuoteLanguage,
        project: project.id,
        systems: project.systems.map((s) => s.id),
        envelope: action.envelopeOnly,
        apiVersion: sharedState.crmParams.crmApi,
      };
      return [
        { ...state, exportingToCrm: true, crmExportResponse: undefined },
        HttpFetch.postWithAuth(sharedState.activeUser)(
          {},
          "/rest/crm/add-project",
          "json",
          "application/json",
          JSON.stringify(req),
          (data) => Action.HandleCrmResponse(data as unknown as Crm.Response)
        ),
      ];
    }

    case "HandleCrmResponse": {
      switch (action.response.type) {
        case "envelope":
          saveAs(
            new Blob([action.response.envelope], {
              type: "application/xml",
            }),
            "envelope.xml"
          );
          return [{ ...state, exportingToCrm: false, crmExportResponse: undefined }];
        case "export":
          return [
            {
              ...state,
              project: action.response.status === 200 ? { ...project, locked: true } : project,
              exportingToCrm: false,
              crmExportResponse: action.response,
            },
            undefined,
            action.response.status === 200 ? SharedState.SharedStateAction.SetCrm(undefined) : undefined,
          ];

        default:
          return [state];
      }
    }

    case "RequestQuote": {
      return QuoteRequestActions.RequestQuote(action, state, sharedState);
    }

    case "RequestQuoteResponseReceived": {
      return QuoteRequestActions.RequestQuoteResponseReceived(action, state, sharedState);
    }

    case "UpdateRoomSo": {
      return RoomActions.UpdateRoomSo(action, state, sharedState);
    }

    case "NoOp":
      return [state];
    default:
      return exhaustiveCheck(action, true);
  }
}

export function calculateSystem(
  market: string,
  metaProject: Project.MetaProductQuery,
  materialTables: Materials.MaterialTables,
  project: Project.Project,
  calcuationStates: Record<string, SystemCalculationState>,
  systemId: string
): {
  readonly updatedCalcState: Record<string, SystemCalculationState>;
  readonly updatedProject: Project.Project;
  readonly roomPatches: ReadonlyArray<Project.Patch<Project.Room>>;
} {
  const system = project.systems.find((s) => s.id === systemId);
  if (!system) {
    return { updatedCalcState: calcuationStates, updatedProject: project, roomPatches: [] };
  }
  const input = Calculators.map(metaProject, materialTables, system, market);
  const result = input && Calculators.calculate(input);
  const fields = input && Calculators.getFields(input);
  const uiUpdates = input && result && Calculators.mapResultToUi(input, result);
  const projectUpdates = input && result && Calculators.mapResultToProject(input, result);

  if (!input || !result || !fields || !uiUpdates || !projectUpdates) {
    return { updatedCalcState: calcuationStates, updatedProject: project, roomPatches: [] };
  }

  const patchedSystem = Project.applyRoomPatchesToSystem(system, uiUpdates.roomPatches || []);
  const patchedInput = Calculators.map(metaProject, materialTables, patchedSystem, market);
  const validation = patchedInput && Calculators.validate(patchedInput);

  if (!validation) {
    return { updatedCalcState: calcuationStates, updatedProject: project, roomPatches: [] };
  }

  const updatedCalcState = {
    ...calcuationStates,
    [system.id]: {
      input,
      validation,
      result,
      fields,
      uiUpdates: uiUpdates.roomPatches,
    },
  };

  const updatedSystem = Project.applyRoomPatchesToSystem(system, projectUpdates.roomPatches);
  const updatedProject = Project.replaceSystem(project, updatedSystem);

  return { updatedCalcState, updatedProject, roomPatches: projectUpdates.roomPatches };
}

export function createRoomUpdateMutations(
  graphQLMutation: ReturnType<typeof graphQLMutationWithAuth>,
  patches: ReadonlyArray<Project.Patch<Project.Room>>,
  systemId: string,
  marketName: string
): Cmd<Action> {
  return graphQLMutation(
    M.updateRoomsMutation,
    { input: { systemId: systemId, patches: Project.mergePatches(patches) } },
    marketName,
    Action.NoOp
  );
}

export function doPriceUpdate(
  systemIds: ReadonlyArray<string>,
  project: Project.Project,
  state: State,
  sharedState: SharedState.SharedState,
  forceUpdate: boolean
): readonly [State, Cmd<Action>?] {
  if (project.locked) {
    return [state, undefined];
  }

  const priceUpdateRate = 1000 * 1200;
  if (
    Date.now() - state.lastPriceUpdate < priceUpdateRate &&
    sharedState.market.name === state.lastPriceUpdateMarket?.name &&
    project.customerNumber === state.lastPriceUpdateCustomerNumber &&
    !forceUpdate
  ) {
    return [state];
  }

  if (!sharedState.market.defaultCustomerNumber) {
    return [state];
  }

  let newState: State = state;

  const updateInputs: Array<GQLOps.ProjectState_UpdateSystemPricesMutationVariables> = [];
  for (const systemId of systemIds) {
    const system = project.systems.find((s) => s.id === systemId);
    if (!system || system.materials.length === 0) {
      continue;
    }
    newState = {
      ...state,
      materialsState: {
        ...state.materialsState,
        [systemId]: {
          type: "price-update",
        },
      },
    };
    updateInputs.push({
      input: {
        id: systemId,
        marketName: sharedState.market.name,
      },
    });
  }

  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const cmds = updateInputs.map((variables) =>
    graphQLMutation<
      GQLOps.ProjectState_UpdateSystemPricesMutation,
      GQLOps.ProjectState_UpdateSystemPricesMutationVariables,
      Action
    >(M.updateSystemPricesMutation, variables, sharedState.market.name, Action.ReceivedUpdatedPrices)
  );

  return [
    {
      ...newState,
      lastPriceUpdate: Date.now(),
      lastPriceUpdateMarket: sharedState.market,
      lastPriceUpdateCustomerNumber: project.customerNumber,
    },
    Cmd.batch(cmds),
  ];
}
