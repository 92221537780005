import { ReportQueryParams } from "..";
import { QueryGenerator } from "../../query";
import { AdapterType, Response } from "./types";
import { getPageProps, imageToBitmapImage } from "../common";
import { fetchImageData } from "../../images";

export function* query(props: ReportQueryParams): QueryGenerator<Response> {
  const cover = props.imageResponse?.filter((image) => image.name === "frontpage");
  const images = yield* fetchImageData(cover || [], props.imageServiceUrl, 1000, 1000);
  return { images };
}

export const execute: AdapterType = async (projectReportParams, common, response) => {
  const pageProps = await getPageProps(projectReportParams, common);
  const coverImageData = response.images[0];

  const coverImage = await imageToBitmapImage(1000, 1000, coverImageData);

  return {
    pageProps: pageProps,
    translate: projectReportParams.translate,
    coverImage: coverImage,
  };
};
