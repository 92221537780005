import { MetaDataShared } from "../types";
import * as Gql from "../../generated/generated-operations";
import { Row, RowWithSortNo, Table } from "../../graphql-utils";
import { Categories } from "../shared/categories";

export type Items = Row<Gql.Items>;
export type ValvePriceAndCost = RowWithSortNo<Gql.ValvePriceAndCost> & {
  readonly [key: Exclude<string, "name" | "type" | "item_number">]: string | null;
};
export type FixedValvePrice = RowWithSortNo<Gql.FixedValvePrice>;

export type ItemsTable = Table<Items>;
export type ValvePriceAndCostTable = Table<ValvePriceAndCost>;
export type FixedValvePriceTable = Table<FixedValvePrice>;

export type Item = {
  readonly type: string;
  readonly itemNumber: string;
  readonly maxAirflow: number | undefined;
};

export interface MaterialTables {
  readonly type: "norway";
  readonly tables: {
    readonly items: ItemsTable;
    readonly valvePriceAndCost: ValvePriceAndCostTable;
    readonly fixedValvePrice: FixedValvePriceTable;
  };
  readonly items: { readonly [itemNumber: string]: Item };
  readonly valveTypes: ReadonlyArray<string>;
  readonly sortNos: ReadonlyMap<string, number>;
  readonly categories: Categories;
}

export interface MaterialSummary {
  readonly type: "norway";
}

export type MetaData = MetaDataShared & {
  readonly type: "norway";
  readonly maxAirFlow: number | undefined;
};
