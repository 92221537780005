import * as Gql from "../generated/generated-operations";
import { Row, Table } from "../graphql-utils";
import { Room, RoomTemplatesTable, System, ErrorMap, Patch, SystemError } from "../project";
import * as Germany from "./germany";
import * as Norway from "./norway";
import * as Global from "./global";

export type CalculatorName = typeof Germany.name | typeof Norway.name;

export type Duct = Pick<Gql.Duct, "duct_63" | "duct_75" | "max_airflow">;
export type DuctTable = Table<Row<Duct>>;

export interface Input {
  readonly calculator: CalculatorName;
  readonly market: string;
  readonly system: System;
  readonly roomTemplates: RoomTemplatesTable;
  readonly ductTable: DuctTable;
}

export interface Updates {
  readonly roomPatches: ReadonlyArray<Patch<Room>>;
}

export interface ValidationOutput {
  readonly hasErrors: boolean;
  readonly roomErrors: ErrorMap<Room>;
  readonly systemErrors: ReadonlyArray<SystemError>;
}

export type Result = Germany.Result | Norway.Result | Global.Result;

export interface Field<T, Field extends keyof T> {
  readonly field: Field;
  readonly readOnly: boolean;
  readonly hidden: boolean;
  readonly onChange?: (value: T[Field]) => Partial<Room>;
  readonly clearOverride?: (room: Room) => Partial<Room> | undefined;
  readonly decimals?: number;
  readonly spinButton?: boolean;
}

export type RoomField = Field<Room, keyof Room>;
export type SystemField = Field<System, keyof System>;

export type RoomFields = ReadonlyArray<RoomField>;
export type SystemFields = ReadonlyArray<SystemField>;

export type RoomFieldsById = { readonly [roomId: string]: RoomFields };

export interface Fields {
  readonly roomFields: RoomFieldsById;
  readonly systemFields: SystemFields;
}

export interface Calculator {
  readonly calculate: (input: Input) => Result | undefined;
  readonly validate: (input: Input) => ValidationOutput;
  readonly getFields: (input: Input) => Fields;
  readonly mapResultToUi: (input: Input, result: Result) => Updates;
  readonly mapResultToProject: (input: Input, result: Result) => Updates;
}
