import * as R from "ramda";
import { isExtractRoom, isSupplyRoom, System } from "../../project";
import { Result, Input } from "../types";
import { ResultByRoomId, Quantities } from "./types";
import { calculateRoomFloorArea } from "./calculation/floor-area";
import { RoomField, Fields, RoomFields, SystemFields } from "..";
import { calculateRoomValveQuantity } from "./calculation/valves";
import { calculateAirFlowPerRoom, calculateRoomVolume, calculateTotalVolume, maybeSumArray } from "./calculation";
import { calculateRoomDuctQuantity, getDuctMaxAirflow } from "./calculation/ducts";

export const name = "global";

export function calculate(input: Input): Result {
  const rooms = input.system.rooms;
  const airChangeRate = input.system.airChangeRate ?? undefined;

  // Rooms results
  const afByRoomId = calculateAirFlowPerRoom(airChangeRate, rooms);
  const ductMaxAirflow = getDuctMaxAirflow(input.ductTable, input.system);
  const roomResults = R.fromPairs(
    rooms.map((room) => {
      const airflow = afByRoomId.get(room.id);
      const volume = calculateRoomVolume(room);
      const area = calculateRoomFloorArea(room);
      const width = room.width || undefined;
      const length = room.length || undefined;
      const valves = calculateRoomValveQuantity(room, airflow);
      const ducts = ductMaxAirflow && airflow ? calculateRoomDuctQuantity(ductMaxAirflow, airflow, valves) : undefined;
      return [
        room.id,
        {
          airflow,
          volume,
          area,
          width,
          length,
          valves,
          ducts,
        },
      ];
    })
  );

  // System results
  const totalVolume = calculateTotalVolume(rooms);
  const totalArea = maybeSumArray(R.values(roomResults).map((result) => result.area));
  const quantities = calculateQuantities(input.system, roomResults);
  const supplyAirflow = maybeSumArray(
    rooms.filter((room) => isSupplyRoom(room)).map((room) => roomResults[room.id]?.airflow)
  );
  const extractAirflow = maybeSumArray(
    rooms.filter((room) => isExtractRoom(room)).map((room) => roomResults[room.id]?.airflow)
  );
  const requiredAirflow = supplyAirflow; // supplyAirflow === extractAirflow

  return {
    type: name,
    requiredAirflow,
    airChangeRate,
    totalArea,
    totalVolume,
    roomResults,
    supplyAirflow,
    extractAirflow,
    quantities,
  };
}

function calculateQuantities(system: System, roomResults: ResultByRoomId): Quantities {
  return system.rooms.reduce(
    (sofar, room) => {
      const results = roomResults[room.id];
      if (!results) {
        return sofar;
      }
      if (isSupplyRoom(room)) {
        return {
          ...sofar,
          supplyValves: results.valves !== undefined ? (sofar.supplyValves || 0) + results.valves : results.valves,
          supplyDucts: results.ducts !== undefined ? (sofar.supplyDucts || 0) + results.ducts : results.ducts,
        };
      } else if (isExtractRoom(room)) {
        return {
          ...sofar,
          extractValves: results.valves !== undefined ? (sofar.extractValves || 0) + results.valves : results.valves,
          extractDucts: results.ducts !== undefined ? (sofar.extractDucts || 0) + results.ducts : results.ducts,
        };
      } else {
        return sofar;
      }
    },
    {
      supplyDucts: undefined,
      extractDucts: undefined,
      supplyValves: undefined,
      extractValves: undefined,
    }
  );
}

export function getFields(input: Input): Fields {
  const roomFields: RoomFields = [
    { field: "roomType", readOnly: false, hidden: false },
    { field: "airType", readOnly: true, hidden: false },
    {
      field: "volume",
      readOnly: true,
      hidden: false,
    },
    { field: "floorType", readOnly: false, hidden: false },
    { field: "airflow", readOnly: true, hidden: false },
    { field: "ceilingHeight", readOnly: false, hidden: false },
    {
      field: "floorArea",
      readOnly: false,
      hidden: false,
      onChange: (v: number) => ({ floorArea: v, volume: null, width: null, length: null }),
    },
    {
      field: "width",
      readOnly: false,
      hidden: false,
      onChange: (v: number) => ({ width: v, floorArea: null, volume: null }),
    },
    {
      field: "length",
      readOnly: false,
      hidden: false,
      onChange: (v: number) => ({ length: v, floorArea: null, volume: null }),
    },
    {
      field: "valves",
      readOnly: false,
      hidden: false,
      decimals: 0,
      onChange: (v: number) => ({ valves: v, customValves: true }),
      clearOverride: (room) => (room.customValves ? { customValves: null } : undefined),
    },
    { field: "ducts", readOnly: true, hidden: false, decimals: 0 },
  ];
  const fields: { [id: string]: ReadonlyArray<RoomField> } = {};
  for (const room of input.system.rooms) {
    fields[room.id] = roomFields;
  }
  const systemFields: SystemFields = [
    { field: "totalArea", readOnly: false, hidden: true },
    { field: "pipeDiameter", readOnly: false, hidden: false },
    { field: "airChangeRate", readOnly: false, hidden: false },
  ];
  return { roomFields: fields, systemFields };
}
