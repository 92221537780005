import { v4 as uuid } from "uuid";
import { createDiffPatch, graphQLMutationWithAuth, Patch, SharedState, variablesFromPatch } from "@rvs/client-infra";
import { Cmd } from "@typescript-tea/core";
import {
  calculateVentilationConcept,
  createDefaultVentilationConceptProject,
  createDefaultVentilationConceptSystem,
} from "@rvs/shared/src/calculators/germany/calculation";
import { Project } from "@rvs/shared";
import { Action, State } from "../state";
import * as GQLOps from "../../../../generated/generated-operations";
import * as M from "../mutations";
import { calculateSystem } from "..";

export function CreateVentilationConceptIfNotExists(
  _action: ReturnType<typeof Action.CreateVentilationConceptIfNotExists>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  if (state.creatingVcIdsInProgress.length > 0) {
    return [state];
  }
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  let updatedProject = state.project;
  const creatingVcIdsInProgress: Array<string> = [];
  const cmds: Array<Cmd<Action>> = [];

  if (!updatedProject.ventilationConcept) {
    const isWindy = state.project.projectPostalcode
      ? state.windyRegions.has(state.project.projectPostalcode)
      : undefined;
    const defaultWindConditions = isWindy !== undefined ? (isWindy ? "high" : "low") : null;
    const vc = createDefaultVentilationConceptProject({
      ventilationConceptId: uuid(),
      defaultWindConditions,
    });
    updatedProject = Project.replaceVentilationConceptProject(updatedProject, vc);
    cmds.push(
      graphQLMutation<
        GQLOps.ProjectState_CreateVentilationConceptProjectMutation,
        GQLOps.ProjectState_CreateVentilationConceptProjectMutationVariables,
        Action
      >(
        M.createVentilationConceptProjectMutation,
        {
          input: { ...vc, projectId: updatedProject.id },
        },
        sharedState.market.name,
        () => Action.CreateVentilationConceptIfNotExistsResponse(vc.id)
      )
    );
    creatingVcIdsInProgress.push(vc.id);
  }

  const systemsWithoutVc = updatedProject.systems.filter((s) => s.ventilationConcept === null);
  const roomsWithoutWindows = state.metaProduct.product?.modules.custom_tables.RoomsWithoutWindows || [];
  for (const system of systemsWithoutVc) {
    const result = state.calculationStates[system.id]?.result;
    const vc = createDefaultVentilationConceptSystem({
      roomsWithoutWindows,
      ventilationConceptId: uuid(),
      system,
      result: result.type === "germany" ? result : undefined,
    });
    updatedProject = Project.replaceVentilationConceptSystem(updatedProject, system.id, vc);
    cmds.push(
      graphQLMutation<
        GQLOps.ProjectState_CreateVentilationConceptSystemMutation,
        GQLOps.ProjectState_CreateVentilationConceptSystemMutationVariables,
        Action
      >(
        M.createVentilationConceptSystemMutation,
        {
          input: { ...vc, systemId: system.id },
        },
        sharedState.market.name,
        () => Action.CreateVentilationConceptIfNotExistsResponse(vc.id)
      )
    );
    creatingVcIdsInProgress.push(vc.id);
  }

  if (cmds.length === 0) {
    return [state];
  }

  return [{ ...state, creatingVcIdsInProgress, project: updatedProject }, Cmd.batch(cmds)];
}

export function CreateVentilationConceptIfNotExistsResponse(
  action: ReturnType<typeof Action.CreateVentilationConceptIfNotExistsResponse>,
  state: State,
  _: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  return [
    {
      ...state,
      creatingVcIdsInProgress: state.creatingVcIdsInProgress.filter((id) => id !== action.vcId),
    },
  ];
}

export function SetDefaultsVentilationConcept(
  _action: ReturnType<typeof Action.SetDefaultsVentilationConcept>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const vc = state.project.ventilationConcept;
  if (!vc || vc.windConditions) {
    return [state];
  }
  const isWindy = state.project.projectPostalcode ? state.windyRegions.has(state.project.projectPostalcode) : undefined;
  const defaultWindConditions = isWindy !== undefined ? (isWindy ? "high" : "low") : null;
  if (!defaultWindConditions) {
    return [state];
  }
  const projectPatch = { id: vc.id, windConditions: defaultWindConditions };
  return updateVentilationConcept(projectPatch, undefined, state, sharedState);
}

export function UpdateVentilationConceptProject(
  action: ReturnType<typeof Action.UpdateVentilationConceptProject>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  return updateVentilationConcept(action.patch, undefined, state, sharedState);
}

export function UpdateVentilationConceptSystem(
  action: ReturnType<typeof Action.UpdateVentilationConceptSystem>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  return updateVentilationConcept(undefined, action.patch, state, sharedState);
}

function updateVentilationConcept(
  projectPatch: Patch<Project.VentilationConceptProject> | undefined,
  systemPatch: Patch<Project.VentilationConceptSystem> | undefined,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  if (!projectPatch && !systemPatch) {
    return [state];
  }

  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  let updatedProject = state.project;
  let updatedProjectVc = updatedProject.ventilationConcept;
  if (!updatedProjectVc) {
    return [state];
  }

  const cmds: Array<Cmd<Action>> = [];

  // Apply project level patch
  if (projectPatch) {
    updatedProjectVc = {
      ...updatedProjectVc,
      ...projectPatch,
      id: updatedProjectVc.id,
    };
    updatedProject = Project.replaceVentilationConceptProject(updatedProject, updatedProjectVc);
    cmds.push(
      graphQLMutation<
        GQLOps.ProjectState_UpdateVentilationConceptProjectMutation,
        GQLOps.ProjectState_UpdateVentilationConceptProjectMutationVariables,
        Action
      >(
        M.updateVentilationConceptProjectMutation,
        variablesFromPatch(projectPatch.id, projectPatch),
        sharedState.market.name,
        Action.NoOp
      )
    );
  }

  // Apply system level patch
  if (systemPatch) {
    const system = updatedProject.systems.find((s) => s.ventilationConcept?.id === systemPatch.id);
    const vc = system?.ventilationConcept;
    if (!system || !vc) {
      return [state];
    }
    const updatedVc: Project.VentilationConceptSystem = {
      ...vc,
      ...systemPatch,
      id: vc.id,
    };
    updatedProject = Project.replaceVentilationConceptSystem(updatedProject, system.id, updatedVc);
  }

  // Recalcualte all ventilation concepts
  for (const system of updatedProject.systems) {
    const vc = system.ventilationConcept;
    if (!vc) {
      continue;
    }
    // TODO: The ventilation concept should be calculated in the regular calculator, same as it's done when a room is modified
    const result = calculateVentilationConcept(updatedProjectVc, vc);
    const calculatedVc: Project.VentilationConceptSystem = {
      ...vc,
      requiredAirflow: result?.requiredAirflow || null,
      infiltrationAirflow: result?.infiltrationAirflow || null,
      airflowStatus: result?.airflowStatus || null,
      higherRequirementsStatus: result?.higherRequirementsStatus || null,
      windowlessRoomsStatus: result?.windowlessRoomsStatus || null,
      furnaceStatus: result?.furnaceStatus || null,
      finalStatus: result?.finalStatus || null,
    };
    updatedProject = Project.replaceVentilationConceptSystem(updatedProject, system.id, calculatedVc);
    const originalVc = state.project.systems.find((s) => s.id === system.id)?.ventilationConcept;
    const diffPatch = originalVc && createDiffPatch(originalVc, calculatedVc);
    if (diffPatch) {
      cmds.push(
        graphQLMutation<
          GQLOps.ProjectState_UpdateVentilationConceptSystemMutation,
          GQLOps.ProjectState_UpdateVentilationConceptSystemMutationVariables,
          Action
        >(
          M.updateVentilationConceptSystemMutation,
          variablesFromPatch(diffPatch.id, diffPatch),
          sharedState.market.name,
          Action.NoOp
        )
      );
    }
  }

  if (cmds.length === 0) {
    return [state];
  }

  // Recalculate system since some calculations depends on the ventilation concept
  let recaculatedProject = updatedProject;
  let updatedCalcState = state.calculationStates;
  for (const system of updatedProject.systems) {
    const res = calculateSystem(
      sharedState.market.name,
      state.metaProduct,
      state.materialTables,
      updatedProject,
      state.calculationStates,
      system.id
    );
    recaculatedProject = res.updatedProject;
    updatedCalcState = res.updatedCalcState;
  }
  updatedProject = recaculatedProject;

  return [{ ...state, project: updatedProject, calculationStates: updatedCalcState }, Cmd.batch(cmds)];
}
