import gql from "graphql-tag";

export const schema = gql`
  type Room {
    id: ID!
    sortNo: Int!
    name: String!
    building: String!
    floor: String!
    apartment: String!
    roomType: String!
    airType: String!
    floorType: String!
    supplyAirFactor: Float
    airflow: Float!
    ceilingHeight: Float!
    floorArea: Float
    width: Float
    length: Float
    volume: Float
    people: Int
    valves: Int
    ducts: Int
    valveType: String
    customAirFlow: Boolean
    customValves: Boolean
  }

  input RoomPatch {
    id: ID!
    sortNo: Int
    name: String
    building: String
    floor: String
    apartment: String
    roomType: String
    airType: String
    floorType: String
    supplyAirFactor: Float
    airflow: Float
    ceilingHeight: Float
    floorArea: Float
    width: Float
    length: Float
    volume: Float
    people: Int
    valves: Int
    ducts: Int
    valveType: String
    customAirFlow: Boolean
    customValves: Boolean
  }

  extend type Mutation {
    createRoom(input: CreateRoomInput!): CreateRoomPayload!
    removeRoom(input: RemoveRoomInput!): RemoveRoomPayload!
    updateRoom(input: UpdateRoomInput!): UpdateRoomPayload!
    updateRooms(input: UpdateRoomsInput!): UpdateRoomsPayload!
  }

  input CreateRoomInput {
    id: ID!
    sortNo: Int!
    systemId: ID!
    name: String!
    building: String!
    floor: String!
    apartment: String!
    roomType: String!
    airType: String!
    floorType: String!
    supplyAirFactor: Float
    airflow: Float!
    ceilingHeight: Float!
    floorArea: Float
    width: Float
    length: Float
    volume: Float
    people: Int
    valves: Int
    ducts: Int
    valveType: String
    customAirFlow: Boolean
    customValves: Boolean
  }

  type CreateRoomPayload {
    id: ID!
  }

  input RemoveRoomInput {
    id: ID!
  }

  type RemoveRoomPayload {
    id: ID!
  }

  input UpdateRoomInput {
    id: ID!
    sortNo: Int
    name: String
    building: String
    floor: String
    apartment: String
    roomType: String
    airType: String
    floorType: String
    supplyAirFactor: Float
    airflow: Float
    ceilingHeight: Float
    floorArea: Float
    width: Float
    length: Float
    volume: Float
    people: Int
    valves: Int
    ducts: Int
    valveType: String
    customAirFlow: Boolean
    customValves: Boolean
  }

  type UpdateRoomPayload {
    id: ID!
  }

  input UpdateRoomsInput {
    systemId: ID!
    patches: [RoomPatch!]!
  }

  type UpdateRoomsPayload {
    id: ID!
    status: String!
  }
`;
