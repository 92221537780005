import { ReportLevel } from "..";
import * as Common from "../common";
import { ReportModule } from "../types";

export const Name = "fixed-pdf";
export const reportLevel: ReportLevel = "system";

export type Response = {};

export type CreatorProps = {
  readonly pageProps: Common.CataloguePageProps;
};

export type AdapterType = ReportModule<CreatorProps, Response>["execute"];
export type CreatorType = ReportModule<CreatorProps, Response>["create"];
