import { exhaustiveCheck } from "ts-exhaustive-check";
import { TextTranslateFn, translateNormalizedText, Translations } from "./translation";
import { NormalizedText, normalizedText } from "./normalized-text";
import { TextTypes } from "./texts";

export type TranslateFn = TextTranslateFn<TextTypes>;

export function createTranslateFn(translations: Translations): TranslateFn {
  return (text, defaultText) => translateNormalizedText(translations, normalizeText(text), defaultText);
}

function normalizeText(text: TextTypes): NormalizedText {
  switch (text.type) {
    case "TextKey": {
      return normalizedText(text.key, text.params);
    }
    case "Dummy": {
      return normalizedText(text.key, text.params);
    }
    default: {
      return exhaustiveCheck(text);
    }
  }
}
