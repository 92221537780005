import gql from "graphql-tag";

export const schema = gql`
  extend type Query {
    project(id: ID!): Project
    projectStatus(id: ID!): ProjectStatus
  }

  extend type Mutation {
    createProject(input: CreateProjectInput!): CreateProjectPayload!
    removeProject(input: RemoveProjectInput!): RemoveProjectPayload!
    updateProject(input: UpdateProjectInput!): UpdateProjectPayload!
    saveProjectAsTemplate(input: SaveProjectAsTemplateInput!): SaveProjectAsTemplatePayload!
    createProjectFromTemplate(input: CreateProjectFromTemplateInput!): CreateProjectFromTemplatePayload!
    updateProjectLockState(input: UpdateProjectLockStateInput!): UpdateProjectLockStatePayload!
    removeTemplate(input: RemoveTemplateInput!): RemoveTemplatePayload!
  }

  type ProjectStatus {
    id: ID!
    exists: Boolean!
    access: Boolean!
  }

  type Project {
    id: ID!
    name: String
    template: Boolean
    systems: [System!]!
    locked: Boolean!
    owned: Boolean!
    shares: [ProjectShare!]!
    permissions: String!
    created: Date
    edited: Date
    projectAddressname: String
    projectStreet: String
    projectPostalcode: String
    projectCity: String
    projectCountry: String
    customerAddressname: String
    customerStreet: String
    customerPostalcode: String
    customerCity: String
    customerCountry: String
    customerCustomerNumber: String
    gaebformat: String
    customerNumber: String
    owner: String
    ventilationConcept: VentilationConceptProject
    userQuoteRequest: QuoteRequest
    organizationQuoteRequests: [QuoteRequest!]!
  }

  input CreateProjectInput {
    id: ID!
  }

  type CreateProjectPayload {
    project: Project!
  }

  input RemoveProjectInput {
    id: ID!
  }

  type RemoveProjectPayload {
    id: ID!
  }

  input UpdateProjectInput {
    id: ID!
    name: String
    projectAddressname: String
    projectStreet: String
    projectPostalcode: String
    projectCity: String
    projectCountry: String
    customerAddressname: String
    customerStreet: String
    customerPostalcode: String
    customerCity: String
    customerCountry: String
    customerCustomerNumber: String
    gaebformat: String
    customerNumber: String
  }

  type UpdateProjectPayload {
    id: ID!
  }

  input UpdateProjectLockStateInput {
    id: ID!
    locked: Boolean!
  }

  type UpdateProjectLockStatePayload {
    id: ID!
    locked: Boolean!
    permissions: String!
  }

  input SaveProjectAsTemplateInput {
    id: ID!
    templateId: ID!
  }

  type SaveProjectAsTemplatePayload {
    id: ID!
  }

  input CreateProjectFromTemplateInput {
    id: ID!
    templateId: ID!
  }

  type CreateProjectFromTemplatePayload {
    id: ID!
  }

  input RemoveTemplateInput {
    id: ID!
  }

  type RemoveTemplatePayload {
    id: ID!
  }
`;
