import * as React from "react";
import { AbstractDocJsx as ADX, AbstractDoc as AD } from "abstract-document";
import { TranslateFn, texts } from "../../lang-texts";
import { standardLeftRightMargin } from "./page";
import { style } from "../../style";
import { headerTitle, headerTitleMain } from ".";

const { Table, TableRow, TableCell, Paragraph, Group, TextRun, Image, render } = ADX;

export function createHeader(
  _logo: AD.ImageResource.ImageResource | undefined,
  translate: TranslateFn,
  projectName: string | undefined | null,
  systemName: string | undefined
  // eslint-disable-next-line functional/prefer-readonly-type
): AD.SectionElement.SectionElement[] {
  const cellStyle = AD.TableCellStyle.create({
    borders: AD.LayoutFoundation.create({
      bottom: 1,
    }),
    borderColor: style.primaryColor,
    padding: AD.LayoutFoundation.create({
      top: 20,
      bottom: 20,
    }),
    verticalAlignment: "Top",
  });
  return [
    render(
      <Table
        columnWidths={[standardLeftRightMargin, Infinity, standardLeftRightMargin]}
        style={AD.TableStyle.create({ alignment: "Center", cellStyle: cellStyle })}
      >
        <TableRow>
          <TableCell />
          <TableCell>
            {headerTitleMain(projectName ?? translate(texts.new_project))}
            {headerTitle(systemName ?? translate(texts.project_printout))}
          </TableCell>
          <TableCell />
        </TableRow>
      </Table>
    ),
  ];
}

export function createHeaderTOC(
  logo: AD.ImageResource.ImageResource | undefined,
  _translate: TranslateFn,
  _title: string | undefined | null
  // eslint-disable-next-line functional/prefer-readonly-type
): AD.SectionElement.SectionElement[] {
  return [
    render(
      <Group>
        <Paragraph
          style={AD.ParagraphStyle.create({
            position: "absolute",
            alignment: "End",
            margins: AD.LayoutFoundation.create({ top: 10 }),
          })}
        >
          {logo && <Image imageResource={logo} width={150} height={40} />}
        </Paragraph>
        <Table
          columnWidths={[Infinity]}
          style={AD.TableStyle.create({ margins: AD.LayoutFoundation.create({ top: 60 }) })}
        >
          <TableRow>
            <TableCell style={AD.TableCellStyle.create({ borders: AD.LayoutFoundation.create({ bottom: 1 }) })}>
              <Paragraph>
                <TextRun text={""} />
              </Paragraph>
            </TableCell>
          </TableRow>
        </Table>
      </Group>
    ),
  ];
}

export function createHeaderFirst(
  _logo: AD.ImageResource.ImageResource | undefined,
  translate: TranslateFn,
  _title: string | undefined | null
  // eslint-disable-next-line functional/prefer-readonly-type
): AD.SectionElement.SectionElement[] {
  return [
    render(
      <Group style={AD.GroupStyle.create({ position: "absolute" })}>
        <Table columnWidths={[Infinity]}>
          <TableRow>
            <TableCell
              style={AD.TableCellStyle.create({
                padding: AD.LayoutFoundation.create({ top: 60, bottom: 80, left: 59.5, right: 59 }),
                background: style.primaryColor,
              })}
            >
              <Paragraph style={AD.ParagraphStyle.create({ position: "absolute" })}>
                <TextRun
                  style={AD.TextStyle.create({ color: "#FFFFFF", fontSize: 22, bold: true })}
                  text={translate(texts.report_cover_page_title)}
                />
              </Paragraph>
            </TableCell>
          </TableRow>
        </Table>
      </Group>
    ),
  ];
}
