import { Dispatch } from "@typescript-tea/core";
import { SharedState } from "@rvs/client-infra";
import { Texts } from "@rvs/shared";
import React from "react";
import * as State from "./state";
import { Alert, Button, H3, Spinner } from "../../../../elements";
import * as ProjectState from "../../project-state";

const texts = Texts.texts;

export function View(props: {
  readonly state: State.State;
  readonly projectState: ProjectState.State;
  readonly sharedState: SharedState.SharedState;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
}): JSX.Element {
  const { projectState, sharedState, dispatchProject } = props;
  const { translate } = sharedState;


  const showRequestQuoteButton =
    projectState.project.customerCountry === "country_germany" &&
    projectState.project.customerPostalcode !== "" &&
    projectState.project.customerAddressname !== "" &&
    projectState.project.customerCity !== "" &&
    projectState.project.customerStreet !== "" &&
    projectState.project.name !== "" &&
    projectState.project.projectCountry !== "" &&
    projectState.project.projectAddressname !== "" &&
    projectState.project.projectPostalcode !== "" &&
    projectState.project.projectCity !== "";

  const showCountryMessage = !showRequestQuoteButton;

  const showMissingInputMessage =
    projectState.project.customerCountry === "country_germany" &&
    (projectState.project.projectCountry === "" ||
      projectState.project.name === "" ||
      projectState.project.projectStreet === "" ||
      projectState.project.projectAddressname === "" ||
      projectState.project.projectPostalcode === "" ||
      projectState.project.projectCity === "" ||
      projectState.project.customerPostalcode === "" ||
      projectState.project.customerAddressname === "" ||
      projectState.project.customerCity === "" ||
      projectState.project.customerStreet === "");

  if (showMissingInputMessage) {
    return <Alert type="warning">{translate(texts.fields_general_tab_must_be_entered)}</Alert>;
  }

  if (showCountryMessage) {
    return <Alert type="warning">{translate(texts.contact_systemair_message)}</Alert>;
  }

  return (
    <div className="flex flex-col space-y-16">
      <H3>{translate(texts.request_quote)}</H3>
      <p>{translate(texts.request_quote_note)}</p>
      <div className={"flex space-x-16 items-center"}>
        {showRequestQuoteButton ? (
          <Button
            disabled={
              projectState.requestQuoteStatus !== "not_sent" &&
              projectState.project.projectCountry !== "country_germany"
            }
            title={translate(texts.request_quote_note)}
            label={translate(texts.request_quote)}
            onClick={() => {
              dispatchProject(ProjectState.Action.RequestQuote());
            }}
          />
        ) : null}
        {projectState.requestQuoteStatus === "sending" ? (
          <Spinner debounce={false} />
        ) : (
          projectState.project.userQuoteRequest && (
            <span>{`${translate(texts.last_quote_requested)}: ${new Date(
              projectState.project.userQuoteRequest.requestDate
            ).toLocaleString()}`}</span>
          )
        )}
      </div>
      {projectState.requestQuoteStatus === "sent_ok" && (
        <Alert type="success">{translate(texts.request_quote_success)}</Alert>
      )}
      {projectState.requestQuoteStatus === "sent_error" && (
        <Alert type="error">{translate(texts.request_quote_failed)}</Alert>
      )}
      {projectState.project.organizationQuoteRequests.length > 0 && (
        <>
          <H3>{translate(texts.current_quote_requests)}</H3>
          <table className="max-w-md">
            <thead>
              <tr>
                <th>{translate(texts.requested_by)}</th>
                <th>{translate(texts.last_quote_requested)}</th>
              </tr>
            </thead>
            <tbody>
              {projectState.project.organizationQuoteRequests.map((qr) => (
                <tr key={qr.id}>
                  <td>{qr.requestedBy}</td>
                  <td>{new Date(qr.requestDate).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}
