import * as React from "react";
import { AbstractDoc as AD, AbstractDocJsx as ADX } from "abstract-document";
import { stylesWithSmallText } from "./standard-style";
import { style } from "../../style";

const { Group, Table, TableRow, TableCell, Paragraph, TextRun, render } = ADX;

export interface TableCellPadding {
  readonly top: number;
  readonly bottom: number;
  readonly left: number;
  readonly right: number;
}

export interface TableLayoutStyle {
  readonly fontSize: number;
  readonly cellPadding: TableCellPadding;
  readonly cellBorder?: TableCellPadding;
  readonly cellBorderColor?: string;
  readonly topMargin: number;
  readonly bottomMargin: number;
  readonly useZebraStripes?: boolean;
  readonly styles: AD.Types.Indexer<AD.Style.Style> | undefined;
}

const tableStyles = {
  compact: {
    fontSize: 6,
    cellPadding: {
      top: 1,
      bottom: -2,
      right: 0.7 - 2,
      left: 0.7,
    },
    topMargin: 10,
    bottomMargin: 10,
    styles: undefined,
  },

  standard: {
    fontSize: 8,
    cellPadding: {
      top: 1,
      bottom: 0,
      right: 0,
      left: 2,
    },
    topMargin: 10,
    bottomMargin: 10,
    styles: undefined,
  },

  medium: {
    fontSize: 7,
    cellPadding: {
      top: 1,
      bottom: -2,
      right: 0,
      left: 2,
    },
    topMargin: 4,
    bottomMargin: 4,
    styles: stylesWithSmallText(),
  },

  noMargin: {
    fontSize: 8,
    cellPadding: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    topMargin: 0,
    bottomMargin: 0,
    styles: undefined,
  },

  noMarginBorderTop: {
    fontSize: 8,
    cellPadding: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    cellBorder: {
      top: 0.5,
      bottom: 0,
      right: 0,
      left: 0,
    },
    topMargin: 0,
    bottomMargin: 0,
    styles: undefined,
  },
};

export type TableStyleName = keyof typeof tableStyles;

export function table(
  headingsRow: ReadonlyArray<TableCellHeading>,
  rows: ReadonlyArray<ReadonlyArray<TableCell>>,
  tableStyleName: TableStyleName,
  repeatableHeaderRows?: ReadonlyArray<ReadonlyArray<TableCell>>
): React.ReactElement<{}> {
  return tableMultHeadings(
    [headingsRow],
    rows,
    headingsRow.map((h) => h.width || Infinity),
    tableStyleName,
    repeatableHeaderRows
  );
}

export interface TableCell {
  readonly heading?: boolean;
  readonly colSpan?: number;
  readonly rowSpan?: number;
  readonly align?: AD.ParagraphStyle.TextAlignment;
  readonly verticalAlign?: AD.TableCellStyle.RowAlignment;
  readonly value: string | JSX.Element;
  readonly noBackground?: boolean;
  readonly backgroundColor?: string;
  readonly rowNo?: number;
  readonly padding?: AD.LayoutFoundation.LayoutFoundationProps;
  readonly bold?: boolean;
}

export interface TableCellHeading extends TableCell {
  readonly width?: number;
}

export function tableMultHeadings(
  headingsRows: ReadonlyArray<ReadonlyArray<TableCellHeading>>,
  tableRows: ReadonlyArray<ReadonlyArray<TableCell>>,
  columnWidths: readonly number[],
  tableStyleName: TableStyleName,
  repeatableHeaderRows?: ReadonlyArray<ReadonlyArray<TableCell>>
): React.ReactElement<{}> {
  const tableLayoutStyle = tableStyles[tableStyleName];
  const cellHeaderText = tableLayoutStyle.styles
    ? (tableLayoutStyle.styles["TextStyle_HeaderText"] as AD.TextStyle.TextStyle)
    : undefined;

  const cellPadding = AD.LayoutFoundation.create(tableLayoutStyle.cellPadding);
  const headingTextStyle = AD.TextStyle.create({
    bold: true,
    color: "white",
    fontSize: tableLayoutStyle.fontSize,
    ...cellHeaderText,
  });
  const headingCellStyleDefault = AD.TableCellStyle.create({
    borders: AD.LayoutFoundation.create({
      top: 0.5,
      left: 0.5,
      right: 0.5,
      bottom: 0.5,
    }),
    borderColor: style.primaryColor,
    background: style.primaryColor,
    padding: cellPadding,
    verticalAlignment: "Top",
  });

  const tables: React.ReactElement<{}>[] = [];
  const newTableHeadings: React.ReactElement<{}>[] = [];

  const topStyleHeader = AD.TableStyle.create({
    margins: AD.LayoutFoundation.create({ top: tableLayoutStyle.topMargin }),
  });

  headingsRows.forEach((headingsRow, rowIndex) => {
    if (headingsRow.length > 0) {
      newTableHeadings.push(
        <Table
          style={rowIndex === 0 ? topStyleHeader : undefined}
          columnWidths={headingsRow.map((h) => h.width || Infinity)}
        >
          <TableRow>
            {headingsRow.map((h) => {
              return (
                <TableCell
                  columnSpan={h.colSpan || 1}
                  rowSpan={h.rowSpan || 1}
                  style={{
                    ...headingCellStyleDefault,
                    padding: { ...headingCellStyleDefault.padding, ...(h.padding || {}) },
                    verticalAlignment: h.verticalAlign || headingCellStyleDefault.verticalAlignment,
                  }}
                >
                  {typeof h.value === "string" ? (
                    <Paragraph style={AD.ParagraphStyle.create({ alignment: h.align || "Start" })}>
                      <TextRun
                        style={AD.TextStyle.create({ ...headingTextStyle, bold: h.bold || headingTextStyle.bold })}
                        text={h.value}
                      />
                    </Paragraph>
                  ) : (
                    h.value
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </Table>
      );
    }
  });

  tables.push(...newTableHeadings);

  const newRepeatableHeaderRows = repeatableHeaderRows
    ? renderRows(repeatableHeaderRows, tableLayoutStyle).map((h) => render(h))
    : [];

  const newTableRows = renderRows(tableRows, tableLayoutStyle);

  const tableStyle = AD.TableStyle.create({
    margins: AD.LayoutFoundation.create({ bottom: tableLayoutStyle.bottomMargin }),
  });
  tables.push(
    <Table style={tableStyle} columnWidths={columnWidths} headerRows={newRepeatableHeaderRows || []}>
      {newTableRows}
    </Table>
  );

  if (tableLayoutStyle.styles) {
    return (
      <Group keepTogether={true} styles={tableLayoutStyle.styles}>
        {tables}
      </Group>
    );
  } else {
    return <Group keepTogether={true}>{tables}</Group>;
  }
}

export function tableHeadingWithUnit(
  text: string,
  unit: string,
  tableStyleName: TableStyleName = "standard",
  align?: AD.ParagraphStyle.TextAlignment
): JSX.Element {
  const tableLayoutStyle = tableStyles[tableStyleName];
  const cellHeaderText = tableLayoutStyle.styles
    ? (tableLayoutStyle.styles["TextStyle_HeaderText"] as AD.TextStyle.TextStyle)
    : undefined;

  const headingTextStyle = AD.TextStyle.create({
    bold: true,
    color: "white",
    fontSize: tableLayoutStyle.fontSize,
    ...cellHeaderText,
  });
  const style = AD.ParagraphStyle.create({ alignment: align || "Center", textStyle: headingTextStyle });
  return (
    <Group>
      <Paragraph style={style}>
        <TextRun text={text} />
      </Paragraph>
      <Paragraph style={style}>
        <TextRun text={`[${unit}]`} />
      </Paragraph>
    </Group>
  );
}

function renderRows(
  tableRows: ReadonlyArray<ReadonlyArray<TableCell>>,
  tableLayoutStyle: TableLayoutStyle
): ReadonlyArray<JSX.Element> {
  const cellPadding = AD.LayoutFoundation.create(tableLayoutStyle.cellPadding);
  const cellHeaderText = tableLayoutStyle.styles
    ? (tableLayoutStyle.styles["TextStyle_HeaderText"] as AD.TextStyle.TextStyle)
    : undefined;
  const headingTextStyle = AD.TextStyle.create({
    bold: true,
    color: "white",
    fontSize: tableLayoutStyle.fontSize,
    ...cellHeaderText,
  });
  const headingCellStyleDefault = AD.TableCellStyle.create({
    borders: AD.LayoutFoundation.create({
      top: 0.5,
      left: 0.5,
      right: 0.5,
      bottom: 0.5,
    }),
    borderColor: style.primaryColor,
    background: style.primaryColor,
    padding: cellPadding,
    verticalAlignment: "Top",
  });
  const cellHeaderCustom = tableLayoutStyle.styles
    ? (tableLayoutStyle.styles["TableCellStyle_Heading"] as AD.TableCellStyle.TableCellStyle)
    : undefined;
  const cellLight = tableLayoutStyle.styles
    ? (tableLayoutStyle.styles["TableCellStyle_Light"] as AD.TableCellStyle.TableCellStyle)
    : undefined;
  const cellTextStyle = AD.TextStyle.create({ fontSize: tableLayoutStyle.fontSize });
  const darkCellStyle = AD.TableCellStyle.create({
    background: style.primaryColor05,
    padding: cellPadding,
    verticalAlignment: "Top",
  });

  const lightCellStyle = cellLight
    ? cellLight
    : AD.TableCellStyle.create({
        padding: cellPadding,
        verticalAlignment: "Top",
      });
  const [cellMapAbsolutePositions, cellMap] = createCellMap(tableRows);
  const newTableRows: React.ReactElement<{}>[] = [];
  tableRows.forEach((tableRow, rowIndex) => {
    newTableRows.push(
      <TableRow>
        {tableRow.map((c, cellIndex) => {
          const isLight = c.rowNo !== undefined ? c.rowNo % 2 === 0 : rowIndex % 2 === 0;

          const cellStyle = c.heading
            ? getHeaderCellStyle(
                cellMapAbsolutePositions,
                cellMap,
                rowIndex,
                cellIndex,
                cellHeaderCustom,
                headingCellStyleDefault
              )
            : (tableLayoutStyle.useZebraStripes === undefined || tableLayoutStyle.useZebraStripes) && isLight
            ? darkCellStyle
            : lightCellStyle;

          const cellStyleCustom = {
            ...cellStyle,
            background:
              c.backgroundColor || c.noBackground
                ? c.noBackground
                  ? undefined
                  : c.backgroundColor
                : cellStyle.background,
            borders: { ...cellStyle.borders, ...tableLayoutStyle.cellBorder },
            borderColor: cellStyle.borderColor || tableLayoutStyle.cellBorderColor,
            padding: { ...cellStyle.padding, ...(c.padding || {}) },
            verticalAlignment: c.verticalAlign || cellStyle.verticalAlignment,
          };

          const textStyle = c.heading ? headingTextStyle : cellTextStyle;
          return (
            <TableCell columnSpan={c.colSpan || 1} rowSpan={c.rowSpan || 1} style={cellStyleCustom}>
              {typeof c.value === "string" ? (
                <Paragraph style={AD.ParagraphStyle.create({ alignment: c.align || "Start" })}>
                  <TextRun
                    style={AD.TextStyle.create({ ...textStyle, bold: c.bold || textStyle.bold })}
                    text={c.value}
                  />
                </Paragraph>
              ) : (
                c.value
              )}
            </TableCell>
          );
        })}
      </TableRow>
    );
  });
  return newTableRows;
}

function getHeaderCellStyle(
  cellMapAbsolutePositions: ReadonlyMap<string, readonly [number, number]>,
  cellMap: ReadonlyMap<string, TableCell>,
  rowIndex: number,
  cellIndex: number,
  cellHeaderCustom: AD.TableCellStyle.TableCellStyle | undefined,
  headingCellStyleDefault: AD.TableCellStyle.TableCellStyle
): AD.TableCellStyle.TableCellStyle {
  const pos = cellMapAbsolutePositions.get(`${cellIndex}-${rowIndex}`);

  if (!pos) {
    return cellHeaderCustom ?? headingCellStyleDefault;
  }

  const [absoluteX, absoluteY] = pos;
  const currentCell = cellMap.get(`${absoluteX}-${absoluteY}`);
  const colSpan = currentCell?.colSpan || 1;
  const headingCellStyleCustom = cellHeaderCustom
    ? ({
        ...cellHeaderCustom,
        borderColors: {
          top: "white",
          left: cellMap.get(`${absoluteX - 1}-${absoluteY}`)?.heading ? "white" : style.primaryColor,
          right: cellMap.get(`${absoluteX + colSpan}-${absoluteY}`)?.heading ? "white" : style.primaryColor,
          bottom: cellMap.get(`${absoluteX}-${absoluteY + 1}`)?.heading ? "white" : style.primaryColor,
        },
      } as AD.TableCellStyle.TableCellStyle)
    : cellHeaderCustom;

  const headingCellStyle = headingCellStyleCustom ? headingCellStyleCustom : headingCellStyleDefault;
  return headingCellStyle;
}

function createCellMap(
  tableRows: ReadonlyArray<ReadonlyArray<TableCell>>
): readonly [ReadonlyMap<string, readonly [number, number]>, ReadonlyMap<string, TableCell>] {
  const cellMap = new Map<string, TableCell>();
  const cellMapAbsolutePosition = new Map<string, [number, number]>();
  for (let rowIndex = 0; rowIndex < tableRows.length; rowIndex++) {
    const row = tableRows[rowIndex];

    let totalColSpan = 0;
    for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {
      while (cellMap.has(`${cellIndex + totalColSpan}-${rowIndex}`)) {
        totalColSpan += cellMap.get(`${cellIndex + totalColSpan}-${rowIndex}`)?.colSpan || 1;
      }

      const cell = row[cellIndex];
      cellMapAbsolutePosition.set(`${cellIndex}-${rowIndex}`, [cellIndex + totalColSpan, rowIndex]);
      for (let colSpanIndex = 0; colSpanIndex < (cell.colSpan || 1); colSpanIndex++) {
        for (let rowSpanIndex = 0; rowSpanIndex < (cell.rowSpan || 1); rowSpanIndex++) {
          cellMap.set(`${cellIndex + colSpanIndex + totalColSpan}-${rowIndex + rowSpanIndex}`, cell);
        }
      }
      totalColSpan += (cell.colSpan || 1) - 1;
    }
  }
  return [cellMapAbsolutePosition, cellMap];
}
