export type Patch<T> = {
  readonly [P in keyof T]?: T[P];
} & { readonly id: string };

export function mergePatches<T>(patches: ReadonlyArray<Patch<T>>): ReadonlyArray<Patch<T>> {
  const mergeMap = new Map<string, Patch<T>>();
  for (const patch of patches) {
    const merged = mergeMap.get(patch.id) || { id: patch.id };
    mergeMap.set(patch.id, { ...merged, ...patch });
  }
  const mergedPatches = Array.from(mergeMap.values());
  return mergedPatches;
}
