import { Dispatch } from "@typescript-tea/core";
import { SharedState } from "@rvs/client-infra";
import { Reports, Texts, UserSettings } from "@rvs/shared";
import { TranslateFn } from "@rvs/shared/src/lang-texts";
import React, { useEffect } from "react";
import * as State from "./state";
import { Button, H3, Checkbox, Spinner } from "../../../../elements";
import * as ProjectState from "../../project-state";

const texts = Texts.texts;

export function View(props: {
  readonly state: State.State;
  readonly projectState: ProjectState.State;
  readonly sharedState: SharedState.SharedState;
  readonly dispatch: Dispatch<State.Action>;
}): JSX.Element {
  const { state, dispatch, sharedState, projectState } = props;
  const { translate, userSettings } = sharedState;
  const { availableReports } = state;
  useEffect(() => {
    switch (state.printing.type) {
      case "Loading": {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const pdfKit = (window as any).PDFDocument;
        const reportParams = State.createReportParams(projectState.project, state.reportsBeingPrinted, sharedState);

        const date = new Date().toISOString().split("T")[0];
        const fileName = `RVS_${sanitizeFilename(projectState.project.name)}_${date}.pdf`;
        Reports.createPrintoutClient(
          projectState.project,
          state.printing.response,
          reportParams,
          fileName,
          pdfKit,
          () => dispatch(State.Action.ReportFinishedLoading())
        );
        break;
      }
      default:
        break;
    }
  }, [state.printing]);
  if (!availableReports) {
    return <Spinner />;
  }

  const disabledReports = getDisabledReports(projectState, translate);
  const selectedReports = UserSettings.selectSelectedReports(userSettings).filter(
    (report) => !disabledReports.find((disabled) => disabled.reportName === report)
  );

  const disablePrintout =
    selectedReports.length === 0 ||
    state.printing.type !== "Idle" ||
    projectState.project.systems.length === 0 ||
    projectState.project.systems.some((system) => system.rooms.length === 0);

  const title =
    projectState.project.systems.length === 0
      ? translate(texts.no_systems)
      : projectState.project.systems.some((system) => system.rooms.length === 0)
      ? translate(texts.system_without_rooms)
      : "";

  return (
    <div className="flex flex-col space-y-16">
      <H3>{translate(texts.select_reports)}</H3>
      <div className="flex flex-col space-y-4">
        <Checkbox
          checked={state.selectAllReports}
          label={translate(texts.all_reports)}
          onChange={() => dispatch(State.Action.SelectAllReports())}
        />
        {availableReports.map((row, id) => (
          <ReportRow
            key={id}
            selectedReports={selectedReports}
            disabledReports={disabledReports}
            reportName={row.report as Reports.ReportType}
            dispatch={dispatch}
            translate={translate}
          />
        ))}
      </div>
      <div className={"flex space-x-16"}>
        <Button
          disabled={disablePrintout}
          title={title}
          label={translate(texts.printout)}
          onClick={() => {
            dispatch(State.Action.CreatePrintout(selectedReports));
          }}
        />
        {state.printing.type !== "Idle" && <Spinner debounce={false} />}
      </div>
    </div>
  );
}

export function ReportRow({
  reportName,
  selectedReports,
  disabledReports,
  dispatch,
  translate,
}: {
  readonly reportName: Reports.ReportType;
  readonly selectedReports: ReadonlyArray<Reports.ReportType>;
  readonly disabledReports: ReadonlyArray<DisabledReport>;
  readonly dispatch: Dispatch<State.Action>;
  readonly translate: Texts.TranslateFn;
}): JSX.Element {
  const disabled = disabledReports.find((s) => s.reportName === reportName);
  return (
    <div className="ml-16">
      <Checkbox
        checked={selectedReports.some((s) => s === reportName)}
        disabled={!!disabled}
        error={disabled ? disabled.reason : ""}
        label={translate(texts.report_name(reportName))}
        onChange={(value) =>
          dispatch(value ? State.Action.SelectReports([reportName]) : State.Action.UnselectReports([reportName]))
        }
      />
    </div>
  );
}

type DisabledReport = {
  readonly reportName: Reports.ReportType;
  readonly reason: string;
};

function getDisabledReports(projectState: ProjectState.State, translate: TranslateFn): ReadonlyArray<DisabledReport> {
  const disabledReports: DisabledReport[] = [];

  const calcState = projectState.calculationStates;
  const systems = projectState.project.systems;

  if (systems.some((system) => !system.ventilationConcept)) {
    disabledReports.push({ reportName: "ventilation", reason: translate(texts.system_have_no_ventilation_concept) });
  }

  if (systems.some((system) => system.materials.length === 0)) {
    disabledReports.push({ reportName: "material-list", reason: translate(texts.system_have_no_materials) });
  }

  if (systems.some((system) => calcState[system.id].validation.hasErrors)) {
    disabledReports.push({ reportName: "air-distribution", reason: translate(texts.system_have_not_been_calculated) });
    disabledReports.push({ reportName: "nominal-flow", reason: translate(texts.system_have_not_been_calculated) });
  }

  return disabledReports;
}

function sanitizeFilename(filename: string | null): string {
  if (!filename) {
    return "";
  }
  const illegalRe = /[/?<>\\:*|"]/g;
  const reservedRe = /^\.+$/;
  return filename.replace(illegalRe, "").replace(reservedRe, "").replace(" ", "_").replace(".", "_");
}
