import { Project } from "../..";
import { calculateSystemDuctQuantity, Distributor, MaterialTables } from ".";
import { isNoNull, NoNull } from "../../graphql-utils";

export function updateDistributorQuantities(
  { items }: MaterialTables,
  materials: ReadonlyArray<Project.Material>,
  system: Project.System
): ReadonlyArray<Project.Material> {
  return materials.map((m) => {
    const item = items[m.itemNumber];
    if (item?.type === "distributor" && isNoNull(item)) {
      return {
        ...m,
        quantity: calculateDistributorSystemQuantity(item, system),
      };
    } else {
      return m;
    }
  });
}

export function calculateDistributorSystemQuantity(distributor: NoNull<Distributor>, system: Project.System): number {
  const { extractDucts, supplyDucts } = calculateSystemDuctQuantity(system);
  const extractQuantity = Math.ceil((extractDucts || 0) / distributor.number_of_connections);
  const supplyQuantity = Math.ceil((supplyDucts || 0) / distributor.number_of_connections);
  return extractQuantity + supplyQuantity;
}
