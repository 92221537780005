import * as React from "react";
import { AbstractDocJsx as ADX, AbstractDoc as AD } from "abstract-document";
import { CreatorType } from "./types";
import * as Common from "../common";
import { Project_ProjectQuery } from "../../generated/generated-operations";

const { AbstractDoc, Section, Paragraph, TextRun, render, Group } = ADX;

export const create: CreatorType = (props) => {
  const styles = Common.styles();
  const numberingDefinitions = Common.numberingDefinitions(styles);
  const fonts = Common.fonts(props.pageProps.fonts);

  const doc = render(
    <AbstractDoc fonts={fonts} styles={styles} numberingDefinitions={numberingDefinitions}>
      <Section id={Common.pageId(props.pageProps)} page={Common.cataloguePage(props.pageProps)}>
        <Paragraph>
          {props.data.map((m) => (
            <TextRun text={m} style={{ fontSize: 24, type: "TextStyle" }}></TextRun>
          ))}
        </Paragraph>
        {renderProject(props.projectData)}
      </Section>
    </AbstractDoc>
  );
  return doc;
};

function renderProject(project: Project_ProjectQuery): JSX.Element {
  if (!project.project) {
    return (
      <Paragraph>
        <TextRun text="" />
      </Paragraph>
    );
  }
  const paragraphStyle = AD.ParagraphStyle.create({ margins: AD.LayoutFoundation.create({ top: 10 }) });
  return (
    <Group>
      <Paragraph style={paragraphStyle}>
        <TextRun text={`project name: ${project.project.name}`} />
      </Paragraph>
      {project.project?.systems.map((system) => (
        <Group>
          <Paragraph style={paragraphStyle}>
            <TextRun text={`system name: ${system.name} `} />
          </Paragraph>
          {system.rooms.map((room) => (
            <Paragraph>
              <TextRun
                text={`room name: ${room.name}, apartment: ${room.apartment}, building: ${room.building}, floor: ${room.floor}`}
              />
            </Paragraph>
          ))}
        </Group>
      ))}
    </Group>
  );
}
