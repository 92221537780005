import { BitmapImage } from "./types";
import * as GQLOps from "../../../generated/generated-operations";
import { ImageData } from "../../../images";
import { HttpResponse } from "../../../query";

const maxImageWidth = 477;

export type ProductImage = GQLOps.Image_Module_ImagesFragment["image"][number];

export async function imagesToBitmapImages(
  maxWidth: number | undefined,
  maxHeight: number | undefined,
  images: ReadonlyArray<ImageData>
): Promise<ReadonlyArray<BitmapImage | undefined>> {
  const bitmaps = await Promise.all(
    images.map(async (image) => {
      const bitmap = image ? await imageToBitmapImage(maxWidth, maxHeight, image) : undefined;
      return bitmap;
    })
  );

  return bitmaps;
}

export async function imageToBitmapImage(
  maxWidth: number | undefined,
  maxHeight: number | undefined,
  image: ImageData | undefined
): Promise<BitmapImage | undefined> {
  const bitmap = image ? await imageRowToBitmapImage(maxWidth, maxHeight, image.image, image.imageData) : undefined;
  return bitmap;
}

export async function imageRowToBitmapImage(
  maxWidth: number | undefined,
  maxHeight: number | undefined,
  original: ProductImage,
  data: HttpResponse
): Promise<BitmapImage | undefined> {
  if (original.file_name && original.file_name.endsWith(".svg")) {
    return vectorImageRowToImage(maxWidth, maxHeight, original, data.response);
  } else {
    const images = await bitmapImageRowToImage(original, data.response, data.headers);
    return images;
  }
}

function detectSvgDimensions(data: Uint8Array): readonly [number | undefined, number | undefined] {
  const svg = new TextDecoder().decode(data.slice(0, 500));

  // Dimensions by width and height attributes in svg element
  const svgWidthMatch = /<svg[^>]*width\s*=\s*"?(\d+)"?[^>]*>/.exec(svg);
  const svgHeightMatch = /<svg[^>]*height\s*=\s*"?(\d+)"?[^>]*>/.exec(svg);
  const svgWidth = svgWidthMatch && svgWidthMatch[1] ? Number.parseInt(svgWidthMatch[1], 10) : undefined;
  const svgHeight = svgHeightMatch && svgHeightMatch[1] ? Number.parseInt(svgHeightMatch[1], 10) : undefined;
  if (svgWidth !== undefined || svgHeight !== undefined) {
    return [svgWidth, svgHeight];
  }

  // Dimensions by viewbox
  const viewBoxMatch = /<svg[^>]*viewBox\s*=\s*"?([\d|\s|.]+)"?[^>]*>/.exec(svg);
  const viewBoxParts = viewBoxMatch && viewBoxMatch[1] && viewBoxMatch[1].split(" ");
  const widthPart = viewBoxParts && viewBoxParts[viewBoxParts.length - 2];
  const heightPart = viewBoxParts && viewBoxParts[viewBoxParts.length - 1];
  const clipWidth = widthPart ? Number.parseFloat(widthPart) : undefined;
  const clipHeight = heightPart ? Number.parseFloat(heightPart) : undefined;
  return [clipWidth, clipHeight];
}

function vectorImageRowToImage(
  maxWidth: number | undefined,
  maxHeight: number | undefined,
  original: ProductImage,
  data: Uint8Array | undefined | null,
  ignorePageWidth: boolean = false
): BitmapImage | undefined {
  if (!original.image || !data) {
    return undefined;
  }
  //const img = new Image();
  //img.src;
  const width = maxWidth || maxImageWidth;
  const height = maxHeight || maxImageWidth;
  const [detectedWidth, detectedHeight] = detectSvgDimensions(data);
  if (!maxWidth || !maxHeight) {
    let svgWidth = detectedWidth || maxImageWidth;
    let svgHeight = detectedHeight || maxImageWidth;
    const aspect = svgWidth / svgHeight;
    if (!ignorePageWidth && svgWidth > maxImageWidth) {
      svgWidth = maxImageWidth;
      svgHeight = maxImageWidth / aspect;
    }
    return {
      name: original.name ?? "",
      file_name: original.file_name ?? "",
      type: original.type ?? "",
      format: "svg",
      width: svgWidth,
      height: svgHeight,
      data: data,
    };
  }

  if (detectedWidth && detectedHeight) {
    // Fit to dimensions
    let newWidth = detectedWidth;
    let newHeight = detectedHeight;
    const widthOver = detectedWidth / width;
    const heightOver = detectedHeight / height;
    if (widthOver > 1 || heightOver > 1) {
      if (widthOver > heightOver) {
        newWidth = width;
        newHeight = detectedHeight * (width / detectedWidth);
      } else {
        newWidth = detectedWidth * (height / detectedHeight);
        newHeight = height;
      }
    }
    return {
      name: original.name ?? "",
      file_name: original.file_name ?? "",
      type: original.type ?? "",
      format: "svg",
      width: newWidth,
      height: newHeight,
      data: data,
    };
  }
  return {
    name: original.name ?? "",
    file_name: original.file_name ?? "",
    type: original.type ?? "",
    format: "svg",
    width: width,
    height: height,
    data: data,
  };
}

async function bitmapImageRowToImage(
  original: ProductImage,
  data: Uint8Array | undefined | null,
  headers: Headers
  // ignorePageWidth: boolean = false
): Promise<BitmapImage | undefined> {
  if (!original.image || !data) {
    return undefined;
  }
  const buffer = Buffer.from(data);
  const imageHeaderWidth = headers.get("x-width");
  const imageHeaderHeight = headers.get("x-height");

  if (imageHeaderWidth === null || imageHeaderHeight === null) {
    return undefined;
    //throw new Error("Image headers x-width or x-height is not available");
  }

  let imageWidth = Number(imageHeaderWidth);
  let imageHeight = Number(imageHeaderHeight);

  const aspect = imageWidth / imageHeight;
  if (imageWidth > maxImageWidth) {
    imageWidth = maxImageWidth;
    imageHeight = maxImageWidth / aspect;
  } else {
    imageHeight = imageWidth / aspect;
  }

  return {
    name: original.name ?? "",
    file_name: original.file_name ?? "",
    type: original.type ?? "",
    format: "jpg",
    width: imageWidth,
    height: imageHeight,
    data: buffer,
  };
}
