import { Permissions } from "../project";
import { ReportType } from "../reports";
import { KeyParams } from "./normalized-text";

export type TextTypes = TextKey | Dummy;

export type TextKey = {
  readonly type: "TextKey";
  readonly key: string;
  readonly params: KeyParams;
};

export type Dummy = {
  readonly type: "Dummy";
  readonly key: string;
  readonly params: KeyParams;
};

export const key = (key: string, params: KeyParams = {}): TextKey => ({
  type: "TextKey",
  key,
  params,
});

export type TextKeyName = keyof typeof texts;

export const texts = {
  http_request_error: key("http_request_error"),
  rename_system: key("rename_system"),
  drag_and_drop_info_system: key("drag_and_drop_info_system"),
  drag_and_drop_info_room: key("drag_and_drop_info_room"),
  duplicate_system: key("duplicate_system"),
  duplicate_room: key("duplicate_room"),
  no_rooms_exists_on_this_system: key("no_rooms_exists_on_this_system"),
  air_distribution: key("air_distribution"),
  positioning_supply_exhaust_diffusers: key("positioning_supply_exhaust_diffusers"),
  over_pressure_note: key("over_pressure_note"),
  doors_with_seals: key("doors_with_seals"),
  doors_without_seals: key("doors_without_seals"),
  free_minimum_area_cm2: key("free_minimum_area_cm2"),
  over_pressure_flow: key("over_pressure_flow"),
  over_pressure: key("over_pressure"),
  sum: key("sum"),
  show_advanced: key("show_advanced"),
  article: key("article"),
  room_name: key("room_name"),
  air_flow: key("air_flow"),
  max_distance_pipes: key("max_distance_pipes"),
  assembly_instructions: key("assembly_instructions"),
  legend: key("legend"),
  legend_number_of_arrows: key("legend_number_of_arrows"),
  system_name: key("system_name"),
  systemair_rvs: key("systemair_rvs"),
  project_printout: key("project_printout"),
  project_list: key("project_list"),
  project: key("project"),
  printout: key("printout"),
  additional_documents: key("additional_documents"),
  additional_documents_note: key("additional_documents_note"),
  upload_document: key("upload_document"),
  download_document: key("download_document"),
  remove_document: key("remove_document"),
  logout: key("logout"),
  not_found_404: key("not_found_404"),
  create_project_from_template: key("create_project_from_template"),
  unlock_project: key("unlock_project"),
  unlock_template: key("unlock_template"),
  unlock_project_note: key("unlock_project_note"),
  cancel: key("cancel"),
  go_to_template: key("go_to_template"),
  go_to_project: key("go_to_project"),
  name: key("name"),
  systems: key("systems"),
  shares: key("shares"),
  supply: key("supply"),
  extract: key("extract"),
  remove_project: key("remove_project"),
  remove_template: key("remove_template"),
  save_as_template: key("save_as_template"),
  lock_project: key("lock_project"),
  lock_template: key("lock_template"),
  template_saved: key("template_saved"),
  project_created: key("project_created"),
  add_system: key("add_system"),
  remove_share: key("remove_share"),
  shared_with: key("shared_with"),
  permissions: key("permissions"),
  email: key("email"),
  share_project: key("share_project"),
  share_template: key("share_template"),
  add_room: key("add_room"),
  new_room: key("new_room"),
  remove_system: key("remove_system"),
  room: key("room"),
  remove_room: key("remove_room"),
  building: key("building"),
  floor: key("floor"),
  apartment: key("apartment"),
  loading: key("loading"),
  select_reports: key("select_reports"),
  confirm_delete_project: key("confirm_delete_project"),
  confirm_delete_template: key("confirm_delete_template"),
  confirm_delete_system: key("confirm_delete_system"),
  confirm_delete_share: key("confirm_delete_share"),
  delete: key("delete"),
  edit: key("edit"),
  template: key("template"),
  new_project: key("new_project"),
  my_projects: key("my_projects"),
  shared_by_others: key("shared_by_others"),
  shared_by_organisation: key("shared_by_organisation"),
  templates: key("templates"),
  search: key("search"),
  no_projects_found: key("no_projects_found"),
  locked: key("locked"),
  no_name: key("no_name"),
  user_settings: key("user_settings"),
  true: key("true"),
  false: key("false"),
  read: key("read"),
  write: key("write"),
  system: key("system"),
  report_cover_page_title: key("report_cover_page_title"),
  report_name: (reportName: ReportType) => key(`report_name_${reportName}`),
  permission_name: (permission: Permissions) => key(`permission_${permission}`),
  next: key("next"),
  previous: key("previous"),
  save: key("save"),
  error_supply_airflow_at_least: (min: number) => key("error_supply_airflow_at_least", { min }),
  error_exhaust_airflow_at_least: (min: number) => key("error_exhaust_airflow_at_least", { min }),
  error_custom_exhaust_airflow_at_least: (min: number) => key("error_custom_exhaust_airflow_at_least", { min }),
  error_value_required: key("error_value_required"),
  error_not_a_valid_number: key("error_not_a_valid_number"),
  error_number_must_be_positive: key("error_number_must_be_positive"),
  error_template_not_found: key("error_template_not_found"),
  error_supply_factor_range: (min: number, max: number) =>
    key(`error_supply_factor_range`, { min: Math.floor(min * 100) / 100, max: Math.floor(max * 100) / 100 }),
  property: (propertyName: string) => key(`property_${propertyName}`),
  general: key("general"),
  material_list: key("material_list"),
  position: key("position"),
  pos: key("pos"),
  item_number: key("item_number"),
  quantity: key("quantity"),
  single_cost: key("single_cost"),
  total_cost: key("total_cost"),
  single_price: key("single_price"),
  total_price: key("total_price"),
  margin: key("margin"),
  material_list_empty: key("material_list_empty"),
  no_systems: key("no_systems"),
  system_without_rooms: key("system_without_rooms"),
  created: key("created"),
  created_by: key("created_by"),
  last_edited: key("last_edited"),
  go_back: key("go_back"),
  go_back_to_project_list: key("go_back_to_project_list"),
  go_back_to_template_list: key("go_back_to_template_list"),
  pipe_diameter: key("pipe_diameter"),
  type: key("type"),
  articles: key("articles"),
  item_type: (itemType: string) => key(`item_type_${itemType}`),
  error_air_unit_range: (min: number, max: number) =>
    key(`error_air_unit_range`, { min: Math.floor(min * 100) / 100, max: Math.floor(max * 100) / 100 }),
  error_air_unit_max_airflow: (requiredAirflow: number, maxAirflow: number) =>
    key(`error_air_unit_max_airflow`, {
      required_airflow: Math.floor(requiredAirflow * 100) / 100,
      max_airflow: Math.floor(maxAirflow * 100) / 100,
    }),
  error_wrong_duct_diameter: key("error_wrong_duct_diameter"),
  error_quantity: (expected: number) => key(`error_quantity`, { expected }),
  error_valve_quantity: (expectedSupplyValves: number, expectedExtractValves: number) =>
    key(`error_valve_quantity`, { expectedSupplyValves, expectedExtractValves }),
  error_valve_connector_quantity: (expected: number) => key(`error_valve_connector_quantity`, { expected }),
  error_duct_quantity: (expected: number) => key(`error_duct_quantity`, { expected }),
  error_item_is_required: key("error_item_is_required"),
  error_distributor_quantity: (expected: number) => key(`error_distributor_quantity`, { expected }),
  error_cant_be_zero: key("error_cant_be_zero"),
  error_not_a_valid_option: key("error_not_a_valid_option"),
  change_air_unit: key("change_air_unit"),
  select_air_unit: key("select_air_unit"),
  update_quantities: key("update_quantities"),
  add_new_items: key("add_new_items"),
  update_prices: key("update_prices"),
  air_unit_not_compatible: key("air_unit_not_compatible"),
  quantities_not_matching_requirements: key("quantities_not_matching_requirements"),
  material_list_not_compatible_with_duct: key("material_list_not_compatible_with_duct"),
  update_material_list: key("update_material_list"),
  error_when_requesting_air_units: key("error_when_requesting_air_units"),
  no_air_units_found: key("no_air_units_found"),
  error_when_requesting_prices: key("error_when_requesting_prices"),
  m3_item_number: key("m3_item_number"),
  item_name: key("item_name"),
  item_description: key("item_description"),
  select: key("select"),
  share_project_mail_subject: key("share_project_mail_subject"),
  share_project_mail_body: (userEmail: string, projectUrl: string) =>
    key(`share_project_mail_body`, { userEmail, projectUrl }),
  project_info: key("project_info"),
  project_name: key("project_name"),
  info_register_shared_project: (url: string, email: string) => key("info_register_shared_project", { url, email }),
  material_category: (categoryName: string) => key(`material_category_${categoryName}`),
  package_name: (packageName: string) => key(`package_name_${packageName}`),
  unable_to_calculate_system: key("unable_to_calculate_system"),
  download_gaeb: key("download_gaeb"),
  details: key("details"),
  project_details: key("project_details"),
  customer_details: key("customer_details"),
  street: key("street"),
  postal_code: key("postal_code"),
  city: key("city"),
  country: key("country"),
  gaeb: key("gaeb"),
  gaeb_format: key("gaeb_format"),
  cant_share_with_yourself: key("cant_share_with_yourself"),
  email_already_has_share: key("email_already_has_share"),
  people: key("people"),
  frontpage_title: key("frontpage_title"),
  table_of_contents: key("table_of_contents"),
  all_reports: key("all_reports"),
  total_area: key("total_area"),
  air_change_rate: key("air_change_rate"),
  heading_nominal_flow_rate: key("heading_nominal_flow_rate"),
  nominal_flow_rate: key("nominal_flow_rate"),
  area: key("area"),
  room_area: key("room_area"),
  report_room_type_description: key("report_room_type_description"),
  report_ventilation_type: key("report_ventilation_type"),
  report_ventilation_moisture: key("report_ventilation_moisture"),
  report_ventilation_reduced: key("report_ventilation_reduced"),
  report_ventilation_nominal: key("report_ventilation_nominal"),
  report_ventilation_intensive: key("report_ventilation_intensive"),
  report_airflow: key("report_airflow"),
  report_air_change: key("report_air_change"),
  report_nominal_airflow_heading: key("report_nominal_airflow_heading"),
  report_airflow_area_note: key("report_airflow_area_note"),
  report_nominal_airflow: key("report_nominal_airflow"),
  report_exhaust_airflow_sum_note: key("report_exhaust_airflow_sum_note"),
  report_ventilation_results_heading: key("report_ventilation_results_heading"),
  report_ventilation_nominal_af_note: key("report_ventilation_nominal_af_note"),
  report_unit_airflow_heading: key("report_unit_airflow_heading"),
  report_unit_airflow: key("report_unit_airflow"),
  report_calculation_error_note: key("report_calculation_error_note"),
  sucessfully_exported_to_crm: key("sucessfully_exported_to_crm"),
  failed_to_export_crm_message: key("failed_to_export_crm_message"),
  export_to_crm: key("export_to_crm"),
  customer_number: key("customer_number"),
  customer_number_override: key("customer_number_override"),
  project_delete_message: key("project_delete_message"),
  project_no_access_message: key("project_no_access_message"),
  project_not_found: key("project_not_found"),
  set_valve_type_all_rooms: key("set_valve_type_all_rooms"),
  airflow_difference_warning: key("airflow_difference_warning"),
  system_type: key("system_type"),
  max_one_item_can_be_selected: key("max_one_item_can_be_selected"), // This category is limited to one selection and another item is already selected in this category
  too_many_selections_message: key("too_many_selections_message"), // Multiple items has been selected in categories limited to one item
  valve_type_name: (valveType: string) => key(`valve_type_${valveType}`),
  valve_type_desc: (valveType: string) => key(`valve_type_desc_${valveType}`),
  valve: key("valve"),
  valve_number: key("valve_number"),
  max_airflow: key("max_airflow"),
  calculation_summary: key("calculation_summary"),
  material_summary: key("material_summary"),
  customer_number_status: key("customer_number_status"),
  no_customer_number_message: key("no_customer_number_message"),
  add_customer_number: key("add_customer_number"),
  customer_number_processed_message: key("customer_number_processed_message"),
  discount: key("discount"),
  itemName: (itemNumber: string) => key(`itemName_${itemNumber}`),
  itemDescription: (itemNumber: string) => key(`itemDescription_${itemNumber}`),
  update_customer_number_from_crm: key("update_customer_number_from_crm"),
  clear_customer_number: key("clear_customer_number"),
  change_to_crm_customer_number: key("change_to_crm_customer_number"),
  change_to_account_customer_number: key("change_to_account_customer_number"),
  select_item_number: key("select_item_number"),
  item_number_required: key("item_number_required"),
  new_items_available: key("new_items_available"),
  item_has_been_removed: key("item_has_been_removed"),
  remove_custom_material: key("remove_custom_material"),
  display_product_images: key("display_product_images"),
  result_total_area: key("result_total_area"),
  result_total_volume: key("result_total_volume"),
  system_have_no_ventilation_concept: key("system_have_no_ventilation_concept"),
  system_have_no_materials: key("system_have_no_materials"),
  system_have_not_been_calculated: key("system_have_not_been_calculated"),
  airflow_rates_summary: key("airflow_rates_summary"),

  // Ventilation concept
  ventilation_concept_not_available: key("ventilation_concept_not_available"),
  auto_set_wind_conditions_info_note: key("auto_set_wind_conditions_info_note"),
  ventilation_concept_update_using_postal_code: key("ventilation_concept_update_using_postal_code"),
  ventilation_concept: key("ventilation_concept"),
  ventilation_concept_title_building_data: key("ventilation_concept_title_building_data"),
  ventilation_concept_title_energy_standard: key("ventilation_concept_title_energy_standard"),
  ventilation_concept_title_wind_conditions: key("ventilation_concept_title_wind_conditions"),
  ventilation_concept_title_building_type: key("ventilation_concept_title_building_type"),
  ventilation_concept_title_tightness_of_building: key("ventilation_concept_title_tightness_of_building"),
  ventilation_concept_title_air_tightness: key("ventilation_concept_title_air_tightness"),
  ventilation_concept_title_thermal_protection: key("ventilation_concept_title_thermal_protection"),
  ventilation_concept_title_occupancy: key("ventilation_concept_title_occupancy"),
  ventilation_concept_title_rooms_without_windows: key("ventilation_concept_title_rooms_without_windows"),
  ventilation_concept_title_furnace: key("ventilation_concept_title_furnace"),
  ventilation_concept_title_higher_requirements: key("ventilation_concept_title_higher_requirements"),
  ventilation_concept_title_results: key("ventilation_concept_title_results"),
  ventilation_concept_yes: key("ventilation_concept_yes"),
  ventilation_concept_no: key("ventilation_concept_no"),
  vc_copy_building_data_from_system: key("vc_copy_building_data_from_system"),
  vc_thermal_protection_high: key("vc_thermal_protection_high"),
  vc_thermal_protection_low: key("vc_thermal_protection_low"),
  vc_thermal_protection_high_description: key("vc_thermal_protection_high_description"),
  vc_thermal_protection_low_description: key("vc_thermal_protection_low_description"),
  vc_wind_conditions_low: key("vc_wind_conditions_low"),
  vc_wind_conditions_high: key("vc_wind_conditions_high"),
  vc_building_type_single_floor: key("vc_building_type_single_floor"),
  vc_building_type_multi_floor: key("vc_building_type_multi_floor"),
  vc_building_tightness_standard: key("vc_building_tightness_standard"),
  vc_building_tightness_manually: key("vc_building_tightness_manually"),
  vc_occupany_low: key("vc_occupany_low"),
  vc_occupany_high: key("vc_occupany_high"),
  vc_higher_requirements_sound: key("vc_higher_requirements_sound"),
  vc_higher_requirements_air_quality: key("vc_higher_requirements_air_quality"),
  vc_higher_requirements_energy_efficiency: key("vc_higher_requirements_energy_efficiency"),
  vc_status_infiltration_below_required: key("vc_status_infiltration_below_required"),
  vc_status_higher_requirements_selected: key("vc_status_higher_requirements_selected"),
  vc_status_rooms_without_windows: key("vc_status_rooms_without_windows"),
  vc_status_building_has_a_furnace: key("vc_status_building_has_a_furnace"),
  vc_status_airflow_for_moisture_protection: key("vc_status_airflow_for_moisture_protection"),
  vc_status_airflow_through_infiltration: key("vc_status_airflow_through_infiltration"),
  vc_measure_requirements: key("vc_measure_requirements"),
  vc_measure_description: key("vc_measure_description"),
  vc_increased_requirement_air_sound_energy: key("vc_increased_requirement_air_sound_energy"),
  vc_increased_requirement_moisture_protection: key("vc_increased_requirement_moisture_protection"),
  vc_recommend_ventilation_system: key("vc_recommend_ventilation_system"),
  vc_proof_of_ventilation_technology: key("vc_proof_of_ventilation_technology"),
  request_quote_email_text: (projectLink: string, email: string) =>
    key("request_quote_email_text", { projectLink, email }),
  request_quote_email_subject: key("request_quote_email_subject"),
  request_quote_success: key("request_quote_success"),
  request_quote_failed: key("request_quote_failed"),
  request_quote: key("request_quote"),
  request_quote_note: key("request_quote_note"),
  request_quote_link_to_project: key("request_quote_link_to_project"),
  requested_for_quote: key("requested_for_quote"),
  last_quote_requested: key("last_quote_requested"),
  current_quote_requests: key("current_quote_requests"),
  requested_by: key("requested_by"),
  add_custom_item: key("add_custom_item"),
  contact_systemair_message: key("contact_systemair_message"),
  fields_general_tab_must_be_entered: key("fields_general_tab_must_be_entered"),
};
