import { isNoNull, NoNull } from "../../graphql-utils";
import { logWarn, Project } from "../..";
import { Duct, MaterialTables } from "./types";
import { isExtractRoom, isSupplyRoom } from "../../project";

export function updateDuctQuantities(
  { items, tables }: MaterialTables,
  materials: ReadonlyArray<Project.Material>,
  system: Project.System
): ReadonlyArray<Project.Material> {
  const materialsWithId = materials.map((m, idx) => ({ material: m, id: idx }));
  const ductMaterial = materialsWithId.find(
    ({ material }) => items[material.itemNumber]?.type === "duct" && material.included
  );
  if (!ductMaterial) {
    // Nothing selected to update
    return materials;
  }

  const duct = tables.ductTable.find((r) => r.item_number === ductMaterial.material.itemNumber);

  let ductQuantity: number | undefined = undefined;
  if (duct && isNoNull(duct)) {
    ductQuantity = calculateDuctRollSystemQuantity(duct, system);
  } else {
    logWarn(`Missing data: duct:${JSON.stringify(duct)}`);
  }

  return materialsWithId.map(({ material, id }) => {
    if (ductMaterial.id === id) {
      return {
        ...material,
        quantity: ductQuantity || 0,
      };
    } else {
      return material;
    }
  });
}

export function calculateDuctRollSystemQuantity(duct: NoNull<Duct>, system: Project.System): number | undefined {
  const ductQuantity = calculateSystemDuctQuantity(system);
  const resultSum = (ductQuantity.supplyDucts || 0) + (ductQuantity.extractDucts || 0);
  const ductPieceLength = 14;
  const ductRollQuantity = Math.ceil((resultSum * ductPieceLength) / duct.quantity);
  return ductRollQuantity;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isCompatibleWithDuctDiameter(item: any, ductDiameter: number): boolean {
  const key = `duct_${ductDiameter}`;
  if (item[key] === undefined) {
    return true;
  }
  return item[key] === "1";
}

export function calculateSystemDuctQuantity(system: Project.System): {
  readonly supplyDucts: number | undefined;
  readonly extractDucts: number | undefined;
} {
  const supplyDucts = system.rooms
    .filter((r) => isSupplyRoom(r))
    .map((room) => room.ducts || 0)
    .reduce<number | undefined>((sum, ductQuantity) => (sum = (sum || 0) + (ductQuantity || 0)), undefined);
  const extractDucts = system.rooms
    .filter((r) => isExtractRoom(r))
    .map((room) => room.ducts || 0)
    .reduce<number | undefined>((sum, ductQuantity) => (sum = (sum || 0) + (ductQuantity || 0)), undefined);
  return {
    supplyDucts,
    extractDucts,
  };
}
