import { isExtractRoom, isSupplyRoom, System } from "../../project";

export function calculateSystemValveQuantity(system: System): {
  readonly supplyValves: number;
  readonly extractValves: number;
} {
  const supplyValves = system.rooms.filter((r) => isSupplyRoom(r)).reduce((sum, room) => sum + (room.valves || 0), 0);
  const extractValves = system.rooms.filter((r) => isExtractRoom(r)).reduce((sum, room) => sum + (room.valves || 0), 0);
  return { supplyValves, extractValves };
}
