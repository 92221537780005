import { Project, Markets } from "../..";
import { categorizeMaterials } from "../functions";
import { MaterialTables } from "../types";

export function sortList(
  sortNos: ReadonlyMap<string, number>,
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<Project.Material> {
  const customOrder = new Map(materials.filter((m) => m.type === "custom").map((m, i) => [m.id, i + 9999]));
  return materials
    .slice(0)
    .sort((a, b) => {
      const aSortNo = (a.type === "custom" ? customOrder.get(a.id) : sortNos.get(a.itemNumber || "")) ?? 99999;
      const bSortNo = (b.type === "custom" ? customOrder.get(b.id) : sortNos.get(b.itemNumber || "")) ?? 99999;
      return aSortNo - bSortNo;
    })
    .map((m, i) => ({ ...m, sortNo: i }));
}

export function sortListUiOrder(
  market: Markets.Market,
  materialTables: MaterialTables,
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<Project.Material> {
  const categories = categorizeMaterials(market, materialTables, materials);

  const sortedMaterials = [];
  for (const category of categories) {
    for (const material of category.materials) {
      sortedMaterials.push(material);
    }
  }

  return sortedMaterials;
}
