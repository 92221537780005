import * as React from "react";
import { AbstractDocJsx as ADX, AbstractDoc as AD } from "abstract-document";

const { Paragraph, TextRun } = ADX;

export function h1(text: string): JSX.Element {
  return (
    <Paragraph styleName={"H1"}>
      <TextRun text={text} />
    </Paragraph>
  );
}

export function h2(text: string): JSX.Element {
  return (
    <Paragraph styleName={"H2"}>
      <TextRun text={text} />
    </Paragraph>
  );
}

export function textRun(text: string): JSX.Element {
  return (
    <Paragraph>
      <TextRun text={text} />
    </Paragraph>
  );
}

export function boldText(text: string): JSX.Element {
  return (
    <Paragraph
      style={AD.ParagraphStyle.create({
        textStyle: AD.TextStyle.create({ bold: true }),
        margins: { top: 10, bottom: 0, right: 0, left: 0 },
      })}
    >
      <TextRun text={text} />
    </Paragraph>
  );
}
