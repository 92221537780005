import { DocumentNode } from "graphql/language/ast";
import gql from "graphql-tag";

export const updateProjectMutation: DocumentNode = gql`
  mutation Project_updateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
    }
  }
`;

export const updateRoomMutation = gql`
  mutation Project_updateRoom($input: UpdateRoomInput!) {
    updateRoom(input: $input) {
      id
    }
  }
`;

export const updateRoomsMutation = gql`
  mutation Project_updateRooms($input: UpdateRoomsInput!) {
    updateRooms(input: $input) {
      id
    }
  }
`;
