import { AbstractDoc as AD } from "abstract-document";
import { Paragraph, TextRun } from "abstract-document/lib/abstract-document-jsx";
import * as R from "ramda";
import React from "react";
import { style } from "../../style";
import { FontData } from "../types";

export function createTextStyle(
  bold?: boolean,
  italic?: boolean,
  fontSize?: number,
  color?: string,
  underline?: boolean,
  mediumBold?: boolean
): AD.TextStyle.TextStyle {
  return AD.TextStyle.create({
    underline: underline,
    fontFamily: "DaxlineOffcPro",
    ...(fontSize !== undefined ? { fontSize: fontSize } : {}),
    ...(bold !== undefined ? { bold: bold } : {}),
    ...(mediumBold !== undefined ? { mediumBold: mediumBold } : {}),
    ...(italic !== undefined ? { italic: italic } : {}),
    color: color,
  });
}

export function createStyles(
  tuples: ReadonlyArray<readonly [string, AD.Style.Style]>
): AD.Types.Indexer<AD.Style.Style> {
  return R.fromPairs(
    tuples.map(
      (s) => [AD.StyleKey.create(s[1].type, s[0]), s[1]] as R.KeyValuePair<AD.StyleKey.StyleKey, AD.Style.Style>
    )
  );
}

export function createParagraphStyle(
  bold: boolean,
  italic: boolean,
  fontSize?: number,
  spacingBefore?: number,
  spacingAfter?: number,
  color?: string,
  alignment?: AD.ParagraphStyle.TextAlignment,
  underline?: boolean,
  mediumBold?: boolean
): AD.ParagraphStyle.ParagraphStyle {
  return AD.ParagraphStyle.create({
    margins: AD.LayoutFoundation.create({
      top: spacingBefore,
      bottom: spacingAfter ?? 2,
    }),
    alignment: alignment,
    textStyle: createTextStyle(bold, italic, fontSize, color, underline, mediumBold),
  });
}

export function headerTitleMain(
  text: string,
  spacingAfter?: number,
  fontSize?: number,
  mediumBold?: boolean
): React.ReactElement<{}> {
  return (
    <Paragraph
      style={createParagraphStyle(
        mediumBold ? false : true,
        false,
        fontSize ?? 14,
        0,
        spacingAfter ?? 4,
        style.primaryColor,
        undefined,
        undefined,
        mediumBold ? true : false
      )}
    >
      <TextRun text={text} />
    </Paragraph>
  );
}

export function headerTitle(text: string, spacingAfter?: number, fontSize?: number): React.ReactElement<{}> {
  return (
    <Paragraph style={createParagraphStyle(false, false, fontSize ?? 10, 0, spacingAfter ?? 4, style.primaryColor)}>
      <TextRun text={text} />
    </Paragraph>
  );
}

export function fonts(font: FontData): AD.Types.Indexer<AD.Font.Font> {
  if (!font.normal) {
    return {
      DaxlineOffcPro: AD.Font.create({
        normal: "Helvetica",
        bold: "Helvetica",
        italic: "Helvetica",
        boldItalic: "Helvetica",
      }),
    };
  }
  return {
    DaxlineOffcPro: AD.Font.create(font),
  };
}

export function styles(): AD.Types.Indexer<AD.Style.Style> {
  return { ...standardStyles(), ...defaultStyles() };
}

export function stylesWithSmallText(): AD.Types.Indexer<AD.Style.Style> {
  return { ...standardStyles(), ...defaultStyles(7) };
}

function defaultStyles(fontSize: number = 8): AD.Types.Indexer<AD.Style.Style> {
  const paragraphStyle = createParagraphStyle(false, false, fontSize, 0, 4, "black");
  const textStyle = createTextStyle(false, false, fontSize, "black");
  const tableStyle = AD.TableStyle.create();
  const tableCellStyle = AD.TableCellStyle.create();
  return {
    [AD.StyleKey.create(paragraphStyle.type, "Default")]: paragraphStyle,
    [AD.StyleKey.create(textStyle.type, "Default")]: textStyle,
    [AD.StyleKey.create(tableStyle.type, "Default")]: tableStyle,
    [AD.StyleKey.create(tableCellStyle.type, "Default")]: tableCellStyle,
  };
}

function standardStyles(): AD.Types.Indexer<AD.Style.Style> {
  const styles2 = createStyles([
    ["H1", createTextStyle(false, false, 16, style.primaryColor)],
    ["H1", createParagraphStyle(false, false, 16, 15, 7, style.primaryColor)],
    ["H2", createTextStyle(false, false, 14, style.primaryColor)],
    ["H2", createParagraphStyle(false, false, 14, 15, 7, style.primaryColor)],
    ["H3", createTextStyle(false, false, 12, style.primaryColor)],
    ["H3", createParagraphStyle(false, false, 12, 8, 7, style.primaryColor)],
    ["H4", createTextStyle(false, false, 10, style.primaryColor)],
    ["H4", createParagraphStyle(false, false, 10, 10, 7, style.primaryColor)],
    ["H5", createTextStyle(false, false, 9, style.primaryColor)],
    ["H5", createParagraphStyle(false, false, 9, 10, 7, style.primaryColor)],
    ["H6", createTextStyle(true, false, 7, "black")],
    ["H6", createParagraphStyle(true, false, 7, 10, 7, "black")],
    ["Emphasis", createTextStyle(false, true)],
    ["Emphasis", createParagraphStyle(false, true)],
    ["Strong", createTextStyle(true, false)],
    ["Strong", createParagraphStyle(true, false)],
    ["Emphasis", createTextStyle(false, true)],
    ["Emphasis", createTextStyle(false, true)],
    [
      "Subscript",
      {
        ...createTextStyle(),
        subScript: true,
        verticalPosition: -0.65,
        fontScale: 0.7,
      },
    ],
    [
      "Superscript",
      {
        ...createTextStyle(),
        superScript: true,
        verticalPosition: -0.1,
        fontScale: 0.7,
      },
    ],
    ["Description", createTextStyle(false, true, 7)],
    ["Description", createParagraphStyle(false, true, 7, 1, 3)],
  ]);
  return styles2;
}
