import gql from "graphql-tag";

export const schema = gql`
  extend type Mutation {
    createVentilationConceptProject(
      input: CreateVentilationConceptProjectInput!
    ): CreateVentilationConceptProjectPayload!
    removeVentilationConceptProject(
      input: RemoveVentilationConceptProjectInput!
    ): RemoveVentilationConceptProjectPayload!
    updateVentilationConceptProject(
      input: UpdateVentilationConceptProjectInput!
    ): UpdateVentilationConceptProjectPayload!
    createVentilationConceptSystem(input: CreateVentilationConceptSystemInput!): CreateVentilationConceptSystemPayload!
    removeVentilationConceptSystem(input: RemoveVentilationConceptSystemInput!): RemoveVentilationConceptSystemPayload!
    updateVentilationConceptSystem(input: UpdateVentilationConceptSystemInput!): UpdateVentilationConceptSystemPayload!
  }

  # Ventilation concept project level
  type VentilationConceptProject {
    id: ID!
    windConditions: String
    buildingType: String
    buildingTightnessInput: String
    buildingTightness: Float
    thermalProtection: String
  }

  input CreateVentilationConceptProjectInput {
    id: ID!
    projectId: ID!
    windConditions: String
    buildingType: String
    buildingTightnessInput: String
    buildingTightness: Float
    thermalProtection: String
  }

  type CreateVentilationConceptProjectPayload {
    id: ID!
  }

  input RemoveVentilationConceptProjectInput {
    id: ID!
  }

  type RemoveVentilationConceptProjectPayload {
    id: ID!
  }

  input UpdateVentilationConceptProjectInput {
    id: ID!
    windConditions: String
    buildingType: String
    buildingTightnessInput: String
    buildingTightness: Float
    thermalProtection: String
  }

  type UpdateVentilationConceptProjectPayload {
    id: ID!
  }

  # Ventilation concept system level
  type VentilationConceptSystem {
    id: ID!
    name: String
    totalArea: Float
    totalVolume: Float
    occupancy: String
    windowlessRooms: Boolean
    furnace: Boolean
    higherRequirementsSound: Boolean
    higherRequirementsAirQuality: Boolean
    higherRequirementsEnergyEfficiency: Boolean
    requiredAirflow: Float
    infiltrationAirflow: Float
    airflowStatus: String
    higherRequirementsStatus: String
    windowlessRoomsStatus: String
    furnaceStatus: String
    finalStatus: String
  }

  input CreateVentilationConceptSystemInput {
    id: ID!
    systemId: ID!
    name: String
    totalArea: Float
    totalVolume: Float
    occupancy: String
    windowlessRooms: Boolean
    furnace: Boolean
    higherRequirementsSound: Boolean
    higherRequirementsAirQuality: Boolean
    higherRequirementsEnergyEfficiency: Boolean
    requiredAirflow: Float
    infiltrationAirflow: Float
    airflowStatus: String
    higherRequirementsStatus: String
    windowlessRoomsStatus: String
    furnaceStatus: String
    finalStatus: String
  }

  type CreateVentilationConceptSystemPayload {
    id: ID!
  }

  input RemoveVentilationConceptSystemInput {
    id: ID!
  }

  type RemoveVentilationConceptSystemPayload {
    id: ID!
  }

  input UpdateVentilationConceptSystemInput {
    id: ID!
    name: String
    totalArea: Float
    totalVolume: Float
    occupancy: String
    windowlessRooms: Boolean
    furnace: Boolean
    higherRequirementsSound: Boolean
    higherRequirementsAirQuality: Boolean
    higherRequirementsEnergyEfficiency: Boolean
    requiredAirflow: Float
    infiltrationAirflow: Float
    airflowStatus: String
    higherRequirementsStatus: String
    windowlessRoomsStatus: String
    furnaceStatus: String
    finalStatus: String
  }

  type UpdateVentilationConceptSystemPayload {
    id: ID!
  }
`;
