import { graphQLMutationWithAuth, SharedState } from "@rvs/client-infra";
import { Cmd } from "@typescript-tea/core";
import { v4 as uuid } from "uuid";
import { Action, State } from "../state";
import * as GQLOps from "../../../../generated/generated-operations";
import * as M from "../mutations";

export function RequestQuote(
  _: ReturnType<typeof Action.RequestQuote>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  if (!sharedState.market.requestQuoteEmail) {
    return [state];
  }
  return [
    { ...state, requestQuoteStatus: "sending" },
    graphQLMutation<
      GQLOps.ProjectState_CreateQuoteRequestMutation,
      GQLOps.ProjectState_CreateQuoteRequestMutationVariables,
      Action
    >(
      M.createQuoteRequestMutation,
      {
        input: {
          id: uuid(),
          market: sharedState.market.name,
          projectId: state.project.id,
        },
      },
      sharedState.market.name,
      (data) => Action.RequestQuoteResponseReceived(data)
    ),
  ];
}

export function RequestQuoteResponseReceived(
  action: ReturnType<typeof Action.RequestQuoteResponseReceived>,
  state: State,
  _sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  if (action.mutation.createQuoteRequest.quoteRequestSent) {
    return [
      {
        ...state,
        requestQuoteStatus: "sent_ok",
        project: {
          ...state.project,
          userQuoteRequest: action.mutation.createQuoteRequest.quoteRequest || state.project.userQuoteRequest,
          organizationQuoteRequests: state.project.organizationQuoteRequests.map((qr) =>
            qr.id === action.mutation.createQuoteRequest.quoteRequest?.id
              ? action.mutation.createQuoteRequest.quoteRequest
              : qr
          ),
        },
      },
    ];
  } else {
    return [{ ...state, requestQuoteStatus: "sent_error" }];
  }
}

//
