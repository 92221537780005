import { Result } from "..";

export function calculateAirflowDiff(result: Result): number | undefined {
  const supplyAirflow = result?.supplyAirflow;
  const extractAirflow = result?.extractAirflow;
  if (supplyAirflow === undefined || extractAirflow === undefined) {
    return undefined;
  }
  const minValue = Math.min(supplyAirflow, extractAirflow);
  const maxValue = Math.max(supplyAirflow, extractAirflow);
  const difference = 1 - minValue / maxValue;
  const diffPercent = difference * 100;
  return diffPercent;
}

export function checkSupplyExtractAfDiff(result: Result): boolean {
  const diff = calculateAirflowDiff(result);
  if (diff && diff > 50) {
    return false;
  } else {
    return true;
  }
}
