import { Room, RoomTemplatesTable, isExtractRoom } from "../../../project";
import { BalanceSupplyAirflowsResult } from "./balance-supply-airflow";
import { ExtractBalanceResult } from "./balance-extract-airflow";
import { logWarn } from "../../..";

export function calculateRoomAirflow(
  balanceSupplyResult: BalanceSupplyAirflowsResult | undefined,
  balanceExtractResult: ExtractBalanceResult | undefined,
  room: Room
): number {
  const airflow =
    balanceSupplyResult?.resultByRoomId.get(room.id)?.airflow ||
    balanceExtractResult?.resultByRoomId.get(room.id)?.airflow ||
    (room.customAirFlow ? room.airflow : undefined) ||
    0;
  return airflow;
}

export function calculateNominalAirflow(roomTemplatesTable: RoomTemplatesTable, room: Room): number | undefined {
  const nominalAirflow = roomTemplatesTable.find((t) => t.key === room.roomType)?.default_extract_airflow || undefined;
  if (nominalAirflow === undefined && isExtractRoom(room)) {
    logWarn(`No default 'default_extract_airflow' found for room type '${room.roomType}'`);
  }
  return nominalAirflow;
}

export function calculateForcedMinAirflow(roomTemplatesTable: RoomTemplatesTable, room: Room): number {
  if (!isExtractRoom(room)) {
    return 0;
  }
  const forcedMinAirflow = roomTemplatesTable.find((t) => t.key === room.roomType)?.forced_min_airflow || undefined;
  return forcedMinAirflow || 0;
}
