import { v4 as uuid } from "uuid";
import { MarketValveTypesTable } from "..";
import { Texts } from "../..";
import { TranslateFn } from "../../lang-texts";
import { logWarn } from "../../logger";
import { Room, RoomFloorTable, RoomTemplatesTable } from "../types";
import { Patch } from "./patch";

export function createRoom(
  roomFloorTable: RoomFloorTable,
  roomTemplatesTable: RoomTemplatesTable,
  marketValveTypes: MarketValveTypesTable,
  market: string,
  sortNo: number,
  translate: TranslateFn
): Room {
  const floorType = roomFloorTable[0].text_id || "";
  const template =
    roomTemplatesTable.find((t) => t.key === "supply_room" && t.market === market) ||
    roomTemplatesTable.find((t) => t.market === market) ||
    roomTemplatesTable[0];
  const defaultValveType = marketValveTypes.find((r) => r.market_name === market)?.type || null;
  return {
    id: uuid(),
    sortNo: sortNo,
    name: translate((template.key && Texts.key(template.key)) || Texts.texts.new_room),
    building: "",
    floor: "",
    apartment: "",
    roomType: template.key || "",
    airType: template.air_type || "",
    floorType: floorType,
    supplyAirFactor: template.default_supply_air_factor,
    airflow: 0,
    ceilingHeight: template.default_height || 2.5,
    floorArea: 0,
    width: null,
    length: null,
    volume: null,
    people: null,
    valves: null,
    ducts: null,
    valveType: defaultValveType,
    customAirFlow: null,
    customValves: null,
  };
}

// TODO: Can this logic be moved to the calculator code so everything is at the same place?
export function updateRoom<T extends keyof Room>(
  roomTemplatesTable: RoomTemplatesTable,
  market: string,
  room: Room,
  property: T,
  value: Room[T],
  translate: TranslateFn
): Patch<Room> {
  switch (property) {
    case "roomType": {
      const template = roomTemplatesTable.find((r) => r.key === value && r.market === market);
      if (!template) {
        logWarn(`Template '${value}' not found. Market: ${market}`);
        return { id: room.id };
      }
      const roomType = template.key;
      const airType = template.air_type;
      const ceilingHeight = template.default_height;
      const supplyAirFactor = template.default_supply_air_factor;
      if (roomType === null || airType === null || ceilingHeight === null) {
        logWarn(`Template missing data for template '${value}'. Market: ${market}`);
        return { id: room.id };
      }
      return {
        id: room.id,
        name: value && typeof value === "string" ? translate(Texts.key(value)) : translate(Texts.texts.new_room),
        roomType,
        airType,
        ceilingHeight,
        supplyAirFactor,
      };
    }
    default:
      return { id: room.id, [property]: value };
  }
}
