import { AbstractDocExporters } from "abstract-document";
import * as Stream from "stream";
import fs from "fs";
import { createDocument } from "./report-runner-shared";
import { ProjectReportParams, ReportQueryResponse } from "./types";
import { Project } from "..";

export async function createPrintoutServer(
  project: Project.Project,
  responses: ReportQueryResponse,
  projectReportParams: ReadonlyArray<ProjectReportParams>,
  pdfKit: {}
): Promise<void> {
  const doc = await createDocument(project, responses, projectReportParams);
  const stream = new Stream.PassThrough();

  AbstractDocExporters.Pdf.exportToStream(pdfKit, stream, doc);
  streamToBuffer(stream).then((data) =>
    fs.writeFile("test.pdf", data, (err) => {
      if (err) {
        throw new Error();
      }
      return undefined;
    })
  );
}

function streamToBuffer(stream: Stream.Stream): Promise<Buffer> {
  return new Promise((resolve) => {
    const chunks: Array<Buffer> = [];
    stream.on("data", (chunk: Buffer) => {
      chunks.push(chunk);
    });
    stream.on("end", () => {
      const result = Buffer.concat(chunks);
      resolve(result);
    });
  });
}
