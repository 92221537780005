import { Materials, Project } from "../..";
import { TranslateFn } from "../../lang-texts";
import * as Common from "../common";
import { System } from "../../generated/generated-operations";
import { ReportModule } from "../types";
import { BitmapImage } from "../common";
import { ReportLevel } from "..";

export const Name = "material-list";
export const reportLevel: ReportLevel = "system";

export type Response = {
  readonly materialTables: Materials.MaterialTables;
  readonly projectResponse: Project.ProjectQuery;
};
export type CreatorProps = {
  readonly pageProps: Common.CataloguePageProps;
  readonly translate: TranslateFn;
  readonly systemWithArticles:
    | {
        readonly system: System;
        readonly materialsWithMeta: ReadonlyArray<Materials.MetaData>;
      }
    | undefined;
  readonly itemImages: ReadonlyMap<string, BitmapImage>;
};

export type AdapterType = ReportModule<CreatorProps, Response>["execute"];
export type CreatorType = ReportModule<CreatorProps, Response>["create"];
