import * as React from "react";
import { AbstractDocJsx as ADX, AbstractDoc as AD } from "abstract-document";
import { CreatorType } from "./types";
import * as Common from "../common";
import { texts, TranslateFn, key as createText } from "../../lang-texts";
import { style } from "../../style";
import { HeadingsWithSystem } from "..";

const {
  AbstractDoc,
  Section,
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  HyperLink,
  TextField,
  TocSeparator,
  render,
} = ADX;

export const create: CreatorType = ({ translate, pageProps, headings }) => {
  const styles = Common.styles();
  const numberingDefinitions = Common.numberingDefinitions(styles);
  const fonts = Common.fonts(pageProps.fonts);

  const doc = render(
    <AbstractDoc fonts={fonts} styles={styles} numberingDefinitions={numberingDefinitions}>
      <Section id={Common.pageId(pageProps)} page={Common.catalogueTOCPage(pageProps)}>
        <Table columnWidths={[Infinity]}>
          <TableRow>
            <TableCell
              style={AD.TableCellStyle.create({
                padding: AD.LayoutFoundation.create({
                  top: 0,
                  bottom: 51.386,
                  left: 0,
                  right: 0,
                }),
                background: style.primaryColor,
              })}
            >
              <Paragraph
                style={AD.ParagraphStyle.create({
                  position: "absolute",
                  margins: AD.LayoutFoundation.create({ top: 41, left: 10 }),
                })}
              >
                <TextRun
                  style={AD.TextStyle.create({ color: "#ffffff", fontSize: 17, bold: true, baseline: "bottom" })}
                  text={translate(texts.table_of_contents)}
                />
              </Paragraph>
            </TableCell>
          </TableRow>
        </Table>

        <Table
          columnWidths={[Infinity, 20]}
          style={AD.TableStyle.create({
            position: "relative",
            margins: AD.LayoutFoundation.create({ left: 10, top: 65, right: 10 }),
          })}
        >
          {renderHeadings(translate, headings)}
        </Table>
      </Section>
    </AbstractDoc>
  );
  return doc;
};

function renderHeadings(translate: TranslateFn, headings: HeadingsWithSystem): ReadonlyArray<JSX.Element> {
  const cellStyle = AD.TableCellStyle.create({});
  let previousSystem: string | undefined;
  const rows = [];
  for (const heading of headings) {
    const text = translate(createText(`report_name_${heading.reportId}`));
    const target = `${heading.reportId}_${heading.systemId}`;
    const isNewSystem = (heading.systemName && previousSystem !== heading.systemName) || false;
    previousSystem = heading.systemName;
    if (heading.systemName && isNewSystem) {
      rows.push(
        <TableRow key={target}>
          <TableCell>
            <Paragraph>
              <TextRun text={heading.systemName} />
            </Paragraph>
          </TableCell>
        </TableRow>
      );
    }

    rows.push(
      <TableRow key={target}>
        <TableCell style={cellStyle}>
          <Paragraph
            style={AD.ParagraphStyle.create({
              margins: AD.LayoutFoundation.create({ left: heading.systemId ? 10 : 0 }),
            })}
          >
            <HyperLink
              text={text}
              target={`#${target}`}
              style={AD.TextStyle.create({ underline: false, fontSize: 9 })}
            />
            <TocSeparator></TocSeparator>
          </Paragraph>
        </TableCell>
        <TableCell style={cellStyle}>
          <Paragraph style={AD.ParagraphStyle.create({ alignment: "End" })}>
            <TextField style={AD.TextStyle.create({ alignment: "right" })} fieldType="PageNumberOf" target={target} />
          </Paragraph>
        </TableCell>
      </TableRow>
    );
  }
  return rows;
}
