import * as React from "react";
import { AbstractDocJsx as ADX, AbstractDoc as AD } from "abstract-document";
import { texts, TranslateFn, TextKey } from "../../lang-texts";
import { CreatorType } from "./types";
import * as Common from "../common";
import { isVentilationConceptStatus, System, VentilationConceptProject, VentilationConceptSystem } from "../../project";
import { getUnitName } from "../../units";
import { h1 } from "../common/elements";
import { roundTo } from "../../utils";

const { AbstractDoc, Section, Group, Table, TableRow, TableCell, Paragraph, TextRun, render } = ADX;

export const create: CreatorType = ({ project, system, pageProps, translate }) => {
  const styles = Common.styles();
  const numberingDefinitions = Common.numberingDefinitions(styles);
  const fonts = Common.fonts(pageProps.fonts);

  const doc = render(
    <AbstractDoc fonts={fonts} styles={styles} numberingDefinitions={numberingDefinitions}>
      <Section id={Common.pageId(pageProps)} page={Common.cataloguePage(pageProps)}>
        {h1(translate(texts.ventilation_concept))}
        {system && system.ventilationConcept && project?.ventilationConcept
          ? ventilationConceptData(system, project.ventilationConcept, system.ventilationConcept, translate)
          : []}
        {system && system.ventilationConcept && project?.ventilationConcept
          ? ventilationConceptRequiredMeasurements(system, system.ventilationConcept, translate)
          : []}
      </Section>
    </AbstractDoc>
  );
  return doc;
};

type TableDataRows = readonly {
  readonly field: TextKey;
  readonly value: TextKey | number | null | string;
}[];

function ventilationConceptData(
  system: System,
  vcProject: VentilationConceptProject,
  vcSystem: VentilationConceptSystem,
  translate: TranslateFn
): JSX.Element {
  const mapTableRows = ([headers, rows]: readonly [readonly Common.TableCellHeading[], TableDataRows]): JSX.Element => {
    const tableRows: Common.TableCell[][] = rows.map((val) => {
      const rowValue = val.value === null ? "" : isTextKey(val.value) ? translate(val.value) : val.value.toString();
      return [
        { value: translate(val.field), noBackground: true },
        { value: rowValue, align: "End", noBackground: true },
      ];
    });
    return Common.table(headers, tableRows, "standard");
  };

  return (
    <Group key={system.id}>
      {mapTableRows(buildingData(system, vcProject, vcSystem, translate))}
      {mapTableRows(airtightness(system, vcProject, translate))}
      {mapTableRows(additionalRequirements(system, vcSystem, translate))}
      {mapTableRows(results(system, vcSystem, translate))}
    </Group>
  );
}

function ventilationConceptRequiredMeasurements(
  system: System,
  vcSystem: VentilationConceptSystem,
  translate: TranslateFn
): JSX.Element {
  const bottomTextStyle = AD.TextStyle.create({ fontSize: 10, bold: true });
  const bottomParagraphStyle = AD.ParagraphStyle.create({ margins: { top: 10, left: 0, right: 0, bottom: 5 } });

  const increasedRequirementAirSoundEnergy =
    isVentilationConceptStatus(vcSystem.higherRequirementsStatus) && vcSystem.higherRequirementsStatus !== "green";
  const increasedRequirementMoistureProtection =
    isVentilationConceptStatus(vcSystem.airflowStatus) && vcSystem.airflowStatus !== "green";

  const increasedRequirements = increasedRequirementAirSoundEnergy || increasedRequirementMoistureProtection;
  return (
    <Group key={system.id}>
      <Table columnWidths={[Infinity]}>
        <TableRow>
          <TableCell
            style={AD.TableCellStyle.create({
              borders: AD.LayoutFoundation.create({ top: 1.5, bottom: 1.5, left: 1.5, right: 1.5 }),
              padding: AD.LayoutFoundation.create({ top: 13, bottom: 10, left: 10, right: 10 }),
            })}
          >
            <Paragraph
              style={AD.ParagraphStyle.create({
                alignment: "Center",
                textStyle: AD.TextStyle.create({ fontSize: 12, bold: true }),
              })}
            >
              <TextRun text={translate(texts.vc_measure_requirements)} />
            </Paragraph>
          </TableCell>
        </TableRow>
      </Table>
      {increasedRequirements ? (
        <Paragraph style={bottomParagraphStyle}>
          <TextRun style={bottomTextStyle} text={translate(texts.vc_measure_description)} />
        </Paragraph>
      ) : (
        []
      )}
      {increasedRequirementAirSoundEnergy ? (
        <Paragraph style={bottomParagraphStyle}>
          <TextRun style={bottomTextStyle} text={translate(texts.vc_increased_requirement_air_sound_energy)} />
        </Paragraph>
      ) : (
        []
      )}

      {increasedRequirementMoistureProtection ? (
        <Paragraph style={bottomParagraphStyle}>
          <TextRun style={bottomTextStyle} text={translate(texts.vc_increased_requirement_moisture_protection)} />
        </Paragraph>
      ) : (
        []
      )}
      <Paragraph style={bottomParagraphStyle}>
        <TextRun style={bottomTextStyle} text={translate(texts.vc_recommend_ventilation_system)} />
      </Paragraph>
      <Paragraph style={bottomParagraphStyle}>
        <TextRun style={bottomTextStyle} text={translate(texts.vc_proof_of_ventilation_technology)} />
      </Paragraph>
    </Group>
  );
}

function buildingData(
  _: System,
  vcProject: VentilationConceptProject,
  vcSystem: VentilationConceptSystem,
  translate: TranslateFn
): readonly [readonly Common.TableCellHeading[], TableDataRows] {
  const tableHeader: Common.TableCellHeading[] = [
    { value: translate(texts.ventilation_concept_title_building_data), width: Infinity },
    { value: "", width: Infinity },
  ];

  return [
    tableHeader,
    [
      {
        field: texts.ventilation_concept_title_building_type,
        value:
          vcProject.buildingType === "single_floor"
            ? texts.vc_building_type_single_floor
            : texts.vc_building_type_multi_floor,
      },
      {
        field: texts.ventilation_concept_title_wind_conditions,
        value: vcProject.windConditions === "low" ? texts.vc_wind_conditions_low : texts.vc_wind_conditions_high,
      },
      {
        field: texts.ventilation_concept_title_thermal_protection,
        value:
          vcProject.thermalProtection === "low" ? texts.vc_thermal_protection_low : texts.vc_thermal_protection_high,
      },
      {
        field: texts.ventilation_concept_title_occupancy,
        value: vcSystem.occupancy === "low" ? texts.vc_occupany_low : texts.vc_occupany_high,
      },
      {
        field: texts.ventilation_concept_title_rooms_without_windows,
        value: vcSystem.windowlessRooms === true ? texts.ventilation_concept_yes : texts.ventilation_concept_no,
      },
      {
        field: texts.ventilation_concept_title_furnace,
        value: vcSystem.furnace === true ? texts.ventilation_concept_yes : texts.ventilation_concept_no,
      },
      { field: texts.result_total_area, value: roundTo(vcSystem.totalArea, 2) },
      { field: texts.result_total_volume, value: roundTo(vcSystem.totalVolume, 2) },
    ],
  ];
}

function airtightness(
  _: System,
  vcProject: VentilationConceptProject,
  translate: TranslateFn
): readonly [readonly Common.TableCellHeading[], TableDataRows] {
  const tableHeader: Common.TableCellHeading[] = [
    { value: translate(texts.ventilation_concept_title_air_tightness), width: Infinity },
    { value: "", width: Infinity },
  ];

  return [
    tableHeader,
    [
      {
        field: texts.ventilation_concept_title_tightness_of_building,
        value: vcProject.buildingTightness,
      },
    ],
  ];
}

function additionalRequirements(
  _: System,
  vcSystem: VentilationConceptSystem,
  translate: TranslateFn
): readonly [readonly Common.TableCellHeading[], TableDataRows] {
  const tableHeader: Common.TableCellHeading[] = [
    { value: translate(texts.ventilation_concept_title_higher_requirements), width: Infinity },
    { value: "", width: Infinity },
  ];

  return [
    tableHeader,
    [
      {
        field: texts.vc_higher_requirements_sound,
        value: vcSystem.higherRequirementsSound === true ? texts.ventilation_concept_yes : texts.ventilation_concept_no,
      },
      {
        field: texts.vc_higher_requirements_air_quality,
        value:
          vcSystem.higherRequirementsAirQuality === true ? texts.ventilation_concept_yes : texts.ventilation_concept_no,
      },
      {
        field: texts.vc_higher_requirements_energy_efficiency,
        value:
          vcSystem.higherRequirementsEnergyEfficiency === true
            ? texts.ventilation_concept_yes
            : texts.ventilation_concept_no,
      },
    ],
  ];
}

function results(
  _: System,
  vcSystem: VentilationConceptSystem,
  translate: TranslateFn
): readonly [readonly Common.TableCellHeading[], TableDataRows] {
  const tableHeader: Common.TableCellHeading[] = [
    { value: translate(texts.ventilation_concept_title_results), width: Infinity },
    { value: "", width: Infinity },
  ];

  const unitPart = getUnitName("CubicMeterPerHour");
  const formatNumber = (value: number | null | undefined): string => {
    return `${value === undefined || value === null ? "-" : value.toFixed(0)} ${unitPart}`;
  };

  return [
    tableHeader,
    [
      {
        field: texts.vc_status_airflow_for_moisture_protection,
        value: formatNumber(vcSystem.requiredAirflow),
      },
      {
        field: texts.vc_status_airflow_through_infiltration,
        value: formatNumber(vcSystem.infiltrationAirflow),
      },
    ],
  ];
}

function isTextKey(obj: number | string | TextKey): obj is TextKey {
  return (obj as TextKey).type !== undefined;
}
