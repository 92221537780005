import * as React from "react";
import { AbstractDocJsx as ADX, AbstractDoc as AD } from "abstract-document";
import { CreatorType } from "./types";
import * as Common from "../common";
import { catalogueWidth } from "../common";

const { AbstractDoc, Section, Paragraph, TextRun, Image, Group, render } = ADX;

export const create: CreatorType = ({ pageProps, coverImage }) => {
  const styles = Common.styles();
  const numberingDefinitions = Common.numberingDefinitions(styles);
  const fonts = Common.fonts(pageProps.fonts);
  const coverImageRes = coverImage ? Common.createImageResource(coverImage) : undefined;

  const doc = render(
    <AbstractDoc fonts={fonts} styles={styles} numberingDefinitions={numberingDefinitions}>
      <Section id={Common.pageId(pageProps)} page={Common.catalogueFirstPage(pageProps)}>
        <Group>
          {/* <Paragraph
            style={AD.ParagraphStyle.create({
              alignment: "Center",
              textStyle: AD.TextStyle.create({ fontSize: 12, bold: true }),
            })}
          >
            <TextRun text={translate(texts.frontpage_title)} />
          </Paragraph> */}

          <Paragraph
            style={AD.ParagraphStyle.create({ alignment: "Start", margins: AD.LayoutFoundation.create({ top: 0 }) })}
          >
            {coverImageRes ? (
              <Image width={catalogueWidth} height={600} imageResource={coverImageRes}></Image>
            ) : (
              <TextRun text="Cover image not found" />
            )}
          </Paragraph>
        </Group>
      </Section>
    </AbstractDoc>
  );
  return doc;
};
