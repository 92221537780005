import { Project } from "..";
import { TextKey } from "../lang-texts";
import * as G from "./germany";
import * as N from "./norway";

export const customItemsCategoryName = "custom_items";

export type MaterialTables = G.MaterialTables | N.MaterialTables;

export type MetaData = G.MetaData | N.MetaData;

export interface MetaDataShared {
  readonly group: string;
  readonly category: string | undefined;
  readonly readOnlyQuantity: boolean;
  readonly singleGroupSelection?: boolean;
  readonly material: Project.Material;
}

export interface ValidationError {
  readonly category?:
    | "quantity"
    | "duct_diameter"
    | "wrong_air_unit"
    | "missing_item_number"
    | "new_item_available"
    | "too_many_selections"
    | "item_has_been_removed";
  readonly materialId?: string;
  readonly message: TextKey;
}

export interface ValidationResult {
  readonly errors: ReadonlyArray<ValidationError>;
  readonly errorsById: {
    readonly [materialId: string]: ValidationError;
  };
}

export interface Category {
  readonly name: string;
  readonly materials: ReadonlyArray<Project.Material>;
}

export type MaterialChange = "created" | "updated" | "deleted";
export interface UpdateListResult {
  readonly materials: ReadonlyArray<Project.Material>;
  readonly changedMaterialIds: ReadonlyMap<string, MaterialChange>;
}

export interface ItemPrices {
  readonly [itemNumber: string]: {
    readonly salesPrice: number | undefined;
    readonly netPrice: number | undefined;
    readonly cost: number | undefined;
    readonly currency: string | undefined;
  };
}
export type GetItemPricesFn = (itemNumbers: ReadonlyArray<string>) => Promise<ItemPrices>;

export interface PackageItem {
  readonly sortNo: number;
  readonly packageName: string;
  readonly itemNumber: string;
  readonly group: string | undefined;
  readonly category: string | undefined;
  readonly quantity: number;
}

export interface ItemPackages {
  readonly [packageName: string]: ReadonlyArray<PackageItem>;
}
