import gql from "graphql-tag";

export const schema = gql`
  type System {
    id: ID!
    sortNo: Int!
    name: String!
    pipeDiameter: Float!
    totalArea: Float
    airChangeRate: Float
    rooms: [Room!]!
    materials: [Material!]!
    ventilationConcept: VentilationConceptSystem
    additionalDocuments: [SystemPdf!]
  }

  extend type Mutation {
    createSystem(input: CreateSystemInput!): CreateSystemPayload!
    duplicateSystem(input: DuplicateSystemInput!): DuplicateSystemPayload!
    removeSystem(input: RemoveSystemInput!): RemoveSystemPayload!
    updateSystem(input: UpdateSystemInput!): UpdateSystemPayload!
    updateSystemPrices(input: UpdateSystemPricesInput!): UpdateSystemPricesPayload!
  }

  input CreateSystemInput {
    id: ID!
    sortNo: Int!
    projectId: ID!
    name: String!
    pipeDiameter: Float!
    totalArea: Float
    airChangeRate: Float
  }

  type CreateSystemPayload {
    system: System!
  }

  input DuplicateSystemInput {
    id: ID!
    newSortNo: Int!
    projectId: ID!
    newId: ID!
  }

  type DuplicateSystemPayload {
    system: System!
  }

  input RemoveSystemInput {
    id: ID!
  }

  type RemoveSystemPayload {
    id: ID!
  }

  input UpdateSystemInput {
    id: ID!
    sortNo: Int
    name: String
    pipeDiameter: Float
    totalArea: Float
    airChangeRate: Float
  }

  type UpdateSystemPayload {
    id: ID!
  }

  input UpdateSystemPricesInput {
    id: ID!
    marketName: String!
  }

  type UpdateSystemPricesPayload {
    id: ID!
    status: String!
    updatedMaterials: [Material!]!
    debugData: String!
  }
`;
