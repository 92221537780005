import { Room, RoomTemplatesTable } from "../../../project";
import { logWarn } from "../../../logger";

export function calculateRoomFloorArea(roomTemplatesTable: RoomTemplatesTable, room: Room): number | undefined {
  const template = roomTemplatesTable.find((r) => r.key === room.roomType);
  if (!template) {
    logWarn(`calculateRoomFloorArea: Not room template found for room type ${room.roomType}`);
    return undefined;
  }
  if (template.airflow_calculation === "people" || template.airflow_calculation === "default_airflow") {
    return undefined;
  }
  if (room.width !== null && room.length !== null) {
    return room.width * room.length;
  } else {
    return room.floorArea ?? undefined;
  }
}
