import { Project } from "../..";
import { TranslateFn } from "../../lang-texts";
import * as Common from "../common";
import { ReportModule } from "../types";
import * as GQLOps from "../../generated/generated-operations";
import { ReportLevel } from "..";

export const Name = "air-distribution";
export const reportLevel: ReportLevel = "system";

export type Response = {
  readonly floorData: GQLOps.ProductMetaFloorQueryQuery;
  readonly projectResponse: Project.ProjectQuery;
};

export type CreatorProps = {
  readonly data: readonly string[];
  readonly pageProps: Common.CataloguePageProps;
  readonly floorData: GQLOps.RoomFloorTableFragment | undefined;
  readonly translate: TranslateFn;
  readonly projectResponse: Project.ProjectQuery;
};

export type AdapterType = ReportModule<CreatorProps, Response>["execute"];
export type CreatorType = ReportModule<CreatorProps, Response>["create"];
