import { ReportLevel } from "..";
import { TranslateFn } from "../../lang-texts";
import * as Common from "../common";
import { ReportModule } from "../types";

export const Name = "services";
export const reportLevel: ReportLevel = "system";

export type Response = {};

export type CreatorProps = {
  readonly data: readonly string[];
  readonly pageProps: Common.CataloguePageProps;
  readonly translate: TranslateFn;
};

export type AdapterType = ReportModule<CreatorProps, Response>["execute"];
export type CreatorType = ReportModule<CreatorProps, Response>["create"];
