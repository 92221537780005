export interface Request {
  readonly quoteId: string;
  readonly market?: string;
  readonly locale?: string;
  readonly project: string;
  readonly shopLanguageCode: string;
  readonly systems: ReadonlyArray<string>;
  readonly envelope: boolean;
  readonly apiVersion?: string;
}

export interface EnvelopeResponse {
  readonly type: "envelope";
  readonly envelope: string;
}

export interface ExportResponse {
  readonly type: "export";
  readonly status: number;
  readonly message: string;
}

export type Response = EnvelopeResponse | ExportResponse;
