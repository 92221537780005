import gql from "graphql-tag";

export const schema = gql`
  extend type Query {
    userSettings: [UserSetting!]!
  }

  extend type Mutation {
    updateUserSettings(input: [UpdateUserSettingInput!]!): [UpdateUserSettingPayload!]!
  }

  type UserSetting {
    name: String!
    value: String
  }

  input UpdateUserSettingInput {
    name: String!
    value: String
  }

  type UpdateUserSettingPayload {
    usersetting: UserSetting!
  }
`;
