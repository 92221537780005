import { VentilationConceptProject, VentilationConceptSystem } from "..";
import { Project, System, Room, Material, SystemPdf } from "../types";
import { Patch } from "./patch";

export interface UpdateRoomResult {
  readonly updatedProject: Project;
  readonly roomPatches: ReadonlyArray<Patch<Room>>;
}

export function removeSystem(project: Project, systemId: string): Project {
  return {
    ...project,
    systems: project.systems.filter((s) => s.id !== systemId),
  };
}

export function replaceSystem(project: Project, system: System): Project {
  return {
    ...project,
    systems: project.systems.map((s) => (s.id === system.id ? system : s)).sort((a, b) => a.sortNo - b.sortNo),
  };
}

export function addSystemLast(project: Project, system: System): Project {
  return {
    ...project,
    systems: [...project.systems, system],
  };
}

export function insertSystemAfter(
  project: Project,
  position: "Last" | "After",
  copiedSystemId: string,
  newSystem: System
): { readonly updatedProjects: Project; readonly patches: ReadonlyArray<Patch<System>>; readonly newSystem: System } {
  // const newSystem = {...systemToCopy, id: v4()}
  if (position === "After") {
    const sortedSystems = project.systems.slice(0).sort((a, b) => a.sortNo - b.sortNo);
    const afterIndex = sortedSystems.findIndex((r) => r.id === copiedSystemId);
    const systemsAfter = afterIndex !== -1 ? sortedSystems.slice(afterIndex + 1) : [];

    if (afterIndex === -1 || systemsAfter.length === 0) {
      return { updatedProjects: addSystemLast(project, newSystem), patches: [], newSystem };
    }

    const sortNoAfter = sortedSystems[afterIndex].sortNo + 1;
    const updatedNewSystem = { ...newSystem, sortNo: sortNoAfter };
    const newSystemPatch = { id: newSystem.id, sortNo: sortNoAfter };

    const newSystems = [...project.systems, updatedNewSystem];
    const updatedProjects = { ...project, systems: newSystems };

    const patches = systemsAfter.map((r) => ({ id: r.id, sortNo: r.sortNo + 1 }));
    const patchSort = [...patches, newSystemPatch];
    const patchedProject = applySystemPatchesToProject(updatedProjects, patchSort);

    const newSortedSystems = [...patchedProject.systems].sort((a, b) => a.sortNo - b.sortNo);
    const updatedSortedProjects = { ...patchedProject, systems: newSortedSystems };
    return { updatedProjects: updatedSortedProjects, patches: patchSort, newSystem };
  } else {
    return { updatedProjects: addSystemLast(project, newSystem), patches: [], newSystem };
  }
}

export function addRoom(
  project: Project,
  systemId: string,
  position: { readonly type: "last" } | { readonly type: "after"; readonly roomId: string },
  room: Room
): { readonly updatedProject: Project; readonly updatedNewRoom: Room; readonly patches: ReadonlyArray<Patch<Room>> } {
  if (position.type === "after") {
    const system = project.systems.find((s) => s.id === systemId);
    if (!system) {
      return { updatedProject: project, updatedNewRoom: room, patches: [] };
    }

    const sortedRooms = system.rooms.slice(0).sort((a, b) => a.sortNo - b.sortNo);
    const afterIndex = sortedRooms.findIndex((r) => r.id === position.roomId);
    const roomsAfter = afterIndex !== -1 ? sortedRooms.slice(afterIndex + 1) : [];

    if (afterIndex === -1 || roomsAfter.length === 0) {
      return addRoomLast(project, systemId, room);
    }

    const patches = roomsAfter.map((r) => ({ id: r.id, sortNo: r.sortNo + 1 }));
    const patchedSystem = applyRoomPatchesToSystem(system, patches);

    const sortNoAfter = sortedRooms[afterIndex].sortNo + 1;
    const updatedNewRoom = { ...room, sortNo: sortNoAfter };
    const newRooms = [...patchedSystem.rooms, updatedNewRoom].sort((a, b) => a.sortNo - b.sortNo);
    const systemWithRoom = { ...patchedSystem, rooms: newRooms };

    const updatedProject = replaceSystem(project, systemWithRoom);

    return { updatedProject, updatedNewRoom, patches };
  } else {
    return addRoomLast(project, systemId, room);
  }
}

export function removeRoom(project: Project, systemId: string, roomId: string): Project {
  return {
    ...project,
    systems: project.systems.map((s) =>
      s.id === systemId ? { ...s, rooms: s.rooms.filter((r) => r.id !== roomId) } : s
    ),
  };
}

export function replaceRoom(project: Project, systemId: string, room: Room): Project {
  const system = project.systems.find((s) => s.id === systemId);
  if (!system) {
    return project;
  }
  return {
    ...project,
    systems: project.systems.map((s) =>
      s.id === systemId
        ? {
            ...s,
            rooms: s.rooms.map((r) => (r.id === room.id ? room : r)).sort((a, b) => a.sortNo - b.sortNo),
          }
        : s
    ),
  };
}

export function addMaterials(project: Project, systemId: string, materials: ReadonlyArray<Material>): Project {
  const system = project.systems.find((s) => s.id === systemId);
  if (!system) {
    return project;
  }
  const newSystem: System = {
    ...system,
    materials: [...system.materials, ...materials].sort((a, b) => a.sortNo - b.sortNo),
  };
  return {
    ...project,
    systems: project.systems.map((s) => (s.id === systemId ? newSystem : s)),
  };
}

export function removeMaterials(project: Project, systemId: string, materialIds: ReadonlyArray<string>): Project {
  const system = project.systems.find((s) => s.id === systemId);
  if (!system) {
    return project;
  }
  const newSystem: System = {
    ...system,
    materials: system.materials.filter((m) => !materialIds.some((idToRemove) => m.id === idToRemove)),
  };
  return {
    ...project,
    systems: project.systems.map((s) => (s.id === systemId ? newSystem : s)),
  };
}

export function replaceMaterials(project: Project, systemId: string, materials: ReadonlyArray<Material>): Project {
  const system = project.systems.find((s) => s.id === systemId);
  if (!system) {
    return project;
  }
  const newSystem: System = {
    ...system,
    materials: system.materials
      .map((m) => {
        const updatedMaterial = materials.find((p) => p.id === m.id);
        return updatedMaterial ? updatedMaterial : m;
      })
      .sort((a, b) => a.sortNo - b.sortNo),
  };
  return {
    ...project,
    systems: project.systems.map((s) => (s.id === systemId ? newSystem : s)),
  };
}

export function applySystemPatchesToProject(project: Project, patches: ReadonlyArray<Patch<System>>): Project {
  const updatedUiSystems = project.systems.map((s) => {
    const patchesForSystem = patches.filter((p) => p.id === s.id);
    let patchedSystem = s;
    for (const patch of patchesForSystem) {
      patchedSystem = { ...patchedSystem, ...patch };
    }
    return patchedSystem;
  });
  const updatedUiSystem = updatedUiSystems
    ? {
        ...project,
        systems: updatedUiSystems,
      }
    : project;
  return updatedUiSystem;
}

export function applyRoomPatchesToSystem(system: System, patches: ReadonlyArray<Patch<Room>>): System {
  const updatedUiRooms = system.rooms.map((room) => {
    const patchesForRoom = patches.filter((p) => p.id === room.id);
    let patchedRoom = room;
    for (const patch of patchesForRoom) {
      patchedRoom = { ...patchedRoom, ...patch };
    }
    return patchedRoom;
  });
  const updatedUiSystem = updatedUiRooms
    ? {
        ...system,
        rooms: updatedUiRooms,
      }
    : system;
  return updatedUiSystem;
}

export function applyVentilationConceptProjectPatch(
  project: Project,
  patch: Patch<VentilationConceptProject>
): Project {
  const updatedProject: Project = project.ventilationConcept
    ? {
        ...project,
        ventilationConcept: {
          ...project.ventilationConcept,
          ...patch,
        },
      }
    : project;
  return updatedProject;
}

export function replaceVentilationConceptProject(project: Project, vc: VentilationConceptProject | null): Project {
  return {
    ...project,
    ventilationConcept: vc,
  };
}

export function applyVentilationConceptSystemPatch(project: Project, patch: Patch<VentilationConceptSystem>): Project {
  const updatedProject = {
    ...project,
    systems: project.systems.map((s) =>
      s.ventilationConcept?.id === patch.id ? { ...s, ventilationConcept: { ...s.ventilationConcept, ...patch } } : s
    ),
  };
  return updatedProject;
}

export function replaceVentilationConceptSystem(
  project: Project,
  systemId: string,
  vc: VentilationConceptSystem | null
): Project {
  return {
    ...project,
    systems: project.systems.map((s) => (s.id === systemId ? { ...s, ventilationConcept: vc } : s)),
  };
}

function addRoomLast(
  project: Project,
  systemId: string,
  room: Room
): { readonly updatedProject: Project; readonly updatedNewRoom: Room; readonly patches: ReadonlyArray<Patch<Room>> } {
  const system = project.systems.find((s) => s.id === systemId);
  if (!system) {
    return { updatedProject: project, updatedNewRoom: room, patches: [] };
  }

  const lastSortNo = Math.max(...(system?.rooms || []).map((r) => r.sortNo), 0) + 1;
  const updatedNewRoom = {
    ...room,
    sortNo: lastSortNo,
  };
  const updatedProject = {
    ...project,
    systems: project.systems.map((s) => (s.id === systemId ? { ...s, rooms: [...s.rooms, updatedNewRoom] } : s)),
  };

  return { updatedProject, updatedNewRoom: updatedNewRoom, patches: [] };
}

// function addSystemLast(
//   project: Project,
//   systemId: string,
//   room: Room
// ): { readonly updatedProject: Project; readonly updatedNewRoom: Room; readonly patches: ReadonlyArray<Patch<Room>> } {
//   const system = project.systems.find((s) => s.id === systemId);
//   if (!system) {
//     return { updatedProject: project, updatedNewRoom: room, patches: [] };
//   }

//   const lastSortNo = Math.max(...(system?.rooms || []).map((r) => r.sortNo), 0) + 1;
//   const updatedNewRoom = {
//     ...room,
//     sortNo: lastSortNo,
//   };
//   const updatedProject = {
//     ...project,
//     systems: project.systems.map((s) => (s.id === systemId ? { ...s, rooms: [...s.rooms, updatedNewRoom] } : s)),
//   };

//   return { updatedProject, updatedNewRoom: updatedNewRoom, patches: [] };
// }

export function addSystemPdf(project: Project, systemId: string, pdf: SystemPdf): Project {
  const system = project.systems.find((s) => s.id === systemId);
  if (!system) {
    return project;
  }
  const newSystem: System = {
    ...system,
    additionalDocuments: [...(system.additionalDocuments || []), pdf],
  };
  return {
    ...project,
    systems: project.systems.map((s) => (s.id === systemId ? newSystem : s)),
  };
}

export function replaceSystemPdf(project: Project, systemId: string, updatedSystemPdf: SystemPdf): Project {
  const system = project.systems.find((s) => s.id === systemId);
  if (!system) {
    return project;
  }
  const newSystem: System = {
    ...system,
    additionalDocuments:
      system.additionalDocuments?.map((d) => {
        return d.id === updatedSystemPdf.id ? updatedSystemPdf : d;
      }) || [],
  };
  return {
    ...project,
    systems: project.systems.map((s) => (s.id === systemId ? newSystem : s)),
  };
}

export function removeSystemPdf(project: Project, systemId: string, systemPdfId: string): Project {
  const system = project.systems.find((s) => s.id === systemId);
  if (!system) {
    return project;
  }
  const newSystem: System = {
    ...system,
    additionalDocuments: system.additionalDocuments?.filter((d) => d.id !== systemPdfId) || [],
  };
  return {
    ...project,
    systems: project.systems.map((s) => (s.id === systemId ? newSystem : s)),
  };
}
