import * as R from "ramda";
import {
  isExtractRoom,
  isSupplyRoom,
  RoomTemplatesTable,
  System,
  Room,
  isOverPressureRoom,
  getRoomType,
} from "../../project";
import { Result, Input } from "../types";
import { ResultByRoomId, RoomResult, Quantities } from "./types";
import { calculateTotalArea, calculateRoomFloorArea } from "./calculation/floor-area";
import { calculateAirflowForArea, calculateTotalNominalExtractAirflow } from "./calculation/system-airflow";
import { calculateRoomAirflow, calculateNominalAirflow } from "./calculation/room-airflow";
import { RoomField, Fields, RoomFields, SystemFields, DuctTable } from "..";
import { calculateRoomValveQuantity } from "./calculation/valves";
import { BalanceExtractAirflow, BalanceSupplyAirflow, calculateRoomVolume } from "./calculation";
import { calculateTotalVolume } from "./calculation/room-volume";
import { calculateVentilationChangeRates } from "./calculation/ventilation-change-rate";
import { calculateRoomDuctQuantity, getDuctMaxAirflow } from "./calculation/ducts";
import { calculateBalancedAirflow } from "./calculation/balanace-airflow";

export const name = "germany";

export function calculate(input: Input): Result {
  const totalArea = calculateTotalArea(input.system.rooms);
  const totalVolume = calculateTotalVolume(input.system.rooms);
  const airflowForTotalArea = totalArea && calculateAirflowForArea(totalArea);
  const { requiredAirflow, balanceSupplyResult, balanceExtractResult } = calculateBalancedAirflow(
    input.roomTemplates,
    input.system
  );
  const roomResults = calculateRoomResults(
    input.roomTemplates,
    input.ductTable,
    input.system,
    balanceSupplyResult,
    balanceExtractResult
  );
  const { extractRoomsArea, extractRoomsTotalNominalAirflow } = calculateExtractRoomResults(
    input.roomTemplates,
    input.system.rooms,
    roomResults
  );
  const totalAirflows = roomResults && calcateTotalRoomAirflows(input.system, roomResults);
  const ventilationChangeRates =
    requiredAirflow !== undefined && totalVolume
      ? calculateVentilationChangeRates(
          input.system.ventilationConcept?.requiredAirflow || undefined,
          requiredAirflow,
          totalVolume
        )
      : undefined;
  const quantities = calculateQuantities(input.system, roomResults);
  return {
    type: name,
    requiredAirflow: requiredAirflow,
    totalArea: totalArea,
    airflowForTotalArea: airflowForTotalArea,
    roomResults: roomResults,
    extractRoomsTotalArea: extractRoomsArea,
    extractRoomsTotalNominalAirflow: extractRoomsTotalNominalAirflow,
    supplyAirflow: totalAirflows?.supplyAirflow,
    extractAirflow: totalAirflows?.extractAirflow,
    totalVolume: totalVolume,
    ventilationChangeRates: ventilationChangeRates,
    quantities: quantities,
  };
}

function calculateQuantities(system: System, roomResults: ResultByRoomId): Quantities {
  return system.rooms.reduce(
    (sofar, room) => {
      const results = roomResults[room.id];
      if (!results) {
        return sofar;
      }
      if (isSupplyRoom(room)) {
        return {
          ...sofar,
          supplyValves: results.valves !== undefined ? (sofar.supplyValves || 0) + results.valves : results.valves,
          supplyDucts: results.ducts !== undefined ? (sofar.supplyDucts || 0) + results.ducts : results.ducts,
        };
      } else if (isExtractRoom(room)) {
        return {
          ...sofar,
          extractValves: results.valves !== undefined ? (sofar.extractValves || 0) + results.valves : results.valves,
          extractDucts: results.ducts !== undefined ? (sofar.extractDucts || 0) + results.ducts : results.ducts,
        };
      } else {
        return sofar;
      }
    },
    {
      supplyDucts: undefined,
      extractDucts: undefined,
      supplyValves: undefined,
      extractValves: undefined,
    }
  );
}

function calcateTotalRoomAirflows(
  system: System,
  roomResults: ResultByRoomId
): { readonly supplyAirflow: number; readonly extractAirflow: number } {
  const extractAirflow = system.rooms
    .filter((r) => isExtractRoom(r))
    .reduce((sum, r) => sum + (roomResults[r.id]?.airflow || 0), 0);
  const supplyAirflow = system.rooms
    .filter((r) => isSupplyRoom(r))
    .reduce((sum, r) => sum + (roomResults[r.id]?.airflow || 0), 0);
  return { supplyAirflow, extractAirflow };
}

function calculateExtractRoomResults(
  roomTemplates: RoomTemplatesTable,
  rooms: ReadonlyArray<Room>,
  roomResults: ResultByRoomId
): { readonly extractRoomsArea: number | undefined; readonly extractRoomsTotalNominalAirflow: number | undefined } {
  const extractRooms = rooms.filter((r) => isExtractRoom(r));
  const extractRoomsArea = extractRooms.reduce(
    (sum, room) => (sum === undefined ? roomResults[room.id]?.area : (roomResults[room.id]?.area || 0) + sum),
    undefined
  );
  const extractRoomsTotalNominalAirflow = calculateTotalNominalExtractAirflow(roomTemplates, rooms);
  return { extractRoomsArea, extractRoomsTotalNominalAirflow };
}

function calculateRoomResults(
  roomTemplatesTable: RoomTemplatesTable,
  ductTable: DuctTable,
  system: System,
  balanceSupplyResult: BalanceSupplyAirflow.BalanceSupplyAirflowsResult | undefined,
  balanceExtractResult: BalanceExtractAirflow.ExtractBalanceResult | undefined
): ResultByRoomId {
  const ductMaxAirflow = getDuctMaxAirflow(ductTable, system);
  const roomResults: Array<[string, RoomResult]> = system.rooms.map((room) => {
    const area = calculateRoomFloorArea(room);
    const volume = calculateRoomVolume(room);
    const airflow = calculateRoomAirflow(balanceSupplyResult, balanceExtractResult, room);
    const nominalAirflow = calculateNominalAirflow(roomTemplatesTable, room);
    const width = room.width || undefined;
    const length = room.length || undefined;
    const roomType = getRoomType(room);
    const valves =
      room.customValves && room.valves !== null
        ? room.valves
        : roomType && calculateRoomValveQuantity(roomType, airflow);
    const ducts = ductMaxAirflow !== undefined ? calculateRoomDuctQuantity(ductMaxAirflow, airflow, valves) : undefined;
    return [room.id, { area, volume, airflow, nominalAirflow, width, length, valves, ducts }];
  });
  const resultById = R.fromPairs(roomResults);
  return resultById;
}

const sharedRoomFields: RoomFields = [
  { field: "roomType", readOnly: false, hidden: false },
  { field: "airType", readOnly: true, hidden: false },
  {
    field: "volume",
    readOnly: true,
    hidden: false,
  },
  { field: "floorType", readOnly: false, hidden: false },
  { field: "ceilingHeight", readOnly: false, hidden: false },
  {
    field: "floorArea",
    readOnly: false,
    hidden: false,
    onChange: (v: number) => ({ floorArea: v, volume: null, width: null, length: null }),
  },
  {
    field: "width",
    readOnly: false,
    hidden: false,
    onChange: (v: number) => ({ width: v, floorArea: null, volume: null }),
  },
  {
    field: "length",
    readOnly: false,
    hidden: false,
    onChange: (v: number) => ({ length: v, floorArea: null, volume: null }),
  },
  { field: "people", readOnly: false, hidden: true },
  {
    field: "valves",
    readOnly: false,
    hidden: false,
    decimals: 0,
    onChange: (v: number) => ({ valves: v, customValves: true }),
    clearOverride: (room) => (room.customValves ? { customValves: null } : undefined),
  },
  { field: "ducts", readOnly: true, hidden: false, decimals: 0 },
];

const supplyRoomFields: RoomFields = [
  { field: "supplyAirFactor", readOnly: false, hidden: false, spinButton: true },
  { field: "airflow", readOnly: true, hidden: false },
];

const extractRoomFields: RoomFields = [
  { field: "supplyAirFactor", readOnly: false, hidden: true },
  {
    field: "airflow",
    readOnly: false,
    hidden: false,
    onChange: (v: number) => ({ airflow: v, customAirFlow: true }),
    clearOverride: (room) => (room.customAirFlow ? { customAirFlow: null } : undefined),
  },
];

const overPressureRoomFields: RoomFields = [
  { field: "supplyAirFactor", readOnly: false, hidden: true },
  { field: "airflow", readOnly: true, hidden: true },
];

export function getFields(input: Input): Fields {
  const fields: { [id: string]: ReadonlyArray<RoomField> } = {};
  for (const room of input.system.rooms) {
    if (isSupplyRoom(room)) {
      fields[room.id] = [...sharedRoomFields, ...supplyRoomFields];
    } else if (isExtractRoom(room)) {
      fields[room.id] = [...sharedRoomFields, ...extractRoomFields];
    } else if (isOverPressureRoom(room)) {
      fields[room.id] = [...sharedRoomFields, ...overPressureRoomFields];
    }
  }
  const systemFields: SystemFields = [
    { field: "totalArea", readOnly: false, hidden: true },
    { field: "pipeDiameter", readOnly: false, hidden: false },
  ];
  return { roomFields: fields, systemFields };
}
