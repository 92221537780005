import { getGaebFormat } from ".";
import { Project, Texts } from "..";
import { TranslateFn } from "../lang-texts";
import { ExportInfo } from "./types";

export function isExportInfoOk(exportInfo: ExportInfo | undefined): boolean {
  return (
    !!exportInfo &&
    !!exportInfo.projectAddress.addressname &&
    !!exportInfo.projectAddress.street &&
    !!exportInfo.projectAddress.postalcode &&
    !!exportInfo.projectAddress.city &&
    !!exportInfo.projectAddress.country
  );
}

export function getExportInfo(
  project: Project.Project,
  gaebFormatString: string | undefined,
  translate: TranslateFn
): ExportInfo | undefined {
  const gaebformat = getGaebFormat(gaebFormatString || "");

  if (!gaebformat) {
    return undefined;
  }

  return {
    gaebformat: {
      format: gaebformat.format,
      name: gaebformat.name,
      extension: gaebformat.extension,
      mime: gaebformat.mime,
    },
    projectAddress: {
      addressname: project.projectAddressname ?? "",
      street: project.projectStreet ?? "",
      postalcode: project.projectPostalcode ?? "",
      city: project.projectCity ?? "",
      country: project.projectCountry !== null ? translate(Texts.key(project.projectCountry)) : "",
    },
  };
}
