import * as Gql from "../../generated/generated-operations";
import { Row, Table } from "../../graphql-utils";
import { Categories } from "../shared/categories";
import { MetaDataShared, ItemPackages } from "../types";

type ItemRow<T> = Omit<Row<T>, "long_description" | "short_description" | "name">;

export type AirUnits = ItemRow<Gql.AirUnits>;
export type AirUnitArticles = ItemRow<Gql.AirUnitArticles>;
export type AirUnitAlternatives = ItemRow<Gql.AirUnitAlternatives>;
export type Accessory = ItemRow<Gql.Accessory>;
export type Distributor = ItemRow<Gql.Distributor>;
export type Duct = ItemRow<Gql.Duct>;
export type Valve = ItemRow<Gql.Valve>;
export type ValveConnector = ItemRow<Gql.ValveConnector>;
export type Packages = ItemRow<Gql.Packages>;

export type AirUnitsTable = Table<AirUnits>;
export type AirUnitArticlesTable = Table<AirUnitArticles>;
export type AirUnitAlternativesTable = Table<AirUnitAlternatives>;
export type AccessoryTable = Table<Accessory>;
export type DistributorTable = Table<Distributor>;
export type DuctTable = Table<Duct>;
export type ValveTable = Table<Valve>;
export type ValveConnectorTable = Table<ValveConnector>;
export type PackagesTable = Table<Packages>;

export type ItemType = "air_unit" | "accessory" | "distributor" | "duct" | "valve" | "valve_connector" | "unknown";

export type AirUnitItem = { readonly type: "air_unit" } & AirUnits;
export type AccessoryItem = { readonly type: "accessory" } & Accessory;
export type DistributorItem = { readonly type: "distributor" } & Distributor;
export type DuctItem = { readonly type: "duct" } & Duct;
export type ValveItem = { readonly type: "valve" } & Valve;
export type ValveConnectorItem = { readonly type: "valve_connector" } & ValveConnector;

export type Item =
  | AirUnitItem
  | AccessoryItem
  | DistributorItem
  | DuctItem
  | ValveItem
  | ValveConnectorItem
  | {
      readonly type: "unknown";
      readonly item_number: string;
      readonly duct_63: string;
      readonly duct_75: string;
    };

// An item that is stored in a material list but can't be found in the data
export const unknownItem: Item = {
  type: "unknown",
  item_number: "-",
  duct_63: "0",
  duct_75: "0",
};

export interface MaterialTables {
  readonly type: "germany";
  readonly tables: {
    readonly airUnitsTable: AirUnitsTable;
    readonly airUnitArticlesTable: AirUnitArticlesTable;
    readonly airUnitAlternativesTable: AirUnitAlternativesTable;
    readonly accessoryTable: AccessoryTable;
    readonly distributorTable: DistributorTable;
    readonly ductTable: DuctTable;
    readonly valveTable: ValveTable;
    readonly valveConnectorTable: ValveConnectorTable;
    readonly packagesTable: PackagesTable;
  };
  readonly items: { readonly [itemNumber: string]: Item };
  readonly itemPackages: ItemPackages;
  readonly sortNos: ReadonlyMap<string, number>;
  readonly categories: Categories;
}

export type MetaData = MetaDataShared & {
  readonly type: "germany";
};
