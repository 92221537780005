import { MaterialTables } from ".";
import { Project } from "../..";

export function updateConnectorQuantities(
  { items }: MaterialTables,
  materials: ReadonlyArray<Project.Material>,
  system: Project.System
): ReadonlyArray<Project.Material> {
  const numRequiredConnectors = calculateConnectorSystemQuantity(system);
  return materials.map((material) => {
    if (items[material.itemNumber]?.type === "valve_connector") {
      return {
        ...material,
        quantity: numRequiredConnectors,
      };
    } else {
      return material;
    }
  });
}

export function calculateConnectorSystemQuantity(system: Project.System): number {
  return system.rooms.reduce((sum, room) => sum + (room.valves || 0), 0);
}
