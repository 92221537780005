import { Patch, Room, System } from "../../project";
import { Input, Updates } from "../types";
import { Result } from "./types";

export function mapResultToUi(input: Input, result: Result): Updates {
  const patches: Array<Patch<Room>> = [];
  for (const room of input.system.rooms) {
    const roomResult = result.roomResults[room.id];
    if (!roomResult) {
      continue;
    }
    patches.push({
      id: room.id,
      volume: roomResult.volume ?? room.volume,
      floorArea: roomResult.area ?? room.floorArea,
      width: roomResult.width ?? room.width,
      length: roomResult.length ?? room.length,
      valves: roomResult.valves ?? null,
      ducts: roomResult.ducts ?? null,
    });
    if (room.airflow !== roomResult.airflow && roomResult.airflow === undefined) {
      patches.push({ id: room.id, airflow: roomResult.airflow });
    }
  }
  const airflowUpdates = createAirflowUpdates(input.system, result);
  return {
    roomPatches: [...patches, ...airflowUpdates],
  };
}

export function mapResultToProject(input: Input, result: Result): Updates {
  const patches: Array<Patch<Room>> = [];
  for (const room of input.system.rooms) {
    const roomResult = result.roomResults[room.id];
    if (!roomResult) {
      continue;
    }

    const newValves = roomResult.valves ?? null;
    if (room.valves !== newValves) {
      patches.push({ id: room.id, valves: newValves });
    }

    const newDucts = roomResult.ducts ?? null;
    if (room.ducts !== newDucts) {
      patches.push({ id: room.id, ducts: newDucts });
    }
  }
  const airflowUpdates = createAirflowUpdates(input.system, result);
  return {
    roomPatches: [...patches, ...airflowUpdates],
  };
}

function createAirflowUpdates(system: System, result: Result): ReadonlyArray<Patch<Room>> {
  const updates: Array<Patch<Room>> = [];
  for (const room of system.rooms) {
    const newAirflow = result.roomResults[room.id]?.airflow;
    if (room.airflow === newAirflow || newAirflow === undefined) {
      continue;
    }
    updates.push({ id: room.id, airflow: newAirflow });
  }
  return updates;
}
