import {
  unknownItem,
  updateDuctQuantities,
  updateConnectorQuantities,
  updateValveQuantities,
  getMaterialsForAirUnit,
} from ".";
import { Project } from "../..";
import { updateDistributorQuantities } from "./distributor";
import { MaterialTables, MetaData } from "./types";
import { UpdateListResult } from "../types";
import { getListChanges, sortList } from "../shared";
import { customItemsCategoryName } from "..";

export function createMaterialListForUnit(
  materialTables: MaterialTables,
  system: Project.System,
  selectedAirUnit: Project.Material
): UpdateListResult {
  const withoutCustomMaterials = system.materials.filter((m) => m.type !== "custom");
  const currentMap = new Map(withoutCustomMaterials.map((m) => [m.itemNumber, m]));
  const materialsForUnit = getMaterialsForAirUnit(materialTables, selectedAirUnit, system).map((m) => {
    const current = currentMap.get(m.itemNumber);
    if (current) {
      // Keep old material if the item number exists in the old list to avoid unecessary updates
      currentMap.delete(m.itemNumber);
      return { ...m, id: current.id };
    } else {
      return m;
    }
  });

  const updatedQtys = updateQuantities(materialTables, materialsForUnit, system);

  // Custom materials should be kept even when units are changed
  const withCustomMaterials = [...updatedQtys, ...system.materials.filter((m) => m.type === "custom")];

  const updatedSortNo = sortList(materialTables.sortNos, withCustomMaterials);

  const changes = getListChanges(system, updatedSortNo);

  return {
    materials: updatedSortNo,
    changedMaterialIds: changes,
  };
}

export function updateMaterialListQuantities(materialTables: MaterialTables, system: Project.System): UpdateListResult {
  const updatedQtys = updateQuantities(materialTables, system.materials, system);
  const changes = getListChanges(system, updatedQtys);
  return {
    materials: updatedQtys,
    changedMaterialIds: changes,
  };
}

export function updateMaterialListAddNewItems(
  materialTables: MaterialTables,
  system: Project.System,
  selectedAirUnit: Project.Material
): UpdateListResult {
  const materialsForUnit = getMaterialsForAirUnit(materialTables, selectedAirUnit, system);
  const withoutCustomMaterials = system.materials.filter((m) => m.type !== "custom");
  const currentMap = new Map(withoutCustomMaterials.map((m) => [m.itemNumber, m]));
  const materialsWithNewItems = system.materials.slice(0);
  for (const materialForUnit of materialsForUnit) {
    if (!currentMap.has(materialForUnit.itemNumber)) {
      materialsWithNewItems.push(materialForUnit);
    }
  }

  const updatedSortNo = sortList(materialTables.sortNos, materialsWithNewItems);

  const changes = getListChanges(system, updatedSortNo);

  return {
    materials: updatedSortNo,
    changedMaterialIds: changes,
  };
}

export function getMetaData(materialTables: MaterialTables, material: Project.Material): MetaData {
  if (material.type === "custom") {
    return {
      type: "germany",
      group: customItemsCategoryName,
      category: undefined,
      readOnlyQuantity: false,
      material,
    };
  } else if (material.packageName) {
    const packageItems = materialTables.itemPackages[material.packageName] || [];
    const item = packageItems.find((i) => i.itemNumber === material.itemNumber);
    return {
      type: "germany",
      group: item?.group || "unknown",
      category: item?.category,
      readOnlyQuantity: false,
      material,
    };
  } else {
    const item = materialTables.items[material.itemNumber] || unknownItem;
    return {
      type: "germany",
      group: item.type,
      category: item.type === "accessory" ? item.category || undefined : undefined,
      readOnlyQuantity: false,
      material,
    };
  }
}

export function getCustomItemNumbers(materialTables: MaterialTables): ReadonlyArray<string> {
  return Array.from(Object.keys(materialTables.items));
}

function updateQuantities(
  materialTables: MaterialTables,
  materials: ReadonlyArray<Project.Material>,
  system: Project.System
): ReadonlyArray<Project.Material> {
  let updatedMaterials: ReadonlyArray<Project.Material> = materials.filter((m) => m.type !== "custom");
  updatedMaterials = updateValveQuantities(materialTables, updatedMaterials, system);
  updatedMaterials = updateDuctQuantities(materialTables, updatedMaterials, system);
  updatedMaterials = updateConnectorQuantities(materialTables, updatedMaterials, system);
  updatedMaterials = updateDistributorQuantities(materialTables, updatedMaterials, system);
  const materialsMap = new Map(updatedMaterials.map((m) => [m.id, m]));
  return materials.map((m) => materialsMap.get(m.id) || m);
}
