import { texts } from "../../lang-texts";
import {
  isSupplyRoom,
  Room,
  validateNumber,
  validateNumberNonZero,
  RoomError,
  RoomTemplates,
  validateRequired,
} from "../../project";
import { Input, ValidationOutput } from "../types";

export function validate(input: Input): ValidationOutput {
  const roomErrors: { [roomId: string]: ReadonlyArray<RoomError> } = {};
  for (const room of input.system.rooms) {
    const roomTemplate = input.roomTemplates.find((t) => t.key === room.roomType && t.market === input.market);
    const errors = validateRoom(room, roomTemplate);
    if (errors.length > 0) {
      roomErrors[room.id] = errors;
    }
  }
  const systemErrors = [];
  systemErrors.push(...validateNumberNonZero("airChangeRate", input.system));
  systemErrors.push(...validateRequired("airChangeRate", input.system));
  return {
    hasErrors: Object.keys(roomErrors).length > 0,
    roomErrors,
    systemErrors: [],
  };
}

function validateRoom(room: Room, roomTemplate: RoomTemplates | undefined): ReadonlyArray<RoomError> {
  const errors: Array<RoomError> = [];

  if (isSupplyRoom(room)) {
    errors.push(...validateNumber("supplyAirFactor", room));
  }

  errors.push(...validateNumber("ceilingHeight", room));

  errors.push(...validateNumber("airflow", room));

  errors.push(...validateNumber("valves", room));

  if (room.width === null && room.length === null && room.floorArea === null) {
    errors.push(...validateNumber("volume", room));
    errors.push(...validateNumberNonZero("volume", room));
  } else if (room.width === null && room.length === null) {
    errors.push(...validateNumber("floorArea", room));
    errors.push(...validateNumberNonZero("floorArea", room));
  } else if (room.floorArea === null) {
    errors.push(...validateNumber("length", room));
    errors.push(...validateNumber("width", room));
    errors.push(...validateNumberNonZero("length", room));
    errors.push(...validateNumberNonZero("width", room));
  }

  errors.push(...validateTemplate(room, roomTemplate));

  return errors;
}

function validateTemplate(room: Room, template: RoomTemplates | undefined): ReadonlyArray<RoomError> {
  if (!template) {
    return [{ property: "roomType", message: texts.error_template_not_found }];
  }

  if (isSupplyRoom(room)) {
    const defaultAirFactor = template.default_supply_air_factor;
    const airFactorRange = template.supply_air_factor_range;
    const supplyAirFactor = room.supplyAirFactor;
    if (defaultAirFactor === null || airFactorRange === null || supplyAirFactor === null) {
      return [];
    }

    const minFactor = defaultAirFactor - airFactorRange;
    const maxFactor = defaultAirFactor + airFactorRange;
    if (supplyAirFactor < minFactor || supplyAirFactor > maxFactor) {
      return [{ property: "supplyAirFactor", message: texts.error_supply_factor_range(minFactor, maxFactor) }];
    }
  }

  return [];
}
