import { v4 as uuid } from "uuid";
import { AirUnitArticles, isCompatibleWithDuctDiameter } from ".";
import { Project, SelectionToolApi } from "../..";
import { AirUnitsTable, MaterialTables } from "./types";

export function getSuitableAirUnits(
  requiredAirflow: number,
  airUnitsTable: AirUnitsTable,
  unitsFromSelectionTool: ReadonlyArray<SelectionToolApi.Unit>
): ReadonlyArray<Project.Material> {
  const selectionToolItemNumbers = new Set(unitsFromSelectionTool.map((u) => u.m3ItemNo));
  return airUnitsTable
    .filter((u) => {
      if (
        u.max_airflow === null ||
        u.min_airflow === null ||
        requiredAirflow < u.min_airflow ||
        requiredAirflow > u.max_airflow
      ) {
        return false;
      }
      if (!selectionToolItemNumbers.has(u.item_number || "")) {
        return false;
      }
      return true;
    })
    .map((u) => itemToMaterial(u.item_number, { required: "1", quantity: 1 }));
}

export function getMaterialsForAirUnit(
  materialTables: MaterialTables,
  airUnit: Project.Material,
  system: Project.System
): ReadonlyArray<Project.Material> {
  const {
    airUnitArticlesTable,
    airUnitAlternativesTable,
    accessoryTable,
    distributorTable,
    ductTable,
    valveTable,
    valveConnectorTable,
  } = materialTables.tables;

  const unitArticles = new Map(
    airUnitArticlesTable
      .filter((r) => r.air_unit_item_number === airUnit.itemNumber)
      .map((r) => [r.article_item_number, r])
  );

  const alternativeAirUnits = airUnitAlternativesTable
    .filter((unit) => unit.air_unit_item_number === airUnit.itemNumber)
    .map((i) => ({
      ...Project.createMaterial(uuid(), "standard", 0, i.alternative_air_unit_item_number || ""),
      quantity: 1,
      included: false,
    }));

  const ducts = ductTable
    .filter((r) => unitArticles.has(r.item_number) && isCompatibleWithDuctDiameter(r, system.pipeDiameter))
    .map((i) => itemToMaterial(i.item_number, unitArticles.get(i.item_number)!));

  const valves = valveTable
    .filter((r) => unitArticles.has(r.item_number) && isCompatibleWithDuctDiameter(r, system.pipeDiameter))
    .map((i) => itemToMaterial(i.item_number, unitArticles.get(i.item_number)!));

  const valveConnectors = valveConnectorTable
    .filter((r) => unitArticles.has(r.item_number) && isCompatibleWithDuctDiameter(r, system.pipeDiameter))
    .map((i) => itemToMaterial(i.item_number, unitArticles.get(i.item_number)!));

  const distributors = distributorTable
    .filter((r) => unitArticles.has(r.item_number) && isCompatibleWithDuctDiameter(r, system.pipeDiameter))
    .map((i) => itemToMaterial(i.item_number, unitArticles.get(i.item_number)!));

  const accessorys = accessoryTable
    .filter((r) => unitArticles.has(r.item_number) && isCompatibleWithDuctDiameter(r, system.pipeDiameter))
    .map((i) => itemToMaterial(i.item_number, unitArticles.get(i.item_number)!));

  const materials = [
    airUnit,
    ...alternativeAirUnits,
    ...ducts,
    ...valves,
    ...valveConnectors,
    ...distributors,
    ...accessorys,
  ].map((m, idx) => ({
    ...m,
    sortNo: idx,
  }));

  return materials;
}

function itemToMaterial(
  itemNumber: string | null,
  airUnitArticles: Pick<AirUnitArticles, "required" | "quantity">
): Project.Material {
  return {
    ...Project.createMaterial(uuid(), "standard", 0, itemNumber || ""),
    included: airUnitArticles.required === "1",
    quantity: airUnitArticles.quantity || 0,
  };
}
