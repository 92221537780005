import * as R from "ramda";
import { v4 as uuid } from "uuid";
import { sortList } from ".";
import { Project } from "../..";
import { UpdateListResult, ItemPackages, PackageItem } from "../types";
import { getListChanges } from "./list-changes";

export interface PackageStatus {
  readonly packageName: string;
  readonly added: boolean;
}

export function addPackage(
  itemPackages: ItemPackages,
  sortNos: ReadonlyMap<string, number>,
  system: Project.System,
  packageName: string
): UpdateListResult {
  const packageItemsToAdd = getPackageItemsToAdd(itemPackages, system, packageName);
  const materialsToAdd = packageItemsToAdd.map((i) => ({
    ...Project.createMaterial(uuid(), "standard", 0, i.itemNumber),
    packageName: i.packageName,
    quantity: i.quantity,
    included: true,
  }));
  const newMaterials = [...system.materials, ...materialsToAdd];
  const sortedMaterials = sortList(sortNos, newMaterials);
  const changes = getListChanges(system, sortedMaterials);
  return {
    materials: sortedMaterials,
    changedMaterialIds: changes,
  };
}

export function removePackage(
  system: Project.System,
  sortNos: ReadonlyMap<string, number>,
  packageName: string
): UpdateListResult {
  const existingPackages = getExistingPackages(system);
  const existingMaterials = existingPackages[packageName] || [];
  const materialIdsToRemove = new Set(existingMaterials.map((m) => m.id));
  const newMaterials = system.materials.filter((m) => !materialIdsToRemove.has(m.id));
  const sortedMaterials = sortList(sortNos, newMaterials);
  const changes = getListChanges(system, sortedMaterials);
  return {
    materials: sortedMaterials,
    changedMaterialIds: changes,
  };
}

export function getPackageStatus(itemPackages: ItemPackages, system: Project.System): ReadonlyArray<PackageStatus> {
  const packageNames = R.uniq([
    ...system.materials.filter((m) => !!m.packageName).map((m) => m.packageName || ""),
    ...(R.keys(itemPackages) as ReadonlyArray<string>),
  ]);
  const statuses = packageNames.map(
    (packageName): PackageStatus => ({
      packageName,
      added: getPackageItemsToAdd(itemPackages, system, packageName).length === 0,
    })
  );
  const sortNo = new Map(R.values(itemPackages).map((p) => [p[0].packageName, Math.min(...p.map((i) => i.sortNo))]));
  const sortedStatuses = statuses.sort((a, b) => (sortNo.get(a.packageName) || 0) - (sortNo.get(b.packageName) || 0));
  return sortedStatuses;
}

function getPackageItemsToAdd(
  itemPackages: ItemPackages,
  system: Project.System,
  packageName: string
): ReadonlyArray<PackageItem> {
  const existingPackages = getExistingPackages(system);
  const existingMaterials = existingPackages[packageName] || [];
  const existingItemNumbers = new Set(existingMaterials.map((m) => m.itemNumber));
  const itemsInPackage = itemPackages[packageName] || [];
  const packageItemsToAdd = itemsInPackage.filter((i) => !existingItemNumbers.has(i.itemNumber));
  return packageItemsToAdd;
}

function getExistingPackages(system: Project.System): {
  readonly [packageName: string]: ReadonlyArray<Project.Material>;
} {
  return R.groupBy(
    (m) => m.packageName || "",
    system.materials.filter((m) => !!m.packageName)
  );
}
