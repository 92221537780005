import { MaterialType } from ".";
import { Material } from "./types";

export function createMaterial(id: string, type: MaterialType, sortNo: number, itemNumber: string): Material {
  return {
    id: id,
    type: type,
    sortNo: sortNo,
    itemNumber: itemNumber,
    included: false,
    singleSalesPrice: null,
    singleNetPrice: null,
    singleCost: null,
    currency: null,
    quantity: 0,
    name: null,
    packageName: null,
  };
}

export function isMaterialEqual(m1: Material, m2: Material): boolean {
  const eq: { [Key in keyof Material]: boolean } = {
    currency: m1.currency === m2.currency,
    id: m1.id === m2.id,
    type: m1.type === m2.type,
    included: m1.included === m2.included,
    itemNumber: m1.itemNumber === m2.itemNumber,
    quantity: numbersEqual(m1.quantity, m2.quantity),
    singleCost: numbersEqual(m1.singleCost, m2.singleCost),
    singleSalesPrice: numbersEqual(m1.singleSalesPrice, m2.singleSalesPrice),
    singleNetPrice: numbersEqual(m1.singleNetPrice, m2.singleNetPrice),
    sortNo: m1.sortNo === m2.sortNo,
    name: m1.name === m2.name,
    packageName: m1.packageName === m2.packageName,
  };
  return (
    eq.currency &&
    eq.id &&
    eq.type &&
    eq.included &&
    eq.itemNumber &&
    eq.quantity &&
    eq.singleCost &&
    eq.singleSalesPrice &&
    eq.singleNetPrice &&
    eq.sortNo &&
    eq.name &&
    eq.packageName
  );
}

function numbersEqual(n1: number | null, n2: number | null): boolean {
  if (n1 === null || n2 === null) {
    return n1 === n2;
  } else {
    return Math.abs(n1 - n2) < 0.00001;
  }
}
