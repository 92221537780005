export interface RoomResult {
  readonly airflow: number | undefined;
  readonly area: number | undefined;
  readonly width: number | undefined;
  readonly length: number | undefined;
  readonly people: number | undefined;
  readonly valves: number | undefined;
}

export type ResultByRoomId = { readonly [roomId: string]: RoomResult };

export interface Result {
  readonly type: "norway";
  readonly floorAirflow: number | undefined;
  readonly supplyAirflow: number | undefined;
  readonly extractAirflow: number | undefined;
  readonly requiredAirflow: number | undefined;
  readonly roomResults: ResultByRoomId;
}
