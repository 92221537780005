import { requestTemplate } from "./request-template";

const apiUrl = "https://design-cal.systemair.com/calculate/product/multiple";

export interface ApiRequest {
  readonly url: string;
  readonly contentType: "application/json";
  readonly body: string;
}

export type ApiResponse = ReadonlyArray<{
  readonly result?: {
    readonly itemName?: string;
    readonly m3?: string;
  };
}>;

export interface Input {
  readonly supplyAirflow: number; // m^3/h
  readonly extractAirflow: number; // m^3/h
}

export interface Unit {
  readonly itemName: string;
  readonly m3ItemNo: string;
}

export type Result =
  | {
      readonly type: "success";
      readonly units: ReadonlyArray<Unit>;
    }
  | { readonly type: "error" }
  | { readonly type: "no-units-found" };

export function createRequest({
  supplyAirflow,
  extractAirflow,
}: {
  readonly supplyAirflow: number;
  readonly extractAirflow: number;
}): ApiRequest {
  const requestJson = {
    ...requestTemplate,
    calcParams: {
      ...requestTemplate.calcParams,
      supplyAirFlow: {
        ...requestTemplate.calcParams.supplyAirFlow,
        value: {
          ...requestTemplate.calcParams.supplyAirFlow.value,
          value: supplyAirflow,
        },
      },
      extractAirFlow: {
        ...requestTemplate.calcParams.extractAirFlow,
        value: {
          ...requestTemplate.calcParams.extractAirFlow.value,
          value: extractAirflow,
        },
      },
    },
  };
  return {
    url: apiUrl,
    contentType: "application/json",
    body: JSON.stringify(requestJson),
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapResponse(responseJson: any): Result {
  if (!Array.isArray(responseJson)) {
    return { type: "error" };
  }

  const response = responseJson as ApiResponse;

  const unitsResponse = response.map((r) => ({
    itemName: r.result?.itemName,
    m3ItemNo: r.result?.m3,
  }));
  if (unitsResponse.some((u) => !u.itemName || !u.m3ItemNo)) {
    return { type: "error" };
  }
  const units = unitsResponse as ReadonlyArray<Unit>;

  if (units.length === 0) {
    return { type: "no-units-found" };
  }

  return {
    type: "success",
    units: units,
  };
}
