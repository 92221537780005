import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { Cmd } from "@typescript-tea/core";
import { SharedState, Routes } from "@rvs/client-infra";
import * as ProjectState from "../../project-state";

// STATE

export interface State {
  readonly closedItems: ReadonlySet<string>;
  readonly processedSystems: ReadonlySet<string>;
}

export const Action = ctorsUnion({
  ToggleExpanded: (id: string) => ({ id }),
  NoOp: () => ({}),
});

export type Action = CtorsUnion<typeof Action>;

export function init(
  _location: Routes.ProjectLocation,
  _sharedState: SharedState.SharedState,
  prevState: State | undefined,
  _projectState: ProjectState.State
): readonly [State, Cmd<Action>?, ProjectState.Action?, SharedState.SharedStateAction?] {
  return [prevState || { closedItems: new Set(), processedSystems: new Set() }];
}

export function update(
  action: Action,
  state: State,
  _projectState: ProjectState.State,
  _sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "ToggleExpanded": {
      const newClosed = new Set<string>(state.closedItems);
      if (newClosed.has(action.id)) {
        newClosed.delete(action.id);
      } else {
        newClosed.add(action.id);
      }
      return [{ ...state, closedItems: newClosed }];
    }

    case "NoOp": {
      return [state];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}
