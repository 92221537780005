import gql from "graphql-tag";

export const saveProjectAsTemplateMutation = gql`
  mutation GeneralProject_saveProjectAsTemplate($input: SaveProjectAsTemplateInput!) {
    saveProjectAsTemplate(input: $input) {
      id
    }
  }
`;

export const createProjectFromTemplateMutation = gql`
  mutation GeneralProject_createProjectFromTemplate($input: CreateProjectFromTemplateInput!) {
    createProjectFromTemplate(input: $input) {
      id
    }
  }
`;
