import { Calculators } from "..";
import { Room, RoomType, MarketValveTypesTable } from "./types";

export const roundingDecimals = 2;

export function isSupplyRoom(room: Room): boolean {
  return room.airType === "supply";
}

export function isExtractRoom(room: Room): boolean {
  return room.airType === "extract";
}

export function isOverPressureRoom(room: Room): boolean {
  return room.airType === "over_pressure";
}

export function getRoomType(room: Room): RoomType | undefined {
  if (isSupplyRoom(room)) {
    return "supply";
  } else if (isExtractRoom(room)) {
    return "extract";
  } else if (isOverPressureRoom(room)) {
    return "over_pressure";
  } else {
    return undefined;
  }
}

export function getAvailableRoomColumns(
  marketValveTypesTable: MarketValveTypesTable,
  market: string,
  roomFields: Calculators.RoomFieldsById
): ReadonlySet<keyof Room> {
  const set = new Set<keyof Room>();
  for (const fields of Object.values(roomFields)) {
    for (const f of fields) {
      if (!f.hidden) {
        set.add(f.field);
      }
    }
  }
  set.add("name");
  if (marketValveTypesTable.some((r) => r.market_name === market)) {
    set.add("valveType");
  }
  return set;
}
