import { gql } from "graphql-tag";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import {
  SharedState,
  Routes,
  graphQLQueryWithAuth,
  graphQLMutationWithAuth,
  variablesFromPatch,
  RequestGenerator,
} from "@rvs/client-infra";
import { Project, Calculators, User, Materials } from "@rvs/shared";
import * as General from "./parts/general";
import * as VentilationConcept from "./parts/ventilation-concept";
import * as Edit from "./parts/edit";
import * as MaterialList from "./parts/material-list";
import * as RequestQuote from "./parts/request-quote";
import * as Printout from "./parts/printout";
import * as AdditionalDocuments from "./parts/additional-documents";
import * as GQLOps from "../../generated/generated-operations";
import * as M from "./mutations";
import { clientConfig } from "../../client-config";
import * as ProjectState from "./project-state";

// STATE
const queryMetaWindyRegions = gql`
  query metaWindyRegions($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        custom_tables {
          WindyRegionsGermany {
            postal_code
          }
        }
      }
    }
  }
`;

export interface StagedPdfFile {
  readonly data: ArrayBuffer | string;
  readonly name: string;
  readonly lastModified: number;
}

export type State = InitStateData | InitStateProject | InitStateUpdate | ReadyState | ErrorState;

export interface InitStateData {
  readonly type: "init-data";
  readonly projectId: string;
  readonly location: Routes.ProjectLocation;
  readonly metaProduct: Project.MetaProductQuery | undefined;
  readonly windyRegions: ReadonlySet<string> | undefined;
  readonly materialTables: Materials.MaterialTables | undefined;
  readonly projectStatus: Project.ProjectStatusQuery["projectStatus"] | undefined;
}

export interface InitStateProject {
  readonly type: "init-project";
  readonly location: Routes.ProjectLocation;
  readonly metaProduct: Project.MetaProductQuery;
  readonly windyRegions: ReadonlySet<string>;
  readonly materialTables: Materials.MaterialTables;
  readonly projectStatus: Project.ProjectStatus;
  readonly project: Project.Project | undefined;
}

export interface InitStateUpdate {
  readonly type: "init-update";
  readonly location: Routes.ProjectLocation;
  readonly metaProduct: Project.MetaProductQuery;
  readonly windyRegions: ReadonlySet<string>;
  readonly materialTables: Materials.MaterialTables;
  readonly projectStatus: Project.ProjectStatus;
  readonly project: Project.Project;
  readonly customerNumberUpdated: boolean;
  readonly remainingRecalculationPatches: ReadonlyArray<number>;
}

export interface ReadyState {
  readonly type: "ready";
  readonly location: Routes.ProjectLocation;
  readonly generalState: General.State | undefined;
  readonly ventilationConceptState: VentilationConcept.State | undefined;
  readonly editState: Edit.State | undefined;
  readonly materialListState: MaterialList.State | undefined;
  readonly requestQuoteState: RequestQuote.State | undefined;
  readonly additionalDocumentsState: AdditionalDocuments.State | undefined;
  readonly printoutState: Printout.State | undefined;
  readonly projectStatus: Project.ProjectStatus;
  readonly projectState: ProjectState.State;
}

export interface ErrorState {
  readonly type: "error";
  readonly message: "project_not_found" | "project_no_access";
}

export const Action = ctorsUnion({
  ProjectStateAction: (action: ProjectState.Action) => ({
    action,
  }),
  GeneralAction: (action: General.Action) => ({
    action,
  }),
  VentilationConceptAction: (action: VentilationConcept.Action) => ({
    action,
  }),
  EditAction: (action: Edit.Action) => ({
    action,
  }),
  MaterialListAction: (action: MaterialList.Action) => ({
    action,
  }),
  RequestQuoteAction: (action: RequestQuote.Action) => ({
    action,
  }),
  AdditionalDocuments: (action: AdditionalDocuments.Action) => ({
    action,
  }),
  PrintoutAction: (action: Printout.Action) => ({
    action,
  }),
  DataResponseRecieved: (
    payload:
      | {
          readonly type: "metaProduct";
          readonly data: Project.MetaProductQuery;
        }
      | {
          readonly type: "metaWindyRegionsQuery";
          readonly data: GQLOps.MetaWindyRegionsQuery;
        }
      | {
          readonly type: "materialProduct";
          readonly queryState: RequestGenerator.QueryState<Materials.MaterialTables>;
        }
      | {
          readonly type: "projectStatus";
          readonly data: Project.ProjectStatusQuery | undefined;
        }
  ) => ({ payload }),
  ProjectRecieved: (project: Project.Project | undefined) => ({
    project,
  }),
  UpdateResponseRecieved: (
    payload:
      | {
          readonly type: "customer-number";
        }
      | {
          readonly type: "recalculation";
          readonly patchIndexes: ReadonlyArray<number>;
        }
  ) => ({ payload }),
  NoOp: () => ({}),
});
export type Action = CtorsUnion<typeof Action>;

export function init(
  projectId: string,
  location: Routes.ProjectLocation,
  sharedState: SharedState.SharedState,
  prevState: State | undefined
): readonly [State, Cmd<Action>?] {
  const recalcuationNeeded =
    prevState?.type === "ready" &&
    recalculateProject(
      {
        market: sharedState.market.name,
        metaQueryResponse: prevState.projectState.metaProduct,
        materialTables: prevState.projectState.materialTables,
      },
      prevState.projectState.project
    ).patches.some((patch) => patch.patches.length > 0);

  if (prevState?.type === "ready" && !recalcuationNeeded && projectId === prevState.projectState.project.id) {
    return initPages(
      sharedState,
      {
        ...prevState,
        type: "ready",
        location: location,
      },
      { type: "prev-state", prevState: prevState.projectState }
    );
  } else {
    const projectStatusCmd = graphQLQueryWithAuth(sharedState.activeUser)<
      GQLOps.Project_ProjectStatusQuery,
      GQLOps.Project_ProjectStatusQueryVariables,
      Action
    >(Project.queryStatus, { projectId: projectId }, sharedState.market.name, (data) =>
      Action.DataResponseRecieved({ type: "projectStatus", data })
    );

    const metaProductCmd = sharedState.graphQLProductQuery<
      Project.MetaProductQuery,
      Project.MetaProductQueryVariables,
      Action
    >(
      Project.queryMetaProduct,
      {
        productId: clientConfig.promaster_meta_id,
      },
      (data) => Action.DataResponseRecieved({ type: "metaProduct", data })
    );

    const windyRegionsCmd = sharedState.market.ventilationConceptEnabled
      ? sharedState.graphQLProductQuery<GQLOps.MetaWindyRegionsQuery, GQLOps.MetaWindyRegionsQueryVariables, Action>(
          queryMetaWindyRegions,
          {
            productId: clientConfig.promaster_meta_id,
          },
          (data) => Action.DataResponseRecieved({ type: "metaWindyRegionsQuery", data })
        )
      : undefined;

    const materialResult = RequestGenerator.startQuery(
      sharedState.activeUser,
      sharedState.market.name,
      sharedState.graphQLProductQuery,
      Materials.getMaterialsYield(sharedState.market, clientConfig.promaster_meta_id),
      (queryState) => Action.DataResponseRecieved({ type: "materialProduct", queryState })
    );
    const materialTables = materialResult.type === "done" ? materialResult.response : undefined;
    const materialTablesCmd = materialResult.type === "next" ? materialResult.cmd : undefined;

    return [
      {
        type: "init-data",
        projectId: projectId,
        location: location,
        metaProduct: undefined,
        materialTables: materialTables,
        projectStatus: undefined,
        windyRegions: !windyRegionsCmd ? new Set() : undefined,
      },
      Cmd.batch([projectStatusCmd, windyRegionsCmd, metaProductCmd, materialTablesCmd]),
    ];
  }
}

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "ProjectStateAction": {
      if (state.type !== "ready" || !state.projectState) {
        return [state];
      }
      const [projectState, cmd, sharedStateAction] = ProjectState.update(
        action.action,
        state.projectState,
        sharedState
      );
      if (state.projectState.project.locked !== projectState.project.locked && !projectState.project.locked) {
        // Update prices and calculation after the project has been unlocked
        return startInitUpdate(sharedState, {
          type: "init-update",
          location: state.location,
          metaProduct: projectState.metaProduct,
          windyRegions: projectState.windyRegions,
          materialTables: projectState.materialTables,
          projectStatus: state.projectStatus,
          project: projectState.project,
          customerNumberUpdated: false,
          remainingRecalculationPatches: [],
        });
      }
      return [
        {
          ...state,
          projectState,
        },
        Cmd.map(Action.ProjectStateAction, cmd),
        sharedStateAction,
      ];
    }
    case "GeneralAction": {
      if (state.type !== "ready" || !state.generalState || !state.projectState) {
        return [state];
      }
      const [generalState, cmd, sharedStateAction] = General.update(
        action.action,
        state.generalState,
        state.projectState,
        sharedState
      );
      return [
        {
          ...state,
          generalState,
        },
        Cmd.map(Action.GeneralAction, cmd),
        sharedStateAction,
      ];
    }

    case "VentilationConceptAction": {
      if (state.type !== "ready" || !state.ventilationConceptState || !state.projectState) {
        return [state];
      }
      const [ventilationConceptState, cmd, sharedStateAction] = VentilationConcept.update(
        action.action,
        state.ventilationConceptState,
        state.projectState,
        sharedState
      );
      return [
        {
          ...state,
          ventilationConceptState,
        },
        Cmd.map(Action.VentilationConceptAction, cmd),
        sharedStateAction,
      ];
    }

    case "EditAction": {
      if (state.type !== "ready" || !state.editState || !state.projectState) {
        return [state];
      }
      const [editState, cmd, sharedStateAction] = Edit.update(
        action.action,
        state.editState,
        state.projectState,
        sharedState
      );
      return [
        {
          ...state,
          editState,
        },
        Cmd.map(Action.EditAction, cmd),
        sharedStateAction,
      ];
    }

    case "MaterialListAction": {
      if (state.type !== "ready" || !state.materialListState || !state.projectState) {
        return [state];
      }
      const [materialListState, cmd, sharedStateAction] = MaterialList.update(
        action.action,
        state.materialListState,
        state.projectState,
        sharedState
      );
      return [
        {
          ...state,
          materialListState,
        },
        Cmd.map(Action.MaterialListAction, cmd),
        sharedStateAction,
      ];
    }

    case "RequestQuoteAction": {
      if (state.type !== "ready" || !state.requestQuoteState || !state.projectState) {
        return [state];
      }
      const [requestQuoteState, cmd, sharedStateAction] = RequestQuote.update(
        action.action,
        state.requestQuoteState,
        state.projectState,
        sharedState
      );
      return [
        {
          ...state,
          requestQuoteState,
        },
        Cmd.map(Action.RequestQuoteAction, cmd),
        sharedStateAction,
      ];
    }

    case "AdditionalDocuments": {
      if (state.type !== "ready" || !state.additionalDocumentsState || !state.projectState) {
        return [state];
      }
      const [updateState, cmd, sharedStateAction] = AdditionalDocuments.update(
        action.action,
        state.additionalDocumentsState,
        state.projectState,
        sharedState
      );
      return [
        {
          ...state,
          additionalDocumentsState: updateState,
        },
        Cmd.map(Action.AdditionalDocuments, cmd),
        sharedStateAction,
      ];
    }

    case "PrintoutAction": {
      if (state.type !== "ready" || !state.printoutState || !state.projectState) {
        return [state];
      }
      const [printoutState, cmd, sharedStateAction] = Printout.update(
        action.action,
        state.printoutState,
        state.projectState,
        sharedState
      );
      return [
        {
          ...state,
          printoutState,
        },
        Cmd.map(Action.PrintoutAction, cmd),
        sharedStateAction,
      ];
    }

    case "DataResponseRecieved": {
      if (state.type !== "init-data") {
        return [state];
      }
      let newState = state;
      switch (action.payload.type) {
        case "metaProduct":
          newState = {
            ...newState,
            metaProduct: action.payload.data,
          };
          break;
        case "metaWindyRegionsQuery": {
          const table = action.payload.data.product?.modules.custom_tables.WindyRegionsGermany || [];
          const windyRegions = new Set(table.map((r) => r.postal_code || ""));
          newState = {
            ...newState,
            windyRegions,
          };
          break;
        }
        case "materialProduct": {
          const queryResult = RequestGenerator.nextQuery(
            sharedState.activeUser,
            sharedState.market.name,
            sharedState.graphQLProductQuery,
            action.payload.queryState,
            (queryState) => Action.DataResponseRecieved({ type: "materialProduct", queryState })
          );
          if (queryResult.type === "done") {
            newState = {
              ...newState,
              materialTables: queryResult.response,
            };
          } else {
            return [state, queryResult.cmd];
          }
          break;
        }
        case "projectStatus": {
          const projectStatus = action.payload.data?.projectStatus;

          if (!projectStatus?.exists) {
            return [{ type: "error", message: "project_not_found" }];
          }

          if (!projectStatus?.access) {
            return [{ type: "error", message: "project_no_access" }];
          }

          newState = {
            ...newState,
            projectStatus: action.payload.data?.projectStatus,
          };

          break;
        }
        default:
          exhaustiveCheck(action.payload);
      }
      if (!newState.materialTables || !newState.metaProduct || !newState.projectStatus || !newState.windyRegions) {
        return [newState];
      } else if (newState.projectStatus.exists && newState.projectStatus.access) {
        return [
          {
            type: "init-project",
            location: newState.location,
            metaProduct: newState.metaProduct,
            windyRegions: newState.windyRegions,
            materialTables: newState.materialTables,
            projectStatus: newState.projectStatus,
            project: undefined,
          },
          graphQLQueryWithAuth(sharedState.activeUser)<
            GQLOps.Project_ProjectQuery,
            GQLOps.Project_ProjectQueryVariables,
            Action
          >(Project.query, { projectId: newState.projectId }, sharedState.market.name, (data) =>
            Action.ProjectRecieved(data.project || undefined)
          ),
        ];
      } else {
        return [{ type: "error", message: "project_not_found" }];
      }
    }

    case "ProjectRecieved": {
      if (state.type !== "init-project") {
        return [state];
      }
      if (!action.project) {
        return [{ type: "error", message: "project_not_found" }];
      }
      return startInitUpdate(sharedState, {
        type: "init-update",
        location: state.location,
        metaProduct: state.metaProduct,
        windyRegions: state.windyRegions,
        materialTables: state.materialTables,
        projectStatus: state.projectStatus,
        project: action.project,
        customerNumberUpdated: false,
        remainingRecalculationPatches: [],
      });
    }

    case "UpdateResponseRecieved": {
      if (state.type !== "init-update") {
        return [state];
      }
      let newState = state;
      switch (action.payload.type) {
        case "customer-number":
          newState = {
            ...newState,
            customerNumberUpdated: true,
          };
          break;
        case "recalculation": {
          const patchIndexes = action.payload.patchIndexes;
          const remaining = newState.remainingRecalculationPatches.filter(
            (p) => !patchIndexes.some((patch) => patch === p)
          );
          newState = {
            ...newState,
            remainingRecalculationPatches: remaining,
          };
          break;
        }
        default:
          exhaustiveCheck(action.payload);
      }
      if (!newState.customerNumberUpdated || newState.remainingRecalculationPatches.length > 0) {
        return [newState];
      }
      return initPages(
        sharedState,
        {
          type: "ready",
          location: newState.location,
          generalState: undefined,
          ventilationConceptState: undefined,
          editState: undefined,
          materialListState: undefined,
          requestQuoteState: undefined,
          printoutState: undefined,
          additionalDocumentsState: undefined,
          projectStatus: newState.projectStatus,
        },
        {
          type: "new-state",
          project: newState.project,
          metaProduct: newState.metaProduct,
          windyRegions: newState.windyRegions,
          materialTables: newState.materialTables,
        }
      );
    }

    case "NoOp": {
      return [state];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

function startInitUpdate(
  sharedState: SharedState.SharedState,
  newState: InitStateUpdate
): readonly [State, Cmd<Action>?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const cmds = [];

  // Update customer number
  const newCustomerNumber = getCustomerNumberToUpdateWith(newState.project, sharedState);
  if (newCustomerNumber) {
    newState = {
      ...newState,
      project: { ...newState.project, customerNumber: newCustomerNumber },
    };
    cmds.push(
      graphQLMutation<
        GQLOps.ProjectState_UpdateProjectMutation,
        GQLOps.ProjectState_UpdateProjectMutationVariables,
        Action
      >(
        M.updateProjectMutation,
        variablesFromPatch(newState.project.id, {
          customerNumber: newCustomerNumber,
        }),
        sharedState.market.name,
        () => Action.UpdateResponseRecieved({ type: "customer-number" })
      )
    );
  } else {
    newState = {
      ...newState,
      customerNumberUpdated: true,
    };
  }

  // Recalculate project
  const { updatedProject, patches } = recalculateProject(
    {
      market: sharedState.market.name,
      metaQueryResponse: newState.metaProduct,
      materialTables: newState.materialTables,
    },
    newState.project
  );

  const remainingRecalculationPatches = [];

  let patchIndex = 0;
  for (const systemPatch of patches) {
    const systemPatchIndexes = [];
    const mergedPatches = Project.mergePatches(systemPatch.patches);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const _ of mergedPatches) {
      remainingRecalculationPatches.push(patchIndex);
      systemPatchIndexes.push(patchIndex);
      patchIndex++;
    }
    const roomUpdateMoutations = createRoomUpdateMutations(
      graphQLMutation,
      systemPatch.patches,
      systemPatch.systemId,
      sharedState.market.name,
      systemPatchIndexes
    );
    cmds.push(roomUpdateMoutations);
  }

  newState = {
    ...newState,
    project: updatedProject,
    remainingRecalculationPatches,
  };

  if (cmds.length === 0) {
    return initPages(
      sharedState,
      {
        type: "ready",
        location: newState.location,
        generalState: undefined,
        ventilationConceptState: undefined,
        editState: undefined,
        materialListState: undefined,
        requestQuoteState: undefined,
        printoutState: undefined,
        additionalDocumentsState: undefined,
        projectStatus: newState.projectStatus,
      },
      {
        type: "new-state",
        project: newState.project,
        metaProduct: newState.metaProduct,
        windyRegions: newState.windyRegions,
        materialTables: newState.materialTables,
      }
    );
  } else {
    return [newState, Cmd.batch(cmds)];
  }
}

function initPages(
  sharedState: SharedState.SharedState,
  prevState: Omit<ReadyState, "projectState">,
  projectStateInput:
    | { readonly type: "prev-state"; readonly prevState: ProjectState.State }
    | {
        readonly type: "new-state";
        readonly project: Project.Project;
        readonly metaProduct: Project.MetaProductQuery;
        readonly windyRegions: ReadonlySet<string>;
        readonly materialTables: Materials.MaterialTables;
      }
): readonly [State, Cmd<Action>?] {
  const [projectState, projectStateCmdUnmapped] =
    projectStateInput.type === "prev-state"
      ? [projectStateInput.prevState, undefined]
      : ProjectState.init(
          sharedState,
          projectStateInput.project,
          projectStateInput.metaProduct,
          projectStateInput.windyRegions,
          projectStateInput.materialTables
        );
  const projectStateCmd = projectStateCmdUnmapped && Cmd.map(Action.ProjectStateAction, projectStateCmdUnmapped);
  const state = {
    ...prevState,
    projectState: projectState,
  };

  switch (state.location.type) {
    case "General": {
      const [generalState, generalAction] = General.init(
        state.location,
        sharedState,
        state?.generalState,
        state.projectState.project.id
      );
      const generalLocationCmd = Cmd.map(Action.GeneralAction, generalAction);
      return [
        {
          ...state,
          generalState,
        },
        Cmd.batch<Action>([projectStateCmd, generalLocationCmd]),
      ];
    }

    case "VentilationConcept": {
      const [ventilationConceptState, ventilationConceptAction] = VentilationConcept.init(
        state.location,
        sharedState,
        state?.ventilationConceptState,
        state.projectState
      );
      const ventilationConceptActionCmd = Cmd.map(Action.VentilationConceptAction, ventilationConceptAction);
      return [
        {
          ...state,
          ventilationConceptState,
        },
        Cmd.batch<Action>([projectStateCmd, ventilationConceptActionCmd]),
      ];
    }

    case "Edit": {
      const [editState, editAction] = Edit.init(state.location, sharedState, state.projectState, state?.editState);
      const editLocationCmd = Cmd.map(Action.EditAction, editAction);
      return [
        {
          ...state,
          editState,
        },
        Cmd.batch<Action>([projectStateCmd, editLocationCmd]),
      ];
    }

    case "MaterialList": {
      const [materialListState, materiallistAction] = MaterialList.init(
        state.location,
        sharedState,
        state?.materialListState,
        state.projectState
      );
      const materiallistLocationCmd = Cmd.map(Action.MaterialListAction, materiallistAction);
      return [
        {
          ...state,
          materialListState,
        },
        Cmd.batch<Action>([projectStateCmd, materiallistLocationCmd]),
      ];
    }

    case "RequestQuote": {
      const [requestQuoteState, requestQuoteAction] = RequestQuote.init(
        state.location,
        sharedState,
        state?.requestQuoteState
      );
      const requestQuoteLocationCmd = Cmd.map(Action.RequestQuoteAction, requestQuoteAction);
      return [
        {
          ...state,
          requestQuoteState,
        },
        Cmd.batch<Action>([projectStateCmd, requestQuoteLocationCmd]),
      ];
    }
    case "AdditionalDocuments": {
      const [initState, initAction] = AdditionalDocuments.init(
        state.location,
        sharedState,
        state?.additionalDocumentsState
      );
      const locationCmd = Cmd.map(Action.AdditionalDocuments, initAction);
      return [
        {
          ...state,
          additionalDocumentsState: initState,
        },
        Cmd.batch<Action>([projectStateCmd, locationCmd]),
      ];
    }

    case "Printout": {
      const [printoutState, printoutAction] = Printout.init(state.location, sharedState, state?.printoutState);
      const printoutLocationCmd = Cmd.map(Action.PrintoutAction, printoutAction);
      return [
        {
          ...state,
          printoutState,
        },
        Cmd.batch<Action>([projectStateCmd, printoutLocationCmd]),
      ];
    }
    default: {
      return exhaustiveCheck(state.location, true);
    }
  }
}

function getCustomerNumberToUpdateWith(
  project: Project.Project,
  sharedState: SharedState.SharedState
): string | undefined {
  const approvedCustomerNumber = User.getApprovedCustomerNumber(sharedState.activeUser);
  if (project.customerNumber || Project.isProjectReadOnly(project)) {
    return undefined;
  } else if (sharedState.crmParams?.crmCustomerNumber) {
    return sharedState.crmParams.crmCustomerNumber;
  } else if (approvedCustomerNumber) {
    return approvedCustomerNumber;
  } else {
    return undefined;
  }
}

type systemPatch = {
  readonly systemId: string;
  readonly patches: ReadonlyArray<Project.Patch<Project.Room>>;
};

export function recalculateProject(
  {
    market,
    metaQueryResponse,
    materialTables,
  }: {
    readonly market: string;
    readonly metaQueryResponse: Project.MetaProductQuery;
    readonly materialTables: Materials.MaterialTables;
  },
  project: Project.Project
): {
  readonly updatedProject: Project.Project;
  readonly patches: ReadonlyArray<systemPatch>;
} {
  if (Project.isProjectReadOnly(project)) {
    return { updatedProject: project, patches: [] };
  }
  let updatedProject = project;
  const patches: systemPatch[] = [];
  for (const system of project.systems) {
    const input = Calculators.map(metaQueryResponse, materialTables, system, market);
    const result = input && Calculators.calculate(input);
    const projectUpdates = input && result && Calculators.mapResultToProject(input, result);
    if (!result || !projectUpdates) {
      return { updatedProject: project, patches: [] };
    }
    const updatedSystem = Project.applyRoomPatchesToSystem(system, projectUpdates.roomPatches);
    updatedProject = Project.replaceSystem(updatedProject, updatedSystem);
    patches.push({ patches: projectUpdates.roomPatches, systemId: system.id });
  }
  return { updatedProject, patches };
}

function createRoomUpdateMutations(
  graphQLMutation: ReturnType<typeof graphQLMutationWithAuth>,
  patches: ReadonlyArray<Project.Patch<Project.Room>>,
  systemId: string,
  marketName: string,
  patchIndexes: readonly number[]
): Cmd<Action> {
  return graphQLMutation(
    M.updateRoomsMutation,
    { input: { systemId: systemId, patches: Project.mergePatches(patches) } },
    marketName,
    () => Action.UpdateResponseRecieved({ type: "recalculation", patchIndexes })
  );
}
