import { exhaustiveCheck } from "ts-exhaustive-check";
import { Dispatch } from "@typescript-tea/core";
import { Routes, SharedState } from "@rvs/client-infra";
import { texts } from "@rvs/shared/src/lang-texts";
import React from "react";
import { Project, Texts } from "@rvs/shared";
import * as State from "./state";
import { H2, TabButton, Spinner, Alert } from "../../elements";
import * as General from "./parts/general";
import * as VentilationConcept from "./parts/ventilation-concept";
import * as Edit from "./parts/edit";
import * as MaterialList from "./parts/material-list";
import * as RequestQuote from "./parts/request-quote";
import * as Printout from "./parts/printout";
import * as AdditionalDocuments from "./parts/additional-documents";

export function View({
  state,
  dispatch,
  sharedState,
}: {
  readonly state: State.State;
  readonly dispatch: Dispatch<State.Action>;
  readonly sharedState: SharedState.SharedState;
}): JSX.Element {
  const { translate, market } = sharedState;

  if (state.type === "error") {
    if (state.message === "project_not_found") {
      return (
        <Alert type="error">
          <p>{translate(texts.project_not_found)}</p>
          <BackToProjectListLink project={undefined} translate={translate} />
        </Alert>
      );
    } else if (state.message === "project_no_access") {
      return (
        <Alert type="error">
          <p>{translate(texts.project_no_access_message)}</p>
          <BackToProjectListLink project={undefined} translate={translate} />
        </Alert>
      );
    } else {
      throw new Error("Unknown error state");
    }
  }

  if (state.type !== "ready") {
    return <Spinner />;
  }

  const { projectStatus, projectState } = state;
  const { project } = projectState;

  if (!projectStatus.exists) {
    return (
      <Alert type="error">
        <p>{translate(texts.project_delete_message)}</p>
        <BackToProjectListLink project={project} translate={translate} />
      </Alert>
    );
  } else if (!projectStatus.access) {
    return (
      <Alert type="error">
        <p>{translate(texts.project_no_access_message)}</p>
        <BackToProjectListLink project={project} translate={translate} />
      </Alert>
    );
  }

  if (!project) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="mb-32">
        <BackToProjectListLink project={project} translate={translate} />
      </div>
      <div>
        <H2>
          {project.template
            ? `${translate(texts.template)}${project.name && ` - ${project.name}`}`
            : project.name || translate(texts.project)}
        </H2>
      </div>
      <div className="mb-20">
        <TabButton
          active={state.location.type === "General"}
          label={translate(texts.general)}
          url={Routes.buildUrl(
            Routes.RootLocation.MainLocation(
              Routes.MainLocation.Project(state.projectState.project.id, Routes.ProjectLocation.General())
            )
          )}
        />
        <TabButton
          active={state.location.type === "Edit"}
          label={translate(texts.systems)}
          url={Routes.buildUrl(
            Routes.RootLocation.MainLocation(
              Routes.MainLocation.Project(state.projectState.project.id, Routes.ProjectLocation.Edit())
            )
          )}
        />
        {market.ventilationConceptEnabled && (
          <TabButton
            active={state.location.type === "VentilationConcept"}
            label={translate(texts.ventilation_concept)}
            disabled={project.systems.length === 0}
            url={Routes.buildUrl(
              Routes.RootLocation.MainLocation(
                Routes.MainLocation.Project(state.projectState.project.id, Routes.ProjectLocation.VentilationConcept())
              )
            )}
          />
        )}
        {!project.template && (
          <TabButton
            active={state.location.type === "MaterialList"}
            label={translate(texts.material_list)}
            disabled={project.systems.length === 0}
            url={Routes.buildUrl(
              Routes.RootLocation.MainLocation(
                Routes.MainLocation.Project(state.projectState.project.id, Routes.ProjectLocation.MaterialList())
              )
            )}
          />
        )}

        {!project.template && market.requestQuoteEmail && !sharedState.crmParams && (
          <TabButton
            active={state.location.type === "RequestQuote"}
            label={translate(texts.request_quote)}
            disabled={project.systems.length === 0}
            url={Routes.buildUrl(
              Routes.RootLocation.MainLocation(
                Routes.MainLocation.Project(state.projectState.project.id, Routes.ProjectLocation.RequestQuote())
              )
            )}
          />
        )}
        {market.additionalDocumentsEnabled && (
          <TabButton
            active={state.location.type === "AdditionalDocuments"}
            label={translate(texts.additional_documents)}
            url={Routes.buildUrl(
              Routes.RootLocation.MainLocation(
                Routes.MainLocation.Project(state.projectState.project.id, Routes.ProjectLocation.AdditionalDocuments())
              )
            )}
          />
        )}
        {!project.template && (
          <TabButton
            active={state.location.type === "Printout"}
            label={translate(texts.printout)}
            url={Routes.buildUrl(
              Routes.RootLocation.MainLocation(
                Routes.MainLocation.Project(state.projectState.project.id, Routes.ProjectLocation.Printout())
              )
            )}
          />
        )}
      </div>
      {(() => {
        switch (state.location.type) {
          case "General":
            return state.generalState ? (
              <General.view
                dispatch={Dispatch.map(State.Action.GeneralAction, dispatch)}
                dispatchProject={Dispatch.map(State.Action.ProjectStateAction, dispatch)}
                crmExportResponse={projectState.crmExportResponse}
                exportingToCrm={projectState.exportingToCrm}
                state={state.generalState}
                projectState={projectState}
                sharedState={sharedState}
              />
            ) : undefined;
          case "VentilationConcept":
            return state.ventilationConceptState ? (
              <VentilationConcept.View
                dispatch={Dispatch.map(State.Action.VentilationConceptAction, dispatch)}
                dispatchProject={Dispatch.map(State.Action.ProjectStateAction, dispatch)}
                state={state.ventilationConceptState}
                project={project}
                sharedState={sharedState}
                projectState={projectState}
              />
            ) : undefined;
          case "Edit":
            return state.editState ? (
              <Edit.View
                dispatch={Dispatch.map(State.Action.EditAction, dispatch)}
                dispatchProject={Dispatch.map(State.Action.ProjectStateAction, dispatch)}
                state={state.editState}
                projectState={projectState}
                sharedState={sharedState}
              />
            ) : undefined;
          case "MaterialList":
            return state.materialListState ? (
              <MaterialList.View
                dispatch={Dispatch.map(State.Action.MaterialListAction, dispatch)}
                dispatchProject={Dispatch.map(State.Action.ProjectStateAction, dispatch)}
                crmExportResponse={projectState.crmExportResponse}
                exportingToCrm={projectState.exportingToCrm}
                state={state.materialListState}
                projectState={projectState}
                sharedState={sharedState}
              />
            ) : undefined;
          case "RequestQuote":
            return state.requestQuoteState ? (
              <RequestQuote.View
                dispatch={Dispatch.map(State.Action.RequestQuoteAction, dispatch)}
                dispatchProject={Dispatch.map(State.Action.ProjectStateAction, dispatch)}
                state={state.requestQuoteState}
                projectState={projectState}
                sharedState={sharedState}
              />
            ) : undefined;
          case "AdditionalDocuments":
            return state.additionalDocumentsState ? (
              <AdditionalDocuments.View
                dispatch={Dispatch.map(State.Action.AdditionalDocuments, dispatch)}
                dispatchProject={Dispatch.map(State.Action.ProjectStateAction, dispatch)}
                state={state.additionalDocumentsState}
                projectState={projectState}
                sharedState={sharedState}
              />
            ) : undefined;
          case "Printout":
            return state.printoutState ? (
              <Printout.View
                dispatch={Dispatch.map(State.Action.PrintoutAction, dispatch)}
                state={state.printoutState}
                projectState={projectState}
                sharedState={sharedState}
              />
            ) : undefined;
          default:
            exhaustiveCheck(state.location);
            return <div>{translate(texts.not_found_404)}</div>;
        }
      })()}
    </div>
  );
}

function BackToProjectListLink({
  project,
  translate,
}: {
  readonly project: Project.Project | undefined;
  readonly translate: Texts.TranslateFn;
}): JSX.Element {
  const url = Routes.buildUrl(
    Routes.RootLocation.MainLocation(
      Routes.MainLocation.ProjectList(
        project?.template ? Routes.ProjectListLocation.TemplateProjectList() : Routes.ProjectListLocation.ProjectList()
      )
    )
  );
  const text = translate(project?.template ? texts.go_back_to_template_list : texts.go_back_to_project_list);
  return <a href={url}>{text}</a>;
}
