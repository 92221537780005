import * as ReactDOM from "react-dom";
import { EffectManager, Program } from "@typescript-tea/core";
import { appInfo } from "@rvs/shared";
import { HttpEffectManager, NavigationEffectManager, OidcEffectManager, SessionStorage } from "@rvs/client-infra";
import { registerFontAwesomeIcons } from "../src/elements";
import { initSentry } from "./sentry";
import { clientConfig } from "./client-config";
import * as Root from "./root";
import * as Main from "./main";
//import "react-image-lightbox/style.css";

registerFontAwesomeIcons();
// import * as LocalStorageEffectManager from "./infrastructure/effect-managers/local-storage";

initSentry(
  clientConfig.dsn,
  appInfo.pipelineId,
  clientConfig.env,
  clientConfig.sentry_sample_rate,
  clientConfig.sentry_traces_sample_rate
);

const effectManagers: readonly EffectManager[] = [
  NavigationEffectManager.createEffectManager<Root.Action>(Root.Action.UrlChanged, Root.Action.UrlRequested),
  OidcEffectManager.createOidcEffectManager(),
  HttpEffectManager.createEffectManager<Root.Action>((httpStatus) =>
    Root.Action.DispatchMain(Main.Action.HttpStateChanged(httpStatus))
  ),
  SessionStorage.createEffectManager(),
  // LocalStorageEffectManager.createEffectManager(),
];

const rootProgram: Program<NavigationEffectManager.Url, Root.State, Root.Action, JSX.Element> = {
  init: Root.init,
  update: Root.update,
  view: Root.view,
  subscriptions: Root.subscriptions,
};

const rootElement = document.getElementById("root");
const render = (view: JSX.Element): void => ReactDOM.render(view, rootElement);
Program.run(rootProgram, NavigationEffectManager.getCurrentUrl(), render, effectManagers);
