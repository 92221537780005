import { Project, Texts } from "../..";
import { ValidationError } from "../types";

export function validateChangedMaterialListData(
  availableAirUnitItemNumbers: ReadonlyArray<string>,
  availableItemNumbers: ReadonlyArray<string>,
  system: Project.System
): ReadonlyArray<ValidationError> {
  const errors: Array<ValidationError> = [];

  // Validate added items
  let newItemsPresent: boolean = false;
  const itemsNosInList = new Set(system.materials.filter((m) => m.type === "standard").map((m) => m.itemNumber));
  for (const availableItemNumber of availableItemNumbers) {
    if (!itemsNosInList.has(availableItemNumber)) {
      newItemsPresent = true;
      break;
    }
  }
  if (newItemsPresent) {
    errors.push({
      category: "new_item_available",
      message: Texts.texts.new_items_available,
    });
  }

  // Validate removed items
  const itemNosInPromaster = new Set(availableItemNumbers);
  const airUnitItemNosInPromaster = new Set(availableAirUnitItemNumbers);
  for (const material of system.materials) {
    if (material.type !== "standard") {
      continue;
    }
    if (!itemNosInPromaster.has(material.itemNumber) && !airUnitItemNosInPromaster.has(material.itemNumber)) {
      errors.push({
        category: "item_has_been_removed",
        message: Texts.texts.item_has_been_removed,
        materialId: material.id,
      });
    }
  }

  return errors;
}
