import * as React from "react";

export interface LinkButtonProps {
  readonly label: string;
  readonly onClick: () => void;
  readonly disabled?: boolean;
  readonly active?: boolean;
  readonly extraPadding?: boolean;
}

export function LinkButton(props: LinkButtonProps): JSX.Element {
  const { onClick, label, disabled, active, extraPadding, ...htmlProps } = props;

  const linkClass = `${!disabled && !active ? "cursor-pointer" : ""} select-none ${disabled ? "text-gray-600" : ""}  ${
    active ? "bg-secondary-light font-bold" : ""
  } ${extraPadding ? "px-4" : ""}`;

  return disabled ? (
    <span className={linkClass} {...htmlProps}>
      <div className="text-sm">{label}</div>
    </span>
  ) : (
    <a className={linkClass} onClick={() => onClick()} {...htmlProps}>
      <div className="text-sm">{label}</div>
    </a>
  );
}
