import { isExtractRoom, isSupplyRoom, Room } from "../../../project";
import { calculateRoomVolume } from ".";

export function calculateRoomAirflow(
  totalExtractVolume: number | undefined,
  totalSupplyVolume: number | undefined,
  requiredAirflow: number | undefined,
  room: Room
): number | undefined {
  const volume = calculateRoomVolume(room);
  const airflowForRatio =
    isSupplyRoom(room) && volume && requiredAirflow && totalSupplyVolume
      ? requiredAirflow * (volume / totalSupplyVolume)
      : isExtractRoom(room) && volume && requiredAirflow && totalExtractVolume
      ? requiredAirflow * (volume / totalExtractVolume)
      : undefined;
  const airflow = airflowForRatio ? Math.max(10, airflowForRatio) : undefined;
  return airflow;
}
