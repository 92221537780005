import { isExtractRoom, isSupplyRoom, Room, RoomTemplates, RoomTemplatesTable, System } from "../../../project";
import { logWarn } from "../../../logger";
import { calculateNumberOfPeople } from ".";

export interface SystemAirflowResult {
  readonly floorAirflow: number | undefined;
  readonly supplyAirflow: number | undefined;
  readonly extractAirflow: number | undefined;
  readonly requiredAirflow: number | undefined;
}

export function calculateRoomAirflow(roomTemplateTable: RoomTemplatesTable, room: Room): number | undefined {
  if (isSupplyRoom(room)) {
    return calculateSupplyRoomAirflow(roomTemplateTable, room);
  } else if (isExtractRoom(room)) {
    return calculateExtractRoomAirflow(roomTemplateTable, room);
  } else {
    return undefined;
  }
}

export function calculateSystemAirflow(roomTemplateTable: RoomTemplatesTable, system: System): SystemAirflowResult {
  const supplyAirflow = system.rooms
    .filter((r) => isSupplyRoom(r))
    .reduce((sum, room) => {
      const roomAirflow = calculateSupplyRoomAirflow(roomTemplateTable, room);
      if (roomAirflow === undefined) {
        return sum;
      } else {
        return (sum || 0) + roomAirflow;
      }
    }, undefined);
  const extractAirflow = system.rooms
    .filter((r) => isExtractRoom(r))
    .reduce((sum, room) => {
      const roomAirflow = calculateExtractRoomAirflow(roomTemplateTable, room);
      if (roomAirflow === undefined) {
        return sum;
      } else {
        return (sum || 0) + roomAirflow;
      }
    }, undefined);
  const floorAirflow = system.totalArea !== null ? system.totalArea * 1.2 : undefined;
  const requiredAirflow =
    supplyAirflow !== undefined || extractAirflow !== undefined || floorAirflow !== undefined
      ? Math.max(supplyAirflow || 0, extractAirflow || 0, floorAirflow || 0)
      : undefined;
  return {
    floorAirflow,
    supplyAirflow,
    extractAirflow,
    requiredAirflow,
  };
}

function calculateSupplyRoomAirflow(roomTemplateTable: RoomTemplatesTable, room: Room): number | undefined {
  if (room.customAirFlow) {
    return room.airflow;
  }
  const people = calculateNumberOfPeople(roomTemplateTable, room);
  if (people === undefined) {
    return undefined;
  }
  const airflowLps = people * 7;
  const airflowCmph = airflowLps * 3.6;
  return airflowCmph;
}

function calculateExtractRoomAirflow(roomTemplateTable: RoomTemplatesTable, room: Room): number | undefined {
  if (room.customAirFlow) {
    return room.airflow;
  }
  const template = getTemplate(roomTemplateTable, room);
  if (!template || template.airflow_calculation !== "default_airflow") {
    return undefined;
  }
  const airflowCmph = template.default_extract_airflow ?? undefined;
  return airflowCmph;
}

function getTemplate(roomTemplatesTable: RoomTemplatesTable, room: Room): RoomTemplates | undefined {
  const template = roomTemplatesTable.find((r) => r.key === room.roomType);
  if (!template) {
    logWarn(`calculateNumberOfPeople: Not room template found for room type ${room.roomType}`);
  }
  return template;
}
