import gql from "graphql-tag";
import * as Project from "../../project";
import { graphQLProductQuery, graphQLQuery, QueryGenerator } from "../../query";
import { ReportQueryParams } from "..";
import { AdapterType, Response } from "./types";
import { getPageProps } from "../common";
import * as GQLOps from "../../generated/generated-operations";

const queryProductMetaFloorQuery = gql`
  query productMetaFloorQuery($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        custom_tables {
          ...roomFloorTable
        }
      }
    }
  }
  fragment roomFloorTable on Module_custom_tables {
    RoomFloor {
      key
      text_id
    }
  }
`;

function* floorQuery(): QueryGenerator<GQLOps.ProductMetaFloorQueryQuery> {
  const response = yield* graphQLProductQuery<
    GQLOps.ProductMetaFloorQueryQuery,
    GQLOps.ProductMetaFloorQueryQueryVariables
  >(queryProductMetaFloorQuery, { productId: "54b874d6-ee08-430b-b83f-f1f6809b3d0c" });
  return response;
}

export function* query(params: ReportQueryParams): QueryGenerator<Response> {
  const projectResponse = yield* graphQLQuery<Project.ProjectQuery, Project.ProjectQueryVariables>(Project.query, {
    projectId: params.projectId,
  });
  return { floorData: yield* floorQuery(), projectResponse };
}

export const execute: AdapterType = async (projectReportParams, common, response) => {
  const pageProps = await getPageProps(projectReportParams, common);
  return {
    data: ["hello ", "world ", projectReportParams.projectId],
    pageProps: pageProps,
    floorData: response.floorData.product?.modules.custom_tables,
    translate: projectReportParams.translate,
    projectResponse: response.projectResponse,
  };
};
