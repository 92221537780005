import {
  graphQLMutationWithAuth,
  NavigationEffectManager,
  Routes,
  SharedState,
  variablesFromPatch,
} from "@rvs/client-infra";
import { Cmd } from "@typescript-tea/core";
import { Project } from "@rvs/shared";
import { v4 as uuid } from "uuid";
import { Action, State } from "../state";
import * as GQLOps from "../../../../generated/generated-operations";
import * as M from "../mutations";

export function RemoveProject(
  _: ReturnType<typeof Action.RemoveProject>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;

  return [
    state,
    graphQLMutation<
      GQLOps.ProjectState_RemoveProjectMutation,
      GQLOps.ProjectState_RemoveProjectMutationVariables,
      Action
    >(
      M.removeProjectMutation,
      {
        input: {
          id: project.id,
        },
      },
      sharedState.market.name,
      () => Action.RemoveProjectResponse()
    ),
  ];
}

export function RemoveProjectResponse(
  __: ReturnType<typeof Action.RemoveProjectResponse>,
  state: State,
  _: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const newUrl = Routes.buildUrl(
    Routes.RootLocation.MainLocation(Routes.MainLocation.ProjectList(Routes.ProjectListLocation.ProjectList()))
  );
  return [state, NavigationEffectManager.pushUrl(newUrl)];
}

export function UpdateProject(
  action: ReturnType<typeof Action.UpdateProject>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;

  return [
    { ...state, project: { ...project, ...action.patch } },
    graphQLMutation<
      GQLOps.ProjectState_UpdateProjectMutation,
      GQLOps.ProjectState_UpdateProjectMutationVariables,
      Action
    >(M.updateProjectMutation, variablesFromPatch(project.id, action.patch), sharedState.market.name, Action.NoOp),
  ];
}

export function UpdateProjectLockState(
  action: ReturnType<typeof Action.UpdateProjectLockState>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;

  if (state.project.locked === action.locked) {
    return [state];
  }
  if (action.locked) {
    return [
      { ...state, project: { ...project, locked: action.locked, permissions: "read" } },
      graphQLMutation<
        GQLOps.ProjectState_UpdateProjectLockStateMutation,
        GQLOps.ProjectState_UpdateProjectLockStateMutationVariables,
        Action
      >(
        M.updateProjectLockStateMutation,
        { input: { id: project.id, locked: action.locked } },
        sharedState.market.name,
        (response) =>
          Action.ProjectUnlockResponse(
            response.updateProjectLockState.locked,
            response.updateProjectLockState.permissions as Project.Permissions
          )
      ),
    ];
  } else {
    return [
      state,
      graphQLMutation<
        GQLOps.ProjectState_UpdateProjectLockStateMutation,
        GQLOps.ProjectState_UpdateProjectLockStateMutationVariables,
        Action
      >(
        M.updateProjectLockStateMutation,
        { input: { id: project.id, locked: action.locked } },
        sharedState.market.name,
        (response) =>
          Action.ProjectUnlockResponse(
            response.updateProjectLockState.locked,
            response.updateProjectLockState.permissions as Project.Permissions
          )
      ),
    ];
  }
}

export function ShareProject(
  action: ReturnType<typeof Action.ShareProject>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;

  const newShare: Project.ProjectShare = {
    id: uuid(),
    email: action.email.toLocaleLowerCase(),
    permissions: action.permissions,
  };
  if (!newShare.email || !newShare.permissions) {
    return [state];
  }
  return [
    { ...state, project: { ...project, shares: [...project.shares, newShare] } },
    graphQLMutation<
      GQLOps.ProjectState_CreateProjectShareMutation,
      GQLOps.ProjectState_CreateProjectShareMutationVariables,
      Action
    >(
      M.createProjectShareMutation,
      {
        input: {
          id: newShare.id,
          projectId: project.id,
          email: newShare.email,
          permissions: newShare.permissions,
        },
      },
      sharedState.market.name,
      Action.NoOp
    ),
  ];
}

export function RemoveShare(
  action: ReturnType<typeof Action.RemoveShare>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  const { project } = state;

  return [
    {
      ...state,
      project: {
        ...project,
        shares: project.shares.filter((s) => s.email !== action.shareEmail),
      },
    },
    graphQLMutation<GQLOps.ProjectState_RemoveShareMutation, GQLOps.ProjectState_RemoveShareMutationVariables, Action>(
      M.removeShareShareMutation,
      {
        input: {
          id: action.projectId,
          email: action.shareEmail,
        },
      },
      sharedState.market.name,
      Action.NoOp
    ),
  ];
}
