import { TranslateFn } from "./translate";
import { texts } from "./texts";
import { Permissions } from "../project";

export type MailData = {
  readonly subject: string;
  readonly body: string;
};

export function buildMail(
  translate: TranslateFn,
  userEmail: string,
  otherUserExists: boolean,
  otherUserEmail: string,
  projectName: string,
  permissions: Permissions,
  serverAddress: string,
  projectUrl: string
): MailData {
  const link = `<a href="${serverAddress + projectUrl}">${projectName}</a>`;
  const subject = translate(texts.share_project_mail_subject);
  const message = translate(texts.share_project_mail_body(userEmail, link));
  const noAccountMessage = translate(texts.info_register_shared_project(serverAddress, otherUserEmail ?? ""));
  const nameLine = `${translate(texts.project_name)}: ${projectName}<br>`;
  const permLine = `${translate(texts.permissions)}: ${translate(texts.permission_name(permissions))}`;
  const body =
    `${message}<br><br>${translate(texts.project_info)}<br>` +
    nameLine +
    permLine +
    `${otherUserExists ? "" : `<br><br>${noAccountMessage}`}`;
  return {
    subject,
    body,
  };
}
