import { AbstractDoc as AD } from "abstract-document";
import * as GQLOps from "../../generated/generated-operations";
import { TranslateFn } from "../../lang-texts";
import { FontData } from "../types";
import {
  BitmapImage,
  createImageResource,
  createFooter,
  createHeader,
  createHeaderFirst,
  createFooterFirst,
  imageToBitmapImage,
} from ".";
import { CommonResponse, ProjectReportParams } from "..";
import { createHeaderTOC } from "./header";
import { createFooterTOC } from "./footer";

export const standardLeftRightMargin: number = 60;
export const catalogueWidth: number = 595;
export const catalogueContentWidth: number = catalogueWidth - standardLeftRightMargin * 2;

export interface CataloguePageProps {
  readonly translate: TranslateFn;
  readonly logo: BitmapImage | undefined;
  readonly project: GQLOps.ReportCommonProject_ProjectQuery;
  readonly fonts: FontData;
  readonly systemId: string | undefined;
  readonly systemName: string | undefined;
  readonly reportId: string;
  readonly orientation: "Portrait" | "Landscape";
}

export function cataloguePage(props: CataloguePageProps): AD.MasterPage.MasterPage {
  const pageStyle = AD.PageStyle.create({
    headerMargins: AD.LayoutFoundation.create({
      bottom: 10,
    }),
    contentMargins: AD.LayoutFoundation.create({
      top: 10,
      left: standardLeftRightMargin,
      right: standardLeftRightMargin,
    }),
    footerMargins: AD.LayoutFoundation.create({
      top: 10,
      bottom: 10,
      left: standardLeftRightMargin,
      right: standardLeftRightMargin,
    }),
  });

  const normalImg = props.logo ? createImageResource(props.logo) : undefined;

  const header = createHeader(normalImg, props.translate, props.project.project?.name, props.systemName);
  const footer = createFooter(normalImg, props.translate);

  return AD.MasterPage.create({
    style: pageStyle,
    header: header,
    footer: footer,
  });
}

export function catalogueTOCPage(props: CataloguePageProps): AD.MasterPage.MasterPage {
  const pageStyle = AD.PageStyle.create({
    headerMargins: AD.LayoutFoundation.create({
      bottom: 0,
      left: standardLeftRightMargin,
      right: standardLeftRightMargin,
    }),
    contentMargins: AD.LayoutFoundation.create({
      top: 0,
      left: standardLeftRightMargin,
      right: standardLeftRightMargin,
    }),
    footerMargins: AD.LayoutFoundation.create({
      bottom: 40,
      left: standardLeftRightMargin,
      right: standardLeftRightMargin,
    }),
  });

  const normalImg = props.logo ? createImageResource(props.logo) : undefined;

  const header = createHeaderTOC(normalImg, props.translate, props.project.project?.name);
  const footer = createFooterTOC(normalImg, props.translate);

  return AD.MasterPage.create({
    style: pageStyle,
    header: header,
    footer: footer,
  });
}

export function catalogueFirstPage(props: CataloguePageProps): AD.MasterPage.MasterPage {
  const pageStyle = AD.PageStyle.create({
    headerMargins: AD.LayoutFoundation.create({
      bottom: 0,
      left: 0,
      right: 0,
    }),
    contentMargins: AD.LayoutFoundation.create({
      top: 0,
      left: 0,
      right: 0,
    }),
    footerMargins: AD.LayoutFoundation.create({
      top: 20,
      bottom: 40,
      left: standardLeftRightMargin,
      right: standardLeftRightMargin,
    }),
  });

  const normalImg = props.logo ? createImageResource(props.logo) : undefined;

  const header = createHeaderFirst(normalImg, props.translate, props.project.project?.name);
  const footer = createFooterFirst(normalImg, props.translate);

  return AD.MasterPage.create({
    style: pageStyle,
    header: header,
    footer: footer,
  });
}

export async function getPageProps(
  projectReportParams: ProjectReportParams,
  common: CommonResponse
): Promise<CataloguePageProps> {
  const imageLogo = await imageToBitmapImage(200, 80, common.logoImage[0]);
  return {
    logo: imageLogo,
    fonts: common.fontData,
    translate: projectReportParams.translate,
    project: common.projectResponse,
    reportId: projectReportParams.reportType,
    systemId: projectReportParams.systemId,
    systemName: projectReportParams.systemName,
    orientation: projectReportParams.orientation || "Portrait",
  };
}

export function pageId(pageProps: CataloguePageProps): string {
  return `${pageProps.reportId}_${pageProps.systemId}`;
}
