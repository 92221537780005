import * as React from "react";
import { AbstractDocJsx as ADX } from "abstract-document";
import { texts, TranslateFn } from "../../lang-texts";
import { CreatorType } from "./types";
import * as Common from "../common";
import { h1 } from "../common/elements";
import { Project, Texts } from "../..";
import { table } from "../common";

const { AbstractDoc, Section, Group, render } = ADX;

export const create: CreatorType = ({ project, pageProps, translate }) => {
  const styles = Common.styles();
  const numberingDefinitions = Common.numberingDefinitions(styles);
  const fonts = Common.fonts(pageProps.fonts);

  const doc = render(
    <AbstractDoc fonts={fonts} styles={styles} numberingDefinitions={numberingDefinitions}>
      <Section id={Common.pageId(pageProps)} page={Common.cataloguePage(pageProps)}>
        {h1(translate(texts.project_details))}
        {renderTable({
          translate,
          project,
          heading: texts.project_details,
          rows: [
            [texts.name, "projectAddressname"],
            [texts.street, "projectStreet"],
            [texts.postal_code, "projectPostalcode"],
            [texts.city, "projectCity"],
            [texts.country, "projectCountry"],
          ],
        })}
        {renderTable({
          translate,
          project,
          heading: texts.customer_details,
          rows: [
            [texts.name, "customerAddressname"],
            [texts.street, "customerStreet"],
            [texts.postal_code, "customerPostalcode"],
            [texts.city, "customerCity"],
            [texts.country, "customerCountry"],
            [texts.customer_number, "customerCustomerNumber"],
          ],
        })}
      </Section>
    </AbstractDoc>
  );
  return doc;
};

function renderTable({
  translate,
  project,
  heading,
  rows,
}: {
  readonly translate: TranslateFn;
  readonly project: Project.Project | undefined;
  readonly heading: Texts.TextKey;
  readonly rows: ReadonlyArray<readonly [Texts.TextKey, keyof Project.Project]>;
}): JSX.Element {
  if (!project) {
    return <Group />;
  }
  const columns: ReadonlyArray<Common.TableCellHeading> = [
    { value: translate(heading), width: 150, bold: false },
    { value: "", width: Infinity, bold: false },
  ];
  const tableRows: ReadonlyArray<ReadonlyArray<Common.TableCell>> = rows.map(([label, field]) =>
    renderRow(project, translate(label), field, translate)
  );
  return <Group>{table(columns, tableRows, "standard")}</Group>;
}

function renderRow(
  project: Project.Project,
  label: string,
  field: keyof Project.Project,
  translate: TranslateFn
): ReadonlyArray<Common.TableCell> {
  const value = project[field];

  if (value !== null && (field === "projectCountry" || field === "customerCountry")) {
    return [
      { value: label, align: "Start" },
      { value: typeof value === "string" ? translate(Texts.key(value)) || "" : "", align: "Start" },
    ];
  }

  return [
    { value: label, align: "Start" },
    { value: typeof value === "string" ? value || "" : "", align: "Start" },
  ];
}
