export function maybeSumArray(values: ReadonlyArray<number | undefined | null>): number | undefined {
  return values.reduce<number | undefined>((sofar, value) => {
    if (sofar === undefined) {
      return undefined;
    }
    if (typeof value === "number") {
      return sofar + value;
    } else {
      return undefined;
    }
  }, 0);
}
