import { logWarn } from "../../logger";
import { System, PipeDiameterTable } from "../types";

export function createSystem(
  pipeDiameterTable: PipeDiameterTable,
  id: string,
  name: string,
  sortNo: number
): System | undefined {
  const defaultPipeDiameter = pipeDiameterTable[0].diameter;
  if (defaultPipeDiameter === null) {
    logWarn("No default pipe diameter");
    return undefined;
  }
  return {
    id: id,
    sortNo: sortNo,
    name: name,
    pipeDiameter: defaultPipeDiameter,
    additionalDocuments: [],
    rooms: [],
    materials: [],
    totalArea: null,
    ventilationConcept: null,
    airChangeRate: 0.5,
  };
}
