import { MasterPage } from "abstract-document/lib/abstract-document/page/master-page";
import * as React from "react";
import { AbstractDocJsx as ADX, AbstractDoc as AD } from "abstract-document";
import { CreatorType } from "./types";
import * as Common from "../common";
import { FixedPdfToken } from "../report-runner-shared";

const { AbstractDoc, Section, Paragraph, TextRun, render } = ADX;

export const create: CreatorType = ({ pageProps }) => {
  const styles = Common.styles();
  const numberingDefinitions = Common.numberingDefinitions(styles);
  const page = Common.cataloguePage({ ...pageProps, logo: undefined });
  const orientedPage: MasterPage | undefined =
    page && pageProps.orientation === "Landscape"
      ? { ...page, style: { ...page.style, orientation: "Landscape" }, header: [], footer: [] }
      : { ...page, header: [], footer: [] };

  const doc = render(
    <AbstractDoc numberingDefinitions={numberingDefinitions}>
      <Section id={Common.pageId(pageProps)} page={orientedPage}>
        <Paragraph>
          <TextRun style={AD.TextStyle.create({ color: "#ffffff" })} text={FixedPdfToken} />
        </Paragraph>
      </Section>
    </AbstractDoc>
  );
  return doc;
};
