import { v4 as uuid } from "uuid";
import { Price, Project } from "../..";
import { GetItemPricesFn } from "../types";
import { ExpandedPerValveCostItem, expandPerValveCostItem, FixedValvePriceTable, ValvePriceAndCostTable } from ".";
import { ItemPrices } from "..";

export async function updatePrices(
  valvePriceAndCost: ValvePriceAndCostTable,
  fixedValvePrice: FixedValvePriceTable,
  materials: ReadonlyArray<Project.Material>,
  updateCustomItems: boolean,
  getItemPrices: GetItemPricesFn
): Promise<{ readonly materials: ReadonlyArray<Project.Material>; readonly debugData: string }> {
  const itemNumbers = [];
  const expandedPerValveCostItems = new Map<string, ExpandedPerValveCostItem>();
  for (const m of materials) {
    if (m.type === "valve") {
      const expandedItem = expandPerValveCostItem(valvePriceAndCost, fixedValvePrice, m.itemNumber);
      if (expandedItem) {
        itemNumbers.push(...expandedItem.costMaterials.map((m) => m.itemNumber));
        itemNumbers.push(expandedItem.discountItemNumber);
        expandedPerValveCostItems.set(m.itemNumber, expandedItem);
      }
    } else if (m.type === "standard" || m.type === "custom") {
      itemNumbers.push(m.itemNumber);
    }
  }

  const prices = await getItemPrices(itemNumbers);
  const debugData: Array<string> = ["included;valve_type;itemno;cost;sales_price;net_price;quantity"];
  const updatedMaterials = materials.map((m) => {
    if (m.type === "valve") {
      const expandedItem = expandedPerValveCostItems.get(m.itemNumber);
      if (expandedItem) {
        return updateValveCost(prices, m, expandedItem, debugData);
      } else {
        return m;
      }
    } else if (m.type === "standard") {
      const singleSalesPrice = prices[m.itemNumber]?.salesPrice ?? null;
      const singleNetPrice = prices[m.itemNumber]?.netPrice ?? null;
      const singleCost = prices[m.itemNumber]?.cost ?? null;
      const currency = prices[m.itemNumber]?.currency ?? null;
      debugData.push(
        `${m.included ? "yes" : "no"};;${m.itemNumber};${singleCost};${singleSalesPrice};${singleNetPrice};${
          m.quantity
        }`
      );
      return {
        ...m,
        singleSalesPrice,
        singleNetPrice,
        singleCost,
        currency,
      };
    } else if (m.type === "custom") {
      const currency = prices[m.itemNumber]?.currency ?? null;
      if (updateCustomItems) {
        const singleSalesPrice = prices[m.itemNumber]?.salesPrice ?? null;
        const singleNetPrice = prices[m.itemNumber]?.netPrice ?? null;
        return {
          ...m,
          singleCost: 0,
          singleSalesPrice,
          singleNetPrice,
          currency,
        };
      } else {
        return {
          ...m,
          singleCost: 0,
          currency,
        };
      }
    } else {
      return m;
    }
  });

  return {
    materials: updatedMaterials,
    debugData: debugData.join("\n"),
  };
}

function updateValveCost(
  itemPrices: ItemPrices,
  metaValveMaterial: Project.Material,
  expandedItem: ExpandedPerValveCostItem,
  // eslint-disable-next-line functional/prefer-readonly-type
  debugData: Array<string>
): Project.Material {
  // Valve fixed price material
  const valveDiscount = Price.calculateDiscountPercentage(
    itemPrices[expandedItem.discountItemNumber]?.netPrice,
    itemPrices[expandedItem.discountItemNumber]?.salesPrice
  );
  const valveSalesPrice = expandedItem.fixedPrice;
  const valveNetPrice = Price.calculateNetPrice(valveSalesPrice, valveDiscount);
  const valvePriceCurreny = itemPrices[expandedItem.discountItemNumber]?.currency;
  const priceMaterial: Project.Material = {
    ...Project.createMaterial(uuid(), "standard", 0, "fixed_price_item"),
    singleSalesPrice: valveSalesPrice ?? null,
    singleNetPrice: valveNetPrice ?? null,
    singleCost: 0,
    currency: valvePriceCurreny ?? null,
    included: true,
    quantity: 1,
  };

  // Valve cost material
  const costMaterials = expandedItem.costMaterials.map((m) => ({
    ...m,
    singleSalesPrice: 0,
    singleNetPrice: 0,
    singleCost: itemPrices[m.itemNumber]?.cost ?? null,
    currency: itemPrices[m.itemNumber]?.currency ?? null,
  }));

  const materials = [...costMaterials, priceMaterial];
  const price = Price.calculateMaterialListTotalPrice(materials);

  const priceDataOk = materials.every((m) => m.singleNetPrice !== null && m.singleSalesPrice !== null);
  const costDataOk = materials.every((m) => m.singleCost !== null);

  const updatedMaterial = {
    ...metaValveMaterial,
    singleSalesPrice: priceDataOk ? price.totalSalesPrice ?? null : null,
    singleNetPrice: priceDataOk ? price.totalPrice ?? null : null,
    singleCost: costDataOk ? price.totalCost ?? null : null,
    currency: price.currency ?? null,
  };

  debugData.push(
    ...materials.map(
      (m) =>
        `${m.included ? "yes" : "no"};${metaValveMaterial.itemNumber};${m.itemNumber};${m.singleCost};${
          m.singleSalesPrice
        };${m.singleNetPrice};${m.quantity}`
    )
  );

  return updatedMaterial;
}
