import {
  calculateRoomAirflow,
  calculateRoomVolume,
  calculateTotalExtractVolume,
  calculateTotalSupplyVolume,
  calculateTotalVolume,
  maybeSumArray,
} from ".";
import { Project } from "../../..";
import { isExtractRoom, isSupplyRoom } from "../../../project";

export function calculateRequiredAirflowByVolume(
  airChangeRate: number | undefined,
  rooms: ReadonlyArray<Project.Room>
): number | undefined {
  const totalVolume = calculateTotalVolume(rooms);
  const requiredAirflow = totalVolume && airChangeRate ? airChangeRate * totalVolume : undefined;
  return requiredAirflow;
}

export function calculateAirFlowPerRoom(
  airChangeRate: number | undefined,
  rooms: ReadonlyArray<Project.Room>
): ReadonlyMap<string, number | undefined> {
  // Calculate air flow based on total system exchange rate
  const requiredAirFlowByVolume = calculateRequiredAirflowByVolume(airChangeRate, rooms);
  const totalSupplyVolume = calculateTotalSupplyVolume(rooms);
  const totalExtractVolume = calculateTotalExtractVolume(rooms);
  const preblanaceAfs = rooms.map((room) => ({
    room: room,
    preblanaceAf: requiredAirFlowByVolume
      ? calculateRoomAirflow(totalExtractVolume, totalSupplyVolume, requiredAirFlowByVolume, room)
      : undefined,
  }));

  // Balance air flow between supply and extract rooms. The supply/extract total af might not match because
  // of the minimum af per room.
  const totalExtractAirflow = maybeSumArray(
    preblanaceAfs.filter(({ room }) => isExtractRoom(room)).map(({ preblanaceAf }) => preblanaceAf)
  );
  const totalSupplyAirflow = maybeSumArray(
    preblanaceAfs.filter(({ room }) => isSupplyRoom(room)).map(({ preblanaceAf }) => preblanaceAf)
  );
  const balanced = preblanaceAfs.map(({ room, preblanaceAf }) => ({
    room: room,
    balancedAirFlow: calculateBalancedRoomAirflow({
      totalExtractVolume,
      totalSupplyVolume,
      totalExtractAirflow,
      totalSupplyAirflow,
      preblanaceAf,
      room,
    }),
  }));

  const airFlowPerRoom = new Map(balanced.map(({ room, balancedAirFlow }) => [room.id, balancedAirFlow]));

  return airFlowPerRoom;
}

function calculateBalancedRoomAirflow({
  totalExtractVolume,
  totalSupplyVolume,
  totalExtractAirflow,
  totalSupplyAirflow,
  preblanaceAf,
  room,
}: {
  readonly totalExtractVolume: number | undefined;
  readonly totalSupplyVolume: number | undefined;
  readonly totalExtractAirflow: number | undefined;
  readonly totalSupplyAirflow: number | undefined;
  readonly preblanaceAf: number | undefined;
  readonly room: Project.Room;
}): number | undefined {
  if (!totalExtractAirflow || !totalSupplyAirflow || !totalExtractVolume || !totalSupplyVolume) {
    return undefined;
  }
  const volume = calculateRoomVolume(room);
  if (!preblanaceAf || !volume) {
    return undefined;
  }
  if (Math.abs(totalExtractAirflow - totalSupplyAirflow) < 0.0001) {
    return preblanaceAf;
  } else if (isSupplyRoom(room) && totalExtractAirflow > totalSupplyAirflow) {
    const totalAfToAdd = totalExtractAirflow - totalSupplyAirflow;
    const roomRatio = volume / totalSupplyVolume;
    const airflow = preblanaceAf + totalAfToAdd * roomRatio;
    return airflow;
  } else if (isExtractRoom(room) && totalSupplyAirflow > totalExtractAirflow) {
    const totalAfToAdd = totalSupplyAirflow - totalExtractAirflow;
    const roomRatio = volume / totalExtractVolume;
    const airflow = preblanaceAf + totalAfToAdd * roomRatio;
    return airflow;
  } else {
    return preblanaceAf;
  }
}
