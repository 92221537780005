const topLevelExceptions = [["co", "uk"]];

export function getEmailSld(emailAddress: string): string | undefined {
  const emailParts = emailAddress.split("@");
  if (emailParts.length !== 2) {
    return undefined;
  }
  const domainParts = emailParts[1].split(".");
  if (domainParts.length < 2) {
    return undefined;
  }
  const matchedException = topLevelExceptions.find(
    (e) => domainParts.length > e.length && domainParts.slice(-e.length).every((p, i) => p === e[i])
  );
  const tldLength = matchedException?.length || 1;
  return domainParts[domainParts.length - 1 - tldLength];
}

export function isSystemairEmail(emailAddress: string): boolean {
  return getEmailSld(emailAddress) === "systemair" ? true : false;
}
