import { Project } from "../..";
import { GetItemPricesFn } from "../types";

export async function updatePrices(
  materials: ReadonlyArray<Project.Material>,
  updateCustomItems: boolean,
  getItemPrices: GetItemPricesFn
): Promise<ReadonlyArray<Project.Material>> {
  const prices = await getItemPrices(materials.map((m) => m.itemNumber));
  return materials.map((m) => {
    if (m.type === "custom") {
      if (updateCustomItems) {
        return {
          ...m,
          singleCost: 0,
          singleSalesPrice: prices[m.itemNumber]?.salesPrice ?? null,
          singleNetPrice: prices[m.itemNumber]?.netPrice ?? null,
          currency: prices[m.itemNumber]?.currency ?? null,
        };
      } else {
        return {
          ...m,
          singleCost: 0,
          currency: prices[m.itemNumber]?.currency ?? null,
        };
      }
    } else {
      return {
        ...m,
        singleSalesPrice: prices[m.itemNumber]?.salesPrice ?? null,
        singleNetPrice: prices[m.itemNumber]?.netPrice ?? null,
        singleCost: prices[m.itemNumber]?.cost ?? null,
        currency: prices[m.itemNumber]?.currency ?? null,
      };
    }
  });
}
