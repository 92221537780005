import { v4 as uuid } from "uuid";
import { FixedValvePriceTable, MetaData } from ".";
import { customItemsCategoryName } from "..";
import { logWarn, Project, SelectionToolApi } from "../..";
import { getListChanges, sortList } from "../shared";
import { UpdateListResult } from "../types";
import { getAirUnitMaterials, getStandardMaterials } from "./items";
import { calculateQtyPerValve } from "./quantities-per-valve";
import { ItemsTable, MaterialTables, ValvePriceAndCostTable } from "./types";

export function getSuitableAirUnits(
  _requiredAirflow: number,
  itemsTable: ItemsTable,
  unitsFromSelectionTool: ReadonlyArray<SelectionToolApi.Unit>
): ReadonlyArray<Project.Material> {
  const selectionToolItemNumbers = new Set(unitsFromSelectionTool.map((u) => u.m3ItemNo));
  const airUnitMaterials = getAirUnitMaterials(itemsTable);
  return airUnitMaterials.filter((m) => selectionToolItemNumbers.has(m.itemNumber || ""));
}

export type ExpandedPerValveCostItem = {
  readonly costMaterials: ReadonlyArray<Project.Material>;
  readonly discountItemNumber: string;
  readonly fixedPrice: number;
};

export function expandPerValveCostItem(
  valvePriceAndCost: ValvePriceAndCostTable,
  fixedPriceTable: FixedValvePriceTable,
  valveType: string
): ExpandedPerValveCostItem | undefined {
  const costMaterials = calculateQtyPerValve(valvePriceAndCost, valveType)
    .filter((q) => !!q.quantity)
    .map((q) => ({
      ...Project.createMaterial(uuid(), "standard", 0, q.item.itemNumber),
      included: true,
      quantity: q.quantity || 0,
    }));

  const fixedPriceRow = fixedPriceTable.find((r) => r.system_type === valveType);
  const discountItemNumber = fixedPriceRow?.discount_item_number;
  const fixedPrice = fixedPriceRow?.fixed_price;

  if (costMaterials.length === 0 || !discountItemNumber || !fixedPrice) {
    logWarn(`Missing per valve cost for system type: '${valveType}'`);
    return undefined;
  }

  return {
    costMaterials,
    discountItemNumber,
    fixedPrice,
  };
}

export function createMaterialListForUnit(
  materialTables: MaterialTables,
  system: Project.System,
  selectedAirUnit: Project.Material
): UpdateListResult {
  const currentMaterials = new Map(
    system.materials.filter((m) => m.type !== "custom").map((m) => [`${m.type}_${m.itemNumber}`, m])
  );
  let materials = [selectedAirUnit];

  // Add per valve materials
  materials = updateValveMaterials(materials, system).slice(0);

  // Regular items
  materials.push(
    ...getStandardMaterials(materialTables).map((newMaterial) => {
      const key = `standard_${newMaterial.itemNumber}`;
      const material = currentMaterials.get(key) || newMaterial;
      currentMaterials.delete(key);
      return material;
    })
  );

  // Re-add custom materials
  materials.push(...system.materials.filter((m) => m.type === "custom"));

  const updatedSortNo = sortList(materialTables.sortNos, materials);

  const changedMaterialIds = getListChanges(system, updatedSortNo);

  return {
    materials: updatedSortNo,
    changedMaterialIds: changedMaterialIds,
  };
}

export function updateMaterialListQuantities(materialTables: MaterialTables, system: Project.System): UpdateListResult {
  const updatedQtys = updateValveMaterials(system.materials, system);
  const updatedSortNo = sortList(materialTables.sortNos, updatedQtys);
  const changes = getListChanges(system, updatedSortNo);
  return {
    materials: updatedSortNo,
    changedMaterialIds: changes,
  };
}

export function updateMaterialListAddNewItems(
  materialTables: MaterialTables,
  system: Project.System
): UpdateListResult {
  const standardMaterials = getStandardMaterials(materialTables);
  const currentStandardMaterials = system.materials.filter((m) => m.type === "standard");
  const currentMap = new Map(currentStandardMaterials.map((m) => [m.itemNumber, m]));
  const materialsWithNewItems = system.materials.slice(0);
  for (const standardMaterial of standardMaterials) {
    if (!currentMap.has(standardMaterial.itemNumber)) {
      materialsWithNewItems.push(standardMaterial);
    }
  }

  const updatedSortNo = sortList(materialTables.sortNos, materialsWithNewItems);

  const changes = getListChanges(system, updatedSortNo);

  return {
    materials: updatedSortNo,
    changedMaterialIds: changes,
  };
}

export function getMetaData(materialTables: MaterialTables, material: Project.Material): MetaData {
  const item = materialTables.items[material.itemNumber];
  if (material.type === "valve") {
    return {
      type: "norway",
      group: "valve",
      category: undefined,
      readOnlyQuantity: false,
      singleGroupSelection: false,
      material: material,
      maxAirFlow: undefined,
    };
  } else if (material.type === "standard" && item?.type === "air_unit") {
    return {
      type: "norway",
      group: "air_unit",
      category: undefined,
      readOnlyQuantity: false,
      singleGroupSelection: false,
      material: material,
      maxAirFlow: item.maxAirflow,
    };
  } else if (material.type === "standard" && item) {
    return {
      type: "norway",
      group: "item",
      category: item.type || undefined,
      readOnlyQuantity: false,
      singleGroupSelection: !!item.type, // In "other" category if no type is specified
      material: material,
      maxAirFlow: item.maxAirflow,
    };
  } else if (material.type === "custom") {
    return {
      type: "norway",
      group: customItemsCategoryName,
      category: undefined,
      readOnlyQuantity: false,
      material,
      maxAirFlow: undefined,
    };
  } else {
    return {
      type: "norway",
      group: "unknown",
      category: undefined,
      readOnlyQuantity: false,
      singleGroupSelection: false,
      material: material,
      maxAirFlow: undefined,
    };
  }
}

export function getCustomItemNumbers(materialTables: MaterialTables): ReadonlyArray<string> {
  return Array.from(Object.keys(materialTables.items));
}

function updateValveMaterials(
  materials: ReadonlyArray<Project.Material>,
  system: Project.System
): ReadonlyArray<Project.Material> {
  const currentMaterials = new Map(
    materials.filter((m) => m.type !== "custom").map((m) => [`${m.type}_${m.itemNumber}`, m])
  );
  const valveTypesAndCount = [];
  for (const valveType of Project.getValveTypes(system)) {
    const valveCount = Project.getValveCount(system, valveType);
    if (valveCount === undefined) {
      continue;
    }
    valveTypesAndCount.push({ valveType, valveCount });
  }
  const valveMaterials: ReadonlyArray<Project.Material> = valveTypesAndCount.map(({ valveType, valveCount }) => {
    const material = currentMaterials.get(`valve_${valveType}`) || {
      ...Project.createMaterial(uuid(), "valve", 0, valveType),
      included: true,
      quantity: valveCount,
    };
    return { ...material, quantity: valveCount };
  });

  return [...materials.filter((m) => m.type !== "valve"), ...valveMaterials];
}
