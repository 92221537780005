import gql from "graphql-tag";
// import { PropertyValue } from "@promaster-sdk/property";
// import { ProductDataService } from "@uc/service-effects/lib/product-data-service";
// import { exhaustiveCheck } from "ts-exhaustive-check";
import * as GqlOps from "../generated/generated-operations";
import { KeyParams, NormalizedText } from "./normalized-text";
import { replaceCurly } from "./replace-curly";

export type LanguageTable = GqlOps.LangText_LanguageFragment;

export type Translations = { readonly [key: string]: string | undefined };

export const defaultLanguage: LanguageCode = "en-GB";

export type LanguageCode = "en-GB" | "nn-NO" | "de-DE";

export const LangText_ModulesLanguagesFragment = gql`
  fragment LangText_ModulesLanguages on Modules {
    languages {
      ...LangText_Language
    }
  }

  fragment LangText_Language on Module_languages {
    language {
      name
    }
  }
`;

export const LangText_ModulesTextsFragment = gql`
  fragment LangText_ModulesTexts on Modules {
    texts {
      translatedTexts: text(language: $language) {
        ...LangTexts_Texts
      }
      fallbackTexts: text(language: "en-GB") {
        ...LangTexts_Texts
      }
    }
  }

  fragment LangTexts_Texts on Texts_Text {
    name
    text
  }
`;

export const textsQuery = gql`
  query GetTexts($metaProductId: ID!, $m3MetaProductId: ID!, $language: String!) {
    metaProduct: product(id: $metaProductId) {
      key
      modules {
        ...LangText_ModulesTexts
      }
    }
    m3MetaProduct: product(id: $m3MetaProductId) {
      key
      modules {
        ...LangText_ModulesTexts
      }
    }
  }
  ${LangText_ModulesTextsFragment}
`;

// export function* loadTranslations(
//   productId: string,
//   language: LanguageCode
// ): Program<Translations, readonly [ProductDataService]> {
//   const data = yield* ProductDataService.fetchProductData<GqlOps.GetTextsQuery, GqlOps.GetTextsQueryVariables>(
//     textsQuery,
//     {
//       productId: productId,
//       language: language,
//     }
//   );
//   return translationsFromProductTexts(data.product?.modules.texts.text);
// }

export function getLanguages(languageTable: LanguageTable): ReadonlyArray<LanguageCode> {
  return languageTable.language.map((l) => l.name as LanguageCode);
}

export function translationsFromProductTexts(getTextsQuery: GqlOps.GetTextsQuery): Translations {
  const textsFragments = [getTextsQuery.m3MetaProduct?.modules, getTextsQuery.metaProduct?.modules];
  type MutableTranslations = { [key: string]: string | undefined };
  const texts: MutableTranslations = {};
  for (const textsFragment of textsFragments) {
    if (!textsFragment) {
      continue;
    }
    const translatedTexts = textsFragment.texts.translatedTexts;
    const fallbackTexts = textsFragment.texts.fallbackTexts;
    for (const x of fallbackTexts ?? []) {
      if (x.name !== null && x.name !== undefined && x.text !== null) {
        texts[x.name] = x.text;
      }
    }
    for (const x of translatedTexts ?? []) {
      if (x.name !== null && x.name !== undefined && x.text !== null) {
        texts[x.name] = x.text;
      }
    }
  }
  return texts;
}

export type TextTranslateFn<TTextTypes> = (text: TTextTypes, defaultText?: string) => string;

// export function* loadAndCreateTranslateFn<TTextTypes>(
//   productId: string,
//   productKey: string,
//   normalizeTextFn: (text: TTextTypes) => NormalizedText,
//   languageCode: LanguageCode
// ): Program<TextTranslateFn<TTextTypes>, readonly [ProductDataService]> {
//   const loadedTexts = yield* loadTranslations(productId, languageCode);
//   return (text) => translateNormalizedText(productKey, loadedTexts, normalizeTextFn(text));
// }

export function translateNormalizedText(
  translations: Translations,
  text: NormalizedText,
  defaultText?: string
): string {
  const rawTranslation = translations[text.key];
  if (rawTranslation === undefined) {
    if (defaultText !== undefined) {
      return defaultText;
    } else {
      return getUndefinedTextPlaceholderFromNormalizedText(text);
    }
  } else {
    return replaceCurly(rawTranslation, text.params);
  }
}

function getUndefinedTextPlaceholderFromNormalizedText(text: NormalizedText): string {
  return getUndefinedTextPlaceholder(text.key, text.params);
}

export function getUndefinedTextPlaceholder(key: string, params?: KeyParams): string {
  return params && Object.keys(params).length > 0 ? `{${key} ${JSON.stringify(params)}}` : `{${key}}`;
}

export function getUndefinedPropertyNamePlaceholder(propertyName: string): string {
  return `{${propertyName}}`;
}

// export function getUndefinedPropertyValuePlaceholder(
//   productKey: string,
//   propertyName: string,
//   propertyValue?: PropertyValue.PropertyValue
// ): string {
//   if (!propertyValue) {
//     return `{${productKey}.${propertyName}}`;
//   }
//   switch (propertyValue.type) {
//     case "amount":
//       return propertyValue.value.value.toString();
//     case "integer":
//       return `{${productKey}.${propertyName} value ${propertyValue.value}}`;
//       break;
//     case "text":
//       return propertyValue.value;
//     default:
//       exhaustiveCheck(propertyValue);
//       return "";
//   }
// }
