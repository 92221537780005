import * as R from "ramda";
import { Item, getMetaData, createMaterialListForUnit } from ".";
import { Calculators, Markets, Project, Texts } from "../..";
import { texts } from "../../lang-texts";
import { validateChangedMaterialListData } from "../shared/validate-material-list-data";
import { ValidationResult, ValidationError } from "../types";
import { getAirUnitMaterials, getStandardMaterials } from "./items";
import { MaterialTables } from "./types";

export function validate(
  metaProductQuery: Project.MetaProductQuery,
  market: Markets.Market,
  materialTables: MaterialTables,
  system: Project.System
): ValidationResult {
  const errors = [];

  const nonCustomMaterials = system.materials.filter((m) => m.type !== "custom");
  const input = Calculators.map(metaProductQuery, materialTables, system, market.name);
  const requiredAirflow = input && Calculators.calculate(input)?.requiredAirflow;
  for (const material of nonCustomMaterials) {
    const item = materialTables.items[material.itemNumber];
    if (item?.type === "air_unit") {
      errors.push(...validateAirUnit(requiredAirflow, material.id, item));
    }
  }
  errors.push(...validateGroupSelections(materialTables, nonCustomMaterials));
  errors.push(...validateQuantities(materialTables, system, nonCustomMaterials));

  errors.push(...validateCustomMaterials(system.materials));

  errors.push(...validateChangedData(materialTables, system));

  const errorsById = errors
    .filter((e) => !!e.materialId)
    .reduce<{ [materialId: string]: ValidationError }>((sofar, error) => {
      sofar[error.materialId!] = error;
      return sofar;
    }, {});

  return {
    errors: errors,
    errorsById: errorsById,
  };
}

function validateChangedData(materialTables: MaterialTables, system: Project.System): ReadonlyArray<ValidationError> {
  const airUnitItemNos = getAirUnitMaterials(materialTables.tables.items).map((m) => m.itemNumber);
  const materialsInPromaster = getStandardMaterials(materialTables).map((m) => m.itemNumber);
  return validateChangedMaterialListData(airUnitItemNos, materialsInPromaster, system);
}

function validateCustomMaterials(materials: ReadonlyArray<Project.Material>): ReadonlyArray<ValidationError> {
  const errors: Array<ValidationError> = [];
  for (const m of materials) {
    if (m.type === "custom" && !m.itemNumber) {
      errors.push({
        category: "missing_item_number",
        materialId: m.id,
        message: texts.item_number_required,
      });
    }
  }
  return errors;
}

function validateGroupSelections(
  materialTables: MaterialTables,
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<ValidationError> {
  const items = materialTables.items;
  const errors: Array<ValidationError> = [];
  const withMeta = materials.map((m) => ({ meta: getMetaData(materialTables, m), material: m }));
  const categories = R.groupBy(
    ({ meta }) => meta.category || "",
    withMeta.filter(
      ({ meta, material }) => items[material.itemNumber]?.type && meta.singleGroupSelection && material.included
    )
  );
  for (const categoryName of R.keys(categories)) {
    if (!categoryName) {
      continue;
    }
    const category = categories[categoryName];
    if (category.length > 1) {
      errors.push(
        ...category.map(
          (a): ValidationError => ({
            category: "too_many_selections",
            materialId: a.material.id,
            message: texts.max_one_item_can_be_selected,
          })
        )
      );
    }
  }
  return errors;
}

function validateQuantities(
  materialTables: MaterialTables,
  system: Project.System,
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<ValidationError> {
  const items = materialTables.items;

  const airUnit = materials.find((m) => items[m.itemNumber]?.type === "air_unit");
  if (!airUnit) {
    return [];
  }
  const errors: Array<ValidationError> = [];

  const valveMaterialsCurrent = materials.filter((m) => m.type === "valve");

  const updated = createMaterialListForUnit(materialTables, system, airUnit);
  const valveMaterialsUpdated = updated.materials.filter((m) => m.type === "valve");

  // Check already added valve items
  for (const currentMaterial of valveMaterialsCurrent) {
    const updatedMaterials = valveMaterialsUpdated.find((m) => m.itemNumber === currentMaterial.itemNumber);
    const updatedQuantity = updatedMaterials?.included ? updatedMaterials?.quantity : 0;
    const currentQuantity = currentMaterial.included ? currentMaterial.quantity : 0;

    if (updatedQuantity !== currentQuantity) {
      errors.push({
        category: "quantity",
        materialId: currentMaterial.id,
        message: texts.quantities_not_matching_requirements,
      });
    }
  }

  // Check if new items should be added
  for (const updatedMaterial of valveMaterialsUpdated) {
    const currentMaterial = valveMaterialsCurrent.find((m) => m.itemNumber === updatedMaterial.itemNumber);
    if (!currentMaterial) {
      errors.push({
        category: "quantity",
        message: texts.quantities_not_matching_requirements,
      });
    }
  }

  return errors;
}

function validateAirUnit(
  requiredAirflow: number | undefined,
  materialId: string,
  airUnit: Item
): ReadonlyArray<ValidationError> {
  if (!airUnit.maxAirflow) {
    return [];
  }

  if (requiredAirflow === undefined) {
    return [];
  }

  if (requiredAirflow > airUnit.maxAirflow) {
    return [
      {
        category: "wrong_air_unit",
        materialId,
        message: Texts.texts.error_air_unit_max_airflow(requiredAirflow, airUnit.maxAirflow),
      },
    ];
  }

  return [];
}
