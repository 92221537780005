import { DuctTable } from "../..";
import { System } from "../../../project";

export function calculateRoomDuctQuantity(
  ductMaxAirflow: number,
  roomAirflow: number,
  roomNumValves: number | undefined | null
): number {
  if (!roomNumValves) {
    return 0;
  }
  const airflowPerValve = roomAirflow / roomNumValves;
  const ductQuantityPerValve = Math.ceil(airflowPerValve / ductMaxAirflow);
  const ductQuantity = ductQuantityPerValve * roomNumValves;
  return ductQuantity;
}

export function getDuctMaxAirflow(ductTable: DuctTable, system: System): number | undefined {
  const ductColumn = system.pipeDiameter === 63 ? "duct_63" : system.pipeDiameter === 75 ? "duct_75" : undefined;
  if (!ductColumn) {
    return undefined;
  }
  const maxAirflow = ductTable.find((r) => r[ductColumn] === "1")?.max_airflow;
  if (!maxAirflow) {
    return undefined;
  }
  return maxAirflow;
}
