/* eslint-disable @typescript-eslint/no-explicit-any */
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Dispatch, EffectManager } from "@typescript-tea/core";
import { NavigationCmd, mapCmd } from "./navigation-command";
import { home } from "./home";
import * as Url from "./url";

export function createEffectManager<ActionApp>(
  onUrlChange: (url: Url.Url) => ActionApp,
  onUrlRequest: (urlRequest: UrlRequest) => ActionApp
): EffectManager {
  return {
    home,
    mapCmd,
    mapSub,
    setup: setup(onUrlChange, onUrlRequest),
    onEffects: onEffects(onUrlChange),
    onSelfAction,
  };
}
// -- STATE

export interface State {}

export function init(): readonly [State] {
  return [{}];
}

// -- COMMANDS

// -- SUBSCRIPTIONS

export function mapSub<A1, A2>(__: (a: A1) => A2, _: NavigationCmd<A1>): NavigationCmd<A2> {
  throw new Error("Not implemented.");
}

// -- MANAGER

const setup =
  <ActionApp>(onUrlChange: (url: Url.Url) => ActionApp, onUrlRequest: (urlRequest: UrlRequest) => ActionApp) =>
  (dispatchProgram: Dispatch<ActionApp>): (() => void) => {
    function key(): void {
      dispatchProgram(onUrlChange(getCurrentUrl()));
    }

    window.addEventListener("popstate", key);
    // eslint-disable-next-line no-unused-expressions
    window.navigator.userAgent.indexOf("Trident") < 0 || window.addEventListener("hashchange", key);

    const divertHandler = divertHrefToApp(dispatchProgram, onUrlRequest);
    window.addEventListener("click", divertHandler);

    return () => {
      window.removeEventListener("popstate", key);
      window.removeEventListener("hashchange", key);
      window.removeEventListener("click", divertHandler);
    };
  };

const onEffects =
  <ActionApp>(onUrlChange: (url: Url.Url) => ActionApp) =>
  (
    dispatchApp: Dispatch<ActionApp>,
    _dispatchSelf: Dispatch<never>,
    cmds: readonly NavigationCmd<ActionApp>[],
    _: readonly never[],
    state: State
  ): State => {
    function key(): void {
      dispatchApp(onUrlChange(getCurrentUrl()));
    }
    for (const cmd of cmds) {
      switch (cmd.type) {
        case "PushUrl": {
          window.history.pushState({}, "", cmd.url);
          // cmd.key();
          key();
          // eslint-disable-next-line no-unused-expressions
          cmd.onSuccess && dispatchApp(cmd.onSuccess());
          break;
        }
        case "ReplaceUrl": {
          window.history.replaceState({}, "", cmd.url);
          // cmd.key();
          key();
          // eslint-disable-next-line no-unused-expressions
          cmd.onSuccess && dispatchApp(cmd.onSuccess());
          break;
        }
        case "Back": {
          window.history.go(-cmd.n);
          // cmd.key();
          key();
          // eslint-disable-next-line no-unused-expressions
          cmd.onSuccess && dispatchApp(cmd.onSuccess());
          break;
        }
        case "Forward": {
          window.history.go(cmd.n);
          // cmd.key();
          key();
          // eslint-disable-next-line no-unused-expressions
          cmd.onSuccess && dispatchApp(cmd.onSuccess());
          break;
        }
        case "Load": {
          try {
            window.location.href = cmd.url;
          } catch (err) {
            // Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
            // Other browsers reload the page, so let's be consistent about that.
            // window.location.reload(false);
            window.location.reload();
          }
          // eslint-disable-next-line no-unused-expressions
          cmd.onSuccess && dispatchApp(cmd.onSuccess());
          break;
        }
        case "Reload": {
          // window.location.reload(false);
          window.location.reload();
          // eslint-disable-next-line no-unused-expressions
          cmd.onSuccess && dispatchApp(cmd.onSuccess());
          break;
        }
        case "ReloadAndSkipCache": {
          // window.location.reload(true);
          window.location.reload();
          // eslint-disable-next-line no-unused-expressions
          cmd.onSuccess && dispatchApp(cmd.onSuccess());
          break;
        }
        default: {
          exhaustiveCheck(cmd, true);
        }
      }
    }
    return state;
  };

export function onSelfAction<ActionApp>(
  _dispatchApp: Dispatch<ActionApp>,
  _dispatchSelf: Dispatch<never>,
  _: unknown,
  state: State
): State {
  return state;
}

export function getCurrentUrl(): Url.Url {
  return Url.fromString(window.location.href);
}

const divertHrefToApp =
  <ActionApp>(dispatchProgram: Dispatch<ActionApp>, onUrlRequest: (urlRequest: UrlRequest) => ActionApp) =>
  (event: MouseEvent) => {
    const target = event.target;
    if (!target) {
      return;
    }
    const anchor = (target as any).closest("a"); // Find closest Anchor (or self) becuase target may be <img> inside <a>
    if (!(anchor instanceof HTMLAnchorElement)) {
      return;
    }
    if (anchor.href) {
      if (
        !event.ctrlKey &&
        !event.metaKey &&
        !event.shiftKey &&
        event.button < 1 &&
        !anchor.target &&
        !anchor.hasAttribute("download")
      ) {
        event.preventDefault();
        const href = anchor.href;
        const curr = getCurrentUrl();
        const next = Url.fromString(href);
        dispatchProgram(
          onUrlRequest(
            next && curr.protocol === next.protocol && curr.host === next.host && curr.port === next.port
              ? { type: "InternalUrlRequest", url: href }
              : { type: "ExternalUrlRequest", url: next }
          )
        );
      }
    }
  };

export type UrlRequest = InternalUrlRequest | ExternalUrlRequest;

export type InternalUrlRequest = {
  readonly type: "InternalUrlRequest";
  readonly url: string;
};

export type ExternalUrlRequest = {
  readonly type: "ExternalUrlRequest";
  readonly url: Url.Url;
};
