import { ReportQueryParams } from "..";
import { graphQLQuery, QueryGenerator } from "../../query";
import { AdapterType, Response } from "./types";
import { getPageProps } from "../common";
import { Project } from "../..";

export function* query(props: ReportQueryParams): QueryGenerator<Response> {
  const projectResponse = yield* graphQLQuery<Project.ProjectQuery, Project.ProjectQueryVariables>(Project.query, {
    projectId: props.projectId,
  });
  return {
    project: projectResponse,
  };
}

export const execute: AdapterType = async (projectReportParams, common, response) => {
  const pageProps = await getPageProps(projectReportParams, common);
  const system = response.project.project?.systems.find((system) => system.id === projectReportParams.systemId);

  return {
    system: system,
    pageProps: pageProps,
    translate: projectReportParams.translate,
  };
};
