import { PDFDocument } from "pdf-lib";
import { AbstractDocExporters } from "abstract-document";
import { AbstractDoc } from "abstract-document/lib/abstract-document";
import BlobStream from "blob-stream";
import { saveAs } from "file-saver";
import * as ReportShared from "./report-runner-shared";
import { ProjectReportParams, ReportQueryResponse } from "./types";
import { Project } from "..";

export async function createPrintoutClient(
  project: Project.Project,
  responses: ReportQueryResponse,
  projectReportParams: ReadonlyArray<ProjectReportParams>,
  fileName: string,
  pdfKit: {},
  onSuccess?: () => void
): Promise<void> {
  const doc = await ReportShared.createDocument(project, responses, projectReportParams);

  const exportPdfToStream = async (doc: PDFDocument): Promise<void> => {
    const bytes = await doc.save();
    saveAs(new Blob([bytes]), fileName);
    if (onSuccess) {
      onSuccess();
    }
  };

  const pdfBuffer = await createPdf(pdfKit, doc);

  await ReportShared.replaceFixedPages(project, pdfBuffer, exportPdfToStream);
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
async function createPdf(pdfKit: any, doc: AbstractDoc.AbstractDoc): Promise<ArrayBuffer> {
  const mergeStream = BlobStream();
  const promise = new Promise<ArrayBuffer>((resolve) => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    mergeStream.on("finish", async () => {
      const blob = await mergeStream.toBlob().arrayBuffer();
      resolve(blob);
    });
  });
  AbstractDocExporters.Pdf.exportToStream(pdfKit, mergeStream, doc);
  const buffer = await promise;
  return buffer;
}
