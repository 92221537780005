import gql from "graphql-tag";

export const schema = gql`
  extend type Mutation {
    createSystemPdf(input: CreateSystemPdfInput!): CreateSystemPdfPayload!
    removeSystemPdf(input: RemoveSystemPdfInput!): RemoveSystemPdfPayload!
    updateSystemPdf(input: UpdateSystemPdfInput!): UpdateSystemPdfPayload!
  }

  # Ventilation concept system level
  type SystemPdf {
    id: ID!
    name: String!
    pdf: String!
    sortNo: Int!
  }

  input CreateSystemPdfInput {
    id: ID!
    systemId: ID!
    name: String!
    pdf: String!
    sortNo: Int!
  }

  type CreateSystemPdfPayload {
    id: ID!
  }

  input RemoveSystemPdfInput {
    id: ID!
    systemId: ID!
  }

  type RemoveSystemPdfPayload {
    id: ID!
  }

  input UpdateSystemPdfInput {
    id: ID!
    system: ID!
    name: String!
    pdf: String!
    sortNo: Int!
  }

  type UpdateSystemPdfPayload {
    id: ID!
  }
`;
