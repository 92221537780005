import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { Project, SelectionToolApi, Materials } from "@rvs/shared";
import { SharedState, Routes, HttpFetch } from "@rvs/client-infra";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import * as GQLOps from "../../../../generated/generated-operations";
import * as ProjectState from "../../project-state";

// STATE

export type SelectingUnit = {
  readonly type: "selecting-unit";
  readonly suitableAirUnits: ReadonlyArray<Project.Material>;
  readonly requiredAirflow: number;
};

export type SystemState =
  | SelectingUnit
  | { readonly type: "searching-unit" }
  | { readonly type: "unit-search-error" }
  | { readonly type: "no-units-found" };

export function isBusyState(
  systemState: SystemState | undefined,
  materialsState: ProjectState.MaterialsState | undefined
): boolean {
  return (
    systemState?.type === "searching-unit" ||
    systemState?.type === "selecting-unit" ||
    materialsState?.type === "list-update" ||
    materialsState?.type === "price-update"
  );
}

export interface MetaTables {
  readonly roomTemplates: GQLOps.RoomTemplatesTableFragment["RoomTemplates"];
}
export interface State {
  readonly systemStates: ReadonlyMap<string, SystemState>;
  readonly expandedItems: ReadonlySet<string>;
  readonly openLightbox: {
    readonly isOpen: boolean;
    readonly imgUrl: string;
  };
}

export const Action = ctorsUnion({
  RequestSaveUnits: (systemId: string) => ({ systemId }),
  SaveUnitsReceived: (systemId: string, requiredAirflow: number, searchResult: SelectionToolApi.Result) => ({
    systemId,
    requiredAirflow,
    searchResult,
  }),
  SelectUnitDone: (systemId) => ({ systemId }),
  ToggleExpanded: (id: string) => ({ id }),
  OpenLightbox: (isOpen: boolean, imgUrl: string) => ({ isOpen, imgUrl }),
});

export type Action = CtorsUnion<typeof Action>;

export function init(
  _location: Routes.ProjectLocation,
  _sharedState: SharedState.SharedState,
  prevState: State | undefined,
  projectState: ProjectState.State
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  let state: State = prevState || {
    expandedItems: new Set<string>(),
    systemStates: new Map(),
    openLightbox: { isOpen: false, imgUrl: "" },
  };

  const actions = [];
  for (const system of projectState.project.systems) {
    if (state.systemStates.get(system.id) === undefined && system.materials.length === 0) {
      const [newState, cmds] = requestSaveUnitsAction(system.id, state, projectState);
      state = { ...state, ...newState };
      actions.push(cmds);
    }
  }
  return [state, Cmd.batch(actions)];
}

export function update(
  action: Action,
  state: State,
  projectState: ProjectState.State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const { project } = projectState;
  switch (action.type) {
    case "RequestSaveUnits": {
      return requestSaveUnitsAction(action.systemId, state, projectState);
    }

    case "SaveUnitsReceived": {
      const { systemId, requiredAirflow, searchResult } = action;
      const system = project.systems.find((s) => s.id === systemId);
      if (!system) {
        return [{ ...state, systemStates: updateSystemStates(state.systemStates, systemId, undefined) }];
      }
      if (searchResult.type === "error") {
        return [
          { ...state, systemStates: updateSystemStates(state.systemStates, systemId, { type: "unit-search-error" }) },
        ];
      }
      if (searchResult.type === "no-units-found") {
        return [
          { ...state, systemStates: updateSystemStates(state.systemStates, systemId, { type: "no-units-found" }) },
        ];
      }
      const suitableAirUnits = Materials.getSuitableAirUnits(
        sharedState.market,
        projectState.materialTables,
        searchResult.units,
        requiredAirflow
      );
      if (suitableAirUnits.length === 0) {
        return [
          { ...state, systemStates: updateSystemStates(state.systemStates, systemId, { type: "no-units-found" }) },
        ];
      }
      return [
        {
          ...state,
          systemStates: updateSystemStates(state.systemStates, systemId, {
            type: "selecting-unit",
            suitableAirUnits,
            requiredAirflow,
          }),
        },
      ];
    }

    case "SelectUnitDone": {
      return [{ ...state, systemStates: updateSystemStates(state.systemStates, action.systemId, undefined) }];
    }

    case "ToggleExpanded": {
      const newExpanded = new Set<string>(state.expandedItems);
      if (newExpanded.has(action.id)) {
        newExpanded.delete(action.id);
      } else {
        newExpanded.add(action.id);
      }
      return [{ ...state, expandedItems: newExpanded }];
    }

    case "OpenLightbox": {
      return [{ ...state, openLightbox: { isOpen: action.isOpen, imgUrl: action.imgUrl } }];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

function requestSaveUnitsAction(
  systemId: string,
  state: State,
  projectState: ProjectState.State
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const system = projectState.project.systems.find((s) => s.id === systemId);
  if (!system) {
    return [state];
  }
  if (isBusyState(state.systemStates.get(system.id), projectState.materialsState[system.id])) {
    return [state];
  }
  const calcState = projectState.calculationStates[system.id];
  const calculationResult = calcState?.result;
  const calculationValidation = calcState?.validation;
  const requiredAirflow = calculationResult?.requiredAirflow;
  const supplyAirflow = calculationResult?.supplyAirflow;
  const extractAirflow = calculationResult?.extractAirflow;
  if (!calculationValidation || !supplyAirflow || !extractAirflow || !requiredAirflow) {
    return [state];
  }
  const request = SelectionToolApi.createRequest({ supplyAirflow, extractAirflow });
  return [
    { ...state, systemStates: updateSystemStates(state.systemStates, system.id, { type: "searching-unit" }) },
    HttpFetch.post({}, request.url, "json", request.contentType, request.body, (data) =>
      Action.SaveUnitsReceived(system.id, requiredAirflow, SelectionToolApi.mapResponse(data))
    ),
  ];
}

function updateSystemStates(
  systemStates: ReadonlyMap<string, SystemState>,
  systemId: string,
  newState: SystemState | undefined
): ReadonlyMap<string, SystemState> {
  const newStates = new Map(systemStates);
  if (newState) {
    newStates.set(systemId, newState);
  } else {
    newStates.delete(systemId);
  }
  return newStates;
}
