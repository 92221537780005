import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { SharedState, Routes } from "@rvs/client-infra";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import * as ProjectState from "../../project-state";

// STATE

export interface State {}

export const Action = ctorsUnion({
  Dummy: () => ({}),
});

export type Action = CtorsUnion<typeof Action>;

export function init(
  _location: Routes.ProjectLocation,
  _sharedState: SharedState.SharedState,
  prevState: State | undefined
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  return [prevState || {}];
}

export function update(
  action: Action,
  state: State,
  _projectState: ProjectState.State,
  _sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "Dummy": {
      return [state];
    }
    default:
      return exhaustiveCheck(action.type, true);
  }
}
