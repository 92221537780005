/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { Texts, Project, Calculators, UserSettings } from "@rvs/shared";
import { convertTo, Quantities, Quantity, Units, UnitTypes } from "@rvs/shared/src/units";
import { selectUnit } from "@rvs/shared/src/user-settings";
import { texts } from "@rvs/shared/src/lang-texts";
import * as GQLOps from "../../../../../generated/generated-operations";
import { Icon, NumberField, NumberValue, Selector, Textfield } from "../../../../../elements";
import * as ProjectState from "../../../project-state";
import { Action } from "../state";

const ProjectAction = ProjectState.Action;

type ValueOf<T> = T[keyof T];

type RoomProperty = keyof Project.Room;
type UpdateRoomFn = (property: RoomProperty, value: Project.Room[RoomProperty]) => void;
type ClearOverrideFn = (property: RoomProperty) => void;

export function Room({
  onDragStart,
  onDragEnter,
  onDragEnd,
  onDragOver,
  systemId,
  room,
  dispatch,
  dispatchProject,
  translate,
  isReadonly,
  roomFloorTable,
  roomTemplatesTable,
  marketValveTypesTable,
  market,
  userSettings,
  expandedItems,
  isFirst,
  errors,
  fields,
  availableColumns,
  isNewRoom,
  isMoving,
}: {
  readonly onDragStart: () => void;
  readonly onDragEnter: () => void;
  readonly onDragEnd: () => void;
  readonly onDragOver: () => void;
  readonly systemId: string;
  readonly dispatch: Dispatch<Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly translate: Texts.TranslateFn;
  readonly room: GQLOps.Room;
  readonly isReadonly: boolean;
  readonly roomFloorTable: Project.RoomFloorTable;
  readonly roomTemplatesTable: Project.RoomTemplatesTable;
  readonly marketValveTypesTable: Project.MarketValveTypesTable;
  readonly market: string;
  readonly userSettings: UserSettings.UserSettings;
  readonly expandedItems: ReadonlySet<string>;
  readonly isFirst: boolean;
  readonly errors: ReadonlyArray<Project.RoomError>;
  readonly fields: Calculators.RoomFields;
  readonly availableColumns: ReadonlySet<keyof Project.Room>;
  readonly isNewRoom: boolean;
  readonly dragging?: boolean;
  readonly isMoving?: boolean;
}): JSX.Element {
  const fieldByName = new Map(fields.map((f) => [f.field, f]));

  const updateRoom: UpdateRoomFn = (property, value) => {
    const projectPatch = Project.updateRoom(roomTemplatesTable, market, room, property, value, translate);
    const field = fieldByName.get(property);
    const onChangePatch = field?.onChange ? field.onChange(value) : {};
    dispatchProject(ProjectAction.UpdateRoom(systemId, { ...projectPatch, ...onChangePatch }));
  };

  const isOverridden = (property: RoomProperty): boolean => {
    const field = fieldByName.get(property);
    return !!field?.clearOverride && !!field.clearOverride(room);
  };

  const clearOverride: ClearOverrideFn = (property) => {
    const field = fieldByName.get(property);
    if (!field?.clearOverride || !field.clearOverride(room)) {
      return;
    }
    dispatchProject(ProjectAction.UpdateRoom(systemId, { id: room.id, ...field.clearOverride(room) }));
  };

  const getError = (property: RoomProperty): string | undefined => {
    const message = errors.find((e) => e.property === property)?.message;
    return message && translate(message);
  };

  const getRoomValue = <T extends string | number>(property: RoomProperty): T | undefined => {
    return (room[property] ?? undefined) as T;
  };

  const translateLabel = (property: RoomProperty): string => translate(Texts.texts.property(property));
  const options = getSelectorOptions(roomFloorTable, roomTemplatesTable, marketValveTypesTable, market);
  const isExpanded = expandedItems.has(room.id);
  const thickBorder = isFirst && !isExpanded;

  const inputTypes = [
    ["roomType", "selector"],
    ["name", "text"],
    ["airType", "text_key"],
    ["floorType", "selector"],
    ["supplyAirFactor", "number"],
    ["airflow", "number", Units.CubicMeterPerHour, selectUnit(userSettings, Quantity.Airflow), Quantity.Airflow],
    ["ceilingHeight", "number", Units.Meter, selectUnit(userSettings, Quantity.Length), Quantity.Length],
    ["floorArea", "number", Units.SquareMeter, selectUnit(userSettings, Quantity.Area), Quantity.Area],
    ["width", "number", Units.Meter, selectUnit(userSettings, Quantity.Length), Quantity.Length],
    ["length", "number", Units.Meter, selectUnit(userSettings, Quantity.Length), Quantity.Length],
    ["volume", "number", Units.CubicMeter, selectUnit(userSettings, Quantity.Volume), Quantity.Volume],
    ["people", "number"],
    ["valves", "number"],
    ["ducts", "number"],
    ["valveType", "selector"],
  ].filter(([p]) => availableColumns.has(p as RoomProperty)) as unknown as ReadonlyArray<
    readonly [RoomProperty, string, UnitTypes | undefined, UnitTypes | undefined, Quantities | undefined]
  >;
  return (
    <React.Fragment>
      <tbody className={isMoving ? "border-l-2 opacity-0 hover:opacity-100 " : "opacity-100"}>
        <tr
          draggable
          onDragStart={onDragStart}
          onDragEnter={onDragEnter}
          onDragEnd={onDragEnd}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDragOver();
          }}
          key="row"
          className={
            isExpanded
              ? "bg-gray-100 border-2 border-gray-200"
              : isNewRoom
              ? "newRoomHighlight dragInfo sorting dragging"
              : "dragInfo sorting dragging "
          }
        >
          <td key={"arrow"} className="border-none relative">
            <div className="hide absolute" title={translate(texts.drag_and_drop_info_room)}>
              <Icon className="mx-8 w-16 text-base absolute left-[-16px]" icon="arrow-up-arrow-down" size={"1x"} />
            </div>
            <Icon
              icon={isExpanded ? "chevron-down" : "chevron-right"}
              className="ml-8 mr-4 w-16"
              onClick={() => dispatch(Action.ToggleExpanded(room.id))}
            />
          </td>

          {inputTypes.map(([property, inputType, fromUnit, toUnit, quantity]) => {
            switch (inputType) {
              case "text_key":
                return (
                  <TextTd
                    key={property}
                    value={getRoomValue(property)}
                    errorMessage={getError(property)}
                    thickBorder={thickBorder}
                    field={fieldByName.get(property)}
                    translateValue={true}
                    translate={translate}
                  />
                );
              case "text":
                return (
                  <TextInput
                    key={property}
                    value={getRoomValue(property)}
                    onChange={(v) => updateRoom(property, v)}
                    readOnly={isReadonly}
                    errorMessage={getError(property)}
                    thickBorder={thickBorder}
                    field={fieldByName.get(property)}
                  />
                );
              case "selector":
                return (
                  <SelectorInput
                    key={property}
                    value={getRoomValue(property)}
                    options={options[property] || []}
                    onChange={(v) => updateRoom(property, v)}
                    readOnly={isReadonly}
                    errorMessage={getError(property)}
                    translate={translate}
                    thickBorder={thickBorder}
                    field={fieldByName.get(property)}
                  />
                );
              case "number":
                return (
                  <NumberInput
                    key={property}
                    value={
                      fromUnit && toUnit && quantity
                        ? convertTo(
                            getRoomValue<number>(property),
                            fromUnit as ValueOf<typeof quantity.units>,
                            toUnit as ValueOf<typeof quantity.units>
                          )
                        : getRoomValue<number>(property)
                    }
                    clearOverride={isOverridden(property) ? () => clearOverride(property) : undefined}
                    onChange={(v) => {
                      updateRoom(
                        property,
                        fromUnit && toUnit && quantity
                          ? convertTo(
                              v,
                              toUnit as ValueOf<typeof quantity.units>,
                              fromUnit as ValueOf<typeof quantity.units>
                            )
                          : v
                      );
                    }}
                    readOnly={isReadonly}
                    errorMessage={getError(property)}
                    thickBorder={thickBorder}
                    field={fieldByName.get(property)}
                  />
                );
              default:
                return <td key={property} />;
            }
          })}

          <td key={"remove"} className={`p-8 ${thickBorder ? "border-t-2" : "border-t-1"}`}>
            <Icon
              key={"remove"}
              icon={"trash-alt"}
              disabled={isReadonly}
              onClick={() => {
                dispatchProject(ProjectAction.RemoveRoom(systemId, room!.id));
              }}
              message={translate(Texts.texts.remove_room)}
            />
          </td>
          <td key={"copy"} className={`p-8 ${thickBorder ? "border-t-2" : "border-t-1"}`}>
            <Icon
              icon={"copy"}
              key={"duplicate"}
              disabled={isReadonly}
              onClick={() => {
                dispatchProject(ProjectAction.DuplicateRoom(systemId, room!.id));
              }}
              message={translate(Texts.texts.duplicate_room)}
            />
          </td>
        </tr>

        {isExpanded ? (
          <tr className="border-2 border-gray-200 h-500 relative ">
            <td></td>
            <td>
              <div className="absolute flex -mt-24">
                <TextInputLabel
                  value={getRoomValue("building")}
                  onChange={(v) => updateRoom("building", v)}
                  readOnly={isReadonly}
                  label={translateLabel("building")}
                  errorMessage={getError("building")}
                />
                <TextInputLabel
                  value={getRoomValue("floor")}
                  onChange={(v) => updateRoom("floor", v)}
                  readOnly={isReadonly}
                  label={translateLabel("floor")}
                  errorMessage={getError("floor")}
                />
                <TextInputLabel
                  value={getRoomValue("apartment")}
                  onChange={(v) => updateRoom("apartment", v)}
                  readOnly={isReadonly}
                  label={translateLabel("apartment")}
                  errorMessage={getError("apartment")}
                />
              </div>
              <div className="absolute top-12 right-12">
                <Icon icon={"times"} onClick={() => dispatch(Action.ToggleExpanded(room.id))} />
              </div>
            </td>
          </tr>
        ) : (
          []
        )}
      </tbody>
    </React.Fragment>
  );
}

function TextInput({
  value,
  onChange,
  readOnly,
  errorMessage,
  thickBorder,
  field,
}: {
  readonly value: string | undefined;
  readonly onChange?: (value: string) => void;
  readonly readOnly?: boolean;
  readonly errorMessage?: string;
  readonly thickBorder?: boolean;
  readonly field: Calculators.RoomField | undefined;
}): JSX.Element {
  if (field?.hidden) {
    return <td className={thickBorder ? "border-t-2" : ""} />;
  } else if (field?.readOnly) {
    return <TextTd value={value} errorMessage={errorMessage} thickBorder={thickBorder} field={field} />;
  } else {
    return (
      <td className={thickBorder ? "border-t-2" : ""}>
        <div
          draggable
          onDragStart={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          className="px-4 w-fixed-700"
        >
          <Textfield
            readOnly={readOnly}
            value={value || ""}
            errorMessage={errorMessage}
            onChange={(v) => onChange && onChange(v)}
            debounce={true}
            debounceTime={500}
          />
        </div>
      </td>
    );
  }
}

function TextTd({
  value,
  errorMessage,
  thickBorder,
  field,
  translateValue,
  translate,
}: {
  readonly value: string | undefined;
  readonly errorMessage?: string;
  readonly thickBorder?: boolean;
  readonly field: Calculators.RoomField | undefined;
  readonly translateValue?: boolean;
  readonly translate?: Texts.TranslateFn;
}): JSX.Element {
  if (field?.hidden) {
    return <td className={thickBorder ? "border-t-2" : ""} />;
  } else {
    const translatedValue = translateValue && translate && value ? translate(Texts.key(value)) : value;
    return (
      <td className={thickBorder ? "border-t-2" : ""}>
        <div className="px-4 max-w-85">
          <div title={errorMessage}>{translatedValue || "-"}</div>
        </div>
      </td>
    );
  }
}

function NumberInput({
  value,
  onChange,
  readOnly,
  errorMessage,
  thickBorder,
  field,
  decimals,
  clearOverride,
}: {
  readonly value: number | null | undefined;
  readonly onChange?: (value: number) => void;
  readonly clearOverride: (() => void) | undefined;
  readonly readOnly: boolean;
  readonly errorMessage?: string;
  readonly thickBorder?: boolean;
  readonly field: Calculators.RoomField | undefined;
  readonly decimals?: number;
}): JSX.Element {
  const numDecimals = field?.decimals ?? decimals ?? Project.roundingDecimals;
  if (field?.hidden) {
    return <td className={thickBorder ? "border-t-2" : ""} />;
  } else if (field?.readOnly) {
    return (
      <NumberTd value={value ?? null} errorMessage={errorMessage} thickBorder={thickBorder} decimals={numDecimals} />
    );
  } else {
    return (
      <td className={thickBorder ? "border-t-2" : ""}>
        <div
          draggable
          onDragStart={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          className="max-w-85"
        >
          <NumberField
            readOnly={readOnly}
            value={value ?? undefined}
            errorMessage={errorMessage}
            onChange={(v) => (onChange ? onChange(v) : undefined)}
            decimals={numDecimals}
            showSpinButton={field?.spinButton}
            onClearOverride={clearOverride && (() => clearOverride())}
          />
        </div>
      </td>
    );
  }
}

function NumberTd({
  value,
  errorMessage,
  thickBorder,
  decimals = Project.roundingDecimals,
}: {
  readonly value: number | null;
  readonly errorMessage?: string;
  readonly thickBorder?: boolean;
  readonly decimals?: number;
}): JSX.Element {
  return (
    <td className={thickBorder ? "border-t-2" : ""}>
      <div className="max-w-85">
        <NumberValue
          errorMessage={errorMessage}
          value={value}
          decimals={decimals}
          className={"w-full inline-block px-4"}
        />
      </div>
    </td>
  );
}

function TextInputLabel({
  value,
  onChange,
  readOnly,
  errorMessage,
  label,
}: {
  readonly value: string | undefined;
  readonly onChange: (value: string) => void;
  readonly readOnly: boolean;
  readonly errorMessage?: string;
  readonly label?: string;
}): JSX.Element {
  return (
    <div className="px-4 mr-12 w-700">
      {label ? <div>{label}</div> : []}
      <Textfield
        readOnly={readOnly}
        value={value || ""}
        errorMessage={errorMessage}
        onChange={(v) => onChange(v)}
        debounce={true}
        debounceTime={500}
      />
    </div>
  );
}

function SelectorInput({
  value,
  options,
  onChange,
  readOnly,
  errorMessage,
  translate,
  thickBorder,
  field,
}: {
  readonly value: string | undefined;
  readonly options: ReadonlyArray<SelectorOption>;
  readonly onChange: (value: string) => void;
  readonly readOnly: boolean;
  readonly errorMessage?: string;
  readonly translate: Texts.TranslateFn;
  readonly thickBorder?: boolean;
  readonly field: Calculators.RoomField | undefined;
}): JSX.Element {
  const selectorOptions = options.map((o) => ({
    id: o.id,
    name: o.textKey ? translate(Texts.key(o.textKey)) : "",
  }));
  if (field?.hidden) {
    return <td className={thickBorder ? "border-t-2" : ""} />;
  } else {
    return (
      <td className={thickBorder ? "border-t-2" : ""}>
        <div
          draggable
          onDragStart={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          className="px-4"
        >
          <Selector
            readOnly={readOnly}
            options={selectorOptions}
            value={selectorOptions.find((o) => o.id === value) || { id: value || "", name: value || "" }}
            errorMessage={errorMessage}
            onChange={(o) => onChange(o.id)}
          />
        </div>
      </td>
    );
  }
}

type SelectorOption = {
  readonly id: string;
  readonly textKey: string;
};

type SelectorOptions = {
  readonly [property: string]: ReadonlyArray<SelectorOption>;
};

function getSelectorOptions(
  roomFloorTable: Project.RoomFloorTable,
  roomTemplatesTable: Project.RoomTemplatesTable,
  marketValveTypesTable: Project.MarketValveTypesTable,
  market: string
): SelectorOptions {
  return {
    roomType: roomTemplatesTable
      .filter((t) => t.market === market)
      .map((t) => ({ id: t.key || "", textKey: t.key || "" })),
    floorType: roomFloorTable.map((t) => ({ id: t.key || "", textKey: t.key || "" })),
    valveType: marketValveTypesTable
      .filter((r) => r.market_name === market)
      .map((r) => ({ id: r.type || "", textKey: r.name || "" })),
  };
}
