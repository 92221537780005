import { Project, System } from "./types";

export function getNextSystemSortNo(project: Project): number {
  return Math.max(...project.systems.map((s) => s.sortNo), 0) + 1;
}

export function getValveTypes(system: System): ReadonlyArray<string> {
  const set = new Set<string>();
  for (const room of system.rooms) {
    if (room.valveType) {
      set.add(room.valveType);
    }
  }
  return Array.from(set).sort();
}

export function getValveCount(system: System, valveType: string | undefined): number | undefined {
  return system.rooms.reduce<number | undefined>((sofar, room) => {
    if (room.valves !== null && (room.valveType === valveType || valveType === undefined)) {
      return (sofar || 0) + room.valves;
    } else {
      return sofar;
    }
  }, undefined);
}
