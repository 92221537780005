import React from "react";
import { Texts, Project, UserSettings } from "@rvs/shared";
import { selectUnit } from "@rvs/shared/src/user-settings";
import { Quantity, UnitTypes } from "@rvs/shared/src/units";

type RoomProperty = keyof Project.Room;

export function RoomHeader({
  translate,
  availableColumns,
  userSettings,
}: {
  readonly translate: Texts.TranslateFn;
  readonly availableColumns: ReadonlySet<RoomProperty>;
  readonly userSettings: UserSettings.UserSettings;
}): JSX.Element {
  const translateLabel = (property: RoomProperty): string => translate(Texts.texts.property(property));
  const lables = [
    ["roomType"],
    ["name"],
    ["airType"],
    ["floorType"],
    ["supplyAirFactor"],
    ["airflow", selectUnit(userSettings, Quantity.Airflow)],
    ["ceilingHeight", selectUnit(userSettings, Quantity.Length)],
    ["floorArea", selectUnit(userSettings, Quantity.Area)],
    ["width", selectUnit(userSettings, Quantity.Length)],
    ["length", selectUnit(userSettings, Quantity.Length)],
    ["volume", selectUnit(userSettings, Quantity.Volume)],
    ["people"],
    ["valves"],
    ["ducts"],
    ["valveType"],
  ].filter(([l]) => availableColumns.has(l as RoomProperty)) as unknown as ReadonlyArray<
    [RoomProperty, UnitTypes | undefined]
  >;
  return (
    <thead className="">
      <tr>
        <th key="empty" className="border-none"></th>
        {lables.map(([l, unit]) => (
          <th key={l} className="px-4 border-none">
            {translateLabel(l)} {unit ? `(${unit.unitName})` : ""}
          </th>
        ))}
      </tr>
    </thead>
  );
}
